import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { connect } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

class BarChart extends PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {

    let { chartLabels, chartData, chartTitle, isSkipYaxis, maintainAspectRatio } = this.props
    const options = {
      indexAxis: 'y',
      maintainAspectRatio: maintainAspectRatio,
      scales: {
        x: {
          suggestedMin: 0,
          suggestedMax: 100,
          grid: {
            display: false,
          },
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value) {
              return value + '%';
            }
          }
        },
        y: {
          ticks:{
            autoSkip: isSkipYaxis,
            beginAtZero: true
          },
          grid: {
            display: false
          }
        },
      },
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: chartTitle || null,
        },
        legend: {
          display: false
       },
        tooltip: {
          callbacks: {
            label: (context) => context.raw + '%'
          }
        }
      },
    };

    const data = {
      labels: chartLabels,
      datasets: [
        {
          label: '',
          data: chartData || [50, 25, 75, 15, 18, 25, 88],
          fill: false,
          backgroundColor: ['rgba(54, 162, 235)'],
          borderWidth: 0
        }
      ],
    };


    return <div style={{height:options.maintainAspectRatio? "" :"700px"}}>
      <Bar options={options} data={data} />
    </div>
  }
}

BarChart.propTypes = {
  chartLabels: PropTypes.array,
  chartData: PropTypes.array,
  chartTitle: PropTypes.string,

};
BarChart.defaultProps = {
  chartLabels: [],
  chartData: [],
  chartTitle: '',
};

export default connect()(BarChart);