import React from "react";
import ViewPlayerPage from "./ViewPlayerPage";
import DemoPagePage from "./DemoPagePage";
import { Box, Divider, Typography } from "@material-ui/core";
import { Image } from "../modules/Media";
import { IMAGES } from "../assets";

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: { xs: "column", md: "row" },
        overflowY: "auto",
        height: "100%",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: {
            xs: "2 2 0",
            sm: "0.5 0.5 auto",
            md: "0 0 30%",
            lg: "0 0 40%",
          },
          backgroundColor: "black",
          color: "white",
          maxHeight: {
            xs: "97px",
            sm: "92px",
            md: "100%",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "45px 0", md: 0, lg: "0", xl: "20px" },
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: { xs: "19px", sm: "15px", md: "20px" },
            right: { xs: 0, sm: 0, md: "30px" },
            margin: { xs: "0 auto", sm: "0 auto", md: 0 },
            left: { xs: 0, sm: "0", md: "unset" },
            display: "flex",
            justifyContent: "center",
            height: "46px",
            width: "140px",
            cursor: "pointer",
          }}
          //   onClick={handleLogoClick}
        >
          <Image
            alt="CoachTools Logo"
            src={process.env.REACT_APP_LOGO}
            sx={{ height: "46px", width: "140px" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          // backgroundColor: "white",
          color: "black",
          display: "flex",
          flexDirection: "column",
          position: "static",
          paddingBottom: { xs: "50px", md: "0" },
          paddingTop: { xs: "20px", md: "0" },
          paddingLeft: { xs: "20px", sm: "20px", md: 0 },
          paddingRight: { xs: "20px", sm: "20px", md: 0 },
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            left: { xs: "0px", md: "20px" },
            top: { xs: "0px", md: "53px" },
            textAlign: { xs: "center", md: "left" },
            marginBottom: "20px",
            marginTop: { xs: "15px", md: "0" },
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "#2f3244",
              fontWeight: 600,
              fontFamily: "Poppins, sans-serif",
              marginTop: "-15px",
            }}
          >
            Page Not Found
          </Typography>
        </Box>
      </Box>

      {/* New Box Centered on the Screen */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#ffffff",
          padding:  "20px", // Adjust padding based on screen size
          borderRadius: "8px",
          boxShadow: "rgb(0 0 0 / 21%) 0px 0px 3px 1px, 0 4px 20px rgba(0, 0, 0, 0.2)", // Combined boxShadow styles
          width: { xs: "100%", sm: "100%", md: "auto" },
          maxWidth: { xs: "96%", sm: "95%", md: "830px" }, // Updated maxWidth for larger screens/ Translate only for larger screens
        }}
        
      >
        <Box style={{ width: "100%" }}>
          <Typography
            variant="body1"
            style={{
              textAlign: "center",
              fontFamily: "Poppins, sans-serif",
              fontSize: "16px",
              fontWeight: "400",
              color: "#333",
              margin: "20px 0",
            }}
          >
            <h3 style={{ fontWeight: 600 }}>
              Whoops. Looks like you ran a wrong route. The page you are looking
              for no longer exists.
            </h3>
            <br />
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "auto",
            width: "100%",
            marginTop: "100px",
          }}
        >
          <Divider
            style={{
              backgroundColor: "#eeeeee",
              width: "100%",
              margin: "-38px 0",
            }}
          />

          <img
            alt="CoachTools Logo"
            src={IMAGES.Blacklogo}
            style={{
              width: "145px",
              margin: "0 auto",
              background: "white",
              border: "5px solid #fff",
              cursor: "pointer",
            }}
            //   onClick={handleLogoClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
