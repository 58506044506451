import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { DivBlock, LinkBlock, List, ListItem } from "../modules/Basic";
import { Span } from "../modules/Typography";
import LeftNavPersonIcon from "../assets/images/LeftNavLogo.png";
import { toggleClassInbody } from "../utils/function";
class PlayerSideNavComponent extends Component {
  render() {
    //let { row, index, errors } = this.props;
    return (
      <Fragment>
        <DivBlock
		      className={"sidenav sidenav-padding"}
          context={{
            elements: {
              5036: { type: "Body" },
              5037: { type: "DivBlock" },
              5038: { type: "DivBlock" },
              5049: { type: "DivBlock" },
              5050: { type: "DivBlock" },
              5064: { type: "DivBlock" },
              5065: { type: "DivBlock" },
              5066: { type: "DivBlock" },
              5067: { type: "DivBlock" },
              5068: { type: "DivBlock" },
              5069: { type: "DivBlock" },
              5070: { type: "Heading" },
              5071: { type: "table" },
              5072: { type: "thead" },
              5073: { type: "tr" },
              5074: { type: "th" },
              5075: { type: "Span" },
              5076: { type: "th" },
              5077: { type: "Span" },
              5078: { type: "th" },
              5079: { type: "Span" },
              5080: { type: "th" },
              5081: { type: "Span" },
              5082: { type: "th" },
              5083: { type: "Span" },
              5084: { type: "th" },
              5085: { type: "Span" },
              5086: { type: "th" },
              5087: { type: "Span" },
              5088: { type: "tbody" },
              5089: { type: "tr" },
              5090: { type: "td" },
              5091: { type: "Span" },
              5092: { type: "td" },
              5093: { type: "Span" },
              5094: { type: "td" },
              5095: { type: "DivBlock" },
              5096: { type: "Span" },
              5097: { type: "td" },
              5098: { type: "DivBlock" },
              5099: { type: "Span" },
              5100: { type: "td" },
              5101: { type: "DivBlock" },
              5102: { type: "Span" },
              5103: { type: "td" },
              5104: { type: "DivBlock" },
              5105: { type: "Span" },
              5106: { type: "td" },
              5107: { type: "DivBlock" },
              5108: { type: "Span" },
              5406: { type: "List" },
              5407: { type: "ListItem" },
              5408: { type: "LinkBlock" },
              5409: { type: "DivBlock" },
              5410: { type: "Image" },
              5411: { type: "Span" },
              5412: { type: "ListItem" },
              5413: { type: "LinkBlock" },
              5414: { type: "DivBlock" },
              5415: { type: "Image" },
              5416: { type: "Span" },
              5417: { type: "ListItem" },
              5418: { type: "LinkBlock" },
              5419: { type: "DivBlock" },
              5420: { type: "Image" },
              5421: { type: "Span" },
            },
            getters: {},
            listeners: [
              { name: "mouseOver.boundaries" },
              { name: "mouseOut.boundaries" },
              { name: "dragEnter" },
              { name: "dragLeave" },
              { name: "dragEnd" },
              { name: "mouseDown.mousemenu" },
              { name: "showSettingsBox.desk" },
              { name: "textChanged.desk" },
              { name: "editTextMode.desk" },
              { name: "componentClick.desk" },
              { name: "pathnameChanged.desk" },
              { name: "cut.desk" },
              { name: "copy.desk" },
              { name: "create_prefab.desk" },
              { name: "create_partial.desk" },
              { name: "editSnippet.desk" },
              { name: "clone.desk" },
              { name: "delete.desk" },
              { name: "before.desk" },
              { name: "after.desk" },
              { name: "first.desk" },
              { name: "last.desk" },
              { name: "replace.desk" },
              { name: "select.desk" },
              { name: "highlight.desk" },
              { name: "dropZoneHover" },
              { name: "dragEnd" },
              { name: "dragStart" },
              { name: "copyStyleProps" },
              { name: "pasteStyleProps" },
              { name: "updateSelectedProps" },
              { name: "dragStart" },
            ],
            triggerCache: {
              after: [{ name: "after.desk" }],
              before: [{ name: "before.desk" }],
              componentClick: [{ name: "componentClick.desk" }],
              copy: [{ name: "copy.desk" }, { name: "copyStyleProps" }],
              create_prefab: [{ name: "create_prefab.desk" }],
              cut: [{ name: "cut.desk" }],
              dragEnd: [{ name: "dragEnd" }, { name: "dragEnd" }],
              dragEnter: [{ name: "dragEnter" }],
              dragStart: [{ name: "dragStart" }, { name: "dragStart" }],
              dropZoneHover: [{ name: "dropZoneHover" }],
              editTextMode: [{ name: "editTextMode.desk" }],
              first: [{ name: "first.desk" }],
              mouseDown: [{ name: "mouseDown.mousemenu" }],
              mouseOut: [{ name: "mouseOut.boundaries" }],
              mouseOver: [{ name: "mouseOver.boundaries" }],
              pathnameChanged: [{ name: "pathnameChanged.desk" }],
              replace: [{ name: "replace.desk" }],
              "showSettingsBox.desk": [{ name: "showSettingsBox.desk" }],
              textChanged: [{ name: "textChanged.desk" }],
            },
          }}
          df-snippet-instance-id={302}
          draggable={true}
          eleKey={"5050"}
          inline={{ "df-snippet-instance-id": 302, draggable: true }}
          isEdit={true}
          root-snippet={true}
          style={{
            backgroundColor: "#2a3142",
            fontSize: "18px",
            width: "205px",
          }}
        >
          <List
            df-snippet-instance-id={302}
            style={{ marginBottom: "0px", marginTop: "0px" }}
            type={"none"}
          >
            <ListItem df-snippet-instance-id={302}>
              <LinkBlock
                className={"a sidenav"}
                df-snippet-instance-id={302}
                duration={1000}
                href={"/player_depth_charts"}
                target={"_self"}
                type={"spa"}
              >
                <DivBlock
                  df-snippet-instance-id={302}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  onClick={()=>toggleClassInbody("toggle-manu")}
                >
                  {/* <Image className={"side-img"} df-snippet-instance-id={302} src={`https://dittofipublicfiles.s3.us-west-2.amazonaws.com/488/${LeftNavPersonIcon}`} >
</Image> */}
                  <img
                    className={"side-img"}
                    df-snippet-instance-id={302}
                    src={LeftNavPersonIcon}
                    alt={LeftNavPersonIcon}
                  />

                  <Span className={"side-text"} df-snippet-instance-id={302}>
                    Depth Charts
                  </Span>
                </DivBlock>
              </LinkBlock>
            </ListItem>

            <ListItem df-snippet-instance-id={302}>
              <LinkBlock
                className={"a sidenav"}
                df-snippet-instance-id={302}
                duration={1000}
                href={"/player_grades"}
                target={"_self"}
                type={"spa"}
              >
                <DivBlock
                  df-snippet-instance-id={302}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  onClick={()=>toggleClassInbody("toggle-manu")}
                >
                  <img
                    className={"side-img"}
                    df-snippet-instance-id={302}
                    src={LeftNavPersonIcon}
                    alt={LeftNavPersonIcon}
                  />

                  {/* <Image className={"side-img"} df-snippet-instance-id={302} src={"https://dittofipublicfiles.s3.us-west-2.amazonaws.com/488/1049-imageedit_6_3588709665.png"} >
</Image> */}

                  <Span className={"side-text"} df-snippet-instance-id={302}>
                    Grades
                  </Span>
                </DivBlock>
              </LinkBlock>
            </ListItem>

            <ListItem df-snippet-instance-id={302}>
              <LinkBlock
                className={"a sidenav"}
                df-snippet-instance-id={302}
                duration={1000}
                href={"/player_exercises"}
                target={"_self"}
                type={"spa"}
              >
                <DivBlock
                  df-snippet-instance-id={302}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  onClick={()=>toggleClassInbody("toggle-manu")}
                >
                  <img
                    className={"side-img"}
                    df-snippet-instance-id={302}
                    src={LeftNavPersonIcon}
                    alt={LeftNavPersonIcon}
                  />

                  {/* <Image className={"side-img"} df-snippet-instance-id={302} src={"https://dittofipublicfiles.s3.us-west-2.amazonaws.com/488/1049-imageedit_6_3588709665.png"} >
</Image> */}

                  <Span
                    className={"side-text"}
                    df-snippet-instance-id={302}
                    style={{}}
                  >
                    Training
                  </Span>
                </DivBlock>
              </LinkBlock>
            </ListItem>
          </List>
        </DivBlock>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(PlayerSideNavComponent);
