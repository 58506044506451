import React from 'react'
import { Label } from './Forms'

const CustomCheckbox = ({name, value, handleChange, label, ...props }) => {
    return (
        <>
            <input type="checkbox" {...props}
                name={name}
                onChange={handleChange}
                value={value}
                checked={!!value}
                defaultChecked={!!value}
            />
            <Label>{label}</Label>
        </>
    )
}

export default CustomCheckbox