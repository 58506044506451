import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock, LinkBlock } from "../modules/Basic";
import { FormBlock, Label, SelectDropDown, TextInput } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { startPageTimer, trackPageDuration } from "../utils/function";

class AddDepthChartPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      startTime: null,
    };
  }
  
  componentDidMount(){
  startPageTimer('Add Depth Chart');
  this.setState({ startTime: Date.now() });
  }


  componentWillUnmount(){
  trackPageDuration('Add Depth Chart',this.state.startTime)
  }

  render() {
    //let { row, index, errors } = this.props;
    let { FormCreateDepthChartErrors, NavBarDropDownIndex } = this.props;
    const { Weeks } = window;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 0 : 0,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                }}
              >
                <DivBlock
                  style={{
                    backgroundColor: "#f8f8fb",
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                  actions={[
                    {
                      action: "Get Users",
                      trigger: "onload",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {},
                    },
                  ]}
                >
                  <DivBlock style={{ backgroundColor: "#f8f8fb" }}>
                    <DivBlock className={"body-main"}>
                      <Columns
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Column
                          lg={6}
                          md={6}
                          sm={12}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          xs={12}
                        >
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              <DivBlock className={"text-center"}>
                                <Heading
                                  className={"h1"}
                                  style={{ fontWeight: "500",}}
                                  type={"h1"}
                                >
                                  Add Depth Chart
                                </Heading>
                              </DivBlock>

                              <FormBlock
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                                actions={[
                                  {
                                    action: "Create Depth Chart",
                                    trigger: "onsubmit",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Title</Label>

                                  <TextInput
                                    className={"form-control"}
                                    name={"FormCreateDepthChart.title"}
                                    placeholder={""}
                                  ></TextInput>

                                  <If show={true} condition={(FormCreateDepthChartErrors && !!FormCreateDepthChartErrors?.title)}>
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormCreateDepthChartErrors
                                          ? FormCreateDepthChartErrors.title
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>

                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Category</Label>

                                  <SelectDropDown
                                    className={"form-control"}
                                    initialValue={"Offense"}
                                    name={"FormCreateDepthChart.category"}
                                    options={[
                                      { label: "Offense", value: "Offense" },
                                      { label: "Defense", value: "Defense" },
                                      {
                                        label: "Special Teams",
                                        value: "Special Teams",
                                      },
                                    ]}
                                  ></SelectDropDown>

                                  <If show={true} condition={(FormCreateDepthChartErrors && !!FormCreateDepthChartErrors.category)}>
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormCreateDepthChartErrors
                                          ? FormCreateDepthChartErrors.category
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>

                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Week</Label>

                                  <SelectDropDown
                                    className={"form-control"}
                                    dataSet={Weeks()}
                                    dataSetLabel={"name"}
                                    dataSetValue={"id"}
                                    initialValue={"1"}
                                    name={"FormCreateDepthChart.week"}
                                    options={[]}
                                    type={"number"}
                                  ></SelectDropDown>

                                  <If show={true} condition={(FormCreateDepthChartErrors && !!FormCreateDepthChartErrors.week)}>
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormCreateDepthChartErrors
                                          ? FormCreateDepthChartErrors.week
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>

                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Tag</Label>

                                  <TextInput
                                    className={"form-control"}
                                    name={"FormCreateDepthChart.Tags"}
                                    placeholder={""}
                                  ></TextInput>
                                </DivBlock>

                                <DivBlock
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    className={"btn btn-primary"}
                                    actions={[
                                      {
                                        action: "Create Depth Chart",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {},
                                      },
                                    ]}
                                  >
                                    Add
                                  </Button>

                                  <DivBlock style={{ marginLeft: "5px" }}>
                                    <LinkBlock
                                      duration={1000}
                                      href={"/depth_chart_lists"}
                                      target={"_self"}
                                      type={"spa"}
                                    >
                                      <DivBlock
                                        className={"btn btn-reset"}
                                        style={{}}
                                      >
                                        <Span>Cancel</Span>
                                      </DivBlock>
                                    </LinkBlock>
                                  </DivBlock>
                                </DivBlock>
                              </FormBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(AddDepthChartPage);
