import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, DivBlock } from "../modules/Basic";
import { DatePicker } from "../modules/Components";
import { SelectDropDown } from "../modules/Forms";
import { DataSeries, MultipleDataGraph } from "../modules/Graphs";
import { Heading, Paragraph, Span } from "../modules/Typography";

class DemoPagePage extends Component {
  render() {
    let { AverageGrades, Grades, Intangibles, Positions } = this.props;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Intangibles",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Init Game Grades Page",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Intangibles Graph",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: { "user id ": 143 },
            },
          ]}
        >
          <DivBlock
            style={{
              bottom: "0px",
              left: "0px",
              overflow: "auto",
              position: "absolute",
              right: "0px",
              top: "0px",
            }}
          >
            <DivBlock>
              <DivBlock className={"body-main"}>
                <DivBlock>
                  <Heading className={"h2"} type={"h1"}>
                    Game &amp; Practice Grades
                  </Heading>

                  <DivBlock>
                    <Paragraph
                      style={{
                        marginBottom: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: 0,
                      }}
                    >
                      Testing link entry in a paragraph. This is a link?
                    </Paragraph>

                    <DivBlock className={"modal-body"}>
                      <DivBlock className={"modal-body"}>
                        <DivBlock>
                          <DivBlock
                            style={{
                              borderBottomColor: "#dddddd",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "1px",
                              borderColor: "#dddddd",
                              borderLeftColor: "#dddddd",
                              borderLeftStyle: "solid",
                              borderLeftWidth: "1px",
                              borderRightColor: "#dddddd",
                              borderRightStyle: "solid",
                              borderRightWidth: "1px",
                              borderStyle: "solid",
                              borderTopColor: "#dddddd",
                              borderTopStyle: "solid",
                              borderTopWidth: "1px",
                              borderWidth: "1px",
                              marginTop: "0px",
                              paddingTop: "10px",
                            }}
                          >
                            <DivBlock
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                              }}
                            >
                              {Intangibles &&
                                isArray(Intangibles) &&
                                Intangibles.map((row, index) => {
                                  return (
                                    <Fragment>
                                      <DivBlock>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            borderBottomColor: "#dddddd",
                                            borderBottomStyle: "solid",
                                            borderBottomWidth: "1px",
                                            borderColor: "#dddddd",
                                            borderLeftColor: "#dddddd",
                                            borderLeftStyle: "solid",
                                            borderLeftWidth: "1px",
                                            borderRightColor: "#dddddd",
                                            borderRightStyle: "solid",
                                            borderRightWidth: "1px",
                                            borderStyle: "solid",
                                            borderTopColor: "#dddddd",
                                            borderTopStyle: "solid",
                                            borderTopWidth: "1px",
                                            borderWidth: "1px",
                                            display: "flex",
                                            fontFamily:"'Poppins', sans-serif",
                                            marginLeft: "5px",
                                            paddingBottom: "5px",
                                            paddingLeft: "5px",
                                            paddingRight: "5px",
                                            paddingTop: "5px",
                                          }}
                                        >
                                          <Span>{row["name"]}</Span>

                                          <Span
                                            className={"key " + row.color}
                                            style={{}}
                                          >
                                            s
                                          </Span>
                                        </DivBlock>
                                      </DivBlock>
                                    </Fragment>
                                  );
                                })}
                            </DivBlock>

                            <MultipleDataGraph
                              alignChartTitle={"center"}
                              chartTitle={"Chart title here"}
                              chartTitleFontFamily={
                                "Helvetica Neue, Helvetica, Arial, sans-serif"
                              }
                              chartTitleFontSize={30}
                              dataSet={"IntangibleData"}
                              dataSetX={"user_id"}
                              fill={"#35b3ee"}
                              showChartTitle={false}
                              style={{
                                alignItems: "center",
                                display: "flex",
                                fontFamily:"'Poppins', sans-serif",
                                height: "250px",
                                justifyContent: "center",
                              }}
                              xAxisAt={"bottom"}
                              xAxisLabel={"Date"}
                              xClassName={"react-stockcharts-x-axis"}
                              xFill={"none"}
                              xFontFamily={
                                "Helvetica Neue, Helvetica, Arial, sans-serif"
                              }
                              xFontSize={12}
                              xFontWeight={400}
                              xInnerTickSize={5}
                              xOpacity={1}
                              xOrient={"bottom"}
                              xOuterTickSize={0}
                              xShowDomain={true}
                              xShowGrid={true}
                              xShowTickLabel={true}
                              xShowTicks={true}
                              xStroke={"#000000"}
                              xStrokeWidth={1}
                              xTickLabelColor={"black"}
                              xTickPadding={6}
                              xTickStroke={"#000000"}
                              xTickStrokeOpacity={1}
                              xTicks={5}
                              xZoomHeight={25}
                              yAxisAt={"left"}
                              yAxisLabel={"Average Input"}
                              yClassName={"react-stockcharts-y-axis"}
                              yFill={"none"}
                              yFontFamily={
                                "Helvetica Neue, Helvetica, Arial, sans-serif"
                              }
                              yFontSize={12}
                              yFontWeight={400}
                              yInnerTickSize={2}
                              yOpacity={1}
                              yOrient={"left"}
                              yOuterTickSize={0}
                              yShowDomain={true}
                              yShowGrid={true}
                              yShowTickLabel={true}
                              yShowTicks={true}
                              yStroke={"#000000"}
                              yStrokeWidth={1}
                              yTickLabelColor={"black"}
                              yTickPadding={1}
                              yTickStroke={"#000000"}
                              yTickStrokeOpacity={1}
                              yTicks={10}
                              yZoomWidth={25}
                            >
                              {Intangibles &&
                                isArray(Intangibles) &&
                                Intangibles.map((row, index) => {
                                  return (
                                    <Fragment>
                                      <DataSeries
                                        dataSetY={`${row["Id"]}`}
                                        fill={row.color}
                                        type={"line"}
                                      ></DataSeries>
                                    </Fragment>
                                  );
                                })}
                            </MultipleDataGraph>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>

                  <DivBlock>
                    <DivBlock
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        paddingBottom: "0.5rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <DivBlock>
                        <SelectDropDown
                          className={"btn btn-primary"}
                          initialValue={"Game"}
                          name={"FormSearchGradesType"}
                          options={[
                            { label: "Game", value: "Game" },
                            { label: "Practice", value: "Practice" },
                          ]}
                          actions={[
                            {
                              action: "Get Game Grades",
                              trigger: "onchange",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                            {
                              action: "Get Average Game Grades",
                              trigger: "onchange",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        ></SelectDropDown>
                      </DivBlock>

                      <DivBlock
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                      >
                        <SelectDropDown
                          className={"btn btn-primary"}
                          dataSet={Positions}
                          dataSetLabel={"name"}
                          dataSetValue={"Id"}
                          enableReinitialize={true}
                          initialValue={
                            Positions && Positions.length > 0
                              ? Positions[0].Id
                              : 0
                          }
                          name={"FormSearchGradesPosition"}
                          options={[]}
                          type={"number"}
                          actions={[
                            {
                              action: "Get Game Grades",
                              trigger: "onchange",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                            {
                              action: "Get Average Game Grades",
                              trigger: "onchange",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        ></SelectDropDown>
                      </DivBlock>

                      <DatePicker
                        className={"btn btn-primary"}
                        name={"FormSearchGradesDate"}
                        style={{}}
                      ></DatePicker>
                    </DivBlock>

                    <DivBlock
                      actions={[
                        {
                          action: "Get Users",
                          trigger: "onload",
                          timeout: "",
                          pollingPeriod: "",
                          inputVariables: { "intangible id": null },
                        },
                      ]}
                    >
                      <table className={"table redTable"} style={{}}>
                        <thead style={{ display: "block" }}>
                          <tr>
                            <th
                              style={{
                                borderBottomColor: "#ddd",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1px",
                                borderLeftColor: "#ddd",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1px",
                                borderRightColor: "#ddd",
                                borderRightStyle: "solid",
                                borderRightWidth: "1px",
                                borderTopColor: "#ddd",
                                borderTopStyle: "solid",
                                borderTopWidth: "1px",
                                paddingBottom: "0.375rem",
                                paddingLeft: "0.75rem",
                                paddingRight: "0.75rem",
                                paddingTop: "0.375rem",
                              }}
                            >
                              <Span className={"Sorting-span"}>Name</Span>
                            </th>

                            {Intangibles &&
                              isArray(Intangibles) &&
                              Intangibles.map((row, index) => {
                                return (
                                  <Fragment>
                                    <th
                                      style={{
                                        borderBottomColor: "#ddd",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1px",
                                        borderLeftColor: "#ddd",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1px",
                                        borderRightColor: "#ddd",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1px",
                                        borderTopColor: "#ddd",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1px",
                                        paddingBottom: "0.375rem",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "0.375rem",
                                      }}
                                    >
                                      <Span className={"Sorting-span"}>
                                        {row["name"]}
                                      </Span>
                                    </th>
                                  </Fragment>
                                );
                              })}
                          </tr>
                        </thead>

                        <tbody style={{ display: "block" }}>
                          {Grades &&
                            isArray(Grades) &&
                            Grades.map((row, index) => {
                              return (
                                <Fragment>
                                  <tr>
                                    <td
                                      style={{
                                        borderBottomColor: "#ddd",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1px",
                                        borderLeftColor: "#ddd",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1px",
                                        borderRightColor: "#ddd",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1px",
                                        borderTopColor: "#ddd",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1px",
                                        paddingBottom: "0.375rem",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "0.375rem",
                                      }}
                                    >
                                      <Span>{row["name"]}</Span>
                                    </td>

                                    {row["grades"] &&
                                      isArray(row["grades"]) &&
                                      row["grades"].map((row, index) => {
                                        return (
                                          <Fragment>
                                            <td
                                              style={{
                                                borderBottomColor: "#ddd",
                                                borderBottomStyle: "solid",
                                                borderBottomWidth: "1px",
                                                borderLeftColor: "#ddd",
                                                borderLeftStyle: "solid",
                                                borderLeftWidth: "1px",
                                                borderRightColor: "#ddd",
                                                borderRightStyle: "solid",
                                                borderRightWidth: "1px",
                                                borderTopColor: "#ddd",
                                                borderTopStyle: "solid",
                                                borderTopWidth: "1px",
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                              }}
                                            >
                                              <SelectDropDown
                                                dataSetLabel={""}
                                                dataSetValue={""}
                                                initialValue={"None"}
                                                name={""}
                                                options={[
                                                  {
                                                    label: "None",
                                                    value: "-1",
                                                  },
                                                  { label: "-", value: "0" },
                                                  { label: "+", value: "100" },
                                                ]}
                                                style={{
                                                  background: "#fff",
                                                  border: "1px solid #ced4da",
                                                  borderRadius: "0.25rem",
                                                  color: "#495057",
                                                  display: "block",
                                                  fontSize: "1rem",
                                                  fontWeight: 400,
                                                  lineHeight: 1.5,
                                                  paddingBottom: "0.375rem",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "0.375rem",
                                                  width: "100%",
                                                }}
                                                actions={[
                                                  {
                                                    action: "Create Grade",
                                                    trigger: "onchange",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      "input intangible id":
                                                        row["intangible_id"],
                                                      "input user id":
                                                        row["user_id"],
                                                    },
                                                  },
                                                ]}
                                              ></SelectDropDown>
                                            </td>
                                          </Fragment>
                                        );
                                      })}
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>

                <Body
                  className={"body"}
                  actions={[
                    {
                      action: "Get Intangibles",
                      trigger: "onload",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {},
                    },
                  ]}
                ></Body>
              </DivBlock>
            </DivBlock>

            <DivBlock>
              <DivBlock className={"body-main"}>
                <DivBlock>
                  <Heading className={"h2"} type={"h1"}>
                    Average Grades
                  </Heading>

                  <DivBlock>
                    <DivBlock>
                      <table className={"table redTable"}>
                        <thead>
                          <tr>
                            <th
                              style={{
                                borderBottomColor: "#ddd",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1px",
                                borderLeftColor: "#ddd",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1px",
                                borderRightColor: "#ddd",
                                borderRightStyle: "solid",
                                borderRightWidth: "1px",
                                borderTopColor: "#ddd",
                                borderTopStyle: "solid",
                                borderTopWidth: "1px",
                                paddingBottom: "0.375rem",
                                paddingLeft: "0.75rem",
                                paddingRight: "0.75rem",
                                paddingTop: "0.375rem",
                              }}
                            ></th>

                            {Intangibles &&
                              isArray(Intangibles) &&
                              Intangibles.map((row, index) => {
                                return (
                                  <Fragment>
                                    <th
                                      style={{
                                        borderBottomColor: "#ddd",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1px",
                                        borderLeftColor: "#ddd",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1px",
                                        borderRightColor: "#ddd",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1px",
                                        borderTopColor: "#ddd",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1px",
                                        paddingBottom: "0.375rem",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "0.375rem",
                                      }}
                                    >
                                      <Span className={"Sorting-span"}>
                                        {`Season Average(${row["name"]})`}
                                      </Span>
                                    </th>
                                  </Fragment>
                                );
                              })}
                          </tr>
                        </thead>

                        <tbody>
                          {AverageGrades &&
                            isArray(AverageGrades) &&
                            AverageGrades.map((row, index) => {
                              return (
                                <Fragment>
                                  <tr>
                                    <td
                                      style={{
                                        borderBottomColor: "#ddd",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1px",
                                        borderLeftColor: "#ddd",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1px",
                                        borderRightColor: "#ddd",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1px",
                                        borderTopColor: "#ddd",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1px",
                                        paddingBottom: "0.375rem",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "0.375rem",
                                      }}
                                    >
                                      <Span>{row["name"]}</Span>
                                    </td>

                                    {row["grades"] &&
                                      isArray(row["grades"]) &&
                                      row["grades"].map((row, index) => {
                                        return (
                                          <Fragment>
                                            <td
                                              style={{
                                                borderBottomColor: "#ddd",
                                                borderBottomStyle: "solid",
                                                borderBottomWidth: "1px",
                                                borderLeftColor: "#ddd",
                                                borderLeftStyle: "solid",
                                                borderLeftWidth: "1px",
                                                borderRightColor: "#ddd",
                                                borderRightStyle: "solid",
                                                borderRightWidth: "1px",
                                                borderTopColor: "#ddd",
                                                borderTopStyle: "solid",
                                                borderTopWidth: "1px",
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                              }}
                                            >
                                              <Span>
                                                {row["integer_value"]}
                                              </Span>
                                            </td>
                                          </Fragment>
                                        );
                                      })}
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(DemoPagePage);
