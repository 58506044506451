import { makeRequest } from "./restApi.js";
export function GetDepthChartPositionUsersPlaceholderEndpoint(payload) {
  return makeRequest("/placeholder", "GET", payload);
}

export function GetAverageGameGradesEndpoint(payload) {
  return makeRequest(
    "/v1/average_game_grades/{type}/{date}/{position_id}/",
    "GET",
    payload
  );
}

export function GetAverageGameGradesDesEndpoint(payload) {
  return makeRequest(
    "/v1/average_game_grades_des/{type}/{date}/{position_id}/",
    "GET",
    payload
  );
}

export function GetAverageGameGradesReportsEndpoint(payload) {
  return makeRequest(
    "/v1/average_game_grades_reports/{type}/{date}/{position_id}/",
    "GET",
    payload
  );
}

export function GetAverageGameGradeSortingEndpoint(payload) {
  return makeRequest("/v1/avg_game_grade", "GET", payload);
}

export function GetAveragePracticeGradeSortingEndpoint(payload) {
  return makeRequest("/v1/avg_practice_grade", "GET", payload);
}

export function CheckEmailEndpoint(payload) {
  return makeRequest("/v1/check_email", "POST", payload);
}

export function CheckSchoolEndpoint(payload) {
  return makeRequest("/v1/check_school", "POST", payload);
}

export function CountPlayByPlayEndpoint(payload) {
  return makeRequest("/v1/count_play", "GET", payload);
}

export function CreatePositionEndpoint(payload) {
  return makeRequest("/v1/create", "POST", payload);
}

export function DeleteCustomFieldEndpoint(payload) {
  return makeRequest("/v1/delete_custom_field/", "DELETE", payload);
}

export function DeleteCustomFieldPlayEndpoint(payload) {
  return makeRequest("/v1/delete_field_play", "DELETE", payload);
}

export function CreateDepthChartRegisterEndpoint(payload) {
  return makeRequest("/v1/depth_charts_register", "POST", payload);
}

export function GetDepthChartBySchoolEndpoint(payload) {
  return makeRequest("/v1/depth_charts_school/{id}/", "GET", payload);
}

export function SetExercisesEndpoint(payload) {
  return makeRequest("/v1/exercises/set", "POST", payload);
}

export function GetGameFileEndpoint(payload) {
  return makeRequest("/v1/game_files/{id}/", "GET", payload);
}

export function GetGameGradesEndpoint(payload) {
  return makeRequest(
    "/v1/game_grades/{date}/{type}/{position_id}/",
    "GET",
    payload
  );
}

export function GetGameGradesDesEndpoint(payload) {
  return makeRequest(
    "/v1/game_grades_des/{date}/{type}/{position_id}/",
    "GET",
    payload
  );
}

export function GetAverageGameGradeSortingDesEndpoint(payload) {
  return makeRequest("/v1/get_avg_game_grades_des", "GET", payload);
}

export function GetAveragePracticeGradeSortingDesEndpoint(payload) {
  return makeRequest("/v1/get_avg_practice_grades_des", "GET", payload);
}

export function GetCurrentPositionEndpoint(payload) {
  return makeRequest("/v1/get_current_position", "GET", payload);
}

export function GetCustomFieldEndpoint(payload) {
  return makeRequest("/v1/get_field", "GET", payload);
}

export function GetCustomFieldPlayEndpoint(payload) {
  return makeRequest("/v1/get_field_play", "GET", payload);
}
export function GetPlayInfoByNameEndpoint(payload) {
  return makeRequest("/v1/get_name", "GET", payload);
}

export function GetPlayInfoByIntangibleEndpoint(payload) {
  return makeRequest("/v1/get_play_intangible", "GET", payload);
}

export function GetPlayerReportsDataEndpoint(payload) {
  return makeRequest("/v1/get_players_data", "GET", payload);
}

export function GetPositionEndpoint(payload) {
  return makeRequest("/v1/get_position", "GET", payload);
}

export function GetPositionCoachEndpoint(payload) {
  return makeRequest("/v1/get_position_coach", "GET", payload);
}

export function GetPlayerReportsByIndexDesEndpoint(payload) {
  return makeRequest("/v1/get_reports_index_des", "GET", payload);
}

export function GetRoastersEndpoint(payload) {
  return makeRequest("/v1/get_roasters", "GET", payload);
}

export function GetSchoolIdEndpoint(payload) {
  return makeRequest("/v1/get_school_id", "GET", payload);
}

export function GetUserGradesEndpointForFilter(payload) {
  return makeRequest("/v1/get_user_grades_by_position", "GET", payload);
}

export function DeleteGradeEndpoint(payload) {
  return makeRequest("/v1/grades", "DELETE", payload);
}

export function CreateGradeEndpoint(payload) {
  return makeRequest("/v1/grades", "POST", payload);
}

export function GetUserGradesGroupedEndpoint(payload) {
  return makeRequest("/v1/grades/grouped/{user_id}/", "GET", payload);
}

export function GetIntangibleSortingEndpoint(payload) {
  return makeRequest(
    "/v1/intangible/{type}/{date}/{position_id}/",
    "GET",
    payload
  );
}

export function DefaultIntangibleEndpoint(payload) {
  return makeRequest("/v1/create_default_intangible", "POST", payload);
}

export function GetIntangiblesGraphEndpoint(payload) {
  return makeRequest("/v1/intangibles/graph", "GET", payload);
}

export function SetIntangiblesEndpoint(payload) {
  return makeRequest("/v1/intangibles/set", "POST", payload);
}

export function EditIntangibleEndpoint(payload) {
  return makeRequest("/v1/intangibles/{id}/", "PUT", payload);
}

export function LogoutEndpoint(payload) {
  return makeRequest("/v1/logout", "GET", payload);
}

export function NewDemoEndpoint(payload) {
  return makeRequest("/v1/new_demo", "GET", payload);
}

export function GetNumberRosterEndpoint(payload) {
  return makeRequest("/v1/number_roster", "GET", payload);
}

export function GetNumberRosterDesEndpoint(payload) {
  return makeRequest("/v1/number_roster_des", "GET", payload);
}

export function MapPlayCsvCustomEndpoint(payload) {
  return makeRequest("/v1/play/csv_custom/{id}/", "POST", payload);
}

export function GetPlayByPlayTitleEndpoint(payload) {
  return makeRequest("/v1/play_by_play_title", "GET", payload);
}

export function GetPlayInfoByDownEndpoint(payload) {
  return makeRequest("/v1/play_down", "GET", payload);
}

export function GetPlayInfoByDownDesEndpoint(payload) {
  return makeRequest("/v1/play_down_des", "GET", payload);
}

export function UpdatePlayFieldEndpoint(payload) {
  return makeRequest("/v1/play_field/{id}/", "PUT", payload);
}

export function DeletePlayHeadingEndpoint(payload) {
  return makeRequest("/v1/play_heading", "DELETE", payload);
}
export function GetPlayInfoByNameDesEndpoint(payload) {
  return makeRequest("/v1/play_name_des", "GET", payload);
}

export function GetPlayInfoByPackageEndpoint(payload) {
  return makeRequest("/v1/play_package", "GET", payload);
}
export function GetPlayInfoByPackageDesEndpoint(payload) {
  return makeRequest("/v1/play_package_des", "GET", payload);
}

export function GetPlayInfoByQtrEndpoint(payload) {
  return makeRequest("/v1/play_qtr", "GET", payload);
}

export function GetPlayInfoByQtrDesEndpoint(payload) {
  return makeRequest("/v1/play_qtr_des", "GET", payload);
}

export function GetPlayInfoBySeriesEndpoint(payload) {
  return makeRequest("/v1/play_series", "GET", payload);
}

export function GetPlayInfoBySeriesDesEndpoint(payload) {
  return makeRequest("/v1/play_series_des", "GET", payload);
}

export function GetPlayInfoByTypeEndpoint(payload) {
  return makeRequest("/v1/play_type", "GET", payload);
}

export function GetPlayInfoByTypeDesEndpoint(payload) {
  return makeRequest("/v1/play_type_des", "GET", payload);
}

export function GetPlayerGradesEndpoint(payload) {
  return makeRequest("/v1/player_grades", "GET", payload);
}

export function GetPlaysEndpoint(payload) {
  return makeRequest("/v1/plays", "GET", payload);
}

export function DeletePlayEndpoint(payload) {
  return makeRequest("/v1/plays", "DELETE", payload);
}

export function CreatePlaysEndpoint(payload) {
  return makeRequest("/v1/plays", "POST", payload);
}

export function SetPlaysEndpoint(payload) {
  return makeRequest("/v1/plays/set", "POST", payload);
}

export function GetPositionRosterEndpoint(payload) {
  return makeRequest("/v1/position_roster", "GET", payload);
}

export function DeletePositionEndpoint(payload) {
  return makeRequest("/v1/positions", "DELETE", payload);
}

export function UpdatePositionEndpoint(payload) {
  return makeRequest("/v1/positions/{id}/", "PUT", payload);
}

export function GetPositionsDesEndpoint(payload) {
  return makeRequest("/v1/positions_des", "GET", payload);
}

export function CreateQuizQuestionEndpoint(payload) {
  return makeRequest("/v1/quiz_questions", "POST", payload);
}

export function DeleteQuizQuestionEndpoint(payload) {
  return makeRequest("/v1/quiz_questions", "DELETE", payload);
}

export function GetQuizQuestionsEndpoint(payload) {
  return makeRequest("/v1/quiz_questions", "GET", payload);
}

export function CreateQuizResponseEndpoint(payload) {
  return makeRequest("/v1/quiz_responses", "POST", payload);
}

export function GetQuizzesEndpoint(payload) {
  return makeRequest("/v1/quizzes", "GET", payload);
}

export function CreateQuizEndpoint(payload) {
  return makeRequest("/v1/quizzes", "POST", payload);
}

export function DeleteQuizEndpoint(payload) {
  return makeRequest("/v1/quizzes", "DELETE", payload);
}

export function GetPlayerReportsByDownEndpoint(payload) {
  return makeRequest("/v1/reports_down", "GET", payload);
}

export function GetPlayerReportsByDownDesEndpoint(payload) {
  return makeRequest("/v1/reports_down_des", "GET", payload);
}

export function GetReportsPDFEndpoint(payload) {
  return makeRequest("/v1/reports_files/{id}/", "GET", payload);
}

export function GetPlayerReportsByNameEndpoint(payload) {
  return makeRequest("/v1/reports_name", "GET", payload);
}

export function GetPlayerReportsByNameDesEndpoint(payload) {
  return makeRequest("/v1/reports_name_des", "GET", payload);
}

export function GetPlayerReportsByPackageEndpoint(payload) {
  return makeRequest("/v1/reports_package", "GET", payload);
}

export function GetPlayerReportsByPackageDesEndpoint(payload) {
  return makeRequest("/v1/reports_package_des", "GET", payload);
}

export function GetPlayerReportsByQtrEndpoint(payload) {
  return makeRequest("/v1/reports_qtr", "GET", payload);
}

export function GetPlayerReportsByQtrDesEndpoint(payload) {
  return makeRequest("/v1/reports_qtr_des", "GET", payload);
}

export function GetPlayerReportsBySeriesEndpoint(payload) {
  return makeRequest("/v1/reports_series", "GET", payload);
}

export function GetPlayerReportsBySeriesDesEndpoint(payload) {
  return makeRequest("/v1/reports_series_des", "GET", payload);
}

export function GetPlayerReportsByTypeEndpoint(payload) {
  return makeRequest("/v1/reports_type", "GET", payload);
}

export function GetPlayerReportsByTypeDesEndpoint(payload) {
  return makeRequest("/v1/reports_type_des", "GET", payload);
}

export function MapCsvEmailEndpoint(payload) {
  return makeRequest("/v1/roster_email/csv/{id}/", "POST", payload);
}

export function DeleteSchoolEndpoint(payload) {
  return makeRequest("/v1/school", "DELETE", payload);
}

export function GetSchoolsEndpoint(payload) {
  return makeRequest("/v1/schools", "GET", payload);
}

export function GetSecondaryRosterEndpoint(payload) {
  return makeRequest("/v1/secondary_roster", "GET", payload);
}

export function GetSecondaryRosterDesEndpoint(payload) {
  return makeRequest("/v1/secondary_roster_des", "GET", payload);
}

export function PlayByPlaySortPositionEndpoint(payload) {
  return makeRequest("/v1/sort_position", "GET", payload);
}
export function UpdateUserNewEndpoint(payload) {
  return makeRequest("/v1/update_ user/{id}/", "PUT", payload);
}

export function GetAverageUserExercisesDesEndpoint(payload) {
  return makeRequest("/v1/user_exercises/average_des", "GET", payload);
}

export function GetAverageUserExercisesByPositionEndpoint(payload) {
  return makeRequest("/v1/user_exercises_position/average", "GET", payload);
}

export function GetAverageUserExercisesByPositionDesEndpoint(payload) {
  return makeRequest("/v1/user_exercises_position_des/average", "GET", payload);
}

export function EditPositionCoachEndpoint(payload) {
  return makeRequest("/v1/users/positionCoach/me", "PUT", payload);
}
export function GetYearRosterEndpoint(payload) {
  return makeRequest("/v1/year_roster", "GET", payload);
}

export function GetYearRosterDesEndpoint(payload) {
  return makeRequest("/v1/year_roster_des", "GET", payload);
}
export function PlayerComparisonIntangible(payload) {
  return makeRequest("/v1/get_all_intangibles_for_school", "GET", payload);
}

//CoachTool V2 API LIST DOWN

// Authorization Endpoints
export function LoginEndpoint(payload) {
  return makeRequest("/login", "POST", payload);
}
export function SignUpEndpoint(payload) {
  return makeRequest("/register", "POST", payload);
}
export function RequestPasswordResetEndpoint(payload) {
  return makeRequest("/request_password_reset", "POST", payload);
}
export function ResetPasswordEndpoint(payload) {
  return makeRequest("/reset_password", "GET", payload);
}

// Current User Detail
export function GetPositionsEndpoint(payload) {
  return makeRequest("/positions", "GET", payload);
}
export function GetCurrentUserEndpoint(payload) {
  return makeRequest("/users/me", "GET", payload);
}

//Headcoach EditProfile EndPoints
export function EditPasswordEndpoint(payload) {
  return makeRequest("/users/password", "PUT", payload);
}
export function EditCoachEndpoint(payload) {
  return makeRequest("/users/coach/me", "PUT", payload);
}

// Files Endpoints

export function GetGameFilesEndpoint(payload) {
  return makeRequest("/game_files", "GET", payload);
}
export function UpdateGameFileEndpoint(payload) {
  return makeRequest("/game_files/{id}/", "PUT", payload);
}
export function UploadGameFileEndpoint(payload) {
  return makeRequest("/game_file", "POST", payload);
}
export function DeleteGameFileEndpoint(payload) {
  return makeRequest("/game_files", "DELETE", payload);
}

// Season - yearly Endpoint
export function GetSeasonYearEndpoint(payload) {
  return makeRequest("/get_year", "GET", payload);
}

// Admin School Report
export function GetSchoolReportEndpoint(payload) {
  return makeRequest("/school_list", "GET", payload);
}
export function UpdateAdminUserEndpoint(payload) {
  return makeRequest("/edit_school", "PUT", payload);
}

//Roster EndPoints
export function GetRosterEndpoint(payload) {
  return makeRequest("/roster", "GET", payload);
}
export function DeleteUserEndpoint(payload) {
  return makeRequest("/users/{id}/", "DELETE", payload);
}
export function GetUserEndpoint(payload) {
  return makeRequest("/users/{id}/", "GET", payload);
}
export function UploadRosterEndpoint(payload) {
  return makeRequest("/upload_roster", "POST", payload);
}
export function MapCsvEndpoint(payload) {
  return makeRequest("/roster/csv/{id}/", "POST", payload);
}
export function MapCsvNoEmailEndpoint(payload) {
  return makeRequest("/roster_no_email/csv/{id}/", "POST", payload);
}
export function UpdateUserEndpoint(payload) {
  return makeRequest("/users/{id}/", "PUT", payload);
}
export function CreateUserEndpoint(payload) {
  return makeRequest("/users", "POST", payload);
}
export function ResendEmailAllPlayer(payload) {
  return makeRequest("/resend_bulk_email", "GET", payload);
}
export function RemoveImageEndpoint(payload) {
  return makeRequest("/delete_image/{id}/", "DELETE", payload);
}

// Intangibles EndPoints
export function GetIntagiblesEndpoint(payload) {
  return makeRequest("/intangibles", "GET", payload);
}
export function CreateIntangibleEndpoint(payload) {
  return makeRequest("/submit_intangibles", "POST", payload);
}
export function DeleteIntangibleEndpoint(payload) {
  return makeRequest("/intangibles", "DELETE", payload);
}

// Production EndPoints
export function GetProductionEndpoint(payload) {
  return makeRequest("/get_production_points", "GET", payload);
}
export function GetSchoolProductionEndpoint(payload) {
  return makeRequest("/get_school_production_point", "GET", payload);
}
export function CreateSchoolProductionEndpoint(payload) {
  return makeRequest("/add_school_production_point", "POST", payload);
}
export function SendNewProductionEndpoint(payload) {
  return makeRequest("/send_new_production_point_request", "POST", payload);
}
export function CreateProductionEndpoint(payload) {
  return makeRequest("/add_production_point", "POST", payload);
}

// Exercises EndPoint
export function GetExercisesEndpoint(payload) {
  return makeRequest("/exercises", "GET", payload);
}
export function CreateExerciseEndpoint(payload) {
  return makeRequest("/exercises", "POST", payload);
}
export function DeleteExerciseEndpoint(payload) {
  return makeRequest("/exercises", "DELETE", payload);
}

// Player Training Report
export function GetAverageUserExercisesEndpoint(payload) {
  return makeRequest("/user_exercises/average", "GET", payload);
}

// Coaches EndPoints
export function GetPositionCoachesEndpoint(payload) {
  return makeRequest("/get_position_coaches", "GET", payload);
}
export function DeletePositionCoachEndpoint(payload) {
  return makeRequest("/position_coaches", "DELETE", payload);
}
export function ResendEmailEndpoint(payload) {
  return makeRequest("/resend_email/{user_id}/", "PUT", payload);
}
export function CreateNewPositionCoachEndpoint(payload) {
  return makeRequest("/new_position_coach", "POST", payload);
}
export function UpdatePositionCoachEndpoint(payload) {
  return makeRequest("/users/positionCoach/{id}", "PUT", payload);
}

//GradingScaleType Endpoint
export function GetScaleType(payload) {
  return makeRequest("/grading_scale_type", "GET", payload);
}
export function EditScaleType(payload) {
  return makeRequest("/edit_scale_type", "PUT", payload);
}

// DepthCharts EndPoints
export function GetBaseDepthChartsEndpoint(payload) {
  return makeRequest("/depth_charts", "GET", payload);
}
export function CreateDepthChartEndpoint(payload) {
  return makeRequest("/depth_charts", "POST", payload);
}
export function DefaultDepthCharts(payload) {
  return makeRequest("/default_positions_depth_charts", "GET", payload);
}
export function GetDepthChartEndpoint(payload) {
  return makeRequest("/depth_charts/{id}/", "GET", payload);
}
export function GetTagsEndpoint(payload) {
  return makeRequest("/tags", "GET", payload);
}
export function GetDepthChartUsersEndpoint(payload) {
  return makeRequest("/depth_chart_users/{id}/", "GET", payload);
}
export function GetBaseDepthChartUsersEndpoint(payload) {
  return makeRequest("/get_base_depth_chart_users/{id}/", "GET", payload);
}
export function GetDepthChartPositionUsersEndpoint(payload) {
  return makeRequest(
    "/depth_chart_users/{depth_chart_id}/{position_id}/",
    "GET",
    payload
  );
}
export function DeleteDepthChartRowEndpoint(payload) {
  return makeRequest("/depth_chart_rows", "DELETE", payload);
}
export function DeleteDepthChartEndpoint(payload) {
  return makeRequest("/depth_charts", "DELETE", payload);
}
export function CreateDepthChartAliasEndpoint(payload) {
  return makeRequest("/depth_chart_alias", "POST", payload);
}
export function UpdateDepthChartAliasEndpoint(payload) {
  return makeRequest("/depth_chart_alias/{id}/", "PUT", payload);
}
export function SetDepthChartUserEndpoint(payload) {
  return makeRequest("/depth_chart_users/{id}/", "PUT", payload);
}
export function DeleteDepthChartUserEndpoint(payload) {
  return makeRequest("/depth_chart_users", "DELETE", payload);
}

export function CreateDepthChartRowEndpoint(payload) {
  return makeRequest("/depth_charts_row", "POST", payload);
}
export function EditDepthChartEndpoint(payload) {
  return makeRequest("/depth_charts/{id}/", "PUT", payload);
}
export function DuplicateDepthChart(payload) {
  return makeRequest("/duplicate_depth_chart", "POST", payload);
}
export function GetInjuredPlayersEndpoint(payload) {
  return makeRequest("/injured_players", "GET", payload);
}
export function DeleteInjuredPlayerEndpoint(payload) {
  return makeRequest("/injured_players", "DELETE", payload);
}
export function CreateInjuredPlayerEndpoint(payload) {
  return makeRequest("/injured_players", "POST", payload);
}
export function GetGroupedChartsEndpoint(payload) {
  return makeRequest("/charts/grouped", "GET", payload);
}
export function DeleteDepthChartUsersEndpoint(payload) {
  return makeRequest("/delete_depth_chart_users", "DELETE", payload);
}
export function GetDepthChartWeeksEndpoint(payload) {
  return makeRequest("/weeks", "GET", payload);
}

// Drag and Drop Field View
export function GetDepthChartFieldViewEndpoint(payload) {
  return makeRequest("/get_field_view/{id}", "GET", payload);
}
export function UpdateDepthChartFieldViewEndpoint(payload) {
  return makeRequest("/set_field_view", "PUT", payload);
}

// Drag and Drop Depth chart Users
export function UpdateDragAndDropUserEndpoint(payload) {
  return makeRequest("/drag_drop_chart_update", "PUT", payload);
}

//
export function DeleteTagEndpoint(payload) {
  return makeRequest("/tags", "DELETE", payload);
}

// Grading EndPoints
export function GetAllGamesEndpoint(payload) {
  return makeRequest("/get_allgames", "GET", payload);
}
export function GetPracticesEndpoint(payload) {
  return makeRequest("/get_practices", "GET", payload);
}
export function CreateGamesEndpoint(payload) {
  return makeRequest("/create_games", "POST", payload);
}
export function CreateQwikcutGamesEndpoint(payload) {
  return makeRequest("/upload_quickcut_video", "POST", payload);
}
export function GetTeams(payload) {
  return makeRequest("/get_teams", "GET", payload);
}
export function GetFirstDepthChartEndpoint(payload) {
  return makeRequest("/get_first_depth_chart", "GET", payload);
}
export function CreatePlayRowEndpoint(payload) {
  return makeRequest("/play_row", "POST", payload);
}
export function CreatePlayDepthChartRowEndpoint(payload) {
  return makeRequest("/play_depth_chart_row", "POST", payload);
}
export function GetPlayByPlayInfoEndpoint(payload) {
  return makeRequest("/play_by_play", "GET", payload);
}
export function GetGameEndpoint(payload) {
  return makeRequest("/game/{id}/", "GET", payload);
}
export function GetAllSeries(payload) {
  return makeRequest("/get_series_by_school", "GET", payload);
}
export function GetFirstUserEndpoint(payload) {
  return makeRequest("/get_first_user", "GET", payload);
}
export function GetAverageIntangibleUsersEndpoint(payload) {
  return makeRequest("/average_intangible_users", "GET", payload);
}
export function GetAverageByIntangiblePlay(payload) {
  return makeRequest("/get_average_intangible_by_play", "GET", payload);
}
export function GetCustomFieldTitlesEndpoint(payload) {
  return makeRequest("/custom_field_titles/", "GET", payload);
}
export function GetPackagesEndpoint(payload) {
  return makeRequest("/packages", "GET", payload);
}
export function GetTotalPlaysEndpoint(payload) {
  return makeRequest("/get_total_plays", "GET", payload);
}
export function GetUserGradesEndpoint(payload) {
  return makeRequest("/get_user_grades", "GET", payload);
}
export function GetCustomDataEndpoint(payload) {
  return makeRequest("/get_data", "GET", payload);
}
export function UpdateGameEndpoint(payload) {
  return makeRequest("/update_game/{id}/", "PUT", payload);
}
export function DeleteGameEndpoint(payload) {
  return makeRequest("/game", "DELETE", payload);
}
export function GetLastIndexEndpoint(payload) {
  return makeRequest("/last_index_play", "GET", payload);
}
export function GetPlayerReportsCountEndpoint(payload) {
  return makeRequest("/get_players_count", "GET", payload);
}
export function CreateCustomPlayDataEndpoint(payload) {
  return makeRequest("/custom_play_data", "POST", payload);
}
export function UpdatePlayIndexEndpoint(payload) {
  return makeRequest("/play_index", "PUT", payload);
}
export function DeletePlayRowEndpoint(payload) {
  return makeRequest("/play_row", "DELETE", payload);
}
export function UploadPlayEndpoint(payload) {
  return makeRequest("/upload_play", "POST", payload);
}
export function MapPlayCsvEndpoint(payload) {
  return makeRequest("/play/csv/{id}", "POST", payload);
}
export function CreatePlayHeadingEndpoint(payload) {
  return makeRequest("/play_heading", "POST", payload);
}
export function GetAllPlayIdsEndpoint(payload) {
  return makeRequest("/get_ids", "POST", payload);
}
export function GetPlayDepthChartUsersEndpoint(payload) {
  return makeRequest("/get_play_depth_chart", "GET", payload);
}
export function UpdatePlayUserEndpoint(payload) {
  return makeRequest("/update_play_user", "PUT", payload);
}
export function DeleteUserDepthChartEndpoint(payload) {
  return makeRequest("/delete_user_depth_chart", "DELETE", payload);
}
export function UpdateProductionPointEndpoint(payload) {
  return makeRequest("/update_production_point", "PUT", payload);
}
export function CreateUserGradesEndpoint(payload) {
  return makeRequest("/create_usergrades", "POST", payload);
}
export function DeleteUserGradeEndpoint(payload) {
  return makeRequest("/user_grade", "DELETE", payload);
}
export function UpdatePlayUserNotesEndpoint(payload) {
  return makeRequest("/update_notes", "PUT", payload);
}
export function DeleteCheckboxBulkGradingEndpoint(payload) {
  return makeRequest("/delete_play_row", "DELETE", payload);
}

// Grade Import
export function UploadGradeEndpoint(payload) {
  return makeRequest("/grade_import", "POST", payload);
}

// bulk Action
export function CreateCheckboxBulkGradingEndpoint(payload) {
  return makeRequest("/create_bulk_play_row", "POST", payload);
}
export function ChangeGradeValue(payload) {
  return makeRequest("/assign_grade", "POST", payload);
}
export function UpdateAllPlayPackageEndpoint(payload) {
  return makeRequest("/update_bulk_depth_charts", "PUT", payload);
}
export function CreateSavedViewsEndpoint(payload) {
  return makeRequest("/create_saved_views", "POST", payload);
}
export function GetDefaultViewEndpoint(payload) {
  return makeRequest("/get_saved__views_names", "GET", payload);
}
export function GetSavedViewEndpoint(payload) {
  return makeRequest("/get_columns_name_wise", "GET", payload);
}
export function DeleteSavedViewEndpoint(payload) {
  return makeRequest("/saved_view", "DELETE", payload);
}

// Grading In play row Data Update
export function UpdatePlayNameEndpoint(payload) {
  return makeRequest("/play_name/{id}/", "PUT", payload);
}
export function UpdatePlayQtrEndpoint(payload) {
  return makeRequest("/play_qtr/{id}/", "PUT", payload);
}
export function UpdatePlaySeriesEndpoint(payload) {
  return makeRequest("/play_series/{id}/", "PUT", payload);
}
export function UpdatePlayDownEndpoint(payload) {
  return makeRequest("/play_down/{id}/", "PUT", payload);
}
export function UpdatePlayDistanceEndpoint(payload) {
  return makeRequest("/play_distance/{id}/", "PUT", payload);
}
export function UpdatePlayTypeEndpoint(payload) {
  return makeRequest("/play_type/{id}/", "PUT", payload);
}
export function UpdatePlayPackageEndpoint(payload) {
  return makeRequest("/play_package/{id}/", "PUT", payload);
}
export function CreateCustomFieldDataEndpoint(payload) {
  return makeRequest("/custom_data", "PUT", payload);
}

// checkbox
export function getConfigurableTableColumns(payload) {
  return makeRequest("/get_selected_table_columns", "GET", payload);
}
export function updateConfigurableTableColumns(payload) {
  return makeRequest("/update_selected_table_columns", "PUT", payload);
}

//drag & drop grading
export function updateGradingTableColumns(payload) {
  return makeRequest("/update_index", "PUT", payload);
}
export function getGradingTableColumns(payload) {
  return makeRequest("/get_index", "GET", payload);
}

//CustomField Filter EndPoints
export function GetCustomFieldDataEndpoint(payload) {
  return makeRequest("/get_field_data/{id}/", "GET", payload);
}
// upload video
export function updateHudlVideoLink(payload) {
  return makeRequest("/update_hudl_video_link", "PUT", payload);
}
export function uploadHudlVideo(payload) {
  return makeRequest("/upload_hudl_video", "POST", payload);
}
export function verifyUploadHudlVideo(payload) {
  return makeRequest("/verify_hudl_link", "POST", payload);
}

//
export function createGradeRow(payload) {
  return makeRequest("/create_grade_row", "POST", payload);
}

// Player Report Endpoints
export function GetPlayerAverageIntangibleEndpoint(payload) {
  return makeRequest("/get_avg_intangible_by_position", "GET", payload);
}
export function GetPlayerProductionPointEndpoint(payload) {
  return makeRequest("/get_production_point", "GET", payload);
}

// Player Comparison Endpoints
export function FilterIntangibles(payload) {
  return makeRequest("/intangibles_by_position", "GET", payload);
}
export function GetComparisonReports(payload) {
  return makeRequest("/comparison_reports", "GET", payload);
}
export function GetGradesGraphEndpoint(payload) {
  return makeRequest("/grades_graph", "GET", payload);
}

// Player account EndPoints

export function GetBaseDepthChartByPlayer(payload) {
  return makeRequest("/player_base_depth_chart/", "GET", payload);
}
export function GetDepthChartByPlayer(payload) {
  return makeRequest("/player_depth_chart/", "GET", payload);
}
export function EditPlayerEndpoint(payload) {
  return makeRequest("/users/player/me", "PUT", payload);
}
export function GetUserExercisesEndpoint(payload) {
  return makeRequest("/user_exercises", "GET", payload);
}
export function GetExercisesGraphEndpoint(payload) {
  return makeRequest("/exercises/graph/{id}/", "GET", payload);
}
export function CreateUserExerciseEndpoint(payload) {
  return makeRequest("/user_exercise", "POST", payload);
}
export function UpdateUserExerciseEndpoint(payload) {
  return makeRequest("/user_exercise/{id}/", "PUT", payload);
}
export function DeleteUserExerciseEndpoint(payload) {
  return makeRequest("/user_exercise/{id}/", "DELETE", payload);
}
export function GetPlayerDepthChartUsersEndpoint(payload) {
  return makeRequest("/get_player_depth_chart_users/{id}", "GET", payload);
}
export function PlayerFilterIntangibles(payload) {
  return makeRequest("/get_player_data_by_position", "GET", payload);
}
export function GetAverageIntangibleEndpoint(payload) {
  return makeRequest("/get_avg_intangible", "GET", payload);
}
export function PlayerIntangibles(payload) {
  return makeRequest("/intangibles_for_player", "GET", payload);
}
