import React, { Component, Fragment } from "react";
import { Body, DivBlock } from "../modules/Basic";
import { FormBlock, Label, SelectDropDown } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";

import { Heading } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { startPageTimer, trackPageDuration } from "../utils/function";

class GradingScaleType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
    };
  }
  
  componentDidMount() {
    startPageTimer('Grading Scale Type');
    this.setState({ startTime: Date.now() });
    this.props.positionData();
    this.props.getScaleData();
  }
    
  componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear){
      dispatch({ type: "LOADING_EVENT",inputVariables:{loader:true} });
      this.props.getScaleData();
    }
  }
    
  componentWillUnmount(){
    trackPageDuration('Grading Scale Type',this.state.startTime)
  }
  render() {
    let {
      CurrentUser,
      Positions,
      GetScaleTypeData,
      NavBarDropDownIndex,
      GradingScaleTypeOptionsData,
      SeasonYearData,
      selectedSeasonYear
    } = this.props;

    var positionData = Positions?.filter((item) =>
      CurrentUser?.secondary?.split(",").includes(item.name)
    );

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            // {
            //   action: "Get Scale Type",
            //   trigger: "onload",
            //   timeout: "",
            //   pollingPeriod: "",
            //   inputVariables: {},
            // },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 2 : 2,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock className={"body-main"}>
                    <DivBlock>
                      <Heading
                        className={"h2"}
                        style={{ justifyContent: "center" }}
                        type={"h2"}
                      >
                        Grading Scale Type
                      </Heading>
                      <Columns
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Column
                          lg={8}
                          md={8}
                          sm={12}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          xs={12}
                        >
                          <DivBlock
                            className={"card"}
                            style={{ marginBottom: "30px" }}
                          >
                            <DivBlock className={"card-body"}>
                              <FormBlock
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Label className={"label-one"}>Defense</Label>
                                {CurrentUser.user_type === "Position Coach" &&
                                  positionData &&
                                  !positionData?.some(
                                    (elements) =>
                                      elements.category === "Defense"
                                  ) && (
                                    <Label>No position are available.</Label>
                                  )}
                                <div style={{ marginBottom: 30 }}>
                                  {CurrentUser.user_type === "Coach" &&
                                    Positions &&
                                    Positions.map((item, index) => {
                                      if (item.category === "Defense") {
                                        return (
                                          <DivBlock
                                            className={"form-group"}
                                            key={index}
                                          >
                                            <Label className={"label"}>
                                              {item.name}
                                            </Label>

                                            <SelectDropDown
                                              className={
                                                "form-control-hide-icon"
                                              }
                                              disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                                              initialValue={
                                                GetScaleTypeData &&
                                                isArray(GetScaleTypeData) &&
                                                GetScaleTypeData?.filter(
                                                  (k) =>
                                                    k.position_id === item.id
                                                )?.[0]?.scale_type
                                              }
                                              name={"FormEditCoach.scale type"}
                                              options={GradingScaleTypeOptionsData}
                                              type={"number"}
                                              actions={[
                                                {
                                                  action: "Edit Scale Type",
                                                  trigger: "onchange",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    positionId: item.id,
                                                  },
                                                },
                                              ]}
                                              enableReinitialize={true}
                                            ></SelectDropDown>
                                          </DivBlock>
                                        );
                                      }
                                      return null;
                                    })}

                                  {CurrentUser.user_type === "Position Coach" &&
                                    positionData &&
                                    positionData?.map((item) => {
                                      if (item.category === "Defense") {
                                        return (
                                          <DivBlock className={"form-group"}>
                                            <Label className={"label"}>
                                              {item.name}
                                            </Label>

                                            <SelectDropDown
                                              className={"form-control"}
                                              disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                                              initialValue={
                                                GetScaleTypeData &&
                                                isArray(GetScaleTypeData) &&
                                                GetScaleTypeData?.filter(
                                                  (k) =>
                                                    k.position_id === item.id
                                                )?.[0]?.scale_type
                                              }
                                              name={"FormEditCoach.scale type"}
                                              options={GradingScaleTypeOptionsData}
                                              type={"number"}
                                              actions={[
                                                {
                                                  action: "Edit Scale Type",
                                                  trigger: "onchange",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    positionId: item.id,
                                                  },
                                                },
                                              ]}
                                              enableReinitialize={true}
                                            ></SelectDropDown>
                                          </DivBlock>
                                        );
                                      }
                                      return null;
                                    })}
                                </div>
                                <Label className={"label-one"}>Offense</Label>
                                {CurrentUser.user_type === "Position Coach" &&
                                  positionData &&
                                  !positionData?.some(
                                    (elements) =>
                                      elements.category === "Offense"
                                  ) && (
                                    <Label>No position are available.</Label>
                                  )}
                                <div style={{ marginBottom: 30 }}>
                                  {CurrentUser.user_type === "Coach" &&
                                    Positions &&
                                    Positions.map((item, index) => {
                                      if (item.category === "Offense") {
                                        return (
                                          <DivBlock
                                            className={"form-group"}
                                            key={index}
                                          >
                                            <Label className={"label"}>
                                              {item.name}
                                            </Label>

                                            <SelectDropDown
                                              className={
                                                "form-control-hide-icon"
                                              }
                                              disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                                              initialValue={
                                                GetScaleTypeData &&
                                                isArray(GetScaleTypeData) &&
                                                GetScaleTypeData?.filter(
                                                  (k) =>
                                                    k.position_id === item.id
                                                )?.[0]?.scale_type
                                              }
                                              name={"FormEditCoach.scale type"}
                                              options={GradingScaleTypeOptionsData}
                                              type={"number"}
                                              actions={[
                                                {
                                                  action: "Edit Scale Type",
                                                  trigger: "onchange",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    positionId: item.id,
                                                  },
                                                },
                                              ]}
                                              enableReinitialize={true}
                                            ></SelectDropDown>
                                          </DivBlock>
                                        );
                                      }
                                      return null;
                                    })}
                                  {CurrentUser.user_type === "Position Coach" &&
                                    positionData &&
                                    positionData?.map((item) => {
                                      if (item.category === "Offense") {
                                        return (
                                          <DivBlock className={"form-group"}>
                                            <Label className={"label"}>
                                              {item.name}
                                            </Label>

                                            <SelectDropDown
                                              className={"form-control"}
                                              disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                                              initialValue={
                                                GetScaleTypeData &&
                                                isArray(GetScaleTypeData) &&
                                                GetScaleTypeData?.filter(
                                                  (k) =>
                                                    k.position_id === item.id
                                                )?.[0]?.scale_type
                                              }
                                              name={"FormEditCoach.scale type"}
                                              options={GradingScaleTypeOptionsData}
                                              type={"number"}
                                              actions={[
                                                {
                                                  action: "Edit Scale Type",
                                                  trigger: "onchange",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    positionId: item.id,
                                                  },
                                                },
                                              ]}
                                              enableReinitialize={true}
                                            ></SelectDropDown>
                                          </DivBlock>
                                        );
                                      }
                                      return null;
                                    })}
                                </div>
                                <Label className={"label-one"}>
                                  Special Teams
                                </Label>
                                {CurrentUser.user_type === "Position Coach" &&
                                  positionData &&
                                  !positionData?.some(
                                    (elements) =>
                                      elements.category === "Special Teams"
                                  ) && (
                                    <Label>No position are available.</Label>
                                  )}
                                {CurrentUser.user_type === "Coach" &&
                                  Positions &&
                                  Positions.map((item, index) => {
                                    if (item.category === "Special Teams") {
                                      return (
                                        <DivBlock className={"form-group"}>
                                          <Label className={"label"}>
                                            {item.name}
                                          </Label>

                                          <SelectDropDown
                                            className={"form-control-hide-icon"}
                                            disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                                            initialValue={
                                              GetScaleTypeData &&
                                              isArray(GetScaleTypeData) &&
                                              GetScaleTypeData?.filter(
                                                (k) => k.position_id === item.id
                                              )?.[0]?.scale_type
                                            }
                                            name={"FormEditCoach.scale type"}
                                            options={GradingScaleTypeOptionsData}
                                            type={"number"}
                                            enableReinitialize={true}
                                            actions={[
                                              {
                                                action: "Edit Scale Type",
                                                trigger: "onchange",
                                                timeout: "",
                                                pollingPeriod: "",
                                                inputVariables: {
                                                  positionId: item.id,
                                                },
                                              },
                                            ]}
                                          ></SelectDropDown>
                                        </DivBlock>
                                      );
                                    }
                                    return null;
                                  })}
                                {CurrentUser.user_type === "Position Coach" &&
                                  positionData &&
                                  positionData?.map((item) => {
                                    if (item.category === "Special Teams") {
                                      return (
                                        <DivBlock className={"form-group"}>
                                          <Label className={"label"}>
                                            {item.name}
                                          </Label>

                                          <SelectDropDown
                                            className={"form-control"}
                                            disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                                            initialValue={
                                              GetScaleTypeData &&
                                              isArray(GetScaleTypeData) &&
                                              GetScaleTypeData?.filter(
                                                (k) => k.position_id === item.id
                                              )?.[0]?.scale_type
                                            }
                                            name={"FormEditCoach.scale type"}
                                            options={GradingScaleTypeOptionsData}
                                            type={"number"}
                                            actions={[
                                              {
                                                action: "Edit Scale Type",
                                                trigger: "onchange",
                                                timeout: "",
                                                pollingPeriod: "",
                                                inputVariables: {
                                                  positionId: item.id,
                                                },
                                              },
                                            ]}
                                            enableReinitialize={true}
                                          ></SelectDropDown>
                                        </DivBlock>
                                      );
                                    }
                                    return null;
                                  })}
                              </FormBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    positionData: () => {
      dispatch({ type: "GET_POSITIONS" });
      return Promise.resolve();
    },
    getScaleData: () => {
      dispatch({ type: "GET_SCALE_TYPE" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GradingScaleType);
