import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock } from "../modules/Basic";
import { FormBlock, Label, TextInput } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import PlayerSideNavComponent from "../components/PlayerSideNavComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { Image } from "../modules/Media";
import ImageCrop from "../modules/ImageCrop";
import { startPageTimer, trackPageDuration } from "../utils/function";

class EditPlayerPage extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      changeImage: "",
      setCropper: "",
      image: "",
      imageName: "",
      width: 0,
      imageCrop: 300,
      imageURl: "",
      errorMessage: "Click image above to edit.",
      startTime: null,
      profileZoomLevel: 0.1,
    };
  }

  componentDidMount() {
    startPageTimer("Edit Profile Player");
    this.setState({ startTime: Date.now() });
    if (this.props?.CurrentUser) {
      this.setState({
        changeImage:
          process.env.REACT_APP_PROFILE +
          this.props?.CurrentUser?.profile_image?.medium,
      });
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.CurrentUser !== this.props.CurrentUser) {
      this.setState({
        changeImage:
          process.env.REACT_APP_PROFILE +
          this.props?.CurrentUser?.profile_image?.medium,
      });
    }
  }

  componentWillUnmount() {
    trackPageDuration("Edit Profile Player", this.state.startTime);
  }

  handleChange = (e) => {
    e.preventDefault();
    let files;
    if (e.target) {
      var img = document.createElement("img");
      var that = this;
      files = e.target.files;
      this.setState({ imageName: e.target.files?.[0]?.name });
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onloadend = (ended) => {
        img.src = ended.target.result;
        img.onload = function () {
          that.setState({ width: this.width });
          if (this.width >= 300) {
            if (this.width >= 600) {
              that.setState({ imageCrop: 600 });
            }
            that.setState({
              changeImage: reader.result,
              modal: true,
              errorMessage: "",
            });
          } else {
            that.setState({
              errorMessage: "Image resolution should be more than 300px.",
            });
          }
        };
      };
    }
  };

  setProfileLogoCanvasAndCropBoxData = () => {
    const { setCropper } = this.state;
    if (setCropper) {
      const imageData = setCropper.getImageData();
      const containerData = setCropper.getContainerData();

      const cropBoxSize = 300; // Fixed crop box size (300x300)
      let canvasWidth, canvasHeight, canvasLeft, canvasTop;

      // Calculate the aspect ratio of the image
      const imageAspectRatio = imageData.width / imageData.height;

      // Fit the image to the cropping area (300x300) while maintaining aspect ratio
      if (imageAspectRatio > 1) {
        // Landscape image or very wide
        canvasHeight = cropBoxSize; // Set height to match the crop box height
        canvasWidth = canvasHeight * imageAspectRatio; // Calculate width based on aspect ratio

        // If the width exceeds the cropping area, scale down to fit
        if (canvasWidth > cropBoxSize) {
          canvasWidth = cropBoxSize;
          canvasHeight = canvasWidth / imageAspectRatio;
        }
      } else {
        // Portrait image or very tall
        canvasWidth = cropBoxSize; // Set width to match the crop box width
        canvasHeight = canvasWidth / imageAspectRatio; // Calculate height based on aspect ratio

        // If the height exceeds the cropping area, scale down to fit
        if (canvasHeight > cropBoxSize) {
          canvasHeight = cropBoxSize;
          canvasWidth = canvasHeight * imageAspectRatio;
        }
      }

      // Center the image within the cropping area
      canvasLeft = (containerData.width - canvasWidth) / 2;
      canvasTop = (containerData.height - canvasHeight) / 2;

      // Set the fixed crop box dimensions (300x300)
      setCropper.setCropBoxData({
        left: (containerData.width - cropBoxSize) / 2,
        top: (containerData.height - cropBoxSize) / 2,
        width: cropBoxSize,
        height: cropBoxSize,
      });

      // Set the canvas data to center and fit the image within the cropping area
      setCropper.setCanvasData({
        left: canvasLeft,
        top: canvasTop,
        width: canvasWidth,
        height: canvasHeight,
      });
    }
  };

  handleCropperInitialized = (instance) => {
    this.setState({ setCropper: instance }, () => {
      setTimeout(() => {
        if (this.state.setCropper) {
          const imageData = this.state.setCropper.getImageData();
          const cropBoxSize = 300; // Fixed crop box size (300x300)

          // Calculate minimum zoom based on the image's natural dimensions
          const minZoomWidth = cropBoxSize / imageData.naturalWidth;
          const minZoomHeight = cropBoxSize / imageData.naturalHeight;
          const minProfileZoomLevel = Math.min(minZoomWidth, minZoomHeight);

          // Set the initial zoom level and min zoom level in state
          this.setState({
            profileZoomLevel: minProfileZoomLevel,
            minProfileZoomLevel,
          });

          // Apply the initial zoom to the cropper
          this.state.setCropper.zoomTo(minProfileZoomLevel);

          // Set the canvas and crop box data
          this.setProfileLogoCanvasAndCropBoxData();
        }
      }, 100); // Adjust the delay as needed
    });
  };

  handleProfileZoomChange = (event) => {
    let newZoomLevel = parseFloat(event.target.value);

    if (this.state.setCropper) {
      // Clamp the zoom level to the calculated minimum zoom level
      newZoomLevel = Math.max(
        newZoomLevel,
        this.state.minProfileZoomLevel || 0.1
      );

      // Zoom the image
      this.state.setCropper.zoomTo(newZoomLevel);

      // Update the state with the new zoom level
      this.setState({ profileZoomLevel: newZoomLevel });

      // Center the crop box
      const cropBoxData = this.state.setCropper.getCropBoxData();
      const cropBoxCenterX = cropBoxData.left + cropBoxData.width / 2;
      const cropBoxCenterY = cropBoxData.top + cropBoxData.height / 2;

      const newImageData = this.state.setCropper.getImageData();
      const newCropBoxLeft = cropBoxCenterX - cropBoxData.width / 2;
      const newCropBoxTop = cropBoxCenterY - cropBoxData.height / 2;

      this.state.setCropper.setCropBoxData({
        left: newCropBoxLeft,
        top: newCropBoxTop,
        width: cropBoxData.width,
        height: cropBoxData.height,
      });
    }
  };

  render() {
    let { CurrentUser, FormEditPlayerErrors } = this.props;

    const getCropData = () => {
      if (this.state.setCropper !== "undefined") {
        const cropper = this.state.setCropper;
        const croppedCanvas = cropper.getCroppedCanvas({
          width: this.state.imageCrop,
          height: this.state.imageCrop,
        });

        let dataURL;

        if (
          this.state.imageName.toLowerCase().endsWith(".jpeg") ||
          this.state.imageName.toLowerCase().endsWith(".jpg")
        ) {
          // Create a new canvas for JPEG images
          const newCanvas = document.createElement("canvas");
          newCanvas.width = croppedCanvas.width;
          newCanvas.height = croppedCanvas.height;

          // Get the context of the new canvas
          const ctx = newCanvas.getContext("2d");

          // Set the background color to white
          ctx.fillStyle = "#ffffff";
          ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);

          // Draw the cropped image onto the new canvas
          ctx.drawImage(croppedCanvas, 0, 0);

          // Get the data URL of the new canvas
          dataURL = newCanvas.toDataURL("image/jpeg");
        } else {
          // Get the data URL of the original cropped canvas for non-JPEG images
          dataURL = croppedCanvas.toDataURL();
        }

        // Set the state with the new image data
        this.setState({
          image: dataURL,
          modal: false,
        });

        const imageData = dataURLtoFile(dataURL, this.state.imageName);
        this.setState({
          image: imageData,
          changeImage: URL.createObjectURL(imageData),
        });
      }
    };

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl?.split(","),
        mime = arr?.[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr?.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    return (
      <Fragment>
        <Body className={"body"}>
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              data-snippet-id={297}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>
            <DivBlock className={"body-container"} style={{}}>
              <PlayerSideNavComponent
                data-snippet-id={302}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></PlayerSideNavComponent>
              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <Heading
                        className={"h2"}
                        style={{ justifyContent: "center" }}
                        type={"h2"}
                      >
                        Edit Profile
                      </Heading>
                      <Columns
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Column
                          lg={8}
                          md={8}
                          sm={12}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          xs={12}
                        >
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              <FormBlock
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                                actions={[
                                  {
                                    action: "Login",
                                    trigger: "onsubmit",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Email</Label>
                                  <TextInput
                                    className={"form-control"}
                                    initialValue={CurrentUser["email"]}
                                    name={"FormEditPlayer.email"}
                                    placeholder={""}
                                    enableReinitialize={true}
                                  ></TextInput>
                                  <If
                                    condition={
                                      FormEditPlayerErrors &&
                                      FormEditPlayerErrors["email"]
                                        ? true
                                        : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormEditPlayerErrors
                                          ? FormEditPlayerErrors["email"]
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Name</Label>
                                  <TextInput
                                    className={"form-control"}
                                    initialValue={CurrentUser["name"]}
                                    name={"FormEditPlayer.name"}
                                    placeholder={""}
                                    enableReinitialize={true}
                                  ></TextInput>
                                  <If
                                    condition={
                                      FormEditPlayerErrors &&
                                      FormEditPlayerErrors["email"]
                                        ? true
                                        : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormEditPlayerErrors
                                          ? FormEditPlayerErrors["name"]
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>
                                <DivBlock
                                  className={"form-group"}
                                  onClick={() => this.imageRef.current.click()}
                                >
                                  <Label className={"label"}>
                                    Profile Image
                                  </Label>
                                  <Image
                                    src={this.state.changeImage}
                                    style={{
                                      height: "150px",
                                      width: "150px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </DivBlock>
                                <Span className={"invalid-feedback"}>
                                  {this.state.errorMessage}
                                </Span>
                                <DivBlock className="remove-input">
                                  <input
                                    type="file"
                                    ref={this.imageRef}
                                    key={this.state.changeImage || ""}
                                    onChange={(e) => this.handleChange(e)}
                                    accept=".jpg, .jpeg, .png"
                                  />
                                </DivBlock>
                                <Button
                                  className={"btn btn-primary"}
                                  actions={[
                                    {
                                      action: "Edit Player",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        image: this.state.image,
                                      },
                                    },
                                  ]}
                                >
                                  Update
                                </Button>
                              </FormBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                      <Columns
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Column
                          lg={8}
                          md={8}
                          sm={12}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          xs={12}
                        >
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              <FormBlock
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                                actions={[
                                  {
                                    action: "Login",
                                    trigger: "onsubmit",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Password</Label>
                                  <TextInput
                                    className={"form-control"}
                                    name={"FormEditPlayer.password"}
                                    placeholder={""}
                                    type={"password"}
                                  ></TextInput>
                                  <If
                                    condition={
                                      FormEditPlayerErrors &&
                                      FormEditPlayerErrors["password"]
                                        ? true
                                        : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormEditPlayerErrors
                                          ? FormEditPlayerErrors["password"]
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>
                                <Button
                                  className={"btn btn-primary"}
                                  actions={[
                                    {
                                      action: "Edit Player Password",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {},
                                    },
                                  ]}
                                >
                                  Update
                                </Button>
                              </FormBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                      <If
                        condition={this.state.modal ? true : false}
                        show={false}
                      >
                        <Then>
                          <DivBlock className={"modal"}>
                            <DivBlock className={"modal-dialog"}>
                              <DivBlock className={"modal-dialog"}>
                                <DivBlock className={"modal-content"}>
                                  <DivBlock className={"modal-header"}>
                                    <Heading
                                      className="modal-heading"
                                      type={"h5"}
                                    >
                                      Crop Image Before Upload
                                    </Heading>

                                    <span
                                      className={"close"}
                                      onClick={() =>
                                        this.setState({
                                          modal: false,
                                          changeImage: this.props?.CurrentUser
                                            ?.profile_image?.medium
                                            ? process.env.REACT_APP_PROFILE +
                                              this.props?.CurrentUser
                                                ?.profile_image?.medium
                                            : null,
                                          image: "",
                                        })
                                      }
                                    >
                                      x
                                    </span>
                                  </DivBlock>
                                  <DivBlock
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      overflow: "auto",
                                    }}
                                  >
                                    <DivBlock style={{ width: "70%" }}>
                                      <ImageCrop
                                        initialAspectRatio={1 / 1}
                                        // aspectRatio={1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={this.state.changeImage}
                                        onInitialized={
                                          this.handleCropperInitialized
                                        }
                                        viewMode={0}
                                        dragMode="move"
                                        cropBoxMovable={false}
                                        checkOrientation={false}
                                        toggleDragModeOnDblclick={false}
                                        modal
                                        background={false}
                                        minCropBoxHeight={100}
                                        minCropBoxWidth={100}
                                        zoomOnWheel={false}
                                      />
                                    </DivBlock>
                                    <DivBlock
                                      style={{
                                        width: "30%",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <DivBlock
                                        className="img-preview"
                                        style={{
                                          width: "100%",
                                          height: "300px",
                                        }}
                                      />
                                    </DivBlock>
                                  </DivBlock>
                                  {/* <DivBlock style={{ marginLeft: "10px" }}>
                                    <Span className={"invalid-feedback"}>
                                      Note : Zoom in or zoom out for cropping
                                      the image.
                                    </Span>
                                  </DivBlock> */}
                                  <DivBlock
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <input
                                      type="range"
                                      value={this.state.profileZoomLevel}
                                      onChange={this.handleProfileZoomChange}
                                      min={
                                        this.state.minProfileZoomLevel || 0.1
                                      } // Set the minimum dynamically
                                      max="3"
                                      step="0.1"
                                    />
                                    <Span
                                      className={"invalid-feedback"}
                                      style={{ color: "black" }}
                                    >
                                      Adjust the zoom level using the slider.
                                    </Span>
                                  </DivBlock>
                                  <DivBlock
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "20px",
                                    }}
                                  >
                                    <button
                                      className={"btn btn-reset DeleteBtn"}
                                      onClick={() =>
                                        this.setState({
                                          modal: false,
                                          changeImage: this.props?.CurrentUser
                                            ?.profile_image?.medium
                                            ? process.env.REACT_APP_PROFILE +
                                              this.props?.CurrentUser
                                                ?.profile_image?.medium
                                            : null,
                                          image: "",
                                        })
                                      }
                                    >
                                      Cancel
                                    </button>

                                    <button
                                      className={"btn btn-primary"}
                                      onClick={getCropData}
                                    >
                                      Crop
                                    </button>
                                  </DivBlock>
                                </DivBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </Then>
                      </If>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(EditPlayerPage);
