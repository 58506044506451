import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { Provider, connect } from "react-redux";
import thunk from "redux-thunk";
import * as effects from "redux-saga/effects";
import { syncHistoryWithStore, routerMiddleware } from "react-router-redux";
import { hashHistory } from "react-router";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PlayReportsDataPage from "./pages/PlayReportsDataPage";
import SignUpPage from "./pages/SignUpPage";
import PlayerReportsPage from "./pages/PlayerReportsPage";
import ManageTagsPage from "./pages/ManageTagsPage";
import PlayReportsPage from "./pages/PlayReportsPage";
import PlayerGradesDataPage from "./pages/PlayerGradesDataPage";
import SupportPage from "./pages/SupportPage";
import PlayerReportsDataPage from "./pages/PlayerReportsDataPage";
import LoginPage from "./pages/LoginPage";
import DepthChartPage from "./pages/DepthChartPage";
import AddDepthChartPage from "./pages/AddDepthChartPage";
import DepthChartListsPage from "./pages/DepthChartListsPage";
import SubmitIntangiblesPage from "./pages/SubmitIntangiblesPage";
import InjuredPlayersPage from "./pages/InjuredPlayersPage";
import ComparePlayersPage from "./pages/ComparePlayersPage";
import ExercisesPage from "./pages/ExercisesPage";
import TrainingPage from "./pages/TrainingPage";
import RosterPage from "./pages/RosterPage";
import PlayerDepthChartsPage from "./pages/PlayerDepthChartsPage";
import PlayerDepthChartPage from "./pages/PlayerDepthChartPage";
import PlayerGradesPage from "./pages/PlayerGradesPage";
import PlayerExercisesPage from "./pages/PlayerExercisesPage";
import FilesPage from "./pages/FilesPage";
import EditCoachPage from "./pages/EditCoachPage";
import EditPlayerPage from "./pages/EditPlayerPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import RequestPasswordResetPage from "./pages/RequestPasswordResetPage";
import ViewPlayerPage from "./pages/ViewPlayerPage";
import DepthChartPositionUsersPage from "./pages/DepthChartPositionUsersPage";
import HeadCoachPage from "./pages/HeadCoachPage";
import PlayGradesPage from "./pages/PlayGradesPage";
import PlayByPlayGradingPage from "./pages/PlayByPlayGradingPage";
import HomePage from "./pages/HomePage";
import GradingScaleType from "./pages/GradingScaleType";
import Loader from "../src/modules/Loader";

// import store from './redux/store';
import reducer from "./redux/reducer.js";
import mainSaga from "./sagas/saga.js";
import { initEffects } from "./sagas/saga.js";
import myMiddleware from "./redux/middleware.js";
import mixpanel from "mixpanel-browser";
import ProductionPointPage from "./pages/ProductionPointPage";
import AddProductionPoint from "./pages/AddProductionPoint";
import AdminSchoolReportPage from "./pages/AdminSchoolReportPage.js";
import { decodeToken, superAdmin } from "./utils/function.js";
import NotFoundPage from "./pages/NotFoundPage.js";

const blockedIpAdress = "122.170.99.65";

mixpanel.init(process.env.REACT_APP_ANALYTICS, {
  ignore_ip: [blockedIpAdress],
});

//import './index.css';

// initEffects(effects);
const sagaMiddleware = createSagaMiddleware();
const reactRouterMiddleware = routerMiddleware(hashHistory);
const store = createStore(
  reducer,
  applyMiddleware(reactRouterMiddleware, myMiddleware, sagaMiddleware, thunk)
);
sagaMiddleware.run(mainSaga);

syncHistoryWithStore(hashHistory, store);

class _App extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: "on_app_started" });
    // dispatch({ type: "GET_SEASON_YEAR" });
  }

  componentDidUpdate() {
    const { dispatch } = this.props;
    if (localStorage.getItem("token")) {
      if (
        Object.keys(this.props?.state?.reducer?.CurrentUser || {})?.length === 0
      ) {
        dispatch({ type: "GET_CURRENT_USER" });
      }
      if (this.props?.state?.reducer?.SeasonYearData.length <= 0) {
        dispatch({ type: "GET_SEASON_YEAR" });
      }
    }
  }

  render() {
    let { state } = this.props;
    const token = localStorage.getItem("token");
    const decodedToken = decodeToken(token);
    if (
      !token &&
      window.location.href !== `${process.env.REACT_APP_URL + "login"}` &&
      window.location.href !== `${process.env.REACT_APP_URL + "signup"}` &&
      window.location.href !==
        `${process.env.REACT_APP_URL + "request_password_reset"}` &&
      window.location.href !== `${process.env.REACT_APP_URL}`
    ) {
      localStorage.setItem("previousRoute", window.location.hash);
    }

    return (
      <div style={{ height: "100%", width: "100%" }}>
        {state.reducer?.isLoaded ? (
          <Loader isLoaded={state.reducer?.isLoaded} />
        ) : null}
        <Router>
          <Switch>
            <Route
              path="/login"
              exact={true}
              render={() => (token ? <Redirect to="/" /> : <LoginPage />)}
            />

            <Route
              path="/signup"
              exact={true}
              render={() => (token ? <Redirect to="/" /> : <SignUpPage />)}
            />

            <Route
              path="/request_password_reset"
              exact={true}
              render={() =>
                token ? <Redirect to="/" /> : <RequestPasswordResetPage />
              }
            />

            <Route
              path="/reset_password"
              exact={true}
              render={() =>
                token ? <Redirect to="/" /> : <ResetPasswordPage />
              }
            />

            <Route
              path="/add_production_point"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : superAdmin?.includes(decodedToken?.email) ? (
                  <AddProductionPoint />
                ) : (
                  <Redirect to="/roster" />
                )
              }
            />

            <Route
              path="/roster"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <RosterPage />
                )
              }
            />

            <Route
              path="/depth_chart_lists"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <DepthChartListsPage />
                )
              }
            />

            <Route
              path="/add_depth_chart"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <AddDepthChartPage />
                )
              }
            />

            <Route
              path="/depth_chart"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <DepthChartPage />
                )
              }
            />

            <Route
              path="/injured_players"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <InjuredPlayersPage />
                )
              }
            />

            <Route
              path="/manage-tags"
              exact={true}
              render={() =>
                state.reducer["CurrentUser"]?.["user_type"] ===
                state.reducer["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <ManageTagsPage />
                )
              }
            />

            <Route
              path="/depth_chart_position_users"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <DepthChartPositionUsersPage />
                )
              }
            />

            <Route
              path="/intangibles"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <SubmitIntangiblesPage />
                )
              }
            />

            <Route
              path="/production_points"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductionPointPage />
                )
              }
            />

            <Route
              path="/exercises"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <ExercisesPage />
                )
              }
            />

            <Route
              path="/support"
              exact={true}
              render={() =>
                !token ? <Redirect to="/login" /> : <SupportPage />
              }
            />

            <Route
              path="/game_files"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <FilesPage />
                )
              }
            />

            <Route
              path="/edit_coach"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <EditCoachPage />
                )
              }
            />

            <Route
              path="/school_report"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : superAdmin?.includes(decodedToken?.email) ? (
                  <AdminSchoolReportPage />
                ) : (
                  <Redirect to="/roster" />
                )
              }
            />

            <Route
              path="/grading_scales"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <GradingScaleType />
                )
              }
            />

            <Route
              path="/play_grades"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <PlayGradesPage />
                )
              }
            />

            <Route
              path="/play_by_play"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <PlayByPlayGradingPage />
                )
              }
            />

            <Route
              path="/player_reports"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <PlayerReportsPage />
                )
              }
            />

            <Route
              path="/play_reports"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <PlayReportsPage />
                )
              }
            />

            <Route
              exact={true}
              path="/play_reports_data"
              render={() =>
                state.reducer["CurrentUser"]["user_type"] ===
                state.reducer["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <PlayReportsDataPage />
                )
              }
            />

            <Route
              exact={true}
              path="/player_reports_data"
              render={() =>
                state.reducer["CurrentUser"]["user_type"] ===
                state.reducer["Player"] ? (
                  <Redirect to="/roster" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <PlayerReportsDataPage />
                )
              }
            />

            <Route
              path="/player_comparison"
              exact={true}
              render={() =>
                state.reducer["CurrentUser"]["user_type"] ===
                state.reducer["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <ComparePlayersPage />
                )
              }
            />

            <Route
              path="/training"
              exact={true}
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <TrainingPage />
                )
              }
            />

            <Route
              exact={true}
              path="/coaches"
              render={() =>
                state.reducer?.["CurrentUser"]?.["user_type"] ===
                state.reducer?.["Player"] ? (
                  <Redirect to="/" />
                ) : !token ? (
                  <Redirect to="/login" />
                ) : (
                  <HeadCoachPage />
                )
              }
            />

            <Route
              exact={true}
              path={["/player_depth_charts", "/player_home"]}
              render={() =>
                !token ? (
                  <Redirect to="/login" />
                ) : state.reducer?.["CurrentUser"]?.["user_type"] ===
                  state.reducer?.["Player"] ? (
                  <PlayerDepthChartsPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />

            <Route
              exact={true}
              path="/player_grades_data"
              render={() =>
                !token ? <Redirect to="/login" /> : <PlayerGradesDataPage />
              }
            />

            <Route
              exact={true}
              path="/edit_player"
              render={() =>
                !token ? <Redirect to="/login" /> : <EditPlayerPage />
              }
            />

            <Route
              exact={true}
              path="/player_grades"
              render={() =>
                !token ? <Redirect to="/login" /> : <PlayerGradesPage />
              }
            />

            <Route
              exact={true}
              path="/player_exercises"
              render={() =>
                !token ? <Redirect to="/login" /> : <PlayerExercisesPage />
              }
            />

            <Route
              exact={true}
              path="/player_depth_chart"
              render={() =>
                !token ? <Redirect to="/login" /> : <PlayerDepthChartPage />
              }
            />
            <Route
              path="/player/:id"
              exact={true}
              render={() => <ViewPlayerPage />}
            />
            <Route
              path="/player"
              exact={true}
              render={() => <NotFoundPage />}
            />
            <Route
              path="/:id"
              exact
              // render={({ match }) => (
              //   <Redirect to={`/player/${match.params.id}`} />
              // )}
              render={({ match }) => {
                const directId = match.params.id;
                const idParts = directId.split("-");
                const lastElement = idParts[idParts.length - 1];
                const isValidId = /^\d+$/.test(lastElement);

                if (isValidId) {
                  return <Redirect to={`/player/${directId}`} />;
                }

                return <NotFoundPage />;
              }}
            />
            <Route path={"*"} render={() => <Redirect to="/roster" />} />
            {/* <Route path="/:id" exact={true} render={() => <ViewPlayerPage />} />
            <Route path="/player/:id" exact={true} render={() => <ViewPlayerPage />} /> */}
            <Route
              path="/"
              exact={true}
              render={() =>
                !token ? (
                  <Redirect to="/login" />
                ) : state.reducer?.["CurrentUser"]?.["user_type"] ===
                  "Player" ? (
                  <Redirect to="/player_home" />
                ) : state.reducer?.["CurrentUser"]?.["user_type"] !==
                  state.reducer?.["Player"] ? (
                  <Redirect to="/roster" />
                ) : (
                  <HomePage />
                )
              }
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    state: state,
  };
};

const App = connect(mapStateToProps, null)(_App);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
