import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Button, DivBlock, LinkBlock } from "../modules/Basic";
import { Heading, Span } from "../modules/Typography";
import { SelectDropDown } from "../modules/Forms";

class DepthChartControlsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
        call: '',
    }
}
  render() {
    //let { row, index, errors } = this.props;
    let {
      AllTags,
      DepthChartListsCategoryFilter,
      Emptystring,
      DepthChartType,
      SelectedWeekId,
      SelectedTagId,
      SelectedTypeId,
      SeasonYearData,
      selectedSeasonYear
    } = this.props;
    const { Weeks } = window;

    let value = {id :0 ,name:'All'}
    const handleWeeks = () => {
      let finalOutput = [...[value],...Weeks()]
      return finalOutput;
    }

    
    const handleTags = () =>{
      let valueTags = {label:'All',id:0,}
      let newTag = AllTags && isArray(AllTags) && AllTags?.map(({ tag: label, Id: value, ...rest }) => ({
        label,
        value,
        ...rest,
      }));
      if(newTag){
        let finalOutput = [...[valueTags],...newTag]  
        return finalOutput;
      }
    }

    return (
      <Fragment>
        <DivBlock
          className={"mb-4"}
          context={{
            elements: {
              1329: { type: "DivBlock" },
              1330: { type: "DivBlock" },
              1331: { type: "Heading" },
              1332: { type: "Span" },
              1333: { type: "Span" },
              1334: { type: "DivBlock" },
              1335: { type: "DivBlock" },
              1336: { type: "DivBlock" },
              1337: { type: "Span" },
              1338: { type: "Span" },
              1339: { type: "If" },
              1340: { type: "Then" },
              1341: { type: "DivBlock" },
              1342: { type: "LinkBlock" },
              1343: { type: "Span" },
              1344: { type: "DivBlock" },
              1345: { type: "DivBlock" },
              1346: { type: "Span" },
              1347: { type: "Span" },
              1348: { type: "If" },
              1349: { type: "Then" },
              1350: { type: "DivBlock" },
              1351: { type: "DivBlock" },
              1352: { type: "LinkBlock" },
              1353: { type: "Span" },
              1354: { type: "DivBlock" },
              1355: { type: "LinkBlock" },
              1356: { type: "Span" },
              1357: { type: "DivBlock" },
              1358: { type: "LinkBlock" },
              1359: { type: "Button" },
              1360: { type: "DivBlock" },
              1361: { type: "LinkBlock" },
              1362: { type: "Button" },
              1363: { type: "DivBlock" },
              1364: { type: "LinkBlock" },
              1365: { type: "Button" },
              1366: { type: "DivBlock" },
              1367: { type: "DivBlock" },
              1368: { type: "Button" },
              1369: { type: "DivBlock" },
              1370: { type: "Button" },
              1371: { type: "DivBlock" },
              1372: { type: "Button" },
              1373: { type: "DivBlock" },
              1374: { type: "DivBlock" },
              1375: { type: "Button" },
              545: { type: "Body" },
              546: { type: "DivBlock" },
              547: { type: "DivBlock" },
              558: { type: "DivBlock" },
              559: { type: "DivBlock" },
              621: { type: "DivBlock" },
              622: { type: "DivBlock" },
              623: { type: "DivBlock" },
              624: { type: "DivBlock" },
              625: { type: "DivBlock" },
              626: { type: "DivBlock" },
              652: { type: "DivBlock" },
              653: { type: "Columns" },
              654: { type: "Column" },
              655: { type: "DivBlock" },
              656: { type: "Heading" },
              657: { type: "Span" },
              658: { type: "Column" },
              659: { type: "DivBlock" },
              660: { type: "DivBlock" },
              661: { type: "Button" },
              662: { type: "If" },
              674: { type: "Button" },
              675: { type: "table" },
              676: { type: "thead" },
              677: { type: "tr" },
              678: { type: "th" },
              679: { type: "Span" },
              680: { type: "th" },
              681: { type: "Span" },
              682: { type: "th" },
              683: { type: "Span" },
              684: { type: "th" },
              685: { type: "Span" },
              686: { type: "th" },
              687: { type: "Span" },
              688: { type: "th" },
              689: { type: "Span" },
              690: { type: "th" },
              691: { type: "Span" },
              692: { type: "th" },
              693: { type: "Span" },
              694: { type: "tbody" },
              695: { type: "tr" },
              696: { type: "td" },
              697: { type: "LinkBlock" },
              698: { type: "DivBlock" },
              699: { type: "Span" },
              700: { type: "td" },
              701: { type: "DivBlock" },
              702: { type: "Span" },
              703: { type: "td" },
              704: { type: "DivBlock" },
              705: { type: "Span" },
              706: { type: "td" },
              707: { type: "DivBlock" },
              708: { type: "Span" },
              709: { type: "td" },
              710: { type: "DivBlock" },
              711: { type: "Span" },
              712: { type: "td" },
              713: { type: "DivBlock" },
              714: { type: "Span" },
              715: { type: "td" },
              716: { type: "DivBlock" },
              717: { type: "Span" },
              718: { type: "td" },
              719: { type: "If" },
              723: { type: "DivBlock" },
              724: { type: "Button" },
              725: { type: "If" },
              752: { type: "If" },
              786: { type: "If" },
              805: { type: "If" },
              824: { type: "If" },
              843: { type: "If" },
              859: { type: "If" },
            },
            getters: {},
            listeners: [
              { name: "mouseOver.boundaries" },
              { name: "mouseOut.boundaries" },
              { name: "dragEnter" },
              { name: "dragLeave" },
              { name: "dragEnd" },
              { name: "mouseDown.mousemenu" },
              { name: "showSettingsBox.desk" },
              { name: "textChanged.desk" },
              { name: "editTextMode.desk" },
              { name: "componentClick.desk" },
              { name: "pathnameChanged.desk" },
              { name: "cut.desk" },
              { name: "copy.desk" },
              { name: "create_prefab.desk" },
              { name: "create_partial.desk" },
              { name: "editSnippet.desk" },
              { name: "clone.desk" },
              { name: "delete.desk" },
              { name: "before.desk" },
              { name: "after.desk" },
              { name: "first.desk" },
              { name: "last.desk" },
              { name: "replace.desk" },
              { name: "select.desk" },
              { name: "highlight.desk" },
              { name: "dropZoneHover" },
              { name: "dragEnd" },
              { name: "dragStart" },
              { name: "copyStyleProps" },
              { name: "pasteStyleProps" },
              { name: "updateSelectedProps" },
              { name: "dragStart" },
            ],
            triggerCache: {
              componentClick: [{ name: "componentClick.desk" }],
              editSnippet: [{ name: "editSnippet.desk" }],
              mouseDown: [{ name: "mouseDown.mousemenu" }],
              mouseOut: [{ name: "mouseOut.boundaries" }],
              mouseOver: [{ name: "mouseOver.boundaries" }],
              pathnameChanged: [{ name: "pathnameChanged.desk" }],
            },
          }}
          df-snippet-instance-id={301}
          draggable={true}
          eleKey={"626"}
          inline={{ "df-snippet-instance-id": 301, draggable: true }}
          isEdit={true}
          root-snippet={true}
          style={{}}
        >
          <DivBlock df-snippet-instance-id={301} style={{}}>
            <DivBlock
              className="game_title"
              style={{
                backgroundColor: "#f8f8fb",
                color: "#ffffff",
                display: "flex",
                marginBottom: "0.5em",
                paddingBottom: "0px",
              }}
            >
              <Heading className={"h2"} style={{fontSize:'30px',fontWeight:'700'}} type={"h2"}>
              Depth Charts
              </Heading>

              <Span className={"h2 h2-span"} style={{fontWeight:600}}>
              Depth Charts /
              </Span>

              <Span className={"h2 h2-inner-span"} style={{}}>
              All Depth Charts
              </Span>
            </DivBlock>
          </DivBlock>

          {/* <DivBlock
            className={"DepthButton-div"}
            df-snippet-instance-id={301}
            style={{}}
            actions={[
              {
                action: "",
                trigger: "",
                timeout: "",
                pollingPeriod: "",
                inputVariables: {},
              },
            ]}
          > */}
          <DivBlock
            className={"dropdown-option depth-chart-wrap"}>
            {/* <Heading className={"h4"} style={{color:'black'}} type={"h4"}>
              Filter by :
            </Heading> */}
            <DivBlock
              className={"dropdown-option dp-dropdown"}
            >
              <Heading
                style={{
                  fontFamily:"'Poppins', sans-serif",
                  fontWeight:"500",
                  fontSize: "14px",
                  marginBottom: '5px',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                }}
                type={"h5"}
              >
                Week
              </Heading>
              <SelectDropDown
                style={{
                  color:'black',
                  borderColor:'white',
                  paddingTop:'6px',
                  paddingBottom:'6px',
                  paddingLeft:'10px',
                  paddingRight:'10px',
                  borderRadius:'5px',
                }}
                className={"form-control"}
                name={"SelectedWeekId"}
                dataSetLabel={"name"}
                dataSetValue={"id"}
                enableReinitialize={true}
                initialValue={SelectedWeekId}
                type={"number"}
                dataSet={handleWeeks()&&isArray(handleWeeks())?handleWeeks():[]}
                options={[]}
                actions={[
                  {
                    action: "Set Depth Chart List Week Filter",
                    trigger: "onchange",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: {
                      "category filter": Emptystring,
                      // "input depth chart list week filter": "",
                      "tag filter": null,
                    },
                  },
                ]}
              ></SelectDropDown>
            </DivBlock>

            <DivBlock
              className={"dropdown-option dp-dropdown"}>
             <Heading
                style={{
                  fontFamily:"'Poppins', sans-serif",
                  fontWeight:"500",
                  fontSize: "14px",
                  marginBottom: '5px',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                }}
                type={"h5"}
              >
                Type
              </Heading>
              <SelectDropDown
                style={{
                  color:'black',
                  borderColor:'white',
                  paddingTop:'6px',
                  paddingBottom:'6px',
                  paddingLeft:'10px',
                  paddingRight:'10px',
                  borderRadius:'5px',
                }}
                className={"form-control"}
                name={"SelectedTypeId"}
                dataSetLabel={"name"}
                dataSetValue={"name"}
                dataSet={DepthChartType}
                enableReinitialize={true}
                initialValue={SelectedTypeId}
                options={[]}
                type={"number"}
                actions={[
                  {
                    action: "Set Depth Chart List Category Filter",
                    trigger: "onchange",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { },
                  },
                ]}
              ></SelectDropDown>
            </DivBlock>

            <DivBlock
              className={"dropdown-option dp-dropdown"}>
              <Heading
                style={{
                  fontFamily:"'Poppins', sans-serif",
                  fontWeight:"500",
                  fontSize: "14px",
                  marginBottom: '5px',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                }}
                type={"h5"}
              >
                Tag
              </Heading>

              <SelectDropDown
                style={{
                  color:'black',
                  borderColor:'white',
                  paddingTop:'6px',
                  paddingBottom:'6px',
                  paddingLeft:'10px',
                  paddingRight:'10px',
                  borderRadius:'5px',
              }}
                className={"form-control"}
                name={"SelectedTagId"}
                type={"number"}
                dataSet={handleTags()}
                initialValue={SelectedTagId}
                enableReinitialize={true}
                options={[]}
                dataSetLabel={"label"}
                dataSetValue={"label"}
                actions={[
                  {
                    action: "Set Depth Chart List Category Filter",
                    trigger: "onchange",
                    timeout: "-1",
                    pollingPeriod: "",
                    inputVariables: {
                      "input category filter":
                        DepthChartListsCategoryFilter,
                      // "input tag filter": row["tag"],
                    },
                  },
                ]}
              ></SelectDropDown>
            </DivBlock>
            <DivBlock className="button-container">

            <DivBlock
              className={"addbtn-div"}
              df-snippet-instance-id={301}
            >
              <LinkBlock
                df-snippet-instance-id={301}
                duration={1000}
                href={"/depth_chart_lists"}
                target={"_self"}
                type={"spa"}
              >
                <Button
                  className={"Depthchart-Div btn btn-reset"}
                  df-snippet-instance-id={301}
                  style={{ marginLeft:'15px',}}
                  actions={[
                    {
                      action: "Reset Depth Chart",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input categoryfilter": null,
                        "input depthchart list week filter": null,
                        "input tagfilter": null,
                        "input tag":'All',
                        "input week":0,
                        "input type":'All',
                      },
                    },
                  ]}
                >
                  Reset
                </Button>
              </LinkBlock>
            </DivBlock>

            <DivBlock
              className={"addbtn-div"}
              df-snippet-instance-id={301}
            >
              <LinkBlock
                df-snippet-instance-id={301}
                duration={1000}
                href={"/injured_players"}
                target={"_self"}
                type={"spa"}
              >
                <Button
                  className={"btn btn-primary Depthchart-div"}
                  df-snippet-instance-id={301}
                  style={{
                    fontWeight:500,
                    backgroundColor:'white',
                    color:'#48c46e',
                    borderWidth:'2px',
                    marginLeft:'15px',
                  }}
                >
                  Injured
                </Button>
              </LinkBlock>
            </DivBlock>

            <DivBlock
              className={"addbtn-div"}
              df-snippet-instance-id={301}
            >
              <LinkBlock
                df-snippet-instance-id={301}
                duration={1000}
                href={"/add_depth_chart"}
                target={"_self"}
                type={"spa"}
              >
                <Button
                  className={"btn btn-primary Depthchart-div"}
                  df-snippet-instance-id={301}
                  disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                  style={
                    SeasonYearData?.[0]?.year === selectedSeasonYear? 
                    {backgroundColor: "#48c46e",
                    borderColor: "#48c46e",
                    fontWeight:500,
                    marginLeft:'15px',}:
                    {backgroundColor: "#a0d2a0",
                    borderColor: "#a0d2a0",
                    fontWeight:500,
                    marginLeft:'15px'}
                  }
                >
                  Add New Package
                </Button>
              </LinkBlock>
            </DivBlock>

            </DivBlock>
          </DivBlock>
          {/* </DivBlock> */}
        </DivBlock>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(DepthChartControlsComponent);
