import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import Collapse from "@material-ui/core/Collapse";
import { Body, Button, DivBlock } from "../modules/Basic";
import {
  Label,
  SelectDropDown,
  FormBlock,
  TextInput,
  FilterReports,
} from "../modules/Forms";
import { Col, Column, Columns, Row } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Paragraph, Span, TextLink } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import BarChart from "../modules/charts/BarChart";
import BarChartLine from "../modules/charts/BarChartLine";
import {
  averageCalculate,
  handleDate,
  handleScaleTypeValue,
  startPageTimer,
  trackPageDuration,
} from "../utils/function";
import BarChartScroll from "../modules/charts/BarChartScroll";
import { Avatar, ButtonBase } from "@material-ui/core";
import { IMAGES } from "../assets";
import IconFilter from "../modules/Forms/components/IconFilter";
import zIndex from "@material-ui/core/styles/zIndex";

class PlayReportsDataPage extends Component {
  constructor(props) {
    super(props);
    this.newPosition = [];
    this.firstPosition = "";
    this.state = {
      rowId: null,
      tabValue: "",
      currentPosition: 0,
      rowAverages: {},
      startTime: null,
      openFilters: false,
      anchorEl: null,
    };
  }

  componentDidMount() {
    startPageTimer("Game And Practice Reports");
    this.setState({ startTime: Date.now() });
    if (!this.props.Positions) {
      this.props.positionData();
    }
    if (!this.props.BaseDepthCharts) {
      this.props.getAllDepthCharts();
    }
    if (!this.props.SeriesTypeArray) {
      this.props.getAllSeries();
    }
    this.props?.getGamePracticeGameData();
  }

  componentWillUnmount() {
    trackPageDuration("Game And Practice Reports", this.state.startTime);
    this.props?.clearGamePracticeData();
  }

  positionsValues = () => {
    this.newPosition = this.props?.Positions?.map(
      ({ name: label, Id: value, ...rest }) => ({
        label,
        value,
        ...rest,
      })
    );
    this?.newPosition?.unshift({ label: "All", id: -1 });
    return this?.newPosition;
  };

  firstPositionValues = () => {
    let firstPosition =
      this.positionsValues() &&
      isArray(this.positionsValues()) &&
      this.positionsValues[0];
    var dataFilter =
      this.props?.Positions &&
      isArray(this.props?.Positions) &&
      this.props?.Positions.find(
        (item, index, array) => item.id == this.props?.SelectedPositionId
      );

    firstPosition =
      dataFilter === undefined ? firstPosition?.name : dataFilter?.name;
    return firstPosition;
  };

  handleSelectDpValue = () => {
    let str = "",
      playTypeName = "",
      positionName = [];
    playTypeName =
      this.props?.PlayTypeArray?.[parseInt(this.props?.SelectedPlayTypeId) + 1]
        ?.label;

    positionName = this.positionsValues()?.filter(
      (item) => item.id === parseInt(this.props?.SelectedPositionId)
    );

    if (
      (this.props?.SelectedCategoryId === "" ||
        this.props?.SelectedCategoryId === "All") &&
      (this.props?.SelectedPlayTypeId === "" ||
        this.props?.SelectedPlayTypeId === "-1") &&
      (this.props?.SelectedQuarterId === "" ||
        this.props?.SelectedQuarterId === "-1") &&
      (this.props?.SelectedSeriesId === "" ||
        this.props?.SelectedSeriesId === "-1") &&
      (this.props?.SelectedDownId === "" ||
        this.props?.SelectedDownId === "-1") &&
      (this.props?.SelectedPositionId === "" ||
        this.props?.SelectedPositionId === "-1")
    ) {
      str = "";
    }
    if (
      this.props?.SelectedCategoryId !== "All" &&
      this.props?.SelectedCategoryId !== ""
    ) {
      str = str + `Category ${this.props?.SelectedCategoryId} / `;
    }
    if (
      this.props?.SelectedPlayTypeId !== "-1" &&
      this.props?.SelectedPlayTypeId !== ""
    ) {
      str = str + `Play Type ${playTypeName} / `;
    }
    if (
      this.props?.SelectedQuarterId !== "" &&
      this.props?.SelectedQuarterId !== "-1"
    ) {
      str = str + `Quarter ${this.props?.SelectedQuarterId} / `;
    }
    if (
      this.props?.SelectedSeriesId !== "" &&
      this.props?.SelectedSeriesId !== "-1"
    ) {
      str = str + `Series ${this.props?.SelectedSeriesId} / `;
    }
    if (
      this.props?.SelectedDownId !== "" &&
      this.props?.SelectedDownId !== "-1"
    ) {
      str = str + `Down ${this.props?.SelectedDownId} / `;
    }

    if (
      this.props?.SelectedPositionId !== "" &&
      this.props?.SelectedPositionId !== "-1"
    ) {
      str = str + `Position ${positionName?.[0]?.label} / `;
    }

    if (
      str !== "" &&
      this.props?.PlayReportsData?.filter((item) => item?.package)?.length > 0
    ) {
      str =
        `${
          this.props?.PlayReportsData?.filter((item) => item?.package)?.length
        } Plays for ` + str;
    }
    if (str !== "") str = str.trim();

    if ((this.props?.SelectedCategoryId === "" ||
      this.props?.SelectedCategoryId === "All") &&
      (this.props?.SelectedPlayTypeId === "" ||
        this.props?.SelectedPlayTypeId === "-1") &&
      (this.props?.SelectedQuarterId === "" ||
        this.props?.SelectedQuarterId === "-1") &&
      (this.props?.SelectedSeriesId === "" ||
        this.props?.SelectedSeriesId === "-1") &&
      (this.props?.SelectedDownId === "" ||
        this.props?.SelectedDownId === "-1") &&
      (this.props?.SelectedPositionId === "" ||
        this.props?.SelectedPositionId === "-1") &&
      this.props?.PlayReportsData?.filter((item) => item?.package)?.length <= 0
    ) {
      str = "No Plays for your selected filters.";
    } else if(this.props?.PlayReportsData?.filter((item) => item?.package)?.length <= 0){
      str = `No Plays for ` + str;
    }
    if (str.charAt(str.length - 1) === "/") {
      str = str.substr(0, str.length - 1);
    }
    return str;
  };

  handleIntangiblesLabel = () => {
    if (
      this.props?.SelectedPositionId === "" ||
      this.props?.SelectedPositionId === "-1" ||
      this.props?.SelectedPositionId === null
    ) {
      return this.props?.AverageByIntangible &&
        isArray(this.props?.AverageByIntangible)
        ? this.props?.AverageByIntangible?.filter(
            (val) => val.name !== null
          ).map((item) => item.name)
        : [];
    } else {
      return this.props?.Intangibles && isArray(this.props?.Intangibles)
        ? this.props?.Intangibles.map((val) => val.name)
        : [];
    }
  };

  handleIntangibleAverages = () => {
    if (
      this.props?.SelectedPositionId === "" ||
      this.props?.SelectedPositionId === "-1" ||
      this.props?.SelectedPositionId === null
    ) {
      if (this.props?.PlayReportsData && isArray(this.props?.PlayReportsData)) {
        let averageArray = [];
        const Intangible =
          this.props?.AverageByIntangible &&
          isArray(this.props?.AverageByIntangible) &&
          this.props?.AverageByIntangible?.map((val) => val.name);
        Intangible &&
          isArray(Intangible) &&
          Intangible?.forEach((value) => {
            const intangible_name = this.findAverages(
              this.handleIntangiblesAvg(value, this.props?.PlayReportsData)
            );
            value
              ? averageArray.push(intangible_name)
              : averageArray.push({ name: "Null" });
          });
        return averageArray;
      }
    } else {
      if (this.props?.PlayReportsData && isArray(this.props?.PlayReportsData)) {
        let averageArray = [];
        const Intangible =
          this.props?.Intangibles &&
          isArray(this.props?.Intangibles) &&
          this.props?.Intangibles?.map((val) => val.name);
        Intangible &&
          isArray(Intangible) &&
          Intangible.forEach((value) => {
            const intangible_name = this.findAverages(
              this.handlePositionIntangibleAvg(
                value,
                this.props?.PlayReportsData
              )
            );
            value
              ? averageArray.push(intangible_name)
              : averageArray.push({ name: "Null" });
          });
        return averageArray;
      }
    }
  };

  findAverages = (avgData) => {
    let modifyData = [];
    [...avgData].forEach((obj) => {
      const newObj = { ...obj?.[0] };
      modifyData.push(newObj);
    });
    return isNaN(
      Number(
        modifyData
          ?.map(({ integer_value }) => integer_value)
          ?.reduce((a, b) => a + b, 0) / avgData?.length
      )
    )
      ? 0
      : Number(
          Math.round(
            modifyData
              ?.map(({ integer_value }) => integer_value)
              ?.reduce((a, b) => a + b, 0) / avgData?.length
          )
        );
  };

  handlePositionIntangibleAvg = (key, data) => {
    return [...data]
      .map((value, i) =>
        value?.averages?.filter((avg) => avg.intangible_name === key)
      )
      ?.filter((val) => val?.length);
  };

  handleIntangiblesAvg = (key, data) => {
    return [...data]
      .map((value, i) =>
        value?.position_averages?.filter((avg) => avg.name === key)
      )
      ?.filter((val) => val?.length);
  };

  handleCollapseAverages = (data) => {
    if (data && isArray(data)) {
      return `${Number(
        data
          ?.map((value) => Number(value?.integer_value))
          ?.reduce((item, accumlator) => item + accumlator, 0) / data?.length
      ).toFixed(2)}%`;
    } else return "None";
  };

  scoreBoard = () => {
    let avgOutput = this.handleIntangibleAverages()?.map((val) => ({
      avg: val,
    }));
    let nameOutput = this.handleIntangiblesLabel()?.map((val) => ({
      name: val,
    }));
    let combineOutput = avgOutput?.map((item, i) =>
      Object.assign({}, item, nameOutput[i])
    );
    let updatedOutput = combineOutput?.filter((item) => item?.avg !== 0);
    let overAllValue = isNaN(
      updatedOutput?.reduce(function (acc, obj) {
        return acc + obj?.avg;
      }, 0) / updatedOutput?.length
    )
      ? []
      : Math.round(
          updatedOutput?.reduce(function (acc, obj) {
            return acc + obj?.avg;
          }, 0) / updatedOutput?.length
        );
    if (avgOutput?.length > 0) {
      let overAllOutput = { avg: overAllValue, name: "Overall" };
      if (combineOutput && overAllOutput) {
        let finalOutput = [...[overAllOutput], ...updatedOutput];
        if (updatedOutput?.length > 0) {
          return finalOutput;
        } else {
          return [];
        }
      }
    }
  };

  handlCombineArray = () => {
    let averageData = this.handleIntangibleAverages()?.map((avg, index) => ({
      avg,
      intangible: this.handleIntangiblesLabel()?.[index],
    }));
    let finalData = averageData?.filter((item) => item?.avg !== 0);
    return finalData;
  };
  handleOpenFilterModal = (event) => {
    this.setState({ openFilters: true, anchorEl: event.currentTarget });
  };
  handleCloseFilterModal = () => {
    this.setState({ openFilters: false });
  };

  render() {
    let {
      CurrentGame,
      GameDateResult,
      Intangibles,
      NavBarDropDownIndex,
      PlayReportsData,
      TotalPlays,
      PlaySortingTable,
      GetScaleTypeData,
      SelectedPositionId,
      QuarterData,
      PlayTypeArray,
      DownDropDownData,
      SeriesTypeArray,
      CategoryCollection,
      PlayReportsMultipleData,
      collapse,
      CustomFieldTitles,
      NoteInfo,
      NoteInfoData,
      isDefaultLoaded,
      AverageByPlay,
      PlayAverages,
      toggleCustomField,
      customFieldData,
    } = this.props;

    let intangiblesLength =
      Intangibles && isArray(Intangibles) && Intangibles.length;

    const handleView = (e) => {
      e.stopPropagation();
    };

    const filterTable = {
      index: "#",
      name: "Name",
      type: "Type",
      qtr: "Qtr",
      series: "Series",
      down: "Down",
      package: "Package",
    };

    return (
      <Fragment>
        <Body
          actions={[
            // {
            //   action: "Get All Series",
            //   trigger: "onload",
            //   timeout: "",
            //   pollingPeriod: "",
            //   inputVariables: {},
            // },
            // {
            //   action: "Get Game Practice Game",
            //   trigger: "onload",
            //   timeout: "",
            //   pollingPeriod: "",
            //   inputVariables: { "input game id": playreportid },
            // },

            {
              action: "Get Intangibles For View Row",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Intangibles For Report",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 1 : 1,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                className="main_section"
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  paddingTop: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"} style={{}}>
                      <DivBlock style={{}}>
                        <DivBlock
                          className="game_title"
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Game & Practice Reports
                          </Heading>

                          <Span className={"h2 h2-span"} style={{}}>
                            Reports /
                          </Span>

                          <Span className={"h2 h2-inner-span"} style={{}}>
                            Game & Practice Reports
                          </Span>
                        </DivBlock>

                        <Columns
                          selectedLayout={"9/3"}
                          style={{
                            backgroundColor: "#ffffff",
                            fontSize: "0px",
                            marginBottom: "10px",
                          }}
                        >
                          <Column
                            lg={8}
                            md={7}
                            sm={12}
                            style={{
                              paddingLeft: "10px",
                              marginBottom: "10px",
                            }}
                            xs={9}
                          >
                            <DivBlock
                              style={{
                                alignItems: "center",
                                display: "block",
                                fontSize: "13px",
                                justifyContent: "flex-start",
                                marginBottom: "10px",
                                marginTop: "10px",
                              }}
                            >
                              <Heading
                                style={{
                                  alignItems: "flex-start",
                                  display: "block",
                                  fontFamily: "'Poppins', sans-serif",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  justifyContent: "flex-start",
                                  marginBottom: 0,
                                  marginLeft: 0,
                                  marginRight: 0,
                                  marginTop: 0,
                                }}
                                className={"h3"}
                                type={"h3"}
                              >
                                {CurrentGame
                                  ? CurrentGame["title"] +
                                    " " +
                                    `(` +
                                    handleDate(GameDateResult,'slash')+
                                    `)`
                                  : ""}
                              </Heading>

                              <Span
                                style={{ fontFamily: "'Poppins', sans-serif" }}
                              >
                                {CurrentGame
                                  ? "Type:" + " " + CurrentGame["type"]
                                  : ""}
                                <Span
                                  style={{ paddingLeft: 15, paddingRight: 15 }}
                                >
                                  {"/"}
                                </Span>
                                {CurrentGame
                                  ? "Total Plays:" +
                                    " " +
                                    (TotalPlays ? TotalPlays : "0")
                                  : ""}
                              </Span>
                            </DivBlock>
                          </Column>

                          <Column
                            lg={4}
                            md={5}
                            sm={12}
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                              paddingRight: "10px",
                            }}
                            xs={12}
                          >
                            <DivBlock className="play-info hide-chart">
                              <TextLink
                                href={"/play_reports"}
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                  fontSize: "14px",
                                }}
                                type={"spa"}
                              >
                                Back to Games &amp; Practices &gt;&gt;
                              </TextLink>
                            </DivBlock>
                          </Column>
                        </Columns>

                        <DivBlock
                          className={"dropdown-option play-report-filter"}
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            marginBottom: "1em",
                            flexWrap: "wrap",
                          }}
                        >
                          <DivBlock
                            className={"dropdown-option-wrapper"}
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              marginBottom: "1em",
                              flexWrap: "wrap",
                            }}
                          >
                            <DivBlock
                              className={"dropdown-option"}
                              style={{ marginLeft: "1em" }}
                            >
                              <Label className={"label"}>Category</Label>

                              <SelectDropDown
                                className={"form-control"}
                                name={"SelectedCategoryId"}
                                options={CategoryCollection}
                                actions={[
                                  {
                                    action: "On Position Filter Data",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      isDefault: true,
                                      initialState: {},
                                    },
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>
                            <DivBlock
                              className={"dropdown-option"}
                              style={{ marginLeft: "1em" }}
                            >
                              <Label className={"label"}>Play Type</Label>

                              <SelectDropDown
                                className={"form-control"}
                                name={"SelectedPlayTypeId"}
                                options={PlayTypeArray}
                                actions={[
                                  {
                                    action: "On Position Filter Data",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      isDefault: true,
                                      initialState: {},
                                    },
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>
                            <DivBlock
                              className={"dropdown-option"}
                              style={{ marginLeft: "1em" }}
                            >
                              <Label className={"label"}>Quarter</Label>

                              <SelectDropDown
                                className={"form-control"}
                                name={"SelectedQuarterId"}
                                options={QuarterData}
                                actions={[
                                  {
                                    action: "On Position Filter Data",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      isDefault: true,
                                      initialState: {},
                                    },
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>
                            <DivBlock
                              className={"dropdown-option"}
                              style={{ marginLeft: "1em" }}
                            >
                              <Label className={"label"}>Series</Label>

                              <SelectDropDown
                                className={"form-control"}
                                name={"SelectedSeriesId"}
                                options={SeriesTypeArray}
                                actions={[
                                  {
                                    action: "On Position Filter Data",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      isDefault: true,
                                      initialState: {},
                                    },
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>
                            <DivBlock
                              className={"dropdown-option"}
                              style={{ marginLeft: "1em" }}
                            >
                              <Label className={"label"}>Down</Label>

                              <SelectDropDown
                                className={"form-control"}
                                name={"SelectedDownId"}
                                options={DownDropDownData}
                                actions={[
                                  {
                                    action: "On Position Filter Data",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      isDefault: true,
                                      initialState: {},
                                    },
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>
                            <DivBlock
                              className={"dropdown-option"}
                              style={{ marginLeft: "1em" }}
                            >
                              <Label className={"label"}>Position Group</Label>

                              <SelectDropDown
                                className={"form-control"}
                                name={"SelectedPositionId"}
                                dataSet={this.positionsValues()}
                                options={[]}
                                dataSetLabel={"label"}
                                dataSetValue={"id"}
                                actions={[
                                  {
                                    action: "On Position Filter Data",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      isDefault: true,
                                      initialState: {},
                                    },
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>
                            {CustomFieldTitles?.length > 0 ? (
                              <DivBlock
                                className={"dropdown-option"}
                                style={{ marginLeft: "1em", width: "auto" }}
                              >
                                <Label className={"label"}>Custom Filter</Label>
                                <IconFilter
                                  onClick={this.handleOpenFilterModal}
                                />
                              </DivBlock>
                            ) : null}
                          </DivBlock>
                          <DivBlock
                            className={"div-print play-report-print-btn"}
                            style={{}}
                          >
                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Print Reports",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              Print
                            </Button>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>

                      {isDefaultLoaded ? null : (
                        <>
                          <Col
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{
                              paddingLeft: "0",
                            }}
                          >
                            {/* <DivBlock style={{ position: 'fixed', zIndex: '1000', width: '100%' }}> */}
                            {/* {this.scoreBoard() && isArray(this.scoreBoard()) ? ( */}
                              <Heading
                                className="h3"
                                type="h3"
                                style={{ marginBottom: "0" }}
                              >
                                Averages
                              </Heading>
                            {/* ) : null} */}
                            <DivBlock
                              id="player_report_average"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "auto",
                                flexWrap: "wrap",
                                marginBottom: "20px",
                              }}
                            >
                              {this.scoreBoard() &&
                              isArray(this.scoreBoard()) &&
                              this.scoreBoard()?.length ? (
                                this.scoreBoard()?.map((row, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <DivBlock
                                        style={{
                                          alignItems: "center",
                                          display: "block",
                                          fontSize: "13px",
                                          justifyContent: "flex-start",
                                          marginBottom: "10px",
                                          marginLeft: "0px",
                                          marginRight: "20px",
                                          marginTop: "10px",
                                          paddingTop: "0px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Heading
                                          style={
                                            row?.name === "Overall"
                                              ? {
                                                  // display: "flex",
                                                  // alignItems: "center",
                                                  // justifyContent: "center",
                                                  backgroundColor: "#677bac",
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  fontSize: "50px",
                                                  fontWeight: "500",
                                                  margin: 0,
                                                  padding: "8px 8px",
                                                  textAlign: "center",
                                                  color: "white",
                                                }
                                              : {
                                                  // display: "flex",
                                                  // alignItems: "center",
                                                  // justifyContent: "center",
                                                  backgroundColor: "#ffffff",
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  fontSize: "50px",
                                                  fontWeight: "500",
                                                  margin: 0,
                                                  padding: "8px 8px",
                                                  textAlign: "center",
                                                }
                                          }
                                          type={"h1"}
                                        >
                                          {row["avg"]}
                                          <span
                                            style={{
                                              verticalAlign: "super",
                                              fontSize: "50%",
                                            }}
                                          >
                                            %
                                          </span>
                                        </Heading>

                                        <DivBlock
                                          style={{
                                            display: "inline-block",
                                            marginRight: "40px",
                                            marginTop: "5px",
                                            // paddingLeft: "10px",
                                            width: "100%",
                                          }}
                                        >
                                          <Span
                                            className={"table-span"}
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            {row["name"]}
                                          </Span>
                                        </DivBlock>
                                      </DivBlock>
                                    </Fragment>
                                  );
                                })
                              ) : (
                                <Paragraph
                                  style={{
                                    fontFamily: "'Poppins', sans-serif",
                                    fontSize: "14px",
                                  }}
                                >
                                  No grades exist for your selected filters.
                                </Paragraph>
                              )}
                            </DivBlock>
                            {/* </DivBlock> */}
                          </Col>
                          {/* <DivBlock style={{ marginTop: '200px', maxHeight: 'calc(100vh - 200px)' }}> */}
                          {PlayReportsData &&
                          isArray(PlayReportsData) &&
                          PlayReportsData?.filter(
                          (data) => data["package"]
                          ).length > 0 ? (
                            <div
                              className={
                                this.handlCombineArray()?.length >= 15 ||
                                PlayReportsData?.filter((item) => item?.package)
                                  ?.length >= 15
                                  ? "play-reports-graph"
                                  : ""
                              }
                            >
                              <Row className="play-reports-print">
                                <Col sm={12} md={12} lg={6} xxl={6}>
                                  <Fragment>
                                    <Heading
                                      className="h3"
                                      type="h3"
                                      style={{ marginBottom: "0" }}
                                    >
                                      Average by Play
                                    </Heading>
                                    {AverageByPlay?.length &&
                                    this.scoreBoard()?.length > 0 ? (
                                      <DivBlock
                                        style={{
                                          width: "auto",
                                          marginRight: "20px",
                                        }}
                                      >
                                        <BarChartScroll
                                          {...{
                                            isSkipYaxis: false,
                                            maintainAspectRatio: false,
                                            lengthData: AverageByPlay?.length,
                                            graphType: "play",
                                          }}
                                          chartTitle="Averages"
                                          chartData={PlayAverages}
                                          barBorderRadius={10}
                                          chartLabels={
                                            AverageByPlay &&
                                            isArray(AverageByPlay) &&
                                            AverageByPlay
                                          }
                                        />
                                      </DivBlock>
                                    ) : (
                                      <Paragraph
                                        style={{
                                          fontFamily: "'Poppins', sans-serif",
                                          fontSize: "14px",
                                        }}
                                      >
                                        No grades exist for your selected
                                        filters.
                                      </Paragraph>
                                    )}
                                  </Fragment>
                                </Col>
                                <Col sm={12} md={12} lg={6} xxl={6}>
                                  <Fragment>
                                    <Heading
                                      className="h3"
                                      type="h3"
                                      style={{ marginBottom: "0" }}
                                    >
                                      Average by Intangible
                                    </Heading>
                                    {this.handlCombineArray() &&
                                    this.handlCombineArray().length > 0 &&
                                    this.scoreBoard()?.length > 0 ? (
                                      <DivBlock>
                                        <BarChartScroll
                                          barBorderRadius={10}
                                          {...{
                                            isSkipYaxis: false,
                                            maintainAspectRatio: false,
                                            lengthData: this.handlCombineArray()
                                              ?.filter((item) => item.avg !== 0)
                                              ?.map((val) => val.avg)?.length,
                                            graphType: "intangible",
                                          }}
                                          chartTitle="Averages"
                                          chartData={this.handlCombineArray()?.map(
                                            (val) => val.avg
                                          )}
                                          chartLabels={this.handlCombineArray()?.map(
                                            (val) => val?.intangible
                                          )}
                                        />
                                      </DivBlock>
                                    ) : (
                                      <Paragraph
                                        style={{
                                          fontFamily: "'Poppins', sans-serif",
                                          fontSize: "14px",
                                        }}
                                      >
                                        No grades exist for your selected
                                        filters.
                                      </Paragraph>
                                    )}
                                  </Fragment>
                                </Col>
                              </Row>
                            </div>
                          ) : null}
                          {/* </DivBlock> */}
                          <DivBlock
                            className={"div-para"}
                            style={{ marginTop: "3rem" }}
                          >
                            {/* <Heading
                              className="h3"
                              type="h3"
                              style={{
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "black",
                              }}
                            >
                              {`Play Breakdown${
                                this.handleSelectDpValue()
                                  ? ` - ${this.handleSelectDpValue()}`
                                  : ""
                              }`}
                            </Heading> */}
                            <Heading
                              className="h3"
                              type="h3"
                              style={{ marginBottom: "0" }}
                            >
                              Play Breakdown
                            </Heading>
                            {PlayReportsData &&
                            isArray(PlayReportsData) &&
                            PlayReportsData?.filter(
                              (data) => data["package"]
                            ).length > 0 ? (
                              <Span
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                  fontSize: "13px",
                                }}
                              >
                                Click any table heading to sort by column. Click
                                any table row to see detailed play stats.
                              </Span>
                            ) : null}

                            <Paragraph
                              style={{
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: "14px",
                              }}
                            >
                              {this.handleSelectDpValue()}
                            </Paragraph>
                          </DivBlock>
                          {PlayReportsData &&
                          isArray(PlayReportsData) &&
                          PlayReportsData?.filter(
                          (data) => data["package"]
                          )?.length > 0 ? (
                            <DivBlock
                              className={"table-scroll"}
                              style={{
                                overflowX: "scroll",
                                marginBottom: "100px",
                              }}
                            >
                              <table
                                className={
                                  "table hide-last-col sticky-col game-report"
                                }
                              >
                                <thead>
                                  <tr className={"thead-bg header-line-height"}>
                                    {isArray(Object.keys(filterTable)) &&
                                      Object.keys(filterTable).map(
                                        (val, id) => {
                                          return (
                                            <th
                                              key={id}
                                              className={
                                                "th-border white-space-nowrap"
                                              }
                                            >
                                              <Span
                                                className={
                                                  "Sorting-span th-span thead-line-height"
                                                }
                                                actions={[
                                                  {
                                                    action:
                                                      "TOGGLE_SORTING_PLAY",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      inputTogglePlay: {
                                                        ToggleSorting:
                                                          PlaySortingTable !==
                                                          true,
                                                        sortingField: val,
                                                      },
                                                    },
                                                  },
                                                ]}
                                              >
                                                {filterTable[val]}
                                              </Span>
                                            </th>
                                          );
                                        }
                                      )}
                                    <th
                                      className={"th-border white-space-nowrap"}
                                      style={{
                                        width: "auto",
                                        minWidth: "120px",
                                      }}
                                    >
                                      <Span
                                        className={"th-span thead-line-height"}
                                      >
                                        PP
                                      </Span>
                                    </th>
                                    <th
                                      className={"th-border white-space-nowrap"}
                                      style={{
                                        width: "auto",
                                        minWidth: "120px",
                                      }}
                                    >
                                      <Span
                                        className={"th-span thead-line-height"}
                                      >
                                        Notes
                                      </Span>
                                    </th>
                                    {CustomFieldTitles &&
                                      isArray(CustomFieldTitles) &&
                                      CustomFieldTitles.map((data, ind) => {
                                        return (
                                          <Fragment key={ind}>
                                            <th
                                              className={
                                                "th-border white-space-nowrap"
                                              }
                                              style={{
                                                width: "auto",
                                                minWidth: "120px",
                                              }}
                                            >
                                              <Span
                                                className={
                                                  "Sorting-span th-span thead-line-height"
                                                }
                                                actions={[
                                                  {
                                                    action:
                                                      "TOGGLE_SORTING_GAME_AND_PRACTICE_CUSTOM_FIELD",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      inputTogglePlay: {
                                                        ToggleSorting:
                                                          PlaySortingTable !==
                                                          true,
                                                        sortingField:
                                                          data?.["title"],
                                                      },
                                                    },
                                                  },
                                                ]}
                                              >
                                                {`${data["title"]}`}
                                              </Span>
                                            </th>
                                          </Fragment>
                                        );
                                      })}
                                    {Intangibles &&
                                      isArray(Intangibles) &&
                                      Intangibles.map((row, index) => {
                                        return (
                                          <Fragment key={index}>
                                            <th
                                              className={
                                                "th-border white-space-nowrap"
                                              }
                                              style={{
                                                width: "auto",
                                                minWidth: "120px",
                                              }}
                                            >
                                              <Span
                                                className={
                                                  "Sorting-span th-span thead-line-height"
                                                }
                                                actions={[
                                                  {
                                                    action:
                                                      "TOGGLE_SORTING_GAME_AND_PRACTICE",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      inputTogglePlay: {
                                                        ToggleSorting:
                                                          PlaySortingTable !==
                                                          true,
                                                        sortingField:
                                                          row?.["name"],
                                                      },
                                                    },
                                                  },
                                                ]}
                                              >
                                                {`${row["name"]}`}
                                              </Span>
                                            </th>
                                          </Fragment>
                                        );
                                      })}
                                    <th className="th-border white-space-nowrap">
                                      <Span
                                        className={
                                          "Sorting-span th-span thead-line-height"
                                        }
                                        actions={[
                                          {
                                            action: "TOGGLE_SORTING_PLAY_AVG",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              inputTogglePlay: {
                                                ToggleSorting:
                                                  PlaySortingTable !== true,
                                                sortingField: "avg",
                                              },
                                            },
                                          },
                                        ]}
                                      >
                                        AVG
                                      </Span>
                                    </th>
                                    <th
                                      className={"th-border white-space-nowrap"}
                                    >
                                      <Span
                                        className={
                                          "Sorting-span th-span thead-line-height"
                                        }
                                      ></Span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {PlayReportsData?.data === null ||
                                  PlayReportsData?.length === 0 ? (
                                    <tr>
                                      <td
                                        colSpan={
                                          8 +
                                          (intangiblesLength
                                            ? intangiblesLength
                                            : 0)
                                        }
                                      >
                                        <Span
                                          className={"table-span"}
                                          style={{ margin: "15px" }}
                                        >
                                          {" "}
                                          No Data found.{" "}
                                        </Span>
                                      </td>
                                    </tr>
                                  ) : null} */}
                                  {PlayReportsData &&
                                    isArray(PlayReportsData) &&
                                    PlayReportsData.filter(
                                      (data) => data["package"]
                                    ).map((row, index) => {
                                      return (
                                        <Fragment key={index}>
                                          {/* <Accordion> */}
                                          <tr
                                            style={{ cursor: "pointer" }}
                                            className={
                                              (index + 1) % 2 === 0
                                                ? "table-data-after"
                                                : "table-data-before"
                                            }
                                            onClick={() => {
                                              this.props.getUserGrades(
                                                index,
                                                this.firstPositionValues(),
                                                {
                                                  "depth chart input ": row,
                                                  "current play package":
                                                    row["package"],
                                                }
                                              );
                                            }}
                                          >
                                            <td className="white-space-nowrap play-report-data">
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                }}
                                              >
                                                {row["index"]}
                                              </Span>
                                            </td>

                                            <td
                                              className="white-space-nowrap play-report-data"
                                              style={{
                                                minWidth: "150px",
                                              }}
                                            >
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                }}
                                              >
                                                {row["name"]}
                                              </Span>
                                            </td>

                                            <td className="white-space-nowrap play-report-data">
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                }}
                                              >
                                                {row["type"]}
                                              </Span>
                                            </td>

                                            <td className="white-space-nowrap play-report-data">
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                }}
                                              >
                                                {row["qtr"]}
                                              </Span>
                                            </td>

                                            <td className="white-space-nowrap play-report-data">
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                }}
                                              >
                                                {row["series"]}
                                              </Span>
                                            </td>

                                            <td className="white-space-nowrap play-report-data">
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                }}
                                              >
                                                {row["down"]}
                                              </Span>
                                            </td>

                                            <td
                                              className="white-space-nowrap play-report-data"
                                              style={{
                                                minWidth: "150px",
                                              }}
                                            >
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                }}
                                              >
                                                {row["package"]
                                                  ? row["package"]
                                                  : "-"}
                                              </Span>
                                            </td>
                                            <td
                                              className="white-space-nowrap play-report-data"
                                              style={{
                                                minWidth: "150px",
                                              }}
                                            >
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                }}
                                              >
                                                &#8964;
                                              </Span>
                                            </td>
                                            <td
                                              className="white-space-nowrap play-report-data"
                                              style={{
                                                minWidth: "150px",
                                              }}
                                            >
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontSize: "14px",
                                                  color: "black",
                                                }}
                                              >
                                                &#8964;
                                              </Span>
                                            </td>
                                            {CustomFieldTitles &&
                                              isArray(CustomFieldTitles) &&
                                              CustomFieldTitles.map(
                                                (item, index) => {
                                                  let customData =
                                                    row["custom_fields"] &&
                                                    isArray(
                                                      row["custom_fields"]
                                                    ) &&
                                                    row?.custom_fields.filter(
                                                      (cData) =>
                                                        cData.title_id ===
                                                        item.id
                                                    );
                                                  return (
                                                    <td
                                                      className="white-space-nowrap play-report-data"
                                                      style={{
                                                        minWidth: "150px",
                                                      }}
                                                    >
                                                      <Span
                                                        className={"table-span"}
                                                        style={{
                                                          fontSize: "14px",
                                                          color: "black",
                                                        }}
                                                      >
                                                        {isArray(customData) &&
                                                        customData[0]?.data
                                                          ? customData[0]?.data
                                                          : "-"}
                                                      </Span>
                                                    </td>
                                                  );
                                                }
                                              )}
                                            {/* <AccordionSummary
                                        expandIcon={
                                          <i
                                            class="fa fa-angle-up"
                                            aria-hidden="true"
                                          ></i>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      > */}

                                            {SelectedPositionId === "" ||
                                            SelectedPositionId === "-1" ||
                                            SelectedPositionId === null
                                              ? Intangibles &&
                                                isArray(Intangibles) &&
                                                Intangibles.map((item, idx) => {
                                                  let showAverages =
                                                    row["position_averages"] &&
                                                    isArray(
                                                      row["position_averages"]
                                                    ) &&
                                                    row[
                                                      "position_averages"
                                                    ].filter(
                                                      (inItem) =>
                                                        inItem.name ===
                                                        item.name
                                                    );
                                                  return (
                                                    <Fragment key={idx}>
                                                      <td className="white-space-nowrap play-report-data">
                                                        <Span
                                                          className={
                                                            "table-span"
                                                          }
                                                          style={{
                                                            fontSize: "14px",
                                                            color: "black",
                                                          }}
                                                        >
                                                          {isArray(
                                                            showAverages
                                                          ) &&
                                                          (showAverages[0]
                                                            ?.integer_value ||
                                                            showAverages[0]
                                                              ?.integer_value ==
                                                              0)
                                                            ? `${showAverages[0]?.integer_value}%`
                                                            : "None"}
                                                        </Span>
                                                      </td>
                                                    </Fragment>
                                                  );
                                                })
                                              : Intangibles &&
                                                isArray(Intangibles) &&
                                                Intangibles.map(
                                                  (cusItem, idx) => {
                                                    let showCustomPosition =
                                                      row["averages"] &&
                                                      isArray(
                                                        row["averages"]
                                                      ) &&
                                                      row["averages"].filter(
                                                        (inItem) =>
                                                          inItem.intangible_name ===
                                                          cusItem.name
                                                      );
                                                    return (
                                                      <Fragment key={idx}>
                                                        <td className="white-space-nowrap play-report-data">
                                                          <Span
                                                            className={
                                                              "table-span"
                                                            }
                                                            style={{
                                                              fontSize: "14px",
                                                              color: "black",
                                                            }}
                                                          >
                                                            {showCustomPosition &&
                                                            showCustomPosition?.length >
                                                              0
                                                              ? showCustomPosition?.[0]?.[
                                                                  "integer_value"
                                                                ] + "%"
                                                              : "None"}
                                                            {/* {row["userdata"] === null ? "None" : showCustomPosition?.[0]?.["integer_value"] + "%"} */}
                                                          </Span>
                                                        </td>
                                                      </Fragment>
                                                    );
                                                  }
                                                )}
                                            <td className="white-space-nowrap">
                                              <Span
                                                className={"table-span"}
                                                style={{
                                                  fontWeight: "700",
                                                  color: "black",
                                                }}
                                              >
                                                {row["avg"]
                                                  ? row["avg"]
                                                  : "None"}
                                                {/* {
                                        SelectedPositionId === "" || SelectedPositionId === "-1" || SelectedPositionId === null ?
                                          <Fragment>{averageCalculate(row['position_averages'], 'position_averages')}</Fragment> : <Fragment>{averageCalculate(row['averages'], 'averages')}</Fragment>
                                      } */}
                                              </Span>
                                            </td>
                                            {/* </AccordionSummary> */}
                                            <td
                                              className="white-space-nowrap play-report-data"
                                              style={{
                                                borderBottomColor: "#000000",
                                                borderBottomStyle: "none",
                                                borderBottomWidth: "",
                                                borderLeftColor: "#000000",
                                                borderLeftStyle: "none",
                                                borderLeftWidth: "",
                                                borderRightColor: "#000000",
                                                borderRightStyle: "none",
                                                borderRightWidth: "",
                                                borderStyle: "none",
                                                borderTopColor: "#000000",
                                                borderTopStyle: "none",
                                                borderTopWidth: "",
                                                paddingBottom: "10px",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "10px",
                                              }}
                                            >
                                              <DivBlock
                                                className={"dropdown-option"}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i
                                                  className={
                                                    collapse?.[index] === true
                                                      ? "fa fa-angle-down"
                                                      : "fa fa-angle-right"
                                                  }
                                                  style={{}}
                                                ></i>
                                              </DivBlock>
                                            </td>
                                          </tr>

                                          {PlayReportsMultipleData[index]
                                            ?.length === 0 &&
                                          collapse?.[index] ? (
                                            <tr>
                                              <td
                                                className="white-space-nowrap"
                                                colSpan={
                                                  11 +
                                                  (CustomFieldTitles
                                                    ? CustomFieldTitles?.length
                                                    : 0) +
                                                  (intangiblesLength
                                                    ? intangiblesLength
                                                    : 0)
                                                }
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                }}
                                              >
                                                {
                                                  <Collapse
                                                    in={collapse?.[index]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <Span
                                                      className={"table-span"}
                                                      style={{
                                                        margin: "10px",
                                                        fontSize: "12px",
                                                        justifyContent:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      No Data found.
                                                    </Span>
                                                  </Collapse>
                                                }
                                              </td>
                                            </tr>
                                          ) : null}

                                          {/* Collapse Data */}
                                          {PlayReportsMultipleData?.[index]
                                            ?.length === 0 &&
                                          collapse?.[
                                            index
                                          ] ? null : PlayReportsMultipleData?.[
                                              index
                                            ]?.length > 0 &&
                                            collapse?.[index] ? (
                                            <>
                                              <th
                                                className={
                                                  "th-border white-space-nowrap"
                                                }
                                              >
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                ></Span>
                                              </th>
                                              <th className={"th-border"}>
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                ></Span>
                                              </th>
                                              <th className={"th-border"}>
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                ></Span>
                                              </th>
                                              <th className={"th-border"}>
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                ></Span>
                                              </th>
                                              <th className={"th-border"}>
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                ></Span>
                                              </th>
                                              <th
                                                className={
                                                  "th-border white-space-nowrap"
                                                }
                                              >
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                >
                                                  Position
                                                </Span>
                                              </th>
                                              <th
                                                className={
                                                  "th-border white-space-nowrap"
                                                }
                                              >
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                >
                                                  Personnel
                                                </Span>
                                              </th>
                                              <th
                                                className={
                                                  "th-border white-space-nowrap"
                                                }
                                              >
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                >
                                                  PP
                                                </Span>
                                              </th>
                                              <th
                                                className={
                                                  "th-border white-space-nowrap"
                                                }
                                              >
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                >
                                                  Notes
                                                </Span>
                                              </th>
                                              {CustomFieldTitles &&
                                                isArray(CustomFieldTitles) &&
                                                CustomFieldTitles.map(
                                                  (item, index) => {
                                                    return (
                                                      <th
                                                        className={
                                                          "th-border white-space-nowrap"
                                                        }
                                                      >
                                                        <Span
                                                          className={
                                                            "th-span thead-line-height"
                                                          }
                                                        ></Span>
                                                      </th>
                                                    );
                                                  }
                                                )}
                                              {Intangibles &&
                                                isArray(Intangibles) &&
                                                Intangibles.map(
                                                  (row, index) => {
                                                    return (
                                                      <th
                                                        className={
                                                          "th-border white-space-nowrap"
                                                        }
                                                      >
                                                        <Span
                                                          className={
                                                            "th-span thead-line-height"
                                                          }
                                                        >
                                                          {row?.name}
                                                        </Span>
                                                      </th>
                                                    );
                                                  }
                                                )}
                                              <th
                                                className={
                                                  "th-border white-space-nowrap"
                                                }
                                              >
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                ></Span>
                                              </th>
                                              <th
                                                className={
                                                  "th-border white-space-nowrap"
                                                }
                                              >
                                                <Span
                                                  className={
                                                    "th-span thead-line-height"
                                                  }
                                                ></Span>
                                              </th>
                                            </>
                                          ) : null}

                                          {/* {PlayReportsMultipleData[index] &&
                                  isArray(PlayReportsMultipleData[index]) &&
                                  PlayReportsMultipleData[index]?.length > 0 &&
                                  <> */}
                                          {PlayReportsMultipleData[index] &&
                                            isArray(
                                              PlayReportsMultipleData[index]
                                            ) &&
                                            PlayReportsMultipleData[index]?.map(
                                              (value, key) => {
                                                if (collapse?.[index]) {
                                                  return (
                                                    <tr
                                                      className={
                                                        (key + 1) % 2 === 0
                                                          ? "collapse-after"
                                                          : "collapse-before"
                                                      }
                                                    >
                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{}}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "5px",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            ></Span>
                                                          </Collapse>
                                                        }
                                                      </td>

                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{}}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "5px",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            ></Span>
                                                          </Collapse>
                                                        }
                                                      </td>
                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{}}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "5px",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            ></Span>
                                                          </Collapse>
                                                        }
                                                      </td>
                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{}}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "5px",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            ></Span>
                                                          </Collapse>
                                                        }
                                                      </td>
                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{}}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "5px",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            ></Span>
                                                          </Collapse>
                                                        }
                                                      </td>

                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{}}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "5px",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            >
                                                              {value.position}
                                                            </Span>
                                                          </Collapse>
                                                        }
                                                      </td>
                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{}}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "5px",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {value.name}{" "}
                                                            </Span>
                                                          </Collapse>
                                                        }
                                                      </td>

                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{}}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "5px",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            >
                                                              {value.production_point ===
                                                              ""
                                                                ? "None"
                                                                : value.production_point}
                                                            </Span>
                                                          </Collapse>
                                                        }
                                                      </td>

                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{}}
                                                        onClick={(e) =>
                                                          handleView(e)
                                                        }
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "5px",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              actions={[
                                                                {
                                                                  action:
                                                                    "Set Notes",
                                                                  trigger:
                                                                    "onclick",
                                                                  timeout: "",
                                                                  pollingPeriod:
                                                                    "",
                                                                  inputVariables:
                                                                    {
                                                                      "set notes": true,
                                                                      "set notes data":
                                                                        value,
                                                                    },
                                                                },
                                                              ]}
                                                            >
                                                              <i
                                                                className="fa fa-eye"
                                                                df-snippet-instance-id={
                                                                  298
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              />
                                                            </Span>
                                                          </Collapse>
                                                        }
                                                      </td>
                                                      {CustomFieldTitles &&
                                                        isArray(
                                                          CustomFieldTitles
                                                        ) &&
                                                        CustomFieldTitles.map(
                                                          (item, index) => {
                                                            return (
                                                              <td
                                                                className="white-space-nowrap"
                                                                style={{}}
                                                              >
                                                                {
                                                                  <Collapse
                                                                    in={
                                                                      collapse?.[
                                                                        index
                                                                      ] === true
                                                                    }
                                                                    timeout="auto"
                                                                    unmountOnExit
                                                                  >
                                                                    <Span
                                                                      className={
                                                                        "table-span"
                                                                      }
                                                                      style={{
                                                                        margin:
                                                                          "5px",
                                                                        fontSize:
                                                                          "12px",
                                                                      }}
                                                                    ></Span>
                                                                  </Collapse>
                                                                }
                                                              </td>
                                                            );
                                                          }
                                                        )}
                                                      {Intangibles &&
                                                        isArray(Intangibles) &&
                                                        Intangibles.map(
                                                          (item, idx) => {
                                                            let modifyData =
                                                              value[
                                                                "user_grades"
                                                              ] &&
                                                              isArray(
                                                                value[
                                                                  "user_grades"
                                                                ]
                                                              ) &&
                                                              value[
                                                                "user_grades"
                                                              ]?.filter(
                                                                (innItem) =>
                                                                  innItem?.intangible_name ===
                                                                  item?.["name"]
                                                              );
                                                            let getScaleTypeArray =
                                                              [];
                                                            if (
                                                              modifyData &&
                                                              isArray(
                                                                modifyData
                                                              )
                                                            ) {
                                                              getScaleTypeArray =
                                                                isArray(
                                                                  GetScaleTypeData
                                                                )
                                                                  ? GetScaleTypeData?.filter(
                                                                      (val) =>
                                                                        val?.position_id ===
                                                                        modifyData[0]
                                                                          ?.position_id
                                                                    )
                                                                  : [];
                                                            }
                                                            return (
                                                              <Fragment
                                                                key={idx}
                                                              >
                                                                <td className="white-space-nowrap">
                                                                  <Collapse
                                                                    in={
                                                                      collapse?.[
                                                                        index
                                                                      ] === true
                                                                    }
                                                                    timeout="auto"
                                                                    unmountOnExit
                                                                  >
                                                                    <Span
                                                                      className={
                                                                        "table-span"
                                                                      }
                                                                      style={{
                                                                        margin:
                                                                          "5px",
                                                                        fontSize:
                                                                          "12px",
                                                                      }}
                                                                    >
                                                                      {handleScaleTypeValue(
                                                                        getScaleTypeArray?.[0]
                                                                          ?.scale_type,
                                                                        modifyData
                                                                      )}
                                                                    </Span>
                                                                  </Collapse>
                                                                </td>
                                                              </Fragment>
                                                            );
                                                          }
                                                        )}

                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{
                                                          borderBottomColor:
                                                            "#000000",
                                                          borderBottomStyle:
                                                            "none",
                                                          borderBottomWidth: "",
                                                          borderLeftColor:
                                                            "#000000",
                                                          borderLeftStyle:
                                                            "none",
                                                          borderLeftWidth: "",
                                                          borderRightColor:
                                                            "#000000",
                                                          borderRightStyle:
                                                            "none",
                                                          borderRightWidth: "",
                                                          borderStyle: "none",
                                                          borderTopColor:
                                                            "#000000",
                                                          borderTopStyle:
                                                            "none",
                                                          borderTopWidth: "",
                                                        }}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "10px",
                                                                fontWeight:
                                                                  "700",
                                                              }}
                                                            >
                                                              {this.handleCollapseAverages(
                                                                value[
                                                                  "user_grades"
                                                                ]
                                                              )}
                                                            </Span>
                                                          </Collapse>
                                                        }
                                                      </td>
                                                      <td
                                                        className="white-space-nowrap"
                                                        style={{
                                                          borderBottomColor:
                                                            "#000000",
                                                          borderBottomStyle:
                                                            "none",
                                                          borderBottomWidth: "",
                                                          borderLeftColor:
                                                            "#000000",
                                                          borderLeftStyle:
                                                            "none",
                                                          borderLeftWidth: "",
                                                          borderRightColor:
                                                            "#000000",
                                                          borderRightStyle:
                                                            "none",
                                                          borderRightWidth: "",
                                                          borderStyle: "none",
                                                          borderTopColor:
                                                            "#000000",
                                                          borderTopStyle:
                                                            "none",
                                                          borderTopWidth: "",
                                                        }}
                                                      >
                                                        {
                                                          <Collapse
                                                            in={
                                                              collapse?.[
                                                                index
                                                              ] === true
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <Span
                                                              className={
                                                                "table-span"
                                                              }
                                                              style={{
                                                                margin: "10px",
                                                                fontWeight:
                                                                  "700",
                                                              }}
                                                            ></Span>
                                                          </Collapse>
                                                        }
                                                      </td>
                                                    </tr>
                                                  );
                                                } else <></>;
                                              }
                                            )}
                                          {/* </>
                                } */}

                                          {/* <AccordionDetails>
                                      <Label>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Suspendisse malesuada
                                        lacus ex, sit amet blandit leo lobortis
                                        eget.
                                      </Label>
                                    </AccordionDetails> */}
                                          {/* </Accordion> */}
                                        </Fragment>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </DivBlock>
                          ) : null}
                        </>
                      )}
                    </DivBlock>
                    <If condition={NoteInfo ? true : false} show={false}>
                      <Then>
                        <DivBlock className={"modal"}>
                          <DivBlock className={"modal-dialog"} style={{}}>
                            <DivBlock className={"modal-dialog"} style={{}}>
                              <DivBlock className={"modal-content"} style={{}}>
                                <DivBlock className={"modal-header"}>
                                  <Heading
                                    className="modal-heading"
                                    type={"h5"}
                                  >
                                    Notes
                                  </Heading>

                                  <Span
                                    className={"close"}
                                    actions={[
                                      {
                                        action: "Set Notes",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          "toggle depth chart": false,
                                        },
                                      },
                                    ]}
                                  >
                                    x
                                  </Span>
                                </DivBlock>

                                <DivBlock className={"modal-body"}>
                                  <FormBlock
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <DivBlock style={{ textAlign: "center" }}>
                                      {NoteInfoData &&
                                      (NoteInfoData.notes === "" ||
                                        NoteInfoData.notes === null) ? (
                                        <Span
                                          style={{
                                            fontFamily: "'Poppins', sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            textAlign: "center",
                                          }}
                                        >
                                          No notes available
                                        </Span>
                                      ) : (
                                        <TextInput
                                          className={"form-control"}
                                          name={"NoteInfoData?.notes"}
                                          placeholder={""}
                                          initialValue={
                                            NoteInfoData?.notes
                                              ? NoteInfoData.notes
                                              : "No notes available"
                                          }
                                          disabled={true}
                                          style={{}}
                                        ></TextInput>
                                      )}
                                    </DivBlock>
                                  </FormBlock>
                                </DivBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </Then>
                    </If>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
          <FilterReports
            openFilters={this.state.openFilters}
            anchorEl={this.state.anchorEl}
            handleCloseFilterModal={this.handleCloseFilterModal}
          />
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    positionData: () => {
      dispatch({ type: "GET_POSITIONS" });
      return Promise.resolve();
    },
    getGameData: (payload) => {
      dispatch({ type: "GET_GAME" }, payload);
      return Promise.resolve();
    },
    getGamePracticeGameData: (payload) => {
      dispatch({
        type: "GET_GAME_PRACTICE_GAME",
        inputVariables: {
          isDefault: true,
        },
      });
      return Promise.resolve();
    },
    getScaleData: () => {
      dispatch({ type: "GET_SCALE_TYPE" });
      return Promise.resolve();
    },
    getAllDepthCharts: () => {
      dispatch({ type: "GET_BASE_DEPTH_CHARTS" });
      return Promise.resolve();
    },
    getAllSeries: () => {
      dispatch({ type: "GET_ALL_SERIES" });
      return Promise.resolve();
    },
    clearGamePracticeData: () => {
      dispatch({ type: "CLEAR_GAME_PRACTICE_REPORT_VALUES" });
      return Promise.resolve();
    },
    getUserGrades: (payload, firstPosition, inputVariables) => {
      dispatch({
        type: "SET_PLAY_DEPTH_CHART_FOR_REPORT_FILTER",
        currentIndex: payload,
        firstPosition,
        inputVariables,
      });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayReportsDataPage);
