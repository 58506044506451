import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { debounced } from "../../../../utils/function";
import { deleteCurrentProductionData } from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 400,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    // margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input:{
    fontSize:'14px'
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomSelectDropDown = ({
  value,
  options,
  id,
  onBlur,
  disabled,
  onInputChange,
  handleChange,
  productionPoint,
  tabValue,
  ...props
}) => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(value);
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();

  const handleInputChange = (event, newInputValue) => {    
    if(event?.key === 'Enter'){
      onInputChange(inputValue)
      setSelectedValue([...selectedValue,newInputValue]);
    }
      if(newInputValue){
        debounced(() => { 
          dispatch({type:"OPTION_CHANGE_PRODUCTION_POINT_REQUEST",inputVariables:{value:productionPoint}})
        },300)
      }else{
        dispatch({type:"GET_SCHOOL_PRODUCTION_POINT",inputVariables:{position_id:tabValue}})
      }
    setInputValue(newInputValue)
  };

  const handleDelete = (e,item,id) =>{
    let updatedData = selectedValue?.filter((e) => e !== item);
    dispatch(deleteCurrentProductionData({
      play_depth_chart_point:id,data:updatedData
    }))  
    handleChange(e,updatedData, id,{isSuccess:false});
  }


  const handleValue = useCallback((value)=>{
    if(value){
      setSelectedValue([...value]);
    }
  },[])

  useEffect(()=>{
  if(JSON.stringify(value) !== JSON.stringify(selectedValue)){
    handleValue(value)
  }
  },[value,handleValue])

  return (
    <div className={classes.formControl}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        size="small"
        limitTags={3}
        onChange={handleChange}
        onInputChange={handleInputChange}
        value={selectedValue || []}
        onBlur={onBlur}
        disabled={disabled}
        freeSolo
        MenuProps={MenuProps}
        disableClearable={true}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              onDelete={(e) => handleDelete(e,option,id)}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            value={inputValue}
            variant="outlined"
            placeholder="Select production point"
          />
        )}
      />
      {/* {props.suggestionText} */}
    </div>
  );
};

export default CustomSelectDropDown;
