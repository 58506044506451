import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { onLoadTrigger, deepMap } from "../../../helpers";
import MultipleDataGraphCanvas from './MultipleDataGraphCanvas.js';
import '../style.css';
import _ from "lodash";

class MultipleDataGraph extends Component { // eslint-disable-line react/prefer-stateless-function

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            redraw: false,
            graph: null
        };
    }

    componentWillMount() {
        const { actions, dispatch } = this.props;
        onLoadTrigger(actions, dispatch)
        this.setState({graph: this.returnGraph()})
        window.addEventListener('resize', this.handleResize)
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {redraw: nextRedraw} = nextState
        const {redraw } = this.state
        if (!redraw){
            this.setState({redraw: true, graph: null})
        }

        return (
          nextProps !== this.props ||
          nextState !== this.state
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {redraw: prevRedraw} = prevState
        const {redraw} = this.state
        if (!prevRedraw && redraw){
            this.setState({redraw: false, graph:this.returnGraph()})
        }
    }


    handleResize = ()=>{
        this.setState({redraw: true, graph: null})
        this.debounceRedraw()
    }

    handleRedraw = () =>{
        setTimeout(()=>{
            this.setState({redraw: false, graph: this.returnGraph()})
        })
    }
    debounceRedraw = _.debounce(this.handleRedraw, 100)


    returnGraph = () =>{
        const { style, store, dataSet, defaultData2, dataSetY, dataSetX, children } = this.props;
        
        // Read data from redux store.
        let data = defaultData2
        if (dataSet) {
            if (typeof dataSet === "string") {
                data = store[dataSet] ? store[dataSet] : defaultData2
            } else {
                data = dataSet
            }
        }

        // Find max y dataset.
        let maxDataSetY;
        let maxVal;
        if(!dataSetY && data) {
            var dataSetYs = {};
            deepMap(children, element => {
                if(React.isValidElement(element)) {
                    if(element.props.dataSetY) {
                        dataSetYs[element.props.dataSetY] = true;
                        maxDataSetY = element.props.dataSetY;
                    }
                }
            });

            data.forEach((d) => {
                Object.keys(dataSetYs).forEach((dstY) => {
                    if(d[dstY] && (!maxVal || d[dstY] > maxVal)) {
                        maxDataSetY = dstY;
                        maxVal = d[dstY];
                    }
                });
            });
        }

        if(!maxDataSetY) {
            maxDataSetY = dataSetY ? dataSetY : 'y';
        }

        // Set default data.
        if(!data || data.length < 2 || !data[0][dataSetX]
            || !data[0][maxDataSetY]) {
            data = [{ [dataSetX]: 1, [maxDataSetY]: 1  }, { [dataSetX]: 2, [maxDataSetY]: 1 } ];
        }

        return (
            <MultipleDataGraphCanvas
                data={data}
                {...this.props}
                dataSetY={maxDataSetY}
            >
                {this.props.children}
            </MultipleDataGraphCanvas>
        );
    }

    render() {
        const { style } = this.props;
        return (
            <div className="dittofi-graph-container dittofi-graph-container-canvas" {...this.props.inline} style={style}>
                {this.state.graph}
            </div>
        );
    }
}


MultipleDataGraph.propTypes = {
    data: PropTypes.array,
    type: PropTypes.oneOf(["svg", "hybrid"]),
    fill: PropTypes.string
};

MultipleDataGraph.defaultProps = {
    type: "svg",
    style: {},
    defaultData4:
        [
            {
                "Id":1,
                "adj low":17.4,
                "adj close":17.56,
                "adj open":17.66,
                "adj volume":3830546,
                "symbol":"VOD",
                "close":17.56,
                "volume":3818019,
                "low":17.4,
                "adj high":17.895,
                "exchange":"XNAS",
                "date":"2021-03-04T00:00:00+0000",
                "open":17.66,
                "high":17.895
            },
            {
                "Id":2,
                "adj low":17.47,
                "adj close":17.63,
                "adj open":17.53,
                "adj volume":2705820,
                "symbol":"VOD",
                "close":17.63,
                "volume":2701648,
                "low":17.47,
                "adj high":17.72,
                "exchange":"XNAS",
                "date":"2021-03-03T00:00:00+0000",
                "open":17.53,
                "high":17.7199
            },
            {
                "Id":3,
                "adj low":17.49,
                "adj close":17.52,
                "adj open":17.58,
                "adj volume":1880643,
                "symbol":"VOD",
                "close":17.52,
                "volume":1874164,
                "low":17.49,
                "adj high":17.64,
                "exchange":"XNAS",
                "date":"2021-03-02T00:00:00+0000",
                "open":17.58,
                "high":17.64
            },
            {
                "Id":4,
                "adj low":17.29,
                "adj close":17.44,
                "adj open":17.32,
                "adj volume":2978652,
                "symbol":"VOD",
                "close":17.44,
                "volume":2978652,
                "low":17.29,
                "adj high":17.525,
                "exchange":"XNAS",
                "date":"2021-03-01T00:00:00+0000",
                "open":17.32,
                "high":17.525
            },],
    defaultData3:

        [
            {
                "Id":1,
                "adj low":17.4,
                "adj close":17.56,
                "adj open":17.66,
                "adj volume":3830546,
                "symbol":"VOD",
                "close":17.56,
                "volume":3818019,
                "low":17.4,
                "adj high":17.895,
                "exchange":"XNAS",
                "date":"2021-03-04T00:00:00+0000",
                "open":17.66,
                "high":17.895
            },
            {
                "Id":2,
                "adj low":17.47,
                "adj close":17.63,
                "adj open":17.53,
                "adj volume":2705820,
                "symbol":"VOD",
                "close":17.63,
                "volume":2701648,
                "low":17.47,
                "adj high":17.72,
                "exchange":"XNAS",
                "date":"2021-03-03T00:00:00+0000",
                "open":17.53,
                "high":17.7199
            },
            {
                "Id":3,
                "adj low":17.49,
                "adj close":17.52,
                "adj open":17.58,
                "adj volume":1880643,
                "symbol":"VOD",
                "close":17.52,
                "volume":1874164,
                "low":17.49,
                "adj high":17.64,
                "exchange":"XNAS",
                "date":"2021-03-02T00:00:00+0000",
                "open":17.58,
                "high":17.64
            },
            {
                "Id":4,
                "adj low":17.29,
                "adj close":17.44,
                "adj open":17.32,
                "adj volume":2978652,
                "symbol":"VOD",
                "close":17.44,
                "volume":2978652,
                "low":17.29,
                "adj high":17.525,
                "exchange":"XNAS",
                "date":"2021-03-01T00:00:00+0000",
                "open":17.32,
                "high":17.525
            },
            {
                "Id":5,
                "adj low":17.08,
                "adj close":17.18,
                "adj open":17.42,
                "adj volume":3285087,
                "symbol":"VOD",
                "close":17.18,
                "volume":3328100,
                "low":17.08,
                "adj high":17.43,
                "exchange":"XNAS",
                "date":"2021-02-26T00:00:00+0000",
                "open":17.42,
                "high":17.43
            },
            {
                "Id":6,
                "adj low":17.57,
                "adj close":17.58,
                "adj open":17.93,
                "adj volume":4046441,
                "symbol":"VOD",
                "close":17.58,
                "volume":4044585,
                "low":17.57,
                "adj high":17.975,
                "exchange":"XNAS",
                "date":"2021-02-25T00:00:00+0000",
                "open":17.93,
                "high":17.975
            },
            {
                "Id":7,
                "adj low":17.845,
                "adj close":17.94,
                "adj open":18.05,
                "adj volume":4925182,
                "symbol":"VOD",
                "close":17.94,
                "volume":4905528,
                "low":17.845,
                "adj high":18.07,
                "exchange":"XNAS",
                "date":"2021-02-24T00:00:00+0000",
                "open":18.05,
                "high":18.07
            },
            {
                "Id":8,
                "adj low":18.06,
                "adj close":18.52,
                "adj open":18.23,
                "adj volume":3505419,
                "symbol":"VOD",
                "close":18.52,
                "volume":3505419,
                "low":18.06,
                "adj high":18.555,
                "exchange":"XNAS",
                "date":"2021-02-23T00:00:00+0000",
                "open":18.23,
                "high":18.555
            },
            {
                "Id":9,
                "adj low":18.39,
                "adj close":18.4,
                "adj open":18.4,
                "adj volume":3726441,
                "symbol":"VOD",
                "close":18.4,
                "volume":3725274,
                "low":18.39,
                "adj high":18.63,
                "exchange":"XNAS",
                "date":"2021-02-22T00:00:00+0000",
                "open":18.4,
                "high":18.63
            },
            {
                "Id":10,
                "adj low":18.48,
                "adj close":18.49,
                "adj open":18.65,
                "adj volume":2280264,
                "symbol":"VOD",
                "close":18.49,
                "volume":2280264,
                "low":18.48,
                "adj high":18.66,
                "exchange":"XNAS",
                "date":"2021-02-19T00:00:00+0000",
                "open":18.65,
                "high":18.66
            },
            {
                "Id":11,
                "adj low":18.285,
                "adj close":18.44,
                "adj open":18.43,
                "adj volume":2039832,
                "symbol":"VOD",
                "close":18.44,
                "volume":2039832,
                "low":18.285,
                "adj high":18.49,
                "exchange":"XNAS",
                "date":"2021-02-18T00:00:00+0000",
                "open":18.43,
                "high":18.49
            },
            {
                "Id":12,
                "adj low":18.47,
                "adj close":18.6,
                "adj open":18.56,
                "adj volume":2359235,
                "symbol":"VOD",
                "close":18.6,
                "volume":2359235,
                "low":18.47,
                "adj high":18.67,
                "exchange":"XNAS",
                "date":"2021-02-17T00:00:00+0000",
                "open":18.56,
                "high":18.67
            },
            {
                "Id":13,
                "adj low":18.66,
                "adj close":18.73,
                "adj open":18.68,
                "adj volume":2786884,
                "symbol":"VOD",
                "close":18.73,
                "volume":2786884,
                "low":18.66,
                "adj high":18.87,
                "exchange":"XNAS",
                "date":"2021-02-16T00:00:00+0000",
                "open":18.68,
                "high":18.87
            },
            {
                "Id":14,
                "adj low":18.49,
                "adj close":18.7,
                "adj open":18.5,
                "adj volume":1871490,
                "symbol":"VOD",
                "close":18.7,
                "volume":1871490,
                "low":18.49,
                "adj high":18.755,
                "exchange":"XNAS",
                "date":"2021-02-12T00:00:00+0000",
                "open":18.5,
                "high":18.755
            },
            {
                "Id":15,
                "adj low":18.51,
                "adj close":18.69,
                "adj open":18.65,
                "adj volume":2504714,
                "symbol":"VOD",
                "close":18.69,
                "volume":2504714,
                "low":18.51,
                "adj high":18.7,
                "exchange":"XNAS",
                "date":"2021-02-11T00:00:00+0000",
                "open":18.65,
                "high":18.7
            },
            {
                "Id":16,
                "adj low":18.44,
                "adj close":18.53,
                "adj open":18.56,
                "adj volume":2031352,
                "symbol":"VOD",
                "close":18.53,
                "volume":2031352,
                "low":18.44,
                "adj high":18.66,
                "exchange":"XNAS",
                "date":"2021-02-10T00:00:00+0000",
                "open":18.56,
                "high":18.66
            },
            {
                "Id":17,
                "adj low":18.4,
                "adj close":18.47,
                "adj open":18.5,
                "adj volume":2154627,
                "symbol":"VOD",
                "close":18.47,
                "volume":2154627,
                "low":18.4,
                "adj high":18.51,
                "exchange":"XNAS",
                "date":"2021-02-09T00:00:00+0000",
                "open":18.5,
                "high":18.51
            },
            {
                "Id":18,
                "adj low":18.31,
                "adj close":18.45,
                "adj open":18.5476,
                "adj volume":5376353,
                "symbol":"VOD",
                "close":18.45,
                "volume":5376353,
                "low":18.31,
                "adj high":18.585,
                "exchange":"XNAS",
                "date":"2021-02-08T00:00:00+0000",
                "open":18.5476,
                "high":18.585
            },
            {
                "Id":19,
                "adj low":18.45,
                "adj close":18.59,
                "adj open":18.45,
                "adj volume":6253471,
                "symbol":"VOD",
                "close":18.59,
                "volume":6253471,
                "low":18.45,
                "adj high":18.67,
                "exchange":"XNAS",
                "date":"2021-02-05T00:00:00+0000",
                "open":18.45,
                "high":18.67
            },
            {
                "Id":20,
                "adj low":18.59,
                "adj close":18.8,
                "adj open":18.7,
                "adj volume":4121014,
                "symbol":"VOD",
                "close":18.8,
                "volume":4121014,
                "low":18.59,
                "adj high":18.83,
                "exchange":"XNAS",
                "date":"2021-02-04T00:00:00+0000",
                "open":18.7,
                "high":18.83
            },
            {
                "Id":21,
                "adj low":18.35,
                "adj close":18.79,
                "adj open":18.41,
                "adj volume":9626573,
                "symbol":"VOD",
                "close":18.79,
                "volume":9626573,
                "low":18.35,
                "adj high":18.82,
                "exchange":"XNAS",
                "date":"2021-02-03T00:00:00+0000",
                "open":18.41,
                "high":18.82
            },
            {
                "Id":22,
                "adj low":17.405,
                "adj close":17.54,
                "adj open":17.44,
                "adj volume":2577917,
                "symbol":"VOD",
                "close":17.54,
                "volume":2577917,
                "low":17.405,
                "adj high":17.57,
                "exchange":"XNAS",
                "date":"2021-02-02T00:00:00+0000",
                "open":17.44,
                "high":17.57
            },
            {
                "Id":23,
                "adj low":17.26,
                "adj close":17.45,
                "adj open":17.4,
                "adj volume":3786464,
                "symbol":"VOD",
                "close":17.45,
                "volume":3786464,
                "low":17.26,
                "adj high":17.51,
                "exchange":"XNAS",
                "date":"2021-02-01T00:00:00+0000",
                "open":17.4,
                "high":17.51
            },
            {
                "Id":24,
                "adj low":17.015,
                "adj close":17.15,
                "adj open":17.36,
                "adj volume":5599479,
                "symbol":"VOD",
                "close":17.15,
                "volume":5599479,
                "low":17.015,
                "adj high":17.44,
                "exchange":"XNAS",
                "date":"2021-01-29T00:00:00+0000",
                "open":17.36,
                "high":17.44
            },
            {
                "Id":25,
                "adj low":17.13,
                "adj close":17.51,
                "adj open":17.17,
                "adj volume":3596591,
                "symbol":"VOD",
                "close":17.51,
                "volume":3596591,
                "low":17.13,
                "adj high":17.65,
                "exchange":"XNAS",
                "date":"2021-01-28T00:00:00+0000",
                "open":17.17,
                "high":17.65
            },
            {
                "Id":26,
                "adj low":17.455,
                "adj close":17.52,
                "adj open":17.79,
                "adj volume":4080992,
                "symbol":"VOD",
                "close":17.52,
                "volume":4080992,
                "low":17.455,
                "adj high":17.8,
                "exchange":"XNAS",
                "date":"2021-01-27T00:00:00+0000",
                "open":17.79,
                "high":17.8
            },
            {
                "Id":27,
                "adj low":17.755,
                "adj close":17.99,
                "adj open":17.8,
                "adj volume":4041312,
                "symbol":"VOD",
                "close":17.99,
                "volume":4041312,
                "low":17.755,
                "adj high":18.045,
                "exchange":"XNAS",
                "date":"2021-01-26T00:00:00+0000",
                "open":17.8,
                "high":18.045
            },
            {
                "Id":28,
                "adj low":17.3882,
                "adj close":17.78,
                "adj open":17.43,
                "adj volume":3161257,
                "symbol":"VOD",
                "close":17.78,
                "volume":3161257,
                "low":17.3882,
                "adj high":17.79,
                "exchange":"XNAS",
                "date":"2021-01-25T00:00:00+0000",
                "open":17.43,
                "high":17.79
            },
            {
                "Id":29,
                "adj low":17.441,
                "adj close":17.58,
                "adj open":17.55,
                "adj volume":1817079,
                "symbol":"VOD",
                "close":17.58,
                "volume":1817079,
                "low":17.441,
                "adj high":17.635,
                "exchange":"XNAS",
                "date":"2021-01-22T00:00:00+0000",
                "open":17.55,
                "high":17.635
            },
            {
                "Id":30,
                "adj low":17.515,
                "adj close":17.63,
                "adj open":17.67,
                "adj volume":2610202,
                "symbol":"VOD",
                "close":17.63,
                "volume":2610202,
                "low":17.515,
                "adj high":17.7,
                "exchange":"XNAS",
                "date":"2021-01-21T00:00:00+0000",
                "open":17.67,
                "high":17.7
            },
            {
                "Id":31,
                "adj low":17.32,
                "adj close":17.51,
                "adj open":17.52,
                "adj volume":2251549,
                "symbol":"VOD",
                "close":17.51,
                "volume":2251549,
                "low":17.32,
                "adj high":17.52,
                "exchange":"XNAS",
                "date":"2021-01-20T00:00:00+0000",
                "open":17.52,
                "high":17.52
            },
            {
                "Id":32,
                "adj low":17.225,
                "adj close":17.46,
                "adj open":17.3,
                "adj volume":3897559,
                "symbol":"VOD",
                "close":17.46,
                "volume":3897559,
                "low":17.225,
                "adj high":17.525,
                "exchange":"XNAS",
                "date":"2021-01-19T00:00:00+0000",
                "open":17.3,
                "high":17.525
            },
            {
                "Id":33,
                "adj low":17.195,
                "adj close":17.36,
                "adj open":17.39,
                "adj volume":2713526,
                "symbol":"VOD",
                "close":17.36,
                "volume":2713526,
                "low":17.195,
                "adj high":17.4,
                "exchange":"XNAS",
                "date":"2021-01-15T00:00:00+0000",
                "open":17.39,
                "high":17.4
            },
            {
                "Id":34,
                "adj low":17.55,
                "adj close":17.65,
                "adj open":17.58,
                "adj volume":3250922,
                "symbol":"VOD",
                "close":17.65,
                "volume":3250922,
                "low":17.55,
                "adj high":17.7466,
                "exchange":"XNAS",
                "date":"2021-01-14T00:00:00+0000",
                "open":17.58,
                "high":17.7466
            },
            {
                "Id":35,
                "adj low":17.39,
                "adj close":17.45,
                "adj open":17.53,
                "adj volume":3072986,
                "symbol":"VOD",
                "close":17.45,
                "volume":3072986,
                "low":17.39,
                "adj high":17.61,
                "exchange":"XNAS",
                "date":"2021-01-13T00:00:00+0000",
                "open":17.53,
                "high":17.61
            },
            {
                "Id":36,
                "adj low":17.045,
                "adj close":17.16,
                "adj open":17.19,
                "adj volume":2882035,
                "symbol":"VOD",
                "close":17.16,
                "volume":2882035,
                "low":17.045,
                "adj high":17.22,
                "exchange":"XNAS",
                "date":"2021-01-12T00:00:00+0000",
                "open":17.19,
                "high":17.22
            },
            {
                "Id":37,
                "adj low":17.3,
                "adj close":17.43,
                "adj open":17.33,
                "adj volume":2629890,
                "symbol":"VOD",
                "close":17.43,
                "volume":2629890,
                "low":17.3,
                "adj high":17.475,
                "exchange":"XNAS",
                "date":"2021-01-11T00:00:00+0000",
                "open":17.33,
                "high":17.475
            },
            {
                "Id":38,
                "adj low":17.345,
                "adj close":17.54,
                "adj open":17.6,
                "adj volume":3650973,
                "symbol":"VOD",
                "close":17.54,
                "volume":3650973,
                "low":17.345,
                "adj high":17.62,
                "exchange":"XNAS",
                "date":"2021-01-08T00:00:00+0000",
                "open":17.6,
                "high":17.62
            },
            {
                "Id":39,
                "adj low":17.675,
                "adj close":17.73,
                "adj open":17.92,
                "adj volume":4455264,
                "symbol":"VOD",
                "close":17.73,
                "volume":4455264,
                "low":17.675,
                "adj high":17.935,
                "exchange":"XNAS",
                "date":"2021-01-07T00:00:00+0000",
                "open":17.92,
                "high":17.935
            },
            {
                "Id":40,
                "adj low":17.53,
                "adj close":17.62,
                "adj open":17.64,
                "adj volume":3868371,
                "symbol":"VOD",
                "close":17.62,
                "volume":3868371,
                "low":17.53,
                "adj high":17.81,
                "exchange":"XNAS",
                "date":"2021-01-06T00:00:00+0000",
                "open":17.64,
                "high":17.81
            },
            {
                "Id":41,
                "adj low":16.845,
                "adj close":17.12,
                "adj open":16.85,
                "adj volume":3058079,
                "symbol":"VOD",
                "close":17.12,
                "volume":3058079,
                "low":16.845,
                "adj high":17.155,
                "exchange":"XNAS",
                "date":"2021-01-05T00:00:00+0000",
                "open":16.85,
                "high":17.155
            },
            {
                "Id":42,
                "adj low":16.785,
                "adj close":16.93,
                "adj open":17.04,
                "adj volume":3698815,
                "symbol":"VOD",
                "close":16.93,
                "volume":3698815,
                "low":16.785,
                "adj high":17.13,
                "exchange":"XNAS",
                "date":"2021-01-04T00:00:00+0000",
                "open":17.04,
                "high":17.13
            },
            {
                "Id":43,
                "adj low":16.46,
                "adj close":16.48,
                "adj open":16.59,
                "adj volume":4218343,
                "symbol":"VOD",
                "close":16.48,
                "volume":4218343,
                "low":16.46,
                "adj high":16.6263,
                "exchange":"XNAS",
                "date":"2020-12-31T00:00:00+0000",
                "open":16.59,
                "high":16.6263
            },
            {
                "Id":44,
                "adj low":16.62,
                "adj close":16.65,
                "adj open":16.79,
                "adj volume":2376731,
                "symbol":"VOD",
                "close":16.65,
                "volume":2376731,
                "low":16.62,
                "adj high":16.8,
                "exchange":"XNAS",
                "date":"2020-12-30T00:00:00+0000",
                "open":16.79,
                "high":16.8
            },
            {
                "Id":45,
                "adj low":16.61,
                "adj close":16.64,
                "adj open":16.85,
                "adj volume":2686521,
                "symbol":"VOD",
                "close":16.64,
                "volume":2686521,
                "low":16.61,
                "adj high":16.8789,
                "exchange":"XNAS",
                "date":"2020-12-29T00:00:00+0000",
                "open":16.85,
                "high":16.8789
            },
            {
                "Id":46,
                "adj low":16.72,
                "adj close":16.75,
                "adj open":16.83,
                "adj volume":2215263,
                "symbol":"VOD",
                "close":16.75,
                "volume":2215263,
                "low":16.72,
                "adj high":16.85,
                "exchange":"XNAS",
                "date":"2020-12-28T00:00:00+0000",
                "open":16.83,
                "high":16.85
            },
            {
                "Id":47,
                "adj low":16.585,
                "adj close":16.71,
                "adj open":16.71,
                "adj volume":1064183,
                "symbol":"VOD",
                "close":16.71,
                "volume":1064183,
                "low":16.585,
                "adj high":16.775,
                "exchange":"XNAS",
                "date":"2020-12-24T00:00:00+0000",
                "open":16.71,
                "high":16.775
            },
            {
                "Id":48,
                "adj low":16.61,
                "adj close":16.64,
                "adj open":16.62,
                "adj volume":2639666,
                "symbol":"VOD",
                "close":16.64,
                "volume":2639666,
                "low":16.61,
                "adj high":16.745,
                "exchange":"XNAS",
                "date":"2020-12-23T00:00:00+0000",
                "open":16.62,
                "high":16.745
            },
            {
                "Id":49,
                "adj low":16.2,
                "adj close":16.26,
                "adj open":16.28,
                "adj volume":3124758,
                "symbol":"VOD",
                "close":16.26,
                "volume":3124758,
                "low":16.2,
                "adj high":16.32,
                "exchange":"XNAS",
                "date":"2020-12-22T00:00:00+0000",
                "open":16.28,
                "high":16.32
            },
            {
                "Id":50,
                "adj low":16.1,
                "adj close":16.4,
                "adj open":16.22,
                "adj volume":6534110,
                "symbol":"VOD",
                "close":16.4,
                "volume":6534110,
                "low":16.1,
                "adj high":16.45,
                "exchange":"XNAS",
                "date":"2020-12-21T00:00:00+0000",
                "open":16.22,
                "high":16.45
            },
            {
                "Id":51,
                "adj low":17.03,
                "adj close":17.15,
                "adj open":17.07,
                "adj volume":6170405,
                "symbol":"VOD",
                "close":17.15,
                "volume":6170405,
                "low":17.03,
                "adj high":17.19,
                "exchange":"XNAS",
                "date":"2020-12-18T00:00:00+0000",
                "open":17.07,
                "high":17.19
            },
            {
                "Id":52,
                "adj low":17.03,
                "adj close":17.12,
                "adj open":17.16,
                "adj volume":5309064,
                "symbol":"VOD",
                "close":17.12,
                "volume":5309064,
                "low":17.03,
                "adj high":17.19,
                "exchange":"XNAS",
                "date":"2020-12-17T00:00:00+0000",
                "open":17.16,
                "high":17.19
            },
            {
                "Id":53,
                "adj low":17.565,
                "adj close":17.0788139697,
                "adj open":17.1757975812,
                "adj volume":4250995,
                "symbol":"VOD",
                "close":17.61,
                "volume":4250995,
                "low":17.565,
                "adj high":17.2048926646,
                "exchange":"XNAS",
                "date":"2020-12-16T00:00:00+0000",
                "open":17.71,
                "high":17.74
            },
            {
                "Id":54,
                "adj low":17.3501,
                "adj close":17.030322164,
                "adj open":17.0691156086,
                "adj volume":4947968,
                "symbol":"VOD",
                "close":17.56,
                "volume":4947968,
                "low":17.3501,
                "adj high":17.0691156086,
                "exchange":"XNAS",
                "date":"2020-12-15T00:00:00+0000",
                "open":17.6,
                "high":17.6
            },
            {
                "Id":55,
                "adj low":17.33,
                "adj close":17.34,
                "adj open":17.81,
                "adj volume":5138898,
                "symbol":"VOD",
                "close":17.34,
                "volume":5138898,
                "low":17.33,
                "adj high":17.82,
                "exchange":"XNAS",
                "date":"2020-12-14T00:00:00+0000",
                "open":17.81,
                "high":17.82
            },
            {
                "Id":56,
                "adj low":17.2,
                "adj close":17.21,
                "adj open":17.55,
                "adj volume":5172565,
                "symbol":"VOD",
                "close":17.21,
                "volume":5172565,
                "low":17.2,
                "adj high":17.615,
                "exchange":"XNAS",
                "date":"2020-12-11T00:00:00+0000",
                "open":17.55,
                "high":17.615
            },
            {
                "Id":57,
                "adj low":17.7,
                "adj close":17.8,
                "adj open":17.76,
                "adj volume":2891085,
                "symbol":"VOD",
                "close":17.8,
                "volume":2891085,
                "low":17.7,
                "adj high":17.95,
                "exchange":"XNAS",
                "date":"2020-12-10T00:00:00+0000",
                "open":17.76,
                "high":17.95
            },
            {
                "Id":58,
                "adj low":17.76,
                "adj close":17.87,
                "adj open":17.9318,
                "adj volume":4958381,
                "symbol":"VOD",
                "close":17.87,
                "volume":4958381,
                "low":17.76,
                "adj high":18.05,
                "exchange":"XNAS",
                "date":"2020-12-09T00:00:00+0000",
                "open":17.9318,
                "high":18.05
            },
            {
                "Id":59,
                "adj low":17.42,
                "adj close":17.52,
                "adj open":17.5,
                "adj volume":3441448,
                "symbol":"VOD",
                "close":17.52,
                "volume":3441448,
                "low":17.42,
                "adj high":17.585,
                "exchange":"XNAS",
                "date":"2020-12-08T00:00:00+0000",
                "open":17.5,
                "high":17.585
            },
            {
                "Id":60,
                "adj low":17.61,
                "adj close":17.81,
                "adj open":17.77,
                "adj volume":3441167,
                "symbol":"VOD",
                "close":17.81,
                "volume":3441167,
                "low":17.61,
                "adj high":17.81,
                "exchange":"XNAS",
                "date":"2020-12-07T00:00:00+0000",
                "open":17.77,
                "high":17.81
            },
            {
                "Id":61,
                "adj low":17.475,
                "adj close":17.55,
                "adj open":17.48,
                "adj volume":4573346,
                "symbol":"VOD",
                "close":17.55,
                "volume":4573346,
                "low":17.475,
                "adj high":17.67,
                "exchange":"XNAS",
                "date":"2020-12-04T00:00:00+0000",
                "open":17.48,
                "high":17.67
            },
            {
                "Id":62,
                "adj low":17.08,
                "adj close":17.12,
                "adj open":17.19,
                "adj volume":3966746,
                "symbol":"VOD",
                "close":17.12,
                "volume":3966746,
                "low":17.08,
                "adj high":17.235,
                "exchange":"XNAS",
                "date":"2020-12-03T00:00:00+0000",
                "open":17.19,
                "high":17.235
            },
            {
                "Id":63,
                "adj low":16.76,
                "adj close":17.03,
                "adj open":16.78,
                "adj volume":5048557,
                "symbol":"VOD",
                "close":17.03,
                "volume":5048557,
                "low":16.76,
                "adj high":17.06,
                "exchange":"XNAS",
                "date":"2020-12-02T00:00:00+0000",
                "open":16.78,
                "high":17.06
            },
            {
                "Id":64,
                "adj low":16.76,
                "adj close":16.84,
                "adj open":16.77,
                "adj volume":3520999,
                "symbol":"VOD",
                "close":16.84,
                "volume":3520999,
                "low":16.76,
                "adj high":16.96,
                "exchange":"XNAS",
                "date":"2020-12-01T00:00:00+0000",
                "open":16.77,
                "high":16.96
            },
            {
                "Id":65,
                "adj low":16.56,
                "adj close":16.58,
                "adj open":16.78,
                "adj volume":2770878,
                "symbol":"VOD",
                "close":16.58,
                "volume":2770878,
                "low":16.56,
                "adj high":16.81,
                "exchange":"XNAS",
                "date":"2020-11-30T00:00:00+0000",
                "open":16.78,
                "high":16.81
            },
            {
                "Id":66,
                "adj low":16.74,
                "adj close":16.85,
                "adj open":16.77,
                "adj volume":2006095,
                "symbol":"VOD",
                "close":16.85,
                "volume":2006095,
                "low":16.74,
                "adj high":16.8641,
                "exchange":"XNAS",
                "date":"2020-11-27T00:00:00+0000",
                "open":16.77,
                "high":16.8641
            },
            {
                "Id":67,
                "adj low":16.7,
                "adj close":16.76,
                "adj open":16.81,
                "adj volume":2493448,
                "symbol":"VOD",
                "close":16.76,
                "volume":2493448,
                "low":16.7,
                "adj high":16.829,
                "exchange":"XNAS",
                "date":"2020-11-25T00:00:00+0000",
                "open":16.81,
                "high":16.829
            },
            {
                "Id":68,
                "adj low":16.65,
                "adj close":16.86,
                "adj open":16.66,
                "adj volume":2351754,
                "symbol":"VOD",
                "close":16.86,
                "volume":2351754,
                "low":16.65,
                "adj high":16.9342,
                "exchange":"XNAS",
                "date":"2020-11-24T00:00:00+0000",
                "open":16.66,
                "high":16.9342
            },
            {
                "Id":69,
                "adj low":16.28,
                "adj close":16.39,
                "adj open":16.48,
                "adj volume":2553046,
                "symbol":"VOD",
                "close":16.39,
                "volume":2553046,
                "low":16.28,
                "adj high":16.5,
                "exchange":"XNAS",
                "date":"2020-11-23T00:00:00+0000",
                "open":16.48,
                "high":16.5
            },
            {
                "Id":70,
                "adj low":16.34,
                "adj close":16.47,
                "adj open":16.4,
                "adj volume":2923156,
                "symbol":"VOD",
                "close":16.47,
                "volume":2923156,
                "low":16.34,
                "adj high":16.5583,
                "exchange":"XNAS",
                "date":"2020-11-20T00:00:00+0000",
                "open":16.4,
                "high":16.5583
            },
            {
                "Id":71,
                "adj low":16.17,
                "adj close":16.34,
                "adj open":16.22,
                "adj volume":2742410,
                "symbol":"VOD",
                "close":16.34,
                "volume":2742410,
                "low":16.17,
                "adj high":16.35,
                "exchange":"XNAS",
                "date":"2020-11-19T00:00:00+0000",
                "open":16.22,
                "high":16.35
            },
            {
                "Id":72,
                "adj low":16.31,
                "adj close":16.32,
                "adj open":16.35,
                "adj volume":3092003,
                "symbol":"VOD",
                "close":16.32,
                "volume":3092003,
                "low":16.31,
                "adj high":16.539,
                "exchange":"XNAS",
                "date":"2020-11-18T00:00:00+0000",
                "open":16.35,
                "high":16.539
            },
            {
                "Id":73,
                "adj low":16.29,
                "adj close":16.35,
                "adj open":16.64,
                "adj volume":4081591,
                "symbol":"VOD",
                "close":16.35,
                "volume":4081591,
                "low":16.29,
                "adj high":16.67,
                "exchange":"XNAS",
                "date":"2020-11-17T00:00:00+0000",
                "open":16.64,
                "high":16.67
            },
            {
                "Id":74,
                "adj low":16.67,
                "adj close":16.74,
                "adj open":16.71,
                "adj volume":7999596,
                "symbol":"VOD",
                "close":16.74,
                "volume":7999596,
                "low":16.67,
                "adj high":16.995,
                "exchange":"XNAS",
                "date":"2020-11-16T00:00:00+0000",
                "open":16.71,
                "high":16.995
            },
            {
                "Id":75,
                "adj low":15.691,
                "adj close":16.12,
                "adj open":15.71,
                "adj volume":4099240,
                "symbol":"VOD",
                "close":16.12,
                "volume":4099240,
                "low":15.691,
                "adj high":16.12,
                "exchange":"XNAS",
                "date":"2020-11-13T00:00:00+0000",
                "open":15.71,
                "high":16.12
            },
            {
                "Id":76,
                "adj low":15.415,
                "adj close":15.49,
                "adj open":15.65,
                "adj volume":3666792,
                "symbol":"VOD",
                "close":15.49,
                "volume":3666792,
                "low":15.415,
                "adj high":15.7659,
                "exchange":"XNAS",
                "date":"2020-11-12T00:00:00+0000",
                "open":15.65,
                "high":15.7659
            },
            {
                "Id":77,
                "adj low":15.48,
                "adj close":15.69,
                "adj open":15.6,
                "adj volume":4993602,
                "symbol":"VOD",
                "close":15.69,
                "volume":4993602,
                "low":15.48,
                "adj high":15.7,
                "exchange":"XNAS",
                "date":"2020-11-11T00:00:00+0000",
                "open":15.6,
                "high":15.7
            },
            {
                "Id":78,
                "adj low":15.42,
                "adj close":15.59,
                "adj open":15.44,
                "adj volume":7813806,
                "symbol":"VOD",
                "close":15.59,
                "volume":7813806,
                "low":15.42,
                "adj high":15.67,
                "exchange":"XNAS",
                "date":"2020-11-10T00:00:00+0000",
                "open":15.44,
                "high":15.67
            },
            {
                "Id":79,
                "adj low":14.85,
                "adj close":14.89,
                "adj open":15.09,
                "adj volume":5591177,
                "symbol":"VOD",
                "close":14.89,
                "volume":5591177,
                "low":14.85,
                "adj high":15.14,
                "exchange":"XNAS",
                "date":"2020-11-09T00:00:00+0000",
                "open":15.09,
                "high":15.14
            },
            {
                "Id":80,
                "adj low":13.94,
                "adj close":13.97,
                "adj open":14.12,
                "adj volume":2573773,
                "symbol":"VOD",
                "close":13.97,
                "volume":2573773,
                "low":13.94,
                "adj high":14.1681,
                "exchange":"XNAS",
                "date":"2020-11-06T00:00:00+0000",
                "open":14.12,
                "high":14.1681
            },
            {
                "Id":81,
                "adj low":14,
                "adj close":14.11,
                "adj open":14,
                "adj volume":3304915,
                "symbol":"VOD",
                "close":14.11,
                "volume":3304915,
                "low":14,
                "adj high":14.21,
                "exchange":"XNAS",
                "date":"2020-11-05T00:00:00+0000",
                "open":14,
                "high":14.21
            },
            {
                "Id":82,
                "adj low":13.64,
                "adj close":13.9,
                "adj open":13.79,
                "adj volume":3659896,
                "symbol":"VOD",
                "close":13.9,
                "volume":3659896,
                "low":13.64,
                "adj high":14,
                "exchange":"XNAS",
                "date":"2020-11-04T00:00:00+0000",
                "open":13.79,
                "high":14
            },
            {
                "Id":83,
                "adj low":13.865,
                "adj close":13.92,
                "adj open":13.94,
                "adj volume":3446938,
                "symbol":"VOD",
                "close":13.92,
                "volume":3446938,
                "low":13.865,
                "adj high":14.06,
                "exchange":"XNAS",
                "date":"2020-11-03T00:00:00+0000",
                "open":13.94,
                "high":14.06
            },
            {
                "Id":84,
                "adj low":13.645,
                "adj close":13.81,
                "adj open":13.73,
                "adj volume":4290985,
                "symbol":"VOD",
                "close":13.81,
                "volume":4290985,
                "low":13.645,
                "adj high":13.88,
                "exchange":"XNAS",
                "date":"2020-11-02T00:00:00+0000",
                "open":13.73,
                "high":13.88
            },
            {
                "Id":85,
                "adj low":13.33,
                "adj close":13.51,
                "adj open":13.49,
                "adj volume":3372568,
                "symbol":"VOD",
                "close":13.51,
                "volume":3372568,
                "low":13.33,
                "adj high":13.525,
                "exchange":"XNAS",
                "date":"2020-10-30T00:00:00+0000",
                "open":13.49,
                "high":13.525
            },
            {
                "Id":86,
                "adj low":13.2,
                "adj close":13.55,
                "adj open":13.2936,
                "adj volume":5912464,
                "symbol":"VOD",
                "close":13.55,
                "volume":5912464,
                "low":13.2,
                "adj high":13.64,
                "exchange":"XNAS",
                "date":"2020-10-29T00:00:00+0000",
                "open":13.2936,
                "high":13.64
            },
            {
                "Id":87,
                "adj low":13.28,
                "adj close":13.43,
                "adj open":13.45,
                "adj volume":6759000,
                "symbol":"VOD",
                "close":13.43,
                "volume":6759000,
                "low":13.28,
                "adj high":13.56,
                "exchange":"XNAS",
                "date":"2020-10-28T00:00:00+0000",
                "open":13.45,
                "high":13.56
            },
            {
                "Id":88,
                "adj low":13.91,
                "adj close":13.98,
                "adj open":14.2,
                "adj volume":4558518,
                "symbol":"VOD",
                "close":13.98,
                "volume":4558518,
                "low":13.91,
                "adj high":14.245,
                "exchange":"XNAS",
                "date":"2020-10-27T00:00:00+0000",
                "open":14.2,
                "high":14.245
            },
            {
                "Id":89,
                "adj low":14.41,
                "adj close":14.52,
                "adj open":14.62,
                "adj volume":4442371,
                "symbol":"VOD",
                "close":14.52,
                "volume":4442371,
                "low":14.41,
                "adj high":14.62,
                "exchange":"XNAS",
                "date":"2020-10-26T00:00:00+0000",
                "open":14.62,
                "high":14.62
            },
            {
                "Id":90,
                "adj low":14.72,
                "adj close":14.84,
                "adj open":14.8,
                "adj volume":6919188,
                "symbol":"VOD",
                "close":14.84,
                "volume":6919188,
                "low":14.72,
                "adj high":14.93,
                "exchange":"XNAS",
                "date":"2020-10-23T00:00:00+0000",
                "open":14.8,
                "high":14.93
            },
            {
                "Id":91,
                "adj low":14.25,
                "adj close":14.55,
                "adj open":14.25,
                "adj volume":6935782,
                "symbol":"VOD",
                "close":14.55,
                "volume":6935782,
                "low":14.25,
                "adj high":14.58,
                "exchange":"XNAS",
                "date":"2020-10-22T00:00:00+0000",
                "open":14.25,
                "high":14.58
            },
            {
                "Id":92,
                "adj low":14.04,
                "adj close":14.33,
                "adj open":14.13,
                "adj volume":8730294,
                "symbol":"VOD",
                "close":14.33,
                "volume":8730294,
                "low":14.04,
                "adj high":14.42,
                "exchange":"XNAS",
                "date":"2020-10-21T00:00:00+0000",
                "open":14.13,
                "high":14.42
            },
            {
                "Id":93,
                "adj low":14.39,
                "adj close":14.46,
                "adj open":14.41,
                "adj volume":3056450,
                "symbol":"VOD",
                "close":14.46,
                "volume":3056450,
                "low":14.39,
                "adj high":14.59,
                "exchange":"XNAS",
                "date":"2020-10-20T00:00:00+0000",
                "open":14.41,
                "high":14.59
            },
            {
                "Id":94,
                "adj low":14.19,
                "adj close":14.21,
                "adj open":14.35,
                "adj volume":4436631,
                "symbol":"VOD",
                "close":14.21,
                "volume":4436631,
                "low":14.19,
                "adj high":14.42,
                "exchange":"XNAS",
                "date":"2020-10-19T00:00:00+0000",
                "open":14.35,
                "high":14.42
            },
            {
                "Id":95,
                "adj low":14.0975,
                "adj close":14.28,
                "adj open":14.11,
                "adj volume":4334682,
                "symbol":"VOD",
                "close":14.28,
                "volume":4334682,
                "low":14.0975,
                "adj high":14.335,
                "exchange":"XNAS",
                "date":"2020-10-16T00:00:00+0000",
                "open":14.11,
                "high":14.335
            },
            {
                "Id":96,
                "adj low":14.115,
                "adj close":14.32,
                "adj open":14.15,
                "adj volume":3918735,
                "symbol":"VOD",
                "close":14.32,
                "volume":3918735,
                "low":14.115,
                "adj high":14.33,
                "exchange":"XNAS",
                "date":"2020-10-15T00:00:00+0000",
                "open":14.15,
                "high":14.33
            },
            {
                "Id":97,
                "adj low":14.33,
                "adj close":14.37,
                "adj open":14.43,
                "adj volume":1908486,
                "symbol":"VOD",
                "close":14.37,
                "volume":1908486,
                "low":14.33,
                "adj high":14.5,
                "exchange":"XNAS",
                "date":"2020-10-14T00:00:00+0000",
                "open":14.43,
                "high":14.5
            },
            {
                "Id":98,
                "adj low":14.5518,
                "adj close":14.6,
                "adj open":14.67,
                "adj volume":2635563,
                "symbol":"VOD",
                "close":14.6,
                "volume":2635563,
                "low":14.5518,
                "adj high":14.69,
                "exchange":"XNAS",
                "date":"2020-10-13T00:00:00+0000",
                "open":14.67,
                "high":14.69
            },
            {
                "Id":99,
                "adj low":14.655,
                "adj close":14.77,
                "adj open":14.73,
                "adj volume":2212874,
                "symbol":"VOD",
                "close":14.77,
                "volume":2212874,
                "low":14.655,
                "adj high":14.83,
                "exchange":"XNAS",
                "date":"2020-10-12T00:00:00+0000",
                "open":14.73,
                "high":14.83
            },
            {
                "Id":100,
                "adj low":14.44,
                "adj close":14.63,
                "adj open":14.6,
                "adj volume":2258206,
                "symbol":"VOD",
                "close":14.63,
                "volume":2258206,
                "low":14.44,
                "adj high":14.68,
                "exchange":"XNAS",
                "date":"2020-10-09T00:00:00+0000",
                "open":14.6,
                "high":14.68
            },
            {
                "Id":101,
                "adj low":118.62,
                "adj close":120.13,
                "adj open":121.75,
                "adj volume":178154975,
                "symbol":"AAPL",
                "close":120.13,
                "volume":164527934,
                "low":118.62,
                "adj high":123.6,
                "exchange":"XNAS",
                "date":"2021-03-04T00:00:00+0000",
                "open":121.75,
                "high":123.6
            },
            {
                "Id":102,
                "adj low":121.84,
                "adj close":122.06,
                "adj open":124.81,
                "adj volume":112966340,
                "symbol":"AAPL",
                "close":122.06,
                "volume":112965897,
                "low":121.84,
                "adj high":125.71,
                "exchange":"XNAS",
                "date":"2021-03-03T00:00:00+0000",
                "open":124.81,
                "high":125.71
            },
            {
                "Id":103,
                "adj low":125.015,
                "adj close":125.12,
                "adj open":128.41,
                "adj volume":102260945,
                "symbol":"AAPL",
                "close":125.12,
                "volume":102260945,
                "low":125.015,
                "adj high":128.72,
                "exchange":"XNAS",
                "date":"2021-03-02T00:00:00+0000",
                "open":128.41,
                "high":128.72
            },
            {
                "Id":104,
                "adj low":122.79,
                "adj close":127.79,
                "adj open":123.75,
                "adj volume":116307892,
                "symbol":"AAPL",
                "close":127.79,
                "volume":116307892,
                "low":122.79,
                "adj high":127.93,
                "exchange":"XNAS",
                "date":"2021-03-01T00:00:00+0000",
                "open":123.75,
                "high":127.93
            },
            {
                "Id":105,
                "adj low":121.2,
                "adj close":121.26,
                "adj open":122.59,
                "adj volume":163424672,
                "symbol":"AAPL",
                "close":121.26,
                "volume":164320000,
                "low":121.2,
                "adj high":124.85,
                "exchange":"XNAS",
                "date":"2021-02-26T00:00:00+0000",
                "open":122.59,
                "high":124.85
            },
            {
                "Id":106,
                "adj low":120.54,
                "adj close":120.99,
                "adj open":124.68,
                "adj volume":144766924,
                "symbol":"AAPL",
                "close":120.99,
                "volume":148199540,
                "low":120.54,
                "adj high":126.4585,
                "exchange":"XNAS",
                "date":"2021-02-25T00:00:00+0000",
                "open":124.68,
                "high":126.4585
            },
            {
                "Id":107,
                "adj low":122.23,
                "adj close":125.35,
                "adj open":124.94,
                "adj volume":111039904,
                "symbol":"AAPL",
                "close":125.35,
                "volume":111039904,
                "low":122.23,
                "adj high":125.56,
                "exchange":"XNAS",
                "date":"2021-02-24T00:00:00+0000",
                "open":124.94,
                "high":125.56
            },
            {
                "Id":108,
                "adj low":118.39,
                "adj close":125.86,
                "adj open":123.76,
                "adj volume":158273022,
                "symbol":"AAPL",
                "close":125.86,
                "volume":158273022,
                "low":118.39,
                "adj high":126.71,
                "exchange":"XNAS",
                "date":"2021-02-23T00:00:00+0000",
                "open":123.76,
                "high":126.705
            },
            {
                "Id":109,
                "adj low":125.6,
                "adj close":126,
                "adj open":128.01,
                "adj volume":102886922,
                "symbol":"AAPL",
                "close":126,
                "volume":100925374,
                "low":125.6,
                "adj high":129.72,
                "exchange":"XNAS",
                "date":"2021-02-22T00:00:00+0000",
                "open":128.01,
                "high":129.72
            },
            {
                "Id":110,
                "adj low":128.8,
                "adj close":129.87,
                "adj open":130.24,
                "adj volume":87668834,
                "symbol":"AAPL",
                "close":129.87,
                "volume":87668834,
                "low":128.8,
                "adj high":130.71,
                "exchange":"XNAS",
                "date":"2021-02-19T00:00:00+0000",
                "open":130.24,
                "high":130.71
            },
            {
                "Id":111,
                "adj low":127.41,
                "adj close":129.71,
                "adj open":129.2,
                "adj volume":96856748,
                "symbol":"AAPL",
                "close":129.71,
                "volume":96856748,
                "low":127.41,
                "adj high":129.995,
                "exchange":"XNAS",
                "date":"2021-02-18T00:00:00+0000",
                "open":129.2,
                "high":129.995
            },
            {
                "Id":112,
                "adj low":129.47,
                "adj close":130.84,
                "adj open":131.25,
                "adj volume":97372199,
                "symbol":"AAPL",
                "close":130.84,
                "volume":97372199,
                "low":129.47,
                "adj high":132.22,
                "exchange":"XNAS",
                "date":"2021-02-17T00:00:00+0000",
                "open":131.25,
                "high":132.22
            },
            {
                "Id":113,
                "adj low":132.79,
                "adj close":133.19,
                "adj open":135.49,
                "adj volume":80576316,
                "symbol":"AAPL",
                "close":133.19,
                "volume":80576316,
                "low":132.79,
                "adj high":136.01,
                "exchange":"XNAS",
                "date":"2021-02-16T00:00:00+0000",
                "open":135.49,
                "high":136.01
            },
            {
                "Id":114,
                "adj low":133.6921,
                "adj close":135.37,
                "adj open":134.35,
                "adj volume":60145130,
                "symbol":"AAPL",
                "close":135.37,
                "volume":60145130,
                "low":133.6921,
                "adj high":135.53,
                "exchange":"XNAS",
                "date":"2021-02-12T00:00:00+0000",
                "open":134.35,
                "high":135.53
            },
            {
                "Id":115,
                "adj low":133.77,
                "adj close":135.13,
                "adj open":135.9,
                "adj volume":64280029,
                "symbol":"AAPL",
                "close":135.13,
                "volume":64280029,
                "low":133.77,
                "adj high":136.39,
                "exchange":"XNAS",
                "date":"2021-02-11T00:00:00+0000",
                "open":135.9,
                "high":136.39
            },
            {
                "Id":116,
                "adj low":134.4,
                "adj close":135.39,
                "adj open":136.48,
                "adj volume":72647988,
                "symbol":"AAPL",
                "close":135.39,
                "volume":72647988,
                "low":134.4,
                "adj high":136.99,
                "exchange":"XNAS",
                "date":"2021-02-10T00:00:00+0000",
                "open":136.48,
                "high":136.99
            },
            {
                "Id":117,
                "adj low":135.85,
                "adj close":136.01,
                "adj open":136.62,
                "adj volume":75986989,
                "symbol":"AAPL",
                "close":136.01,
                "volume":75986989,
                "low":135.85,
                "adj high":137.877,
                "exchange":"XNAS",
                "date":"2021-02-09T00:00:00+0000",
                "open":136.62,
                "high":137.877
            },
            {
                "Id":118,
                "adj low":134.92,
                "adj close":136.91,
                "adj open":136.03,
                "adj volume":71297214,
                "symbol":"AAPL",
                "close":136.91,
                "volume":71297214,
                "low":134.92,
                "adj high":136.96,
                "exchange":"XNAS",
                "date":"2021-02-08T00:00:00+0000",
                "open":136.03,
                "high":136.96
            },
            {
                "Id":119,
                "adj low":135.86,
                "adj close":136.76,
                "adj open":137.35,
                "adj volume":75693830,
                "symbol":"AAPL",
                "close":136.76,
                "volume":75693830,
                "low":135.86,
                "adj high":137.42,
                "exchange":"XNAS",
                "date":"2021-02-05T00:00:00+0000",
                "open":137.35,
                "high":137.42
            },
            {
                "Id":120,
                "adj low":134.59,
                "adj close":137.1843638886,
                "adj open":136.0959953273,
                "adj volume":84183061,
                "symbol":"AAPL",
                "close":137.39,
                "volume":84183061,
                "low":134.59,
                "adj high":137.1943489213,
                "exchange":"XNAS",
                "date":"2021-02-04T00:00:00+0000",
                "open":136.3,
                "high":137.4
            },
            {
                "Id":121,
                "adj low":133.61,
                "adj close":133.7395276165,
                "adj open":135.556803563,
                "adj volume":89880937,
                "symbol":"AAPL",
                "close":133.94,
                "volume":89880937,
                "low":133.61,
                "adj high":135.5667885956,
                "exchange":"XNAS",
                "date":"2021-02-03T00:00:00+0000",
                "open":135.76,
                "high":135.77
            },
            {
                "Id":122,
                "adj low":134.61,
                "adj close":134.99,
                "adj open":135.73,
                "adj volume":82266419,
                "symbol":"AAPL",
                "close":134.99,
                "volume":82266419,
                "low":134.61,
                "adj high":136.31,
                "exchange":"XNAS",
                "date":"2021-02-02T00:00:00+0000",
                "open":135.73,
                "high":136.31
            },
            {
                "Id":123,
                "adj low":130.93,
                "adj close":134.14,
                "adj open":133.75,
                "adj volume":106239823,
                "symbol":"AAPL",
                "close":134.14,
                "volume":106239823,
                "low":130.93,
                "adj high":135.38,
                "exchange":"XNAS",
                "date":"2021-02-01T00:00:00+0000",
                "open":133.75,
                "high":135.38
            },
            {
                "Id":124,
                "adj low":130.21,
                "adj close":131.96,
                "adj open":135.83,
                "adj volume":177523812,
                "symbol":"AAPL",
                "close":131.96,
                "volume":177523812,
                "low":130.21,
                "adj high":136.74,
                "exchange":"XNAS",
                "date":"2021-01-29T00:00:00+0000",
                "open":135.83,
                "high":136.74
            },
            {
                "Id":125,
                "adj low":136.7,
                "adj close":137.09,
                "adj open":139.52,
                "adj volume":142621128,
                "symbol":"AAPL",
                "close":137.09,
                "volume":142621128,
                "low":136.7,
                "adj high":141.99,
                "exchange":"XNAS",
                "date":"2021-01-28T00:00:00+0000",
                "open":139.52,
                "high":141.99
            },
            {
                "Id":126,
                "adj low":140.41,
                "adj close":142.06,
                "adj open":143.43,
                "adj volume":140843759,
                "symbol":"AAPL",
                "close":142.06,
                "volume":140843759,
                "low":140.41,
                "adj high":144.3,
                "exchange":"XNAS",
                "date":"2021-01-27T00:00:00+0000",
                "open":143.43,
                "high":144.3
            },
            {
                "Id":127,
                "adj low":141.37,
                "adj close":143.16,
                "adj open":143.6,
                "adj volume":98390555,
                "symbol":"AAPL",
                "close":143.16,
                "volume":98390555,
                "low":141.37,
                "adj high":144.3,
                "exchange":"XNAS",
                "date":"2021-01-26T00:00:00+0000",
                "open":143.6,
                "high":144.3
            },
            {
                "Id":128,
                "adj low":136.54,
                "adj close":142.92,
                "adj open":143.07,
                "adj volume":157611713,
                "symbol":"AAPL",
                "close":142.92,
                "volume":157611713,
                "low":136.54,
                "adj high":145.09,
                "exchange":"XNAS",
                "date":"2021-01-25T00:00:00+0000",
                "open":143.07,
                "high":145.09
            },
            {
                "Id":129,
                "adj low":135.02,
                "adj close":139.07,
                "adj open":136.28,
                "adj volume":114459360,
                "symbol":"AAPL",
                "close":139.07,
                "volume":114459360,
                "low":135.02,
                "adj high":139.85,
                "exchange":"XNAS",
                "date":"2021-01-22T00:00:00+0000",
                "open":136.28,
                "high":139.85
            },
            {
                "Id":130,
                "adj low":133.59,
                "adj close":136.87,
                "adj open":133.8,
                "adj volume":120529544,
                "symbol":"AAPL",
                "close":136.87,
                "volume":120529544,
                "low":133.59,
                "adj high":139.67,
                "exchange":"XNAS",
                "date":"2021-01-21T00:00:00+0000",
                "open":133.8,
                "high":139.67
            },
            {
                "Id":131,
                "adj low":128.55,
                "adj close":132.03,
                "adj open":128.66,
                "adj volume":104319489,
                "symbol":"AAPL",
                "close":132.03,
                "volume":104319489,
                "low":128.55,
                "adj high":132.49,
                "exchange":"XNAS",
                "date":"2021-01-20T00:00:00+0000",
                "open":128.66,
                "high":132.49
            },
            {
                "Id":132,
                "adj low":126.938,
                "adj close":127.83,
                "adj open":127.78,
                "adj volume":90757329,
                "symbol":"AAPL",
                "close":127.83,
                "volume":90757329,
                "low":126.938,
                "adj high":128.71,
                "exchange":"XNAS",
                "date":"2021-01-19T00:00:00+0000",
                "open":127.78,
                "high":128.71
            },
            {
                "Id":133,
                "adj low":127,
                "adj close":127.14,
                "adj open":128.78,
                "adj volume":111598531,
                "symbol":"AAPL",
                "close":127.14,
                "volume":111598531,
                "low":127,
                "adj high":130.2242,
                "exchange":"XNAS",
                "date":"2021-01-15T00:00:00+0000",
                "open":128.78,
                "high":130.2242
            },
            {
                "Id":134,
                "adj low":128.76,
                "adj close":128.91,
                "adj open":130.8,
                "adj volume":90221755,
                "symbol":"AAPL",
                "close":128.91,
                "volume":90221755,
                "low":128.76,
                "adj high":131,
                "exchange":"XNAS",
                "date":"2021-01-14T00:00:00+0000",
                "open":130.8,
                "high":131
            },
            {
                "Id":135,
                "adj low":128.49,
                "adj close":130.89,
                "adj open":128.76,
                "adj volume":88636831,
                "symbol":"AAPL",
                "close":130.89,
                "volume":88636831,
                "low":128.49,
                "adj high":131.45,
                "exchange":"XNAS",
                "date":"2021-01-13T00:00:00+0000",
                "open":128.76,
                "high":131.45
            },
            {
                "Id":136,
                "adj low":126.86,
                "adj close":128.8,
                "adj open":128.5,
                "adj volume":90440255,
                "symbol":"AAPL",
                "close":128.8,
                "volume":90440255,
                "low":126.86,
                "adj high":129.69,
                "exchange":"XNAS",
                "date":"2021-01-12T00:00:00+0000",
                "open":128.5,
                "high":129.69
            },
            {
                "Id":137,
                "adj low":128.5,
                "adj close":128.98,
                "adj open":129.19,
                "adj volume":100620880,
                "symbol":"AAPL",
                "close":128.98,
                "volume":100620880,
                "low":128.5,
                "adj high":130.17,
                "exchange":"XNAS",
                "date":"2021-01-11T00:00:00+0000",
                "open":129.19,
                "high":130.17
            },
            {
                "Id":138,
                "adj low":130.23,
                "adj close":132.05,
                "adj open":132.43,
                "adj volume":105158245,
                "symbol":"AAPL",
                "close":132.05,
                "volume":105158245,
                "low":130.23,
                "adj high":132.63,
                "exchange":"XNAS",
                "date":"2021-01-08T00:00:00+0000",
                "open":132.43,
                "high":132.63
            },
            {
                "Id":139,
                "adj low":127.86,
                "adj close":130.92,
                "adj open":128.36,
                "adj volume":109578157,
                "symbol":"AAPL",
                "close":130.92,
                "volume":109578157,
                "low":127.86,
                "adj high":131.63,
                "exchange":"XNAS",
                "date":"2021-01-07T00:00:00+0000",
                "open":128.36,
                "high":131.63
            },
            {
                "Id":140,
                "adj low":126.382,
                "adj close":126.6,
                "adj open":127.72,
                "adj volume":155087970,
                "symbol":"AAPL",
                "close":126.6,
                "volume":155087970,
                "low":126.382,
                "adj high":131.0499,
                "exchange":"XNAS",
                "date":"2021-01-06T00:00:00+0000",
                "open":127.72,
                "high":131.0499
            },
            {
                "Id":141,
                "adj low":128.43,
                "adj close":131.01,
                "adj open":128.89,
                "adj volume":97664898,
                "symbol":"AAPL",
                "close":131.01,
                "volume":97664898,
                "low":128.43,
                "adj high":131.74,
                "exchange":"XNAS",
                "date":"2021-01-05T00:00:00+0000",
                "open":128.89,
                "high":131.74
            },
            {
                "Id":142,
                "adj low":126.76,
                "adj close":129.41,
                "adj open":133.52,
                "adj volume":143301887,
                "symbol":"AAPL",
                "close":129.41,
                "volume":143301887,
                "low":126.76,
                "adj high":133.6116,
                "exchange":"XNAS",
                "date":"2021-01-04T00:00:00+0000",
                "open":133.52,
                "high":133.6116
            },
            {
                "Id":143,
                "adj low":131.72,
                "adj close":132.69,
                "adj open":134.08,
                "adj volume":99116586,
                "symbol":"AAPL",
                "close":132.69,
                "volume":99116586,
                "low":131.72,
                "adj high":134.74,
                "exchange":"XNAS",
                "date":"2020-12-31T00:00:00+0000",
                "open":134.08,
                "high":134.74
            },
            {
                "Id":144,
                "adj low":133.4,
                "adj close":133.72,
                "adj open":135.58,
                "adj volume":96452124,
                "symbol":"AAPL",
                "close":133.72,
                "volume":96452124,
                "low":133.4,
                "adj high":135.99,
                "exchange":"XNAS",
                "date":"2020-12-30T00:00:00+0000",
                "open":135.58,
                "high":135.99
            },
            {
                "Id":145,
                "adj low":134.3409,
                "adj close":134.87,
                "adj open":138.05,
                "adj volume":121047324,
                "symbol":"AAPL",
                "close":134.87,
                "volume":121047324,
                "low":134.3409,
                "adj high":138.789,
                "exchange":"XNAS",
                "date":"2020-12-29T00:00:00+0000",
                "open":138.05,
                "high":138.789
            },
            {
                "Id":146,
                "adj low":133.51,
                "adj close":136.69,
                "adj open":133.99,
                "adj volume":123124632,
                "symbol":"AAPL",
                "close":136.69,
                "volume":123124632,
                "low":133.51,
                "adj high":137.34,
                "exchange":"XNAS",
                "date":"2020-12-28T00:00:00+0000",
                "open":133.99,
                "high":137.34
            },
            {
                "Id":147,
                "adj low":131.1,
                "adj close":131.97,
                "adj open":131.32,
                "adj volume":54930064,
                "symbol":"AAPL",
                "close":131.97,
                "volume":54930064,
                "low":131.1,
                "adj high":133.46,
                "exchange":"XNAS",
                "date":"2020-12-24T00:00:00+0000",
                "open":131.32,
                "high":133.46
            },
            {
                "Id":148,
                "adj low":130.78,
                "adj close":130.96,
                "adj open":132.16,
                "adj volume":88223692,
                "symbol":"AAPL",
                "close":130.96,
                "volume":88223692,
                "low":130.78,
                "adj high":132.43,
                "exchange":"XNAS",
                "date":"2020-12-23T00:00:00+0000",
                "open":132.16,
                "high":132.43
            },
            {
                "Id":149,
                "adj low":129.65,
                "adj close":131.88,
                "adj open":131.61,
                "adj volume":169351825,
                "symbol":"AAPL",
                "close":131.88,
                "volume":169351825,
                "low":129.65,
                "adj high":134.405,
                "exchange":"XNAS",
                "date":"2020-12-22T00:00:00+0000",
                "open":131.61,
                "high":134.405
            },
            {
                "Id":150,
                "adj low":123.449,
                "adj close":128.23,
                "adj open":125.02,
                "adj volume":121251553,
                "symbol":"AAPL",
                "close":128.23,
                "volume":121251553,
                "low":123.449,
                "adj high":128.31,
                "exchange":"XNAS",
                "date":"2020-12-21T00:00:00+0000",
                "open":125.02,
                "high":128.31
            },
            {
                "Id":151,
                "adj low":126.12,
                "adj close":126.655,
                "adj open":128.96,
                "adj volume":192541496,
                "symbol":"AAPL",
                "close":126.655,
                "volume":192541496,
                "low":126.12,
                "adj high":129.1,
                "exchange":"XNAS",
                "date":"2020-12-18T00:00:00+0000",
                "open":128.96,
                "high":129.1
            },
            {
                "Id":152,
                "adj low":128.045,
                "adj close":128.7,
                "adj open":128.9,
                "adj volume":94359811,
                "symbol":"AAPL",
                "close":128.7,
                "volume":94359811,
                "low":128.045,
                "adj high":129.58,
                "exchange":"XNAS",
                "date":"2020-12-17T00:00:00+0000",
                "open":128.9,
                "high":129.58
            },
            {
                "Id":153,
                "adj low":126.56,
                "adj close":127.81,
                "adj open":127.41,
                "adj volume":98208591,
                "symbol":"AAPL",
                "close":127.81,
                "volume":98208591,
                "low":126.56,
                "adj high":128.37,
                "exchange":"XNAS",
                "date":"2020-12-16T00:00:00+0000",
                "open":127.41,
                "high":128.37
            },
            {
                "Id":154,
                "adj low":124.13,
                "adj close":127.88,
                "adj open":124.34,
                "adj volume":157572262,
                "symbol":"AAPL",
                "close":127.88,
                "volume":157572262,
                "low":124.13,
                "adj high":127.9,
                "exchange":"XNAS",
                "date":"2020-12-15T00:00:00+0000",
                "open":124.34,
                "high":127.9
            },
            {
                "Id":155,
                "adj low":121.54,
                "adj close":121.78,
                "adj open":122.6,
                "adj volume":79075988,
                "symbol":"AAPL",
                "close":121.78,
                "volume":79075988,
                "low":121.54,
                "adj high":123.35,
                "exchange":"XNAS",
                "date":"2020-12-14T00:00:00+0000",
                "open":122.6,
                "high":123.35
            },
            {
                "Id":156,
                "adj low":120.55,
                "adj close":122.41,
                "adj open":122.43,
                "adj volume":86939786,
                "symbol":"AAPL",
                "close":122.41,
                "volume":86939786,
                "low":120.55,
                "adj high":122.76,
                "exchange":"XNAS",
                "date":"2020-12-11T00:00:00+0000",
                "open":122.43,
                "high":122.76
            },
            {
                "Id":157,
                "adj low":120.15,
                "adj close":123.24,
                "adj open":120.5,
                "adj volume":81312170,
                "symbol":"AAPL",
                "close":123.24,
                "volume":81312170,
                "low":120.15,
                "adj high":123.87,
                "exchange":"XNAS",
                "date":"2020-12-10T00:00:00+0000",
                "open":120.5,
                "high":123.87
            },
            {
                "Id":158,
                "adj low":121,
                "adj close":121.78,
                "adj open":124.53,
                "adj volume":115089193,
                "symbol":"AAPL",
                "close":121.78,
                "volume":115089193,
                "low":121,
                "adj high":125.95,
                "exchange":"XNAS",
                "date":"2020-12-09T00:00:00+0000",
                "open":124.53,
                "high":125.95
            },
            {
                "Id":159,
                "adj low":123.09,
                "adj close":124.38,
                "adj open":124.37,
                "adj volume":82225512,
                "symbol":"AAPL",
                "close":124.38,
                "volume":82225512,
                "low":123.09,
                "adj high":124.98,
                "exchange":"XNAS",
                "date":"2020-12-08T00:00:00+0000",
                "open":124.37,
                "high":124.98
            },
            {
                "Id":160,
                "adj low":122.25,
                "adj close":123.75,
                "adj open":122.31,
                "adj volume":86711990,
                "symbol":"AAPL",
                "close":123.75,
                "volume":86711990,
                "low":122.25,
                "adj high":124.57,
                "exchange":"XNAS",
                "date":"2020-12-07T00:00:00+0000",
                "open":122.31,
                "high":124.57
            },
            {
                "Id":161,
                "adj low":121.52,
                "adj close":122.25,
                "adj open":122.6,
                "adj volume":78260421,
                "symbol":"AAPL",
                "close":122.25,
                "volume":78260421,
                "low":121.52,
                "adj high":122.8608,
                "exchange":"XNAS",
                "date":"2020-12-04T00:00:00+0000",
                "open":122.6,
                "high":122.8608
            },
            {
                "Id":162,
                "adj low":122.21,
                "adj close":122.94,
                "adj open":123.52,
                "adj volume":78967630,
                "symbol":"AAPL",
                "close":122.94,
                "volume":78967630,
                "low":122.21,
                "adj high":123.78,
                "exchange":"XNAS",
                "date":"2020-12-03T00:00:00+0000",
                "open":123.52,
                "high":123.78
            },
            {
                "Id":163,
                "adj low":120.89,
                "adj close":123.08,
                "adj open":122.02,
                "adj volume":89004195,
                "symbol":"AAPL",
                "close":123.08,
                "volume":89004195,
                "low":120.89,
                "adj high":123.37,
                "exchange":"XNAS",
                "date":"2020-12-02T00:00:00+0000",
                "open":122.02,
                "high":123.37
            },
            {
                "Id":164,
                "adj low":120.01,
                "adj close":122.72,
                "adj open":121.01,
                "adj volume":125920963,
                "symbol":"AAPL",
                "close":122.72,
                "volume":125920963,
                "low":120.01,
                "adj high":123.4693,
                "exchange":"XNAS",
                "date":"2020-12-01T00:00:00+0000",
                "open":121.01,
                "high":123.4693
            },
            {
                "Id":165,
                "adj low":116.81,
                "adj close":119.05,
                "adj open":116.97,
                "adj volume":169410176,
                "symbol":"AAPL",
                "close":119.05,
                "volume":169410176,
                "low":116.81,
                "adj high":120.97,
                "exchange":"XNAS",
                "date":"2020-11-30T00:00:00+0000",
                "open":116.97,
                "high":120.97
            },
            {
                "Id":166,
                "adj low":116.22,
                "adj close":116.59,
                "adj open":116.57,
                "adj volume":46691331,
                "symbol":"AAPL",
                "close":116.59,
                "volume":46691331,
                "low":116.22,
                "adj high":117.49,
                "exchange":"XNAS",
                "date":"2020-11-27T00:00:00+0000",
                "open":116.57,
                "high":117.49
            },
            {
                "Id":167,
                "adj low":115.17,
                "adj close":116.03,
                "adj open":115.55,
                "adj volume":76499234,
                "symbol":"AAPL",
                "close":116.03,
                "volume":76499234,
                "low":115.17,
                "adj high":116.75,
                "exchange":"XNAS",
                "date":"2020-11-25T00:00:00+0000",
                "open":115.55,
                "high":116.75
            },
            {
                "Id":168,
                "adj low":112.59,
                "adj close":115.17,
                "adj open":113.91,
                "adj volume":113226248,
                "symbol":"AAPL",
                "close":115.17,
                "volume":113226248,
                "low":112.59,
                "adj high":115.85,
                "exchange":"XNAS",
                "date":"2020-11-24T00:00:00+0000",
                "open":113.91,
                "high":115.85
            },
            {
                "Id":169,
                "adj low":113.75,
                "adj close":113.85,
                "adj open":117.18,
                "adj volume":127959318,
                "symbol":"AAPL",
                "close":113.85,
                "volume":127959318,
                "low":113.75,
                "adj high":117.6202,
                "exchange":"XNAS",
                "date":"2020-11-23T00:00:00+0000",
                "open":117.18,
                "high":117.6202
            },
            {
                "Id":170,
                "adj low":117.29,
                "adj close":117.34,
                "adj open":118.64,
                "adj volume":73604287,
                "symbol":"AAPL",
                "close":117.34,
                "volume":73604287,
                "low":117.29,
                "adj high":118.77,
                "exchange":"XNAS",
                "date":"2020-11-20T00:00:00+0000",
                "open":118.64,
                "high":118.77
            },
            {
                "Id":171,
                "adj low":116.81,
                "adj close":118.64,
                "adj open":117.59,
                "adj volume":74112972,
                "symbol":"AAPL",
                "close":118.64,
                "volume":74112972,
                "low":116.81,
                "adj high":119.06,
                "exchange":"XNAS",
                "date":"2020-11-19T00:00:00+0000",
                "open":117.59,
                "high":119.06
            },
            {
                "Id":172,
                "adj low":118,
                "adj close":118.03,
                "adj open":118.61,
                "adj volume":76322111,
                "symbol":"AAPL",
                "close":118.03,
                "volume":76322111,
                "low":118,
                "adj high":119.82,
                "exchange":"XNAS",
                "date":"2020-11-18T00:00:00+0000",
                "open":118.61,
                "high":119.82
            },
            {
                "Id":173,
                "adj low":118.96,
                "adj close":119.39,
                "adj open":119.55,
                "adj volume":74270973,
                "symbol":"AAPL",
                "close":119.39,
                "volume":74270973,
                "low":118.96,
                "adj high":120.6741,
                "exchange":"XNAS",
                "date":"2020-11-17T00:00:00+0000",
                "open":119.55,
                "high":120.6741
            },
            {
                "Id":174,
                "adj low":118.146,
                "adj close":120.3,
                "adj open":118.92,
                "adj volume":91183018,
                "symbol":"AAPL",
                "close":120.3,
                "volume":91183018,
                "low":118.146,
                "adj high":120.99,
                "exchange":"XNAS",
                "date":"2020-11-16T00:00:00+0000",
                "open":118.92,
                "high":120.99
            },
            {
                "Id":175,
                "adj low":117.87,
                "adj close":119.26,
                "adj open":119.44,
                "adj volume":81688586,
                "symbol":"AAPL",
                "close":119.26,
                "volume":81688586,
                "low":117.87,
                "adj high":119.6717,
                "exchange":"XNAS",
                "date":"2020-11-13T00:00:00+0000",
                "open":119.44,
                "high":119.6717
            },
            {
                "Id":176,
                "adj low":118.57,
                "adj close":119.21,
                "adj open":119.62,
                "adj volume":103350674,
                "symbol":"AAPL",
                "close":119.21,
                "volume":103350674,
                "low":118.57,
                "adj high":120.53,
                "exchange":"XNAS",
                "date":"2020-11-12T00:00:00+0000",
                "open":119.62,
                "high":120.53
            },
            {
                "Id":177,
                "adj low":116.44,
                "adj close":119.49,
                "adj open":117.19,
                "adj volume":112294954,
                "symbol":"AAPL",
                "close":119.49,
                "volume":112294954,
                "low":116.44,
                "adj high":119.63,
                "exchange":"XNAS",
                "date":"2020-11-11T00:00:00+0000",
                "open":117.19,
                "high":119.63
            },
            {
                "Id":178,
                "adj low":114.13,
                "adj close":115.97,
                "adj open":115.55,
                "adj volume":138023390,
                "symbol":"AAPL",
                "close":115.97,
                "volume":138023390,
                "low":114.13,
                "adj high":117.59,
                "exchange":"XNAS",
                "date":"2020-11-10T00:00:00+0000",
                "open":115.55,
                "high":117.59
            },
            {
                "Id":179,
                "adj low":116.05,
                "adj close":116.32,
                "adj open":120.5,
                "adj volume":154515315,
                "symbol":"AAPL",
                "close":116.32,
                "volume":154515315,
                "low":116.05,
                "adj high":121.99,
                "exchange":"XNAS",
                "date":"2020-11-09T00:00:00+0000",
                "open":120.5,
                "high":121.99
            },
            {
                "Id":180,
                "adj low":116.13,
                "adj close":118.69,
                "adj open":118.32,
                "adj volume":114457922,
                "symbol":"AAPL",
                "close":118.69,
                "volume":114457922,
                "low":116.13,
                "adj high":119.2,
                "exchange":"XNAS",
                "date":"2020-11-06T00:00:00+0000",
                "open":118.32,
                "high":119.2
            },
            {
                "Id":181,
                "adj low":116.8686,
                "adj close":118.8247672316,
                "adj open":117.7466293789,
                "adj volume":126387074,
                "symbol":"AAPL",
                "close":119.03,
                "volume":126387074,
                "low":116.8686,
                "adj high":119.4137499474,
                "exchange":"XNAS",
                "date":"2020-11-05T00:00:00+0000",
                "open":117.95,
                "high":119.62
            },
            {
                "Id":182,
                "adj low":112.35,
                "adj close":114.7518020102,
                "adj open":113.9431986206,
                "adj volume":138235482,
                "symbol":"AAPL",
                "close":114.95,
                "volume":138235482,
                "low":112.35,
                "adj high":115.3906985155,
                "exchange":"XNAS",
                "date":"2020-11-04T00:00:00+0000",
                "open":114.14,
                "high":115.59
            },
            {
                "Id":183,
                "adj low":108.73,
                "adj close":110.2495781993,
                "adj open":109.4709230834,
                "adj volume":107624448,
                "symbol":"AAPL",
                "close":110.44,
                "volume":107624448,
                "low":108.73,
                "adj high":111.2977677783,
                "exchange":"XNAS",
                "date":"2020-11-03T00:00:00+0000",
                "open":109.66,
                "high":111.49
            },
            {
                "Id":184,
                "adj low":107.32,
                "adj close":108.5824576307,
                "adj open":108.9218713991,
                "adj volume":122866899,
                "symbol":"AAPL",
                "close":108.77,
                "volume":122866899,
                "low":107.32,
                "adj high":110.4891643887,
                "exchange":"XNAS",
                "date":"2020-11-02T00:00:00+0000",
                "open":109.11,
                "high":110.68
            },
            {
                "Id":185,
                "adj low":107.72,
                "adj close":108.6723024517,
                "adj open":110.8685091888,
                "adj volume":190573476,
                "symbol":"AAPL",
                "close":108.86,
                "volume":190573476,
                "low":107.72,
                "adj high":111.7969056731,
                "exchange":"XNAS",
                "date":"2020-10-30T00:00:00+0000",
                "open":111.06,
                "high":111.99
            },
            {
                "Id":186,
                "adj low":112.2,
                "adj close":115.1211640523,
                "adj open":112.1762504731,
                "adj volume":146129173,
                "symbol":"AAPL",
                "close":115.32,
                "volume":146129173,
                "low":112.2,
                "adj high":116.7283880735,
                "exchange":"XNAS",
                "date":"2020-10-29T00:00:00+0000",
                "open":112.37,
                "high":116.93
            },
            {
                "Id":187,
                "adj low":111.1,
                "adj close":111.0082677993,
                "adj open":114.8516295891,
                "adj volume":143937823,
                "symbol":"AAPL",
                "close":111.2,
                "volume":143937823,
                "low":111.1,
                "adj high":115.2309743892,
                "exchange":"XNAS",
                "date":"2020-10-28T00:00:00+0000",
                "open":115.05,
                "high":115.43
            },
            {
                "Id":188,
                "adj low":114.5399,
                "adj close":116.398957063,
                "adj open":115.2908709365,
                "adj volume":92276772,
                "symbol":"AAPL",
                "close":116.6,
                "volume":92276772,
                "low":114.5399,
                "adj high":117.0777845999,
                "exchange":"XNAS",
                "date":"2020-10-27T00:00:00+0000",
                "open":115.49,
                "high":117.28
            },
            {
                "Id":189,
                "adj low":112.88,
                "adj close":114.8516295891,
                "adj open":113.813422768,
                "adj volume":111850657,
                "symbol":"AAPL",
                "close":115.05,
                "volume":111850657,
                "low":112.88,
                "adj high":116.3490432735,
                "exchange":"XNAS",
                "date":"2020-10-26T00:00:00+0000",
                "open":114.01,
                "high":116.55
            },
            {
                "Id":190,
                "adj low":114.28,
                "adj close":114.8416468312,
                "adj open":116.1893191471,
                "adj volume":82572645,
                "symbol":"AAPL",
                "close":115.04,
                "volume":82572645,
                "low":114.28,
                "adj high":116.3490432735,
                "exchange":"XNAS",
                "date":"2020-10-23T00:00:00+0000",
                "open":116.39,
                "high":116.55
            },
            {
                "Id":191,
                "adj low":114.59,
                "adj close":115.5504226418,
                "adj open":117.2474914841,
                "adj volume":101987954,
                "symbol":"AAPL",
                "close":115.75,
                "volume":101987954,
                "low":114.59,
                "adj high":117.8364741999,
                "exchange":"XNAS",
                "date":"2020-10-22T00:00:00+0000",
                "open":117.45,
                "high":118.04
            },
            {
                "Id":192,
                "adj low":116.45,
                "adj close":116.6684915261,
                "adj open":116.4688363682,
                "adj volume":89945980,
                "symbol":"AAPL",
                "close":116.87,
                "volume":89945980,
                "low":116.45,
                "adj high":118.5003276,
                "exchange":"XNAS",
                "date":"2020-10-21T00:00:00+0000",
                "open":116.67,
                "high":118.705
            },
            {
                "Id":193,
                "adj low":115.63,
                "adj close":117.3073880315,
                "adj open":115.9996467471,
                "adj volume":124423728,
                "symbol":"AAPL",
                "close":117.51,
                "volume":124423728,
                "low":115.63,
                "adj high":118.7748534421,
                "exchange":"XNAS",
                "date":"2020-10-20T00:00:00+0000",
                "open":116.2,
                "high":118.98
            },
            {
                "Id":194,
                "adj low":115.66,
                "adj close":115.7800260734,
                "adj open":119.7531637159,
                "adj volume":120639337,
                "symbol":"AAPL",
                "close":115.98,
                "volume":120639337,
                "low":115.66,
                "adj high":120.2113723033,
                "exchange":"XNAS",
                "date":"2020-10-19T00:00:00+0000",
                "open":119.96,
                "high":120.419
            },
            {
                "Id":195,
                "adj low":118.81,
                "adj close":118.8147844737,
                "adj open":121.0708877581,
                "adj volume":115393808,
                "symbol":"AAPL",
                "close":119.02,
                "volume":115393808,
                "low":118.81,
                "adj high":121.3384256697,
                "exchange":"XNAS",
                "date":"2020-10-16T00:00:00+0000",
                "open":121.28,
                "high":121.548
            },
            {
                "Id":196,
                "adj low":118.15,
                "adj close":120.5018705581,
                "adj open":118.5153017368,
                "adj volume":112559219,
                "symbol":"AAPL",
                "close":120.71,
                "volume":112559219,
                "low":118.15,
                "adj high":120.9910256949,
                "exchange":"XNAS",
                "date":"2020-10-15T00:00:00+0000",
                "open":118.72,
                "high":121.2
            },
            {
                "Id":197,
                "adj low":119.62,
                "adj close":120.981042937,
                "adj open":120.791370537,
                "adj volume":151062308,
                "symbol":"AAPL",
                "close":121.19,
                "volume":151062308,
                "low":119.62,
                "adj high":122.8178703898,
                "exchange":"XNAS",
                "date":"2020-10-14T00:00:00+0000",
                "open":121,
                "high":123.03
            },
            {
                "Id":198,
                "adj low":119.65,
                "adj close":120.891198116,
                "adj open":125.0540081585,
                "adj volume":262330451,
                "symbol":"AAPL",
                "close":121.1,
                "volume":262330451,
                "low":119.65,
                "adj high":125.1738012532,
                "exchange":"XNAS",
                "date":"2020-10-13T00:00:00+0000",
                "open":125.27,
                "high":125.39
            },
            {
                "Id":199,
                "adj low":119.2845,
                "adj close":124.1855082215,
                "adj open":119.8529912948,
                "adj volume":240226769,
                "symbol":"AAPL",
                "close":124.4,
                "volume":240226769,
                "low":119.2845,
                "adj high":124.9641633374,
                "exchange":"XNAS",
                "date":"2020-10-12T00:00:00+0000",
                "open":120.06,
                "high":125.18
            },
            {
                "Id":200,
                "adj low":114.92,
                "adj close":116.7683191051,
                "adj open":115.0812330207,
                "adj volume":100506865,
                "symbol":"AAPL",
                "close":116.97,
                "volume":100506865,
                "low":114.92,
                "adj high":116.7982673788,
                "exchange":"XNAS",
                "date":"2020-10-09T00:00:00+0000",
                "open":115.28,
                "high":117
            }
        ],
    defaultData2: [
        { "x": "2020-01-01T00:00:00+0000"	, "y": 20, "z": 100.11, "a": "a", "b": 20 },
        { "x": "2020-01-02T00:00:00+0000", "y": 31,"z": 200.22, "a": "b", "b": 20 },
        { "x": "2020-01-03T00:00:00+0000", "y": 22,"z": 300.44, "a": "c", "b": 20 },
        { "x": "2020-01-04T00:00:00+0000", "y": 14,"z": 400.66, "a": "d", "b": 20 },
        { "x": "2020-01-05T00:00:00+0000", "y": 21,"z": 500.77, "a": "e", "b": 20 },
        { "x": "2020-01-06T00:00:00+0000", "y": 31,"z": 600.66, "a": "f", "b": 20 },
        { "x": "2020-01-07T00:00:00+0000", "y": 13,"z": 700.55, "a": "g", "b": 20 },
        { "x": "2020-01-08T00:00:00+0000", "y": 17,"z": 800, "a": "h", "b": 20 },
        { "x": "2020-01-09T00:00:00+0000", "y": 33,"z": 900, "a": "i", "b": 20 },
        { "x": "2020-01-10T00:00:00+0000", "y": 25,"z": 1000, "a": "j", "b": 20 },
        { "x": "2020-01-11T00:00:00+0000", "y": 20,"z": 1100, "a": "k", "b": 20 },
        { "x": "2020-01-12T00:00:00+0000", "y": 31,"z": 1200, "a": "l", "b": 20 },
        { "x": "2020-01-13T00:00:00+0000", "y": 22,"z": 1300, "a": "m", "b": 20 },
        { "x": "2020-01-14T00:00:00+0000", "y": 14,"z": 1400, "a": "n", "b": 20 },
        { "x": "2020-01-15T00:00:00+0000", "y": 21,"z": 1500, "a": "o", "b": 20 },
        { "x": "2020-01-16T00:00:00+0000", "y": 31,"z": 1600, "a": "p", "b": 20 },
        { "x": "2020-01-17T00:00:00+0000", "y": 13, "z": 1700, "a": "q", "b": 20 },
        { "x": "2020-01-18T00:00:00+0000", "y": 17, "z": 1800, "a": "r", "b": 20 },
        { "x": "2020-01-19T00:00:00+0000", "y": 33, "z": 1900, "a": "s", "b": 20 },
        { "x": "2020-01-20T00:00:00+0000", "y": 25,"z": 2000, "a": "t", "b": 20 },
        { "x": "2020-01-21T00:00:00+0000", "y": 20,"z": 2100, "a": "u", "b": 20 },
        { "x": "2020-01-22T00:00:00+0000", "y": 31,"z": 2200, "a": "v", "b": 20 },
        { "x": "2020-01-23T00:00:00+0000", "y": 22,"z": 2300, "a": "w", "b": 20 },
        { "x": "2020-01-24T00:00:00+0000", "y": 14,"z": 2400, "a": "x", "b": 20 },
        { "x": "2020-01-25T00:00:00+0000", "y": 21,"z": 2500, "a": "y", "b": 20 },
        { "x": "2020-01-26T00:00:00+0000", "y": 31,"z": 2600, "a": "z", "b": 20 },
        { "x": "2020-01-27T00:00:00+0000", "y": 13,"z": 2700, "a": "aa", "b": 20 },
        { "x": "2020-01-28T00:00:00+0000", "y": 17,"z": 2800, "a": "ab", "b": 20 },
        { "x": "2020-01-29T00:00:00+0000", "y": 33,"z": 2900, "a": "ac", "b": 20 },
        { "x": "2020-01-30T00:00:00+0000", "y": 25,"z": 3000, "a": "ad", "b": 20  }
    ],
    defaultData: [
        { "x": "Item 1", "y": 20 },
        { "x": "Item 2", "y": 31 },
        { "x": "Item 3", "y": 22 },
        { "x": "Item 4", "y": 14 },
        { "x": "Item 5", "y": 21 },
        { "x": "Item 6", "y": 31 },
        { "x": "Item 7", "y": 13 },
        { "x": "Item 8", "y": 17 },
        { "x": "Item 9", "y": 33 },
        { "x": "Item 10", "y": 25 },
        { "x": "Item 11", "y": 20 },
        { "x": "Item 12", "y": 31 },
        { "x": "Item 13", "y": 22 },
        { "x": "Item 14", "y": 14 },
    ],
    dataSet: "",
    dataSetX: "x",
    dataSetY: "",
    fill: "#000",
    seriesName: "",
};

const mapStateToProps = function(state){
    return {
        store: state.reducer,
    }
}
export default connect (mapStateToProps, null) (MultipleDataGraph);

