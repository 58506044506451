import React from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCrop = (props) => {
  return (
    <div style={{ width: "100%" }}>
      <Cropper
        style={{ height: 400, width: "100%" }}
        initialAspectRatio={props.initialAspectRatio}
        preview={props.preview}
        guides={props.guides}
        src={props.src}
        onInitialized={props.onInitialized}
        viewMode={props.viewMode}
        dragMode={props.dragMode}
        cropBoxMovable={props.cropBoxMovable}
        cropBoxResizable={props.cropBoxResizable}
        checkOrientation={props.checkOrientation}
        toggleDragModeOnDblclick={props.toggleDragModeOnDblclick}
        modal={props.modal}
        background={props.background}
        
        minCropBoxHeight={props.minCropBoxHeight}
        minCropBoxWidth={props.minCropBoxWidth}
        
        {...props}
      />
    </div>
  );
};

export default ImageCrop;
