import PlayIcon from './images/Video_Play_Button.png';
import SearchIcon from './images/search.png';
import FilterIcon from './images/filter.png';
import RightIcon from './images/right.png';
import PreviousIcon from './images/previous.png';
import Blacklogo from './images/blacklogo.png';


export const IMAGES = {
    // PlayIcon,
    SearchIcon,
    FilterIcon,
    PlayIcon,
    RightIcon,
    PreviousIcon,
    Blacklogo
};
