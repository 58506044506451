import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock, LinkBlock } from "../modules/Basic";
import { FormBlock, Label, TextInput } from "../modules/Forms";
import { If, Then } from "../modules/Logic";
import { Image } from "../modules/Media";
import { Heading, Span } from "../modules/Typography";
import PlayerSideNavComponent from "../components/PlayerSideNavComponent";

class HomePage extends Component {
  render() {
    let { CurrentUser, EditUser } = this.props;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Positions",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Users",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <DivBlock
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            >
              <LinkBlock
                href={"#"}
                style={{ float: "left" }}
                target={"_self"}
                type={"external"}
              >
                <DivBlock>
                  <Image
                    height={"16px"}
                    src={process.env.REACT_APP_LOGO}
                    style={{ height: "37.5px", maxWidth: "auto" }}
                    width={"16px"}
                  ></Image>
                </DivBlock>
              </LinkBlock>

              <Button className={"btn btn-secondary"}>
                {CurrentUser["name"]}
              </Button>
            </DivBlock>

            <DivBlock className={"body-container"} style={{}}>
              <PlayerSideNavComponent
                context={{
                  elements: {
                    1985: { type: "Body" },
                    1986: { type: "DivBlock" },
                    1987: { type: "DivBlock" },
                    1992: { type: "DivBlock" },
                    2007: { type: "DivBlock" },
                    2008: { type: "DivBlock" },
                    2009: { type: "DivBlock" },
                    2010: { type: "DivBlock" },
                    2011: { type: "If" },
                    2059: { type: "List" },
                    2060: { type: "ListItem" },
                    2061: { type: "LinkBlock" },
                    2062: { type: "DivBlock" },
                    2063: { type: "Span" },
                    2064: { type: "ListItem" },
                    2065: { type: "LinkBlock" },
                    2066: { type: "DivBlock" },
                    2067: { type: "Span" },
                    2068: { type: "ListItem" },
                    2069: { type: "LinkBlock" },
                    2070: { type: "DivBlock" },
                    2071: { type: "Span" },
                    2171: { type: "DivBlock" },
                  },
                  getters: {},
                  listeners: [
                    { name: "mouseOver.boundaries" },
                    { name: "mouseOut.boundaries" },
                    { name: "dragEnter" },
                    { name: "dragLeave" },
                    { name: "dragStart" },
                    { name: "dragEnd" },
                    { name: "mouseDown.mousemenu" },
                    { name: "showSettingsBox.desk" },
                    { name: "textChanged.desk" },
                    { name: "editTextMode.desk" },
                    { name: "componentClick.desk" },
                    { name: "pathnameChanged.desk" },
                    { name: "cut.desk" },
                    { name: "copy.desk" },
                    { name: "editSnippet.desk" },
                    { name: "clone.desk" },
                    { name: "delete.desk" },
                    { name: "before.desk" },
                    { name: "after.desk" },
                    { name: "first.desk" },
                    { name: "last.desk" },
                    { name: "replace.desk" },
                    { name: "select.desk" },
                    { name: "highlight.desk" },
                    { name: "dropZoneHover" },
                    { name: "onDragEnd" },
                    { name: "copyStyleProps" },
                    { name: "pasteStyleProps" },
                    { name: "updateSelectedProps" },
                  ],
                  triggerCache: {
                    componentClick: [{ name: "componentClick.desk" }],
                    editSnippet: [{ name: "editSnippet.desk" }],
                    editTextMode: [{ name: "editTextMode.desk" }],
                    mouseDown: [{ name: "mouseDown.mousemenu" }],
                    mouseOut: [{ name: "mouseOut.boundaries" }],
                    mouseOver: [{ name: "mouseOver.boundaries" }],
                    pathnameChanged: [{ name: "pathnameChanged.desk" }],
                    textChanged: [{ name: "textChanged.desk" }],
                  },
                }}
                data-snippet-id={302}
                df-snippet-instance-id={302}
                draggable={true}
                eleKey={"2171"}
                inline={{ "df-snippet-instance-id": 302, draggable: true }}
                isEdit={true}
                root-snippet={true}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></PlayerSideNavComponent>

              <DivBlock style={{ flex: "1 0 auto", }}>
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}></DivBlock>
                  </DivBlock>

                  <If condition={EditUser ? true : false} show={false}>
                    <Then>
                      <DivBlock
                        className={"modal"}
                        actions={[
                          {
                            action: "Set New Position",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { "input new position": false },
                          },
                          {
                            action: "Edit Position",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { "input position": null },
                          },
                        ]}
                      >
                        <DivBlock className={"modal-dialog"}>
                          <DivBlock className={"modal-dialog"}>
                            <DivBlock className={"modal-content"}>
                              <DivBlock className={"modal-header"}>
                                <Heading
                                  className="modal-heading"
                                  type={"h5"}
                                >
                                  Edit User
                                </Heading>

                                <Span
                                  actions={[
                                    {
                                      action: "Set Edit User",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        "Edit User": null,
                                        "input position": null,
                                      },
                                    },
                                  ]}
                                >
                                  x
                                </Span>
                              </DivBlock>

                              <DivBlock className={"modal-body"}>
                                <FormBlock
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                  actions={[
                                    {
                                      action: "Create User",
                                      trigger: "onsubmit",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {},
                                    },
                                  ]}
                                >
                                  <DivBlock className={"form-group"}>
                                    <Label>Name</Label>

                                    <TextInput
                                      className={"form-control"}
                                      name={"SignupForm.name"}
                                      placeholder={""}
                                    ></TextInput>
                                  </DivBlock>

                                  <Button className={"btn btn-primary"}>
                                    Submit
                                  </Button>
                                </FormBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(HomePage);
