import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getColorByValue } from "../../utils/function";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

class BarChartLine extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let {
      chartLabels,
      chartData,
      chartTitle,
      isSkipYaxis,
      maintainAspectRatio,
      lengthData,
      marginValue,
      barBorderRadius,
      graphType,
    } = this.props;

    const options = {
      indexAxis: "y",
      borderRadius: 10,
      //   height:50,
      maintainAspectRatio: maintainAspectRatio,
      barPercentage: 1.1,
      //   layout:{
      //     padding:{
      //         bottom:-50
      //     }
      //   },
      scales: {
        x: {
          display:false,
          // position: "top",
          // beginAtZero: true,
          // max: 100,
          // grid: {
          //   lineWidth: 1,
          //   borderDash: [2, 2],
          //   drawBorder: false,
          //   drawOnChartArea: false,
          //   drawTicks: true,
          //   tickColor: "black",
          // },
          // ticks: {
          //   color: "black",
          //   font: {
          //     size: 12,
          //     // weight: "bold",
          //     family: "Arial",
          //   },
          //   stepSize: 5,
          // },
        },
        y: {
          ticks: {
            color: "#333",
            font: {
              size: 12,
              weight: "bold",
              family: "Arial",
            },
            autoSkip: isSkipYaxis,
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
        },
      },
      elements: {
        bar: {
          borderWidth: 2,
          borderRadius: barBorderRadius || 0,
        },
      },
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: chartTitle || null,
        },
        legend: {
          display: false,
        },
        tooltip: {
          filter: (tooltipItem) => {
            return tooltipItem.dataset.label !== "Remaining";
          },
          callbacks: {
            label: (context) => {
              if (context.dataset.label === "Remaining") {
                return "";
              }
              const value = context.raw;
              return `${value}%`;
            },
          },
        },
        datalabels: {
          align: "end",
          anchor: "start",
          offset: 2,
          display: (context) => context.dataset.label === "Value",
          color:"black",
          font: {
            size: 14,
            weight: 600,
            family: "Poppins",
          },
        },
      },
    };

    const options2 = {
      indexAxis: "y",
      maintainAspectRatio: maintainAspectRatio,
      scales: {
        x: {
          suggestedMin: 0,
          suggestedMax: 100,
          beginAtZero: true,
          afterFit: (context) => {
            context.height += 30;
          },
          max: 100,
          position: "bottom",
          ticks: {
            color: "black",
            font: {
              size: 12,

              family: "Arial",
            },
            stepSize: 5,
          },
          grid: {
            lineWidth: 1,
            borderDash: [2, 2],

            drawBorder: false,
            drawOnChartArea: false,

            tickColor: "black",
          },
        },
        y: {
          afterFit: (context) => {
            context.width += 20;
          },
          ticks: {
            color: "#333",
            font: {
              size: 12,
              weight: "bold",
              family: "Arial",
            },
            autoSkip: isSkipYaxis,
            beginAtZero: true,
          },
          grid: {
            drawTicks: false,
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          align: "end",
          anchor: "start",
          offset: 2,
          display: false,
          font: {
            size: 14,
            weight: "bold",
            family: "Arial",
          },
        },
      },
    };

    const options3 = {
      indexAxis: "y",
      maintainAspectRatio: maintainAspectRatio,
      scales: {
        x: {
          suggestedMin: 0,
          suggestedMax: 100,
          beginAtZero: true,
          afterFit: (context) => {
            context.height += 26;
          },
          max: 100,
          position: "top",
          ticks: {
            color: "black",
            font: {
              size: 12,

              family: "Arial",
            },
            stepSize: 5,
          },
          grid: {
            lineWidth: 1,
            borderDash: [2, 2],

            drawBorder: false,
            drawOnChartArea: false,

            tickColor: "black",
          },
        },
        y: {
          afterFit: (context) => {
            context.width += 20;
          },
          ticks: {
            color: "#333",
            font: {
              size: 12,
              weight: "bold",
              family: "Arial",
            },
            autoSkip: isSkipYaxis,
            beginAtZero: true,
          },
          grid: {
            drawTicks: false,
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          align: "end",
          anchor: "start",
          offset: 2,
          display: false,
          color:"black",
          font: {
            size: 14,
            weight: 600,
            family: "Poppins",
          },
        },
      },
    };

    const data = {
      labels: chartLabels,
      datasets: [
        {
          label: "Value",
          data: chartData || [50, 25, 75, 15, 18, 25, 88],
          fill: false,
          backgroundColor: (context) => getColorByValue(context.raw),
          hoverBackgroundColor: (context) => getColorByValue(context.raw),
          stack: "stack1",
          borderWidth: 0,
        },
        {
          label: "Remaining",
          data: chartData.map((item) => 100 - item),
          backgroundColor: "#e0e0e0",
          hoverBackgroundColor: "#e0e0e0",
          stack: "stack1",
          borderWidth: 0,
        },
      ],
    };

    const data2 = {
      labels: [],
      datasets: [
        {
          label: "",
          data: data.datasets[0].data || [50, 25, 75, 15, 18, 25, 88],
          fill: false,
          backgroundColor: (context) => getColorByValue(context.raw),
          borderWidth: 0,
        },
      ],
    };

    const isCheck = (value) => {
      if (value !== 1 && value !== 2 && value !== 3) {
        return value * 40;
      } else if (value === 1) {
        return "55px";
      } else if (value === 2) {
        return "95px";
      } else if (value === 3) {
        return "130px";
      }
    };

    return (
      <>
        <div
          style={{
            height: "50px",
            marginLeft:lengthData > 10
            ? graphType === "play"
              ? "7px"
              : "65px"
            : graphType === "intangible"
            ? "57px"
            : "",
            background: "#f8f8fb",
            position: "relative",
          }}
        >
          <Bar options={options3} data={data2} />
        </div>
        <div className="parentDiv">
          <div
            style={{
              height: options.maintainAspectRatio
                ? null
                : lengthData
                ? isCheck(lengthData)
                : "700px",
              
            }}
          >
            <Bar options={options} data={data} />
          </div>
        </div>
        <div
          style={{
            height: "50px",
            top: "-10px",
            marginLeft:lengthData > 10
            ? graphType === "play"
              ? "7px"
              : "65px"
            : graphType === "intangible"
            ? "57px"
            : "",
            background: "#f8f8fb",
          }}
        >
          <Bar options={options2} data={data2} />
        </div>
      </>
    );
  }
}

BarChartLine.propTypes = {
  chartLabels: PropTypes.array,
  chartData: PropTypes.array,
  chartTitle: PropTypes.string,
  barBorderRadius: PropTypes.number,
};
BarChartLine.defaultProps = {
  chartLabels: [],
  chartData: [],
  chartTitle: "",
  barBorderRadius: 0,
};

export default BarChartLine;
