import { isArray } from "lodash";
import mixpanel from "mixpanel-browser";
import { jwtDecode } from "jwt-decode";

export function printDiv(divName) {
  var printContents = document.getElementById(divName).innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  return true;
}

// export const mobileBreakPoint = 767;

// export const toggleClassOnId = (id, className) => {
//
//
//     let element = document.getElementById(id);
//
//     let classList  = element.className.split(" ");
//
//     const index = classList.findIndex(e=>e === className)
//     if(index!== -1){
//         classList.splice(index, 1);
//     } else {
//         classList.push(className);
//     }
//
//     element.className = classList.join(" ")

//     // element.classList = classList;

// }

export const toggleClassInbody = (className) => {
  let element = document.body;
  let classList = element.className.split(" ");
  const index = classList.findIndex((e) => e === className);
  if (index !== -1) {
    classList.splice(index, 1);
  } else {
    classList.push(className);
  }
  element.className = classList.join(" ");

  // element.classList = classList;
};

export const isAllChecked = (array) => {
  let obj = {};
  if (isArray(array)) {
    array.forEach((val) => {
      obj[val] = true;
    });
  } else return {};
};

export const isAllUnChecked = (array) => {
  let obj = {};
  if (isArray(array)) {
    array.forEach((val) => {
      obj[val] = false;
    });
  } else return {};
};

export const isCheckedArray = (mainArray, includeArray) => {
  let obj = {};
  mainArray.forEach((val) => {
    obj[val] = mainArray.includes();
  });
};
export const capitalize = (val) => {
  return val ? val?.charAt(0)?.toUpperCase() + val?.slice(1) : null;
};

export const handleFilter = (array, object) => {
  let temp = [];
  array?.map((item) => {
    if (object[[item.name]]) {
      temp.push(item);
    }
  });
  return temp;
  // let obj = {}
  // array.forEach((val) => {
  //     if(object[val]){
  //         obj[val] = object[val]
  //     }
  // })
  //
  // return obj
};

export const handleScaleTypeValue = (scale_type, value) => {
  switch (scale_type) {
    case 1:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 49
        ? "-"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 50
        ? "+"
        : "None";
    case 2:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 49
        ? "0"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 50
        ? "1"
        : "None";
    case 3:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 30
        ? "1"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 31 &&
          value[0]?.integer_value <= 70
        ? "2"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 71
        ? "3"
        : "None";
    case 4:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 30
        ? "0"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 31 &&
          value[0]?.integer_value <= 70
        ? "1"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 71
        ? "2"
        : "None";
    case 5:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 30
        ? "--"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 31 &&
          value[0]?.integer_value <= 50
        ? "-"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 51 &&
          value[0]?.integer_value <= 70
        ? "+"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 71
        ? "++"
        : "None";
    case 6:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 20
        ? "--"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 21 &&
          value[0]?.integer_value <= 40
        ? "-"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 41 &&
          value[0]?.integer_value <= 60
        ? "0"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 61 &&
          value[0]?.integer_value <= 80
        ? "+"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 81
        ? "++"
        : "None";
    case 7:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 30
        ? "1"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 31 &&
          value[0]?.integer_value <= 70
        ? "2"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 71
        ? "3"
        : "None";
    case 8:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 20
        ? "1"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 21 &&
          value[0]?.integer_value <= 40
        ? "2"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 41 &&
          value[0]?.integer_value <= 60
        ? "3"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 61 &&
          value[0]?.integer_value <= 80
        ? "4"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 81
        ? "5"
        : "None";
    case 9:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 10
        ? "1"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 11 &&
          value[0]?.integer_value <= 20
        ? "2"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 21 &&
          value[0]?.integer_value <= 30
        ? "3"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 31 &&
          value[0]?.integer_value <= 40
        ? "4"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 41 &&
          value[0]?.integer_value <= 50
        ? "5"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 51 &&
          value[0]?.integer_value <= 60
        ? "6"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 61 &&
          value[0]?.integer_value <= 70
        ? "7"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 71 &&
          value[0]?.integer_value <= 80
        ? "8"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 81 &&
          value[0]?.integer_value <= 90
        ? "9"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 91
        ? "10"
        : "None";
    case 10:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value
        ? isArray(value) && value[0]?.integer_value && value[0]?.integer_value
        : "None";
    case 11:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 24
        ? "0"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 25 &&
          value[0]?.integer_value <= 49
        ? "1"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 50 &&
          value[0]?.integer_value <= 74
        ? "2"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 75
        ? "3"
        : "None";
    case 12:
      return isArray(value) &&
        value[0]?.integer_value &&
        value[0]?.integer_value <= 30
        ? "0"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 31 &&
          value[0]?.integer_value <= 70
        ? "0.5"
        : isArray(value) &&
          value[0]?.integer_value &&
          value[0]?.integer_value >= 71
        ? "1"
        : "None";

    default:
      return "None";
  }
};

export const GetScaleTypeValue = (
  scale_type,
  updatedItem,
  GradingPositionScaleTypeData
) => {
  switch (scale_type) {
    case 1:
      return [
        {
          label: "+",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 50
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "-",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 49
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 2:
      return [
        {
          label: "1",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 50
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "0",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 49
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 3:
      return [
        {
          label: "3",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 71
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "2",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 31 &&
            updatedItem[0]?.["integer_value"] <= 70
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "50",
        },
        {
          label: "1",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 30
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 4:
      return [
        {
          label: "2",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 71
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "1",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 31 &&
            updatedItem[0]?.["integer_value"] <= 70
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "50",
        },
        {
          label: "0",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 30
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 5:
      return [
        {
          label: "++",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 71
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "+",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 51 &&
            updatedItem[0]?.["integer_value"] <= 70
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "66",
        },
        {
          label: "-",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 31 &&
            updatedItem[0]?.["integer_value"] <= 50
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "33",
        },
        {
          label: "--",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 30
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 6:
      return [
        {
          label: "++",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 81
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "+",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 61 &&
            updatedItem[0]?.["integer_value"] <= 80
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "75",
        },
        {
          label: "0",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 41 &&
            updatedItem[0]?.["integer_value"] <= 60
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "50",
        },
        {
          label: "-",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 21 &&
            updatedItem[0]?.["integer_value"] <= 40
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "25",
        },
        {
          label: "--",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 20
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 7:
      return [
        {
          label: "3",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 71
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "2",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 31 &&
            updatedItem[0]?.["integer_value"] <= 70
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "50",
        },
        {
          label: "1",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 30
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 8:
      return [
        {
          label: "5",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 81
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "4",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 61 &&
            updatedItem[0]?.["integer_value"] <= 80
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "75",
        },
        {
          label: "3",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 41 &&
            updatedItem[0]?.["integer_value"] <= 60
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "50",
        },
        {
          label: "2",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 21 &&
            updatedItem[0]?.["integer_value"] <= 40
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "25",
        },
        {
          label: "1",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 20
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 9:
      return [
        {
          label: "10",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 91
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "9",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 81 &&
            updatedItem[0]?.["integer_value"] <= 90
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "90",
        },
        {
          label: "8",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 71 &&
            updatedItem[0]?.["integer_value"] <= 80
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "80",
        },
        {
          label: "7",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 61 &&
            updatedItem[0]?.["integer_value"] <= 70
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "70",
        },
        {
          label: "6",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 51 &&
            updatedItem[0]?.["integer_value"] <= 60
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "60",
        },
        {
          label: "5",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 41 &&
            updatedItem[0]?.["integer_value"] <= 50
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "50",
        },
        {
          label: "4",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 31 &&
            updatedItem[0]?.["integer_value"] <= 40
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "40",
        },
        {
          label: "3",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 21 &&
            updatedItem[0]?.["integer_value"] <= 30
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "30",
        },
        {
          label: "2",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 11 &&
            updatedItem[0]?.["integer_value"] <= 20
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "20",
        },
        {
          label: "1",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 10
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "10",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 10:
      return GradingPositionScaleTypeData?.[scale_type];
    case 11:
      return [
        {
          label: "3",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 75
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "2",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 50 &&
            updatedItem[0]?.["integer_value"] <= 74
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "50",
        },
        {
          label: "1",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 25 &&
            updatedItem[0]?.["integer_value"] <= 49
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "25",
        },
        {
          label: "0",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 24
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    case 12:
      return [
        {
          label: "1",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] >= 71
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "100",
        },
        {
          label: "0.5",
          value:
            isArray(updatedItem) &&
            updatedItem[0]?.["integer_value"] >= 31 &&
            updatedItem[0]?.["integer_value"] <= 70
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "50",
        },
        {
          label: "0",
          value:
            isArray(updatedItem) && updatedItem[0]?.["integer_value"] <= 30
              ? isArray(updatedItem) && updatedItem[0]?.["integer_value"]
              : "0",
        },
        {
          label: "None",
          value: "NULL",
        },
      ];
    default:
      return "None";
  }
};

const blockedIpAdress = "122.170.99.65";
const TriggerEvents = async () => {
  return fetch("https://geolocation-db.com/json/", { method: "get" })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {});
};

let ipAddress;
export const startPageTimer = async (pageName) => {
  ipAddress = await TriggerEvents();
  if (ipAddress?.IPv4 !== blockedIpAdress) {
    mixpanel.time_event(`Page Duration - ${pageName}`);
  }
};

export const loginEvent = async (value) => {
  ipAddress = await TriggerEvents();
  if (ipAddress?.IPv4 !== blockedIpAdress) {
    mixpanel.alias(value.email);
    if (value) {
      mixpanel.identify(value.email);
    }
    mixpanel.people.set({
      $name: value.name,
      $email: value.email,
    });
    mixpanel.track("User Logged In", { email: value.email });
  }
};

export const logoutEvent = async () => {
  ipAddress = await TriggerEvents();
  if (ipAddress?.IPv4 !== blockedIpAdress) {
    mixpanel.track("User Logged Out");
    mixpanel.reset();
  }
};

export const trackPageDuration = async (pageName, startTime) => {
  ipAddress = await TriggerEvents();
  if (ipAddress?.IPv4 !== blockedIpAdress) {
    const durationInSeconds = (Date.now() - startTime) / 1000; // Calculate the duration in seconds
    const durationInMinutes = (durationInSeconds / 60).toFixed(2) + " mins"; // Convert the duration to minutes
    // const duration = mixpanel.get_property(`Page Duration - ${pageName}`);
    mixpanel.track(`Page Duration - ${pageName}`, {
      page: pageName,
      Duration: durationInMinutes,
    });
  }
};

export const averageCalculate = (data, name) => {
  if (name === "averages") {
    if (data === null) {
      return "None";
    } else if (data && isArray(data)) {
      return isNaN(
        data
          ?.filter((data) => data?.name !== null)
          ?.map(({ integer_value }) => integer_value)
          ?.reduce((a, b) => a + b, 0) /
          data?.filter((data) => data?.name !== null)?.length
      )
        ? "None"
        : `${Number(
            data
              ?.filter((data) => data?.name !== null)
              ?.map(({ integer_value }) => integer_value)
              ?.reduce((a, b) => a + b, 0) /
              data?.filter((data) => data?.name !== null)?.length
          ).toFixed(2)}%`;
    }
  } else {
    if (data && isArray(data)) {
      return isNaN(
        data
          ?.filter((data) => data?.name !== null)
          ?.map(({ integer_value }) => integer_value)
          ?.reduce((a, b) => a + b, 0) /
          data?.filter((data) => data?.name !== null)?.length
      )
        ? "None"
        : `${Number(
            data
              ?.filter((data) => data?.name !== null)
              ?.map(({ integer_value }) => integer_value)
              ?.reduce((a, b) => a + b, 0) /
              data?.filter((data) => data?.name !== null)?.length
          ).toFixed(2)}%`;
    } else return "None";
  }
};

let timerId = null;

export const debounced = (callback, wait) => {
  if (timerId) {
    clearTimeout(timerId);
  }
  timerId = setTimeout(() => {
    callback();
  }, wait);
};

export const updateValueById = (
  data,
  playerId,
  intangibleId,
  newValue,
  rowId,
  Intangible
) => {
  data?.forEach((player) => {
    if (player.id === playerId && player.user_grades) {
      player.user_grades.forEach((grade) => {
        if (grade.intangible_id === intangibleId) {
          grade.integer_value = newValue;
        }
      });
    }
  });
  return data;
};

export const qwikcutApi = (url, body) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let finalUrl = `${baseUrl}${url}`;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  return fetch(finalUrl, requestOptions)
    .then((response) => {
      return response
        .json()
        .then((responseText) => {
          return responseText?.data;
        })
        .catch((err) => {
          return err;
        });
    })
    .catch((error) => {
      return JSON.stringify(error);
    });
};
export const videoUploadValueById = (arr, targetId, newValue) => {
  return arr.map((item) => {
    // Check if the current item has the target ID
    if (item.id === targetId) {
      // If yes, update the value
      return { ...item, hudlVideoLink: JSON.stringify(newValue) };
    }
    // If no match, return the original item unchanged
    return item;
  });
};

export const updateNotesById = (id, newNotes, data) => {
  return data.map((item) => {
    if (item.id === id) {
      return { ...item, notes: newNotes };
    }
    return item;
  });
};

export const decodeToken = (token) => {
  if (!token) return null;
  return jwtDecode(token);
};

export const handleDate = (date, type = "default") => {
  let objectDate = new Date(date);
  let day = objectDate?.getDate();
  let month = objectDate?.getMonth() + 1;
  let year = objectDate?.getFullYear();

  let finalDay = day < 10 ? `0${day}` : day;
  let finalMonth = month < 10 ? `0${month}` : month;

  // Check the type to determine the format
  if (type === "slash") {
    return `${finalMonth}/${finalDay}/${year}`;
  }

  // Default format: month-day-year
  return `${finalMonth}-${finalDay}-${year}`;
};


export const superAdmin = process.env.REACT_APP_SUPER_ADMIN_EMAIL.split(",");

export const updateDepthChartId = (
  array,
  id,
  newDepthChartId,
  packageTitle
) => {
  // Use map to iterate over each object in the array
  return array.map((obj) => {
    // Check if the object's id matches the id to update
    if (obj?.id === id) {
      // If matched, update the depth_chart_id with new value
      return { ...obj, depth_chart_id: newDepthChartId, title: packageTitle };
    } else {
      // If not matched, return the original object
      return obj;
    }
  });
};

export const getColorByValue = (value) => {
  let color;

  switch (true) {
    case value >= 0 && value < 10:
      color = "#fea3a2";
      break;
    case value >= 10 && value < 20:
      color = "#ffb897";
      break;
    case value >= 20 && value < 30:
      color = "#fed196";
      break;
    case value >= 30 && value < 40:
      color = "#fdefa8";
      break;
    case value >= 40 && value < 50:
      color = "#e0f7af";
      break;
    case value >= 50 && value < 60:
      color = "#c9ecb4";
      break;
    case value >= 60 && value < 70:
      color = "#b4ead2";
      break;
    case value >= 70 && value < 80:
      color = "#b5e8e9";
      break;
    case value >= 80 && value < 90:
      color = "#bfe2f6";
      break;
    case value >= 90:
      color = "#a0e4ff";
      break;
    default:
      color = "#a0e4ff"; // Default color if value doesn't match any case
  }

  return color;
};
