import {
  Chip,
  CircularProgress,
  makeStyles,
  Popover,
  TextField,
  useTheme,
} from "@material-ui/core";
import { DivBlock } from "../../../Basic";
import Label from "../Label";
import { connect } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { Col, Row } from "../../../Layout";
import { Paragraph, Span } from "../../../Typography";

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "400px",
    },
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
  },
}));

const FilterReports = ({
  openFilters,
  anchorEl,
  handleCloseFilterModal,
  CustomFieldTitles,
  dispatch,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [latestData, setLatestData] = useState({});
  const [error, setError] = useState("");
  const [test, setTest] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const updatedData = useMemo(() => {
    return CustomFieldTitles?.map((item) => {
      return { label: item?.title, value: item?.id };
    });
  }, [CustomFieldTitles]);

  const handleAutocompleteChange = (event, newValue, reason) => {
    if (reason === "clear" && selectedValues?.length > 0) {
      handleResetClick();
    } else {
      setSelectedValues(newValue);
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleClick = () => {
    if (isEmptyObject(latestData)) {
      setError("Please select at least one option");
    } else {
      setError("");
      dispatch({
        type: "SELECTED_CUSTOM_FIELD_DATA",
        inputVariables: {
          customFieldData: latestData,
        },
      });
      dispatch({
        type: "ON_POSITION_FILTER_DATA",
        inputVariables: {
          customFieldData: latestData,
          isDefault: true,
          initialState: {},
        },
      });
      setTest(true);
      handleCloseFilterModal();
    }
  };
  const handleResetClick = () => {
    setError("");
    setSelectedValues([]);
    setData([]);
    setLatestData({});
    setTest(false);
    dispatch({
      type: "SELECTED_CUSTOM_FIELD_DATA",
      inputVariables: {
        customFieldData: null,
      },
    });
    dispatch({
      type: "ON_POSITION_FILTER_DATA",
      inputVariables: {
        customFieldData: {},
        isDefault: true,
        initialState: {},
      },
    });
    handleCloseFilterModal();
  };
  const handleChange = (e) => {
    const obj = { ...latestData };
    obj[e.target.name] = e.target.value;
    setLatestData(obj);
  };

  const handleChipClick = (index, value) => {
    const newValues = [...selectedValues];
    newValues.splice(index, 1);
    setSelectedValues(newValues);
    const array = data?.filter((item) => !(value in item));
    setData(array);
    let optionData = latestData;
    delete optionData[value];
    setLatestData(optionData);
    if (newValues?.length === 0 && test) {
      handleResetClick();
    }
  };

  useEffect(() => {
    if (selectedValues?.length > 0) {
      setIsLoading(true);
      let value = selectedValues?.map((item) => item?.value);
      fetch(
        `${process.env.REACT_APP_BASE_URL}/get_field_data/${JSON.stringify(
          value
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          setData(res?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("eer", err);
        });
    } else {
      setError("");
    }
  }, [dispatch, selectedValues]);

  return (
    <div>
      <Popover
        id={"filter-popover"}
        open={openFilters}
        anchorEl={anchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          className: classes.popoverPaper,
        }}
      >
        <div style={{ padding: "20px 15px", height: "auto", width: "100%" }}>
          <DivBlock className={"dropdown-option"}>
            <Label className={"label"}>Custom Field</Label>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={updatedData}
              onChange={handleAutocompleteChange}
              getOptionLabel={(option) => option?.label}
              value={selectedValues}
              limitTags={2}
              defaultValue={[]}
              size="small"
              freeSolo={false}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.label}
                    {...getTagProps({ index })}
                    // onClick={() => handleChipClick(option)}
                    onDelete={() => handleChipClick(index, option.label)}
                  />
                ))
              }
              // renderTags={(value, getTagProps) =>
              //   value.map((option, index) => (
              //     <Chip
              //       variant="default"
              //       key={index}
              //       label={option?.label}
              //       {...getTagProps({ index })}
              //     />
              //   ))
              // }
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  variant="outlined"
                  style={{ height: "45px" }}
                  placeholder="Select custom field"
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: "custom-input-root",
                    },
                  }}
                />
              )}
            />
          </DivBlock>
          <DivBlock className={"dropdown-option"} style={{ marginTop: "20px" }}>
            {selectedValues?.length > 0 ? (
              <>
                {data !== null ? (
                  <Row style={{ border: 0 }}>
                    {data?.map((item) => (
                      <Col sm={12} md={12} lg={6} xxl={6}>
                        <Label className={"label"}>{Object.keys(item)}</Label>

                        <select
                          onChange={handleChange}
                          className={"form-control"}
                          name={Object.keys(item)}
                          value={latestData[Object.keys(item)] || ""}
                        >
                          <option value="" disabled>
                            Select an option
                          </option>
                          {item?.[Object.keys(item)]?.map((result, index) => (
                            <option key={index} value={result?.data}>
                              {result?.data}
                            </option>
                          ))}
                        </select>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Paragraph className={"para"} style={{ marginTop: "15px" }}>
                    No data available.
                  </Paragraph>
                )}
              </>
            ) : null}

            {error !== "" ? (
              <Span className={"invalid-feedback"}>{error}</Span>
            ) : null}
            {isLoading ? (
              <CircularProgress
                size={30}
                thickness={3}
                style={{
                  color: "#48c46e",
                  margin: "0 auto",
                  display: "table",
                }}
              />
            ) : null}
          </DivBlock>
          {selectedValues?.length > 0 ? (
            <DivBlock
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button className={"btn btn-primary"} onClick={handleClick}>
                Apply
              </button>
              <button
                className={"Depthchart-Div btn btn-reset"}
                onClick={handleResetClick}
              >
                Reset
              </button>
            </DivBlock>
          ) : null}
        </div>
      </Popover>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    CustomFieldTitles: state.reducer?.CustomFieldTitles,
    state: state.reducer,
  };
};
export default connect(mapStateToProps, null)(FilterReports);
