import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock } from "../modules/Basic";
import { FormBlock, Label, TextInput } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Paragraph, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { Image } from "../modules/Media";
import TextField from "@material-ui/core/TextField";
import { debounced, startPageTimer, trackPageDuration } from "../utils/function";
import { CircularProgress } from "@material-ui/core";
import { IMAGES } from "../assets";

class TrainingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
      searchValue:'',
      loading: true,
      // message:'',
    };
  }

  componentDidMount(){
    startPageTimer('Team Training Statistics');
    this.setState({ startTime: Date.now() });
    this.props?.getUserExcercisesData();
  }

  componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear){
      dispatch({ type: "LOADING_EVENT",inputVariables:{loader:true} });
      this.props.getUserExcercisesData();
      this.setState({searchValue:''})
    }
  }
  componentWillUnmount(){
    trackPageDuration('Team Training Statistics',this.state.startTime)
  }


  handleChange = (e) =>{
    const { dispatch } = this.props;
    const { value } = e.target;
    this.setState({ searchValue: value, loading: true });

    debounced(() => {
      fetch(`${process.env.REACT_APP_BASE_URL}/user_exercises/average?text_search=${value}&year=${this.props?.selectedSeasonYear}`,
        {method:'GET',headers:{ 
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }})
        .then((response) => response.json()
        .then((text) => {
          dispatch({ type: "GET_TRAINING_VALUES",
              inputVariables: { rosterValue:text.data } 
          });
          this.setState({ loading: false });
        })).catch((err)=>{
          this.setState({ loading: false });
          console.log('eer',err)
        });
    }, 500);
  }
  
  render() {
    let {
      AverageUserExercises,
      Exercises,
      NavBarDropDownIndex,
      TrainingSortingTable,
      isDefaultLoaded
    } = this.props;


    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            // {
            //   action: "Get Average User Exercises",
            //   trigger: "onload",
            //   timeout: "",
            //   pollingPeriod: "",
            //   inputVariables: {},
            // },
            // {
            //   action: "Get Exercises",
            //   trigger: "onload",
            //   timeout: "",
            //   pollingPeriod: "",
            //   inputVariables: {},
            // },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 1 : 1,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                  // actions={[
                  //   {
                  //     action: "Toggle Sorting",
                  //     trigger: "onclick",
                  //     timeout: "",
                  //     pollingPeriod: "",
                  //     inputVariables: {
                  //       "input sorting": SortingSettings !== true,
                  //     },
                  //   },
                  // ]}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <DivBlock style={{}}>
                        <DivBlock
                          className="game_title"
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Team Training Statistics
                          </Heading>

                          <Span className={"h2 h2-span"} style={{}}>
                            Player Results /
                          </Span>

                          <Span className={"h2 h2-inner-span"} style={{}}>
                            Training
                          </Span>
                        </DivBlock>

                        <Columns
                          style={{
                            backgroundColor: "#ffffff",
                            fontSize: "0px",
                            marginBottom: "10px",
                          }}
                        >
                          <Column
                            lg={12}
                            md={12}
                            sm={12}
                            style={{
                              fontFamily:"'Poppins', sans-serif",
                            }}
                            className="search-container"
                            xs={12}
                          >
                            <DivBlock className="training-search-container">
                             <DivBlock className="roster-input">
                              <TextField
                                id="outlined-search"
                                type="search"
                                size="small"
                                variant="outlined"
                                placeholder="Search Player"
                                autoComplete="off"
                                value={this.state.searchValue}
                                onChange={(e)=>this.handleChange(e)}
                                fullWidth
                                sx={{ marginLeft: "0px" }}
                                InputProps={{
                                  style: {
                                    height: "35px",
                                    width: "250px",
                                  },
                                  startAdornment: this.state.searchValue ? (
                                    this.state.loading ? (
                                      <CircularProgress
                                        size={20}
                                        thickness={3}
                                        style={{
                                          display: "flex",
                                          marginRight: "5px",
                                          color: "#48c46e",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={IMAGES.SearchIcon}
                                        alt="icon"
                                        height={"20px"}
                                        width={"20px"}
                                        style={{ marginRight: "5px" }}
                                      />
                                    )
                                  ) : (
                                    <img
                                      src={IMAGES.SearchIcon}
                                      alt="icon"
                                      height={"20px"}
                                      width={"20px"}
                                      style={{ marginRight: "5px" }}
                                    />
                                  ),
                                }}
                              />
                            </DivBlock> 
                            <DivBlock
                              style={{
                                alignItems: "center",
                                display: "flex",
                                fontSize: "13px",
                                justifyContent: "flex-start",
                                marginTop:'10px'
                              }}
                            >
                              <Paragraph
                                style={{
                                  fontSize: "14px",
                                  marginBottom: 0,
                                  marginLeft: 0,
                                  marginRight: "5px",
                                  marginTop: 0,
                                }}
                              >
                                Average lifetime training stats for all players
                                as recorded by players.
                              </Paragraph>
                            </DivBlock>
                            </DivBlock>
                          </Column>
                        </Columns>
                      </DivBlock>
                      {isDefaultLoaded ? null :
                      <DivBlock className="table-responsive-sm">
                      {AverageUserExercises &&
                      isArray(AverageUserExercises) &&
                      AverageUserExercises.length > 0 ?     
                      <table className={"table"}>
                        <thead>
                          <tr className={"thead-bg"}>
                            <th className={"th-border"}>
                              <Span
                                className={"Sorting-span th-span"}/>
                            </th>
                            <th className={"th-border"}>
                              <Span
                                className={"Sorting-span th-span"}
                                actions={[
                                  {
                                    action: "Toggle Sorting Training",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      inputTraining:{
                                        ToggleSorting:
                                          TrainingSortingTable !== true,
                                          sortingField: "position",
                                      }
                                    },
                                  },
                                ]}
                              >
                                Position
                              </Span>
                            </th>
                            <th className={"th-border"}>
                              <Span
                                className={"Sorting-span th-span"}
                                style={{}}
                                actions={[
                                  {
                                    action: "Toggle Sorting Training",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      inputTraining:{
                                        ToggleSorting:
                                          TrainingSortingTable !== true,
                                          sortingField: "name",
                                      }
                                    },
                                  },
                                ]}
                              >
                                Player Name
                              </Span>
                            </th>

                            {Exercises &&
                              isArray(Exercises) &&
                              Exercises.map((row, index) => {
                                return (
                                  <Fragment>
                                    <th className={"th-border"}>
                                      <Span className={"Sorting-span th-span"}
                                       actions={[
                                        {
                                          action: "Toggle Sorting Training Exercises",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            inputTraining:{
                                              ToggleSorting: TrainingSortingTable !== true,
                                                sortingField: `${row["name"]}`,
                                            }
                                          },
                                        },
                                      ]}>
                                        {`${row["name"]}`}
                                      </Span>
                                    </th>
                                  </Fragment>
                                );
                              })}
                          </tr>
                        </thead>

                        <tbody>
                          {AverageUserExercises &&
                            isArray(AverageUserExercises) &&
                            AverageUserExercises.map((row, index) => {
                              return (
                                <Fragment>
                                  <tr className={""}>
                                  <td className="common-table-data"
                                  style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}>
                                    <Image src={process.env.REACT_APP_PROFILE + row?.profile_image?.thumbnail}
                                      style={{
                                        height: '35px',
                                        width: '35px',
                                        borderRadius:'50%'
                                      }}
                                    />
                                    </td>
                                    <td className="common-table-data">
                                      <Span className={"a a-link"}>
                                        {row["position"]}
                                      </Span>
                                    </td>
                                    

                                    <td className="common-table-data">
                                      <Span className={"td-span"} style={{}}>
                                        {row["name"]}
                                      </Span>
                                    </td>

                                    {row["averages"] &&
                                      isArray(row["averages"]) &&
                                      row["averages"].map((row, index) => {
                                        return (
                                          <Fragment>
                                            <td className="common-table-data">
                                              <Span className={"table-span"}>
                                                {row["integer_value"]}
                                              </Span>
                                            </td>
                                          </Fragment>
                                        );
                                      })}
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table> : 
                      <Span className={"table-span"} style={{fontSize:"20px"}}>
                        No data found</Span>}
                      </DivBlock>}
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserExcercisesData: () => {
      dispatch({ type: "GET_AVERAGE_USER_EXERCISES", inputVariables: {
        isDefault: true, 
        rosterValue:''
      }});
      return Promise.resolve();
    },
    getExcercisesData: () => {
      dispatch({ type: "GET_EXERCISES" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TrainingPage);
