export const play_grading_table_columns = {
    '#':true,
    play:true,
    qtr: true,
    series: true,
    down: true,
    distance: true,
    type: true,
    package:true
}

export const apiUrl = `/quickcut`;

export const qwikCutApiEndpoints = {
    authenticateUrl : '/authenticate',
    getUserTeams : `/user/{id}/teams`,
    getUserTeamsSchedule : `/user/{user_id}/team/{team_id}/schedule`,
    getUserTeamsSeasonSchedule:`/user/{user_id}/team/{team_id}/schedule/{season_id}`,
    getStatsColumns:`/stat-columns/{team_id}`,
    getGameStats:`/game/{game_id}/stats/{team_id}`,
}