import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { DivBlock, LinkBlock } from "../modules/Basic";
import { SelectDropDown } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Image } from "../modules/Media";
import { Span } from "../modules/Typography";
import { superAdmin, toggleClassInbody } from "../utils/function";
import { withRouter } from "react-router-dom";

class TopNavbarComponent extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props?.location?.search);
    this.state = {
      toggleMenu: false,
      myParam: Object.fromEntries(params.entries()),
    };
  }

  render() {
    //let { row, index, errors } = this.props;
    let {
      CurrentUser,
      DropdownUserSettings,
      SeasonYearData,
      selectedSeasonYear,
      location,
    } = this.props;

    return (
      <Fragment>
        <DivBlock
          className={"header"}
          context={{
            elements: {
              1376: { type: "Body" },
              1377: { type: "DivBlock" },
              1378: { type: "DivBlock" },
              1389: { type: "DivBlock" },
              1390: { type: "DivBlock" },
              1452: { type: "DivBlock" },
              1453: { type: "DivBlock" },
              1454: { type: "DivBlock" },
              1455: { type: "DivBlock" },
              1456: { type: "DivBlock" },
              1457: { type: "DivBlock" },
              1458: { type: "Heading" },
              1459: { type: "Span" },
              1460: { type: "DivBlock" },
              1461: { type: "DivBlock" },
              1462: { type: "DivBlock" },
              1463: { type: "DivBlock" },
              1464: { type: "Span" },
              1465: { type: "Span" },
              1466: { type: "If" },
              1473: { type: "DivBlock" },
              1474: { type: "DivBlock" },
              1475: { type: "Span" },
              1476: { type: "Span" },
              1477: { type: "If" },
              1478: { type: "Then" },
              1479: { type: "DivBlock" },
              1480: { type: "DivBlock" },
              1481: { type: "Span" },
              1482: { type: "DivBlock" },
              1483: { type: "DivBlock" },
              1484: { type: "Span" },
              1485: { type: "DivBlock" },
              1486: { type: "DivBlock" },
              1487: { type: "Span" },
              1488: { type: "DivBlock" },
              1489: { type: "Span" },
              1490: { type: "table" },
              1491: { type: "thead" },
              1492: { type: "tr" },
              1493: { type: "th" },
              1494: { type: "Span" },
              1495: { type: "Span" },
              1496: { type: "th" },
              1497: { type: "Span" },
              1498: { type: "th" },
              1499: { type: "Span" },
              1500: { type: "th" },
              1501: { type: "Span" },
              1502: { type: "th" },
              1503: { type: "Span" },
              1504: { type: "tbody" },
              1505: { type: "tr" },
              1506: { type: "td" },
              1507: { type: "Span" },
              1508: { type: "td" },
              1509: { type: "Span" },
              1510: { type: "td" },
              1511: { type: "Span" },
              1512: { type: "td" },
              1513: { type: "Span" },
              1514: { type: "td" },
              1515: { type: "Button" },
              1516: { type: "DivBlock" },
              1517: { type: "DivBlock" },
              1518: { type: "DivBlock" },
              1519: { type: "DivBlock" },
              1520: { type: "Span" },
              1521: { type: "Span" },
              1522: { type: "MultipleDataGraph" },
              1523: { type: "DataSeries" },
              1524: { type: "If" },
              1755: { type: "Columns" },
              1756: { type: "Column" },
              1757: { type: "DivBlock" },
              1758: { type: "LinkBlock" },
              1759: { type: "DivBlock" },
              1760: { type: "Image" },
              1761: { type: "Column" },
              1762: { type: "DivBlock" },
              1763: { type: "DivBlock" },
              1764: { type: "DivBlock" },
              1765: { type: "If" },
              1769: { type: "Span" },
              1770: { type: "DivBlock" },
              1771: { type: "Span" },
              1772: { type: "DivBlock" },
              1773: { type: "i" },
              1774: { type: "If" },
              1775: { type: "Then" },
              1776: { type: "DivBlock" },
              1777: { type: "LinkBlock" },
              1778: { type: "DivBlock" },
              1779: { type: "Span" },
              1780: { type: "If" },
              1785: { type: "DivBlock" },
              1786: { type: "Span" },
            },
            getters: {},
            listeners: [
              { name: "mouseOver.boundaries" },
              { name: "mouseOut.boundaries" },
              { name: "dragEnter" },
              { name: "dragLeave" },
              { name: "dragEnd" },
              { name: "mouseDown.mousemenu" },
              { name: "showSettingsBox.desk" },
              { name: "textChanged.desk" },
              { name: "editTextMode.desk" },
              { name: "componentClick.desk" },
              { name: "pathnameChanged.desk" },
              { name: "cut.desk" },
              { name: "copy.desk" },
              { name: "create_prefab.desk" },
              { name: "create_partial.desk" },
              { name: "editSnippet.desk" },
              { name: "clone.desk" },
              { name: "delete.desk" },
              { name: "before.desk" },
              { name: "after.desk" },
              { name: "first.desk" },
              { name: "last.desk" },
              { name: "replace.desk" },
              { name: "select.desk" },
              { name: "highlight.desk" },
              { name: "dropZoneHover" },
              { name: "dragEnd" },
              { name: "dragStart" },
              { name: "copyStyleProps" },
              { name: "pasteStyleProps" },
              { name: "updateSelectedProps" },
              { name: "dragStart" },
            ],
            triggerCache: {
              mouseOut: [{ name: "mouseOut.boundaries" }],
              mouseOver: [{ name: "mouseOver.boundaries" }],
              pathnameChanged: [{ name: "pathnameChanged.desk" }],
            },
          }}
          df-snippet-instance-id={297}
          draggable={true}
          eleKey={"1378"}
          inline={{ "df-snippet-instance-id": 297, draggable: true }}
          isEdit={true}
          root-snippet={true}
          actions={[
            {
              action: "Get Support Page",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
          ]}
        >
          <Columns
            className="header-grid"
            df-snippet-instance-id={297}
            selectedLayout="3/9"
          >
            <Column
              df-snippet-instance-id={297}
              className="mobile_toggle"
              lg={1}
              md={1}
              sm={1}
              xs={1}
            >
              <DivBlock
                className="toggle-nav test"
                onClick={() => {
                  toggleClassInbody("toggle-manu");
                }}
              >
                <i
                  className={"fa fa-bars toggle-nav"}
                  df-snippet-instance-id={298}
                  style={{}}
                ></i>
              </DivBlock>
            </Column>

            <Column
              df-snippet-instance-id={297}
              lg={3}
              md={3}
              sm={3}
              style={{backgroundColor: 'rgb(41 49 66)'}}
              xs={3}
              className="custom-width"
            >
              <DivBlock
                df-snippet-instance-id={297}
                style={{ backgroundColor: "#ffffff" }}
              >
                <LinkBlock
                  df-snippet-instance-id={297}
                  duration={1000}
                  href={"/"}
                  // style={{}}
                  className="top-nav-link"
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    df-snippet-instance-id={297}
                    style={{
                      backgroundColor: "#ffffff",
                      fontSize: "0px",
                      marginRight: "0px",
                      maxHeight: "auto",
                      paddingBottom: "0px",
                      paddingRight: "0px",
                      paddingTop: "0px",
                    }}
                  >
                    <Image
                      alt={"CoachTools Logo"}
                      df-snippet-instance-id={297}
                      src={process.env.REACT_APP_LOGO}
                      className="image_logo"
                      style={{
                        backgroundColor: "#2a3142",
                        fontSize: "0px",
                        height: "75px",
                        lineHeight: "0px",
                        marginRight: "0px",
                        maxHeight: "75px",
                        objectFit: "contain",
                        paddingBottom: "10px",
                        paddingLeft: "36px",
                        paddingRight: "36px",
                        paddingTop: "5px",
                        position: "static",
                        width: "246px",
                      }}
                    ></Image>
                  </DivBlock>
                </LinkBlock>
              </DivBlock>
            </Column>
            <Column
              df-snippet-instance-id={297}
              lg={9}
              md={9}
              sm={9}
              xs={9}
              className="top-nav-profile-container"
            >
              {CurrentUser?.school_logo?.medium ? 
              <Image
                alt={"CoachTools School logo"}
                df-snippet-instance-id={297}
                src={process.env.REACT_APP_PROFILE + CurrentUser?.school_logo?.medium}
                className="school-logo"
                /> : null}
              <DivBlock className={"year-select"} style={CurrentUser?.school_logo?.medium ? {width:'45%'} : {width: '50%'}}>
                {location?.pathname === "/roster" ||
                location?.pathname === "/injured_players" ||
                location?.pathname === "/school_report" ||
                location?.pathname === "/game_files" ||
                location?.pathname === "/edit_coach" ||
                location?.pathname === "/coaches" ||
                location?.pathname === "/support" ||
                location.pathname === "/add_depth_chart" ||
                location?.pathname === "/edit_player" ||
                location?.pathname === "/depth_chart" ||
                location?.pathname === "/play_by_play" ||
                location?.pathname === "/play_reports_data" ? null : (
                  <>
                    <SelectDropDown
                      className={"form-control season-dropdown"}
                      dataSet={
                        SeasonYearData?.length > 0
                          ? SeasonYearData
                          : "No data available"
                      }
                      dataSetLabel={"year"}
                      dataSetValue={"year"}
                      enableReinitialize={true}
                      disabled={
                        location?.pathname === "/play_reports_data" ||
                        location?.pathname === "/play_by_play" ||
                        location?.pathname === "/depth_chart" ||
                        location?.pathname === "/player_depth_chart" ||
                        location?.pathname === "/player_grades_data"
                          ? true
                          : false
                      }
                      initialValue={
                        this.state.myParam?.year
                          ? this.state.myParam?.year
                          : selectedSeasonYear === "" ||
                            selectedSeasonYear === 0
                          ? SeasonYearData?.[0]?.year
                          : selectedSeasonYear
                      }
                      name={"selectedSeasonYear"}
                      type={"number"}
                      // actions={[
                      //   {
                      //     action: "Select Season Year",
                      //     trigger: "onchange",
                      //     timeout: "",
                      //     pollingPeriod: "",
                      //     inputVariables: { initialState: {} },
                      //   },
                      // ]}
                    ></SelectDropDown>
                    <Span className={"invalid-feedback"}>
                      {`You are currently viewing ${selectedSeasonYear} data.`}
                    </Span>
                  </>
                )}
              </DivBlock>
              <DivBlock
                df-snippet-instance-id={297}
                style={{
                  alignItems: "flex-start",
                  display: "flex",
                  justifyContent: "flex-end",
                  width:'50%'
                }}
              >
                <DivBlock className="profile-img">
                  <Image
                    src={
                      process.env.REACT_APP_PROFILE +
                      CurrentUser?.profile_image?.medium
                    }
                    className="top-nav-profile"
                  />
                </DivBlock>
                <DivBlock
                  df-snippet-instance-id={297}
                  className="name-remove"
                  style={{
                    alignSelf: "left",
                    display: "block",
                    flex: "0 1 auto",
                    marginRight: "60px",
                    marginTop: "0px",
                    order: "0",
                    paddingTop: "2px",
                    textAlign: "left",
                  }}
                >
                  <DivBlock
                    df-snippet-instance-id={297}
                    style={{
                      display: "flex",
                      fontFamily: "'Poppins', sans-serif",
                      justifyContent: "flex-start",
                      paddingTop: "5px",
                      textAlign: "right",
                    }}
                  >
                    <If
                      condition={CurrentUser["user_type"] === "Coach"}
                      df-snippet-instance-id={297}
                      style={{ textAlign: "right" }}
                    >
                      <Then df-snippet-instance-id={297}>
                        <DivBlock
                          df-snippet-instance-id={297}
                          style={{ display: "flex", textAlign: "right" }}
                        >
                          <Span
                            df-snippet-instance-id={297}
                            style={{
                              color: "#2a3142",
                              flex: "1 0 auto",
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: "14px",
                              fontWeight: "600",
                              paddingRight: "6px",
                              textAlign: "right",
                            }}
                          >
                            Coach
                          </Span>
                        </DivBlock>
                      </Then>
                    </If>

                    <Span
                      df-snippet-instance-id={297}
                      style={{
                        color: "#2a3142",
                        display: "flex",
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginRight: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      {CurrentUser["name"]}
                    </Span>
                  </DivBlock>

                  <DivBlock
                    df-snippet-instance-id={297}
                    style={{ display: "flex", paddingTop: "0px" }}
                  >
                    <Span
                      df-snippet-instance-id={297}
                      style={{
                        color: "#2a3142",
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginTop: "-4px",
                        paddingRight: "0px",
                        textAlign: "left",
                      }}
                    >
                      {CurrentUser["school_name"]}
                    </Span>
                  </DivBlock>
                </DivBlock>

                <DivBlock
                  df-snippet-instance-id={297}
                  className="setting_icon"
                  style={{
                    alignItems: "center",
                    alignSelf: "auto",
                    color: "#000000",
                    cursor: "pointer",
                    display: "flex",
                    flex: "0 1 auto",
                    fontSize: "16px",
                    justifyContent: "flex-start",
                    marginRight: "30px",
                    order: "0",
                    paddingTop: "7px",
                  }}
                  actions={[
                    {
                      action: "Toggle User Settings Dropdown",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input toggle user settings":
                          DropdownUserSettings !== true,
                      },
                    },
                    {
                      action: "Toggle User Settings Dropdown",
                      trigger: "onmouseleave",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input toggle user settings": false,
                      },
                    },
                  ]}
                >
                  <DivBlock
                    className="setting-menu"
                    onClick={() => toggleClassInbody("setting-menu_item")}
                  >
                    <i
                      className={"fa fa-cog"}
                      df-snippet-instance-id={297}
                      style={{}}
                    ></i>
                  </DivBlock>

                  <If
                    className={"TopDown-If settings-topdown"}
                    condition={DropdownUserSettings}
                    df-snippet-instance-id={297}
                    show={true}
                  >
                    <Then
                      df-snippet-instance-id={297}
                      style={{ flex: "0 1 auto" }}
                    >
                      <DivBlock
                        className={"dropdown-menu dropdown-settings"}
                        df-snippet-instance-id={297}
                        style={{}}
                      >
                        <LinkBlock
                          className={"dropdown-item"}
                          df-snippet-instance-id={297}
                          duration={1000}
                          href={
                            CurrentUser["user_type"] === "Position Coach" ||
                            CurrentUser["user_type"] === "Coach"
                              ? "/edit_coach"
                              : "/edit_player"
                          }
                          target={"_self"}
                          type={"spa"}
                        >
                          <DivBlock df-snippet-instance-id={297}>
                            <Span
                              df-snippet-instance-id={297}
                              style={{ fontFamily: "'Poppins', sans-serif" }}
                            >
                              Profile
                            </Span>
                          </DivBlock>
                        </LinkBlock>

                        <If
                          condition={
                            CurrentUser["user_type"] === "Player" ? false : true
                          }
                          df-snippet-instance-id={297}
                        >
                          <Then df-snippet-instance-id={297}>
                            <LinkBlock
                              className={"dropdown-item"}
                              df-snippet-instance-id={297}
                              duration={1000}
                              href={
                                CurrentUser["user_type"] === "Player"
                                  ? "/home"
                                  : "/coaches"
                              }
                              target={"_self"}
                              type={"spa"}
                            >
                              <DivBlock df-snippet-instance-id={297}>
                                <Span
                                  df-snippet-instance-id={297}
                                  style={{
                                    fontFamily: "'Poppins', sans-serif",
                                  }}
                                >
                                  Coaches
                                </Span>
                              </DivBlock>
                            </LinkBlock>
                          </Then>
                        </If>

                        <If
                          condition={
                            CurrentUser["user_type"] === "Position Coach" ||
                            CurrentUser["user_type"] === "Coach"
                              ? true
                              : false
                          }
                          df-snippet-instance-id={297}
                        >
                          <LinkBlock
                            className={"dropdown-item"}
                            df-snippet-instance-id={297}
                            duration={1000}
                            href={
                              CurrentUser["user_type"] === "Position Coach" ||
                              CurrentUser["user_type"] === "Coach"
                                ? "/grading_scales"
                                : "/home"
                            }
                            target={"_self"}
                            type={"spa"}
                          >
                            <DivBlock df-snippet-instance-id={297}>
                              <Span
                                df-snippet-instance-id={297}
                                style={{ fontFamily: "'Poppins', sans-serif" }}
                              >
                                Grading Scale Type
                              </Span>
                            </DivBlock>
                          </LinkBlock>
                        </If>

                        <If
                          condition={
                            CurrentUser["user_type"] === "Position Coach" ||
                            CurrentUser["user_type"] === "Coach"
                              ? true
                              : false
                          }
                          df-snippet-instance-id={297}
                        >
                          <LinkBlock
                            className={"dropdown-item"}
                            df-snippet-instance-id={297}
                            duration={1000}
                            href={"/production_points"}
                            target={"_self"}
                            type={"spa"}
                          >
                            <DivBlock df-snippet-instance-id={297}>
                              <Span
                                df-snippet-instance-id={297}
                                style={{ fontFamily: "'Poppins', sans-serif" }}
                              >
                                Production Points
                              </Span>
                            </DivBlock>
                          </LinkBlock>
                        </If>

                        <If
                          condition={
                            CurrentUser["user_type"] === "Coach" &&
                            superAdmin?.includes(CurrentUser["email"])
                              ? true
                              : false
                          }
                          df-snippet-instance-id={297}
                        >
                          <LinkBlock
                            className={"dropdown-item"}
                            df-snippet-instance-id={297}
                            duration={1000}
                            href={"/school_report"}
                            target={"_self"}
                            type={"spa"}
                          >
                            <DivBlock df-snippet-instance-id={297}>
                              <Span
                                df-snippet-instance-id={297}
                                style={{ fontFamily: "'Poppins', sans-serif" }}
                              >
                                School Report
                              </Span>
                            </DivBlock>
                          </LinkBlock>
                        </If>

                        <DivBlock
                          className={"dropdown-item"}
                          df-snippet-instance-id={297}
                          actions={[
                            {
                              action: "Logout",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          <Span
                            df-snippet-instance-id={297}
                            style={{ fontFamily: "'Poppins', sans-serif" }}
                          >
                            Logout
                          </Span>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>
                </DivBlock>
              </DivBlock>
            </Column>
          </Columns>
        </DivBlock>

        {/* Mobile CSS */}

        <DivBlock className="mobile_drop_down">
          <If
            className={"TopDown-If settings-topdown"}
            condition={true}
            df-snippet-instance-id={297}
            show={true}
          >
            <Then df-snippet-instance-id={297} style={{ flex: "0 1 auto" }}>
              <DivBlock
                className={"dropdown-menu dropdown-settings"}
                df-snippet-instance-id={297}
                style={{}}
              >
                <DivBlock
                  df-snippet-instance-id={297}
                  className="name_main"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    justifyContent: "flex-start",
                    paddingTop: "5px",
                  }}
                >
                  <DivBlock
                    df-snippet-instance-id={297}
                    style={{ display: "flex", textAlign: "right" }}
                  >
                    <DivBlock style={{ marginLeft: "15px" }}>
                      <Image
                        src={
                          process.env.REACT_APP_PROFILE +
                          CurrentUser?.profile_image?.medium
                        }
                        style={{
                          height: "50px",
                          width: "50px",
                          marginTop: "10px",
                          borderRadius: "50%",
                        }}
                      />
                    </DivBlock>
                    <DivBlock
                      style={{ textAlign: "left", alignSelf: "center",maxWidth:'215px' }}
                    >
                      {CurrentUser["user_type"] === "Coach" ? (
                        <Span
                          df-snippet-instance-id={297}
                          className="name"
                          style={{
                            color: "#2a3142",
                            flex: "1 0 auto",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "14px",
                            fontWeight: "500",
                            paddingRight: "6px",
                            textAlign: "right",
                          }}
                        >
                          Coach
                        </Span>
                      ) : null}
                      <Span
                        df-snippet-instance-id={297}
                        className="name"
                        style={{
                          color: "#2a3142",
                          display: "flex",
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "14px",
                          fontWeight: "500",
                          marginRight: "0px",
                          paddingRight: "0px",
                        }}
                      >
                        {CurrentUser["name"]}
                      </Span>
                    </DivBlock>
                    <Image
                alt={"CoachTools School logo"}
                df-snippet-instance-id={297}
                src={process.env.REACT_APP_PROFILE + CurrentUser?.school_logo?.medium}
                className="school-logo"
                />
                  </DivBlock>
                </DivBlock>
                {location?.pathname === "/roster" ||
                location?.pathname === "/injured_players" ||
                location?.pathname === "/game_files" ||
                location?.pathname === "/edit_coach" ||
                location?.pathname === "/coaches" ||
                location?.pathname === "/support" ||
                location.pathname === "/add_depth_chart" ||
                location?.pathname === "/edit_player" ||
                location?.pathname === "/depth_chart" ||
                location?.pathname === "/play_by_play" ||
                location?.pathname === "/play_reports_data" ? null : (
                  <DivBlock className="manage-select-year">
                    <DivBlock className="manage-select-dropdown">
                      <Span
                        df-snippet-instance-id={297}
                        style={{ fontFamily: "'Poppins', sans-serif" }}
                      >
                        Managing data for season:
                      </Span>
                      <SelectDropDown
                        className={"form-control season-dropdown"}
                        dataSet={
                          SeasonYearData?.length > 0
                            ? SeasonYearData
                            : "No data available"
                        }
                        dataSetLabel={"year"}
                        dataSetValue={"year"}
                        enableReinitialize={true}
                        disabled={
                          location?.pathname === "/play_reports_data" ||
                          location?.pathname === "/play_by_play" ||
                          location?.pathname === "/depth_chart" ||
                          location?.pathname === "/player_depth_chart" ||
                          location?.pathname === "/player_grades_data"
                            ? true
                            : false
                        }
                        initialValue={
                          this.state.myParam?.year
                            ? this.state.myParam?.year
                            : selectedSeasonYear === "" ||
                              selectedSeasonYear === 0
                            ? SeasonYearData?.[0]?.year
                            : selectedSeasonYear
                        }
                        name={"selectedSeasonYear"}
                        type={"number"}
                        actions={[
                          {
                            action: "Select Season Year",
                            trigger: "onchange",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { initialState: {} },
                          },
                        ]}
                      ></SelectDropDown>
                    </DivBlock>
                    <Span className={"invalid-feedback"}>
                      {`You are currently viewing ${selectedSeasonYear} data.`}
                    </Span>
                  </DivBlock>
                )}

                <LinkBlock
                  className={"dropdown-item"}
                  df-snippet-instance-id={297}
                  duration={1000}
                  href={
                    CurrentUser["user_type"] === "Position Coach" ||
                    CurrentUser["user_type"] === "Coach"
                      ? "/edit_coach"
                      : "/edit_player"
                  }
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    df-snippet-instance-id={297}
                    onClick={() => {
                      toggleClassInbody("setting-menu_item");
                    }}
                  >
                    <Span
                      df-snippet-instance-id={297}
                      style={{ fontFamily: "'Poppins', sans-serif" }}
                      actions={[
                        {
                          action: "Toggle User Settings Dropdown",
                          trigger: "onclick",
                          timeout: "",
                          pollingPeriod: "",
                          inputVariables: {
                            "input toggle user settings": false,
                          },
                        },
                      ]}
                    >
                      <i
                        className={"fa fa-user"}
                        df-snippet-instance-id={297}
                        style={{}}
                      ></i>
                      Profile
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <If
                  condition={
                    CurrentUser["user_type"] === "Player" ? false : true
                  }
                  df-snippet-instance-id={297}
                >
                  <Then df-snippet-instance-id={297}>
                    <LinkBlock
                      className={"dropdown-item"}
                      df-snippet-instance-id={297}
                      duration={1000}
                      href={
                        CurrentUser["user_type"] === "Player"
                          ? "/home"
                          : "/coaches"
                      }
                      target={"_self"}
                      type={"spa"}
                    >
                      <DivBlock
                        df-snippet-instance-id={297}
                        onClick={() => {
                          toggleClassInbody("setting-menu_item");
                        }}
                      >
                        <Span
                          df-snippet-instance-id={297}
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                          }}
                          actions={[
                            {
                              action: "Toggle User Settings Dropdown",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "input toggle user settings": false,
                              },
                            },
                          ]}
                        >
                          <i
                            className={"fa fa-users"}
                            df-snippet-instance-id={297}
                            style={{}}
                          ></i>
                          Coaches
                        </Span>
                      </DivBlock>
                    </LinkBlock>
                  </Then>
                </If>

                <If
                  condition={
                    CurrentUser["user_type"] === "Position Coach" ||
                    CurrentUser["user_type"] === "Coach"
                      ? true
                      : false
                  }
                  df-snippet-instance-id={297}
                >
                  <LinkBlock
                    className={"dropdown-item"}
                    df-snippet-instance-id={297}
                    duration={1000}
                    href={
                      CurrentUser["user_type"] === "Position Coach" ||
                      CurrentUser["user_type"] === "Coach"
                        ? "/grading_scales"
                        : "/home"
                    }
                    target={"_self"}
                    type={"spa"}
                  >
                    <DivBlock
                      df-snippet-instance-id={297}
                      onClick={() => {
                        toggleClassInbody("setting-menu_item");
                      }}
                    >
                      <Span
                        df-snippet-instance-id={297}
                        style={{ fontFamily: "'Poppins', sans-serif" }}
                        actions={[
                          {
                            action: "Toggle User Settings Dropdown",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: {
                              "input toggle user settings": false,
                            },
                          },
                        ]}
                      >
                        <i
                          className={"fa fa-star"}
                          df-snippet-instance-id={297}
                          style={{}}
                        ></i>
                        Grading Scale Type
                      </Span>
                    </DivBlock>
                  </LinkBlock>
                </If>

                <If
                  condition={
                    CurrentUser["user_type"] === "Position Coach" ||
                    CurrentUser["user_type"] === "Coach"
                      ? true
                      : false
                  }
                  df-snippet-instance-id={297}
                >
                  <LinkBlock
                    className={"dropdown-item"}
                    df-snippet-instance-id={297}
                    duration={1000}
                    href={
                      CurrentUser["user_type"] === "Position Coach" ||
                      CurrentUser["user_type"] === "Coach"
                        ? "/production_points"
                        : "/home"
                    }
                    target={"_self"}
                    type={"spa"}
                  >
                    <DivBlock
                      df-snippet-instance-id={297}
                      onClick={() => {
                        toggleClassInbody("setting-menu_item");
                      }}
                    >
                      <Span
                        df-snippet-instance-id={297}
                        style={{ fontFamily: "'Poppins', sans-serif" }}
                        actions={[
                          {
                            action: "Toggle User Settings Dropdown",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: {
                              "input toggle user settings": false,
                            },
                          },
                        ]}
                      >
                        <i
                          className={"fa fa-star"}
                          df-snippet-instance-id={297}
                          style={{}}
                        ></i>
                        Production Points
                      </Span>
                    </DivBlock>
                  </LinkBlock>
                </If>

                <If
                  condition={
                    CurrentUser["user_type"] === "Coach" &&
                    superAdmin?.includes(CurrentUser["email"])
                      ? true
                      : false
                  }
                  df-snippet-instance-id={297}
                >
                  <LinkBlock
                    className={"dropdown-item"}
                    df-snippet-instance-id={297}
                    duration={1000}
                    href={
                      CurrentUser["user_type"] === "Coach"
                        ? "/school_report"
                        : "/home"
                    }
                    target={"_self"}
                    type={"spa"}
                  >
                    <DivBlock
                      df-snippet-instance-id={297}
                      onClick={() => {
                        toggleClassInbody("setting-menu_item");
                      }}
                    >
                      <Span
                        df-snippet-instance-id={297}
                        style={{ fontFamily: "'Poppins', sans-serif" }}
                        actions={[
                          {
                            action: "Toggle User Settings Dropdown",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: {
                              "input toggle user settings": false,
                            },
                          },
                        ]}
                      >
                        <i
                          className={"fa fa-file"}
                          df-snippet-instance-id={297}
                          style={{}}
                        ></i>
                        School Report
                      </Span>
                    </DivBlock>
                  </LinkBlock>
                </If>

                <DivBlock
                  className={"dropdown-item"}
                  df-snippet-instance-id={297}
                  actions={[
                    {
                      action: "Logout",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {},
                    },
                  ]}
                >
                  <Span
                    df-snippet-instance-id={297}
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                    actions={[
                      {
                        action: "Toggle User Settings Dropdown",
                        trigger: "onclick",
                        timeout: "",
                        pollingPeriod: "",
                        inputVariables: {
                          "input toggle user settings": false,
                        },
                      },
                    ]}
                  >
                    <i
                      className={"fa fa-sign-out"}
                      df-snippet-instance-id={297}
                      style={{}}
                    ></i>
                    Logout
                  </Span>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>
        </DivBlock>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(withRouter(TopNavbarComponent));
