import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, DivBlock, LinkBlock, List, ListItem } from "../modules/Basic";
import { Column, Columns } from "../modules/Layout";
import { Heading, Paragraph, Span } from "../modules/Typography";
import PlayerSideNavComponent from "../components/PlayerSideNavComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";

class PlayerGradesPage extends Component {
  
  componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear){
      dispatch({ type: "LOADING_EVENT",inputVariables:{loader:true} });
        this.props.getGamesData();
        this.props.getPracticesData();
    }
  }

  render() {
    //let { row, index, errors } = this.props;
    let { Games, Practices, selectedSeasonYear } = this.props;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Games",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Practices",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              data-snippet-id={297}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <PlayerSideNavComponent
                data-snippet-id={302}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></PlayerSideNavComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  paddingTop: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"} style={{}}>
                      <DivBlock style={{}}>
                        <DivBlock
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Player Grades
                          </Heading>
                        </DivBlock>
                      </DivBlock>

                      <Columns
                        selectedLayout={"9/3"}
                        style={{
                          backgroundColor: "#ffffff",
                          fontSize: "0px",
                          marginBottom: "10px",
                        }}
                      >
                        <Column lg={9} md={9} sm={9} xs={9}>
                          <DivBlock
                            style={{
                              alignItems: "center",
                              display: "flex",
                              fontSize: "13px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Paragraph
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px",
                                marginRight: "5px",
                                paddingLeft: "10px",
                              }}
                            >
                              Choose a game or practice below to view individual
                              player metrics.
                            </Paragraph>
                          </DivBlock>
                        </Column>
                      </Columns>

                      <DivBlock className={"mb-4"}>
                        <Span
                          style={{
                            fontFamily:"'Poppins', sans-serif",
                            fontWeight: "500",
                            marginLeft: "10px",
                            marginRight: "0px",
                          }}
                        >
                          Games
                        </Span>

                        <List style={{ marginBottom: "0px", marginTop: "0px" }}>
                          {Games &&
                            isArray(Games) &&
                            Games.map((row, index) => {
                              return (
                                <Fragment>
                                  <ListItem style={{ listStyle: "square" }}>
                                    <LinkBlock
                                      className={"a"}
                                      duration={1000}
                                      href={
                                        "player_grades_data?playgradeid=" +
                                        row["Id"] + '&year=' + selectedSeasonYear
                                      }
                                      target={"_self"}
                                      type={"spa"}
                                    >
                                      <DivBlock>
                                        <Span
                                          className={"a package"}
                                          actions={[
                                            {
                                              action: "Set Depth Chart",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "depth chart": row,
                                              },
                                            },
                                          ]}
                                        >
                                          {row["title"]}
                                        </Span>
                                      </DivBlock>
                                    </LinkBlock>
                                  </ListItem>
                                </Fragment>
                              );
                            })}
                        </List>
                      </DivBlock>

                      <DivBlock className={"mb-4"}>
                        <Span
                          style={{
                            fontFamily:"'Poppins', sans-serif",
                            fontWeight: "500",
                            marginLeft: "10px",
                            marginRight: "0px",
                          }}
                        >
                          Practices
                        </Span>

                        <List style={{ marginBottom: "0px", marginTop: "0px" }}>
                          {Practices &&
                            isArray(Practices) &&
                            Practices.map((row, index) => {
                              return (
                                <Fragment>
                                  <ListItem style={{ listStyle: "square" }}>
                                    <LinkBlock
                                      className={"a"}
                                      duration={1000}
                                      href={
                                        "player_grades_data?playgradeid=" +
                                        row["Id"] + '&year=' + selectedSeasonYear
                                      }
                                      target={"_self"}
                                      type={"spa"}
                                    >
                                      <DivBlock>
                                        <Span
                                          className={"a package"}
                                          actions={[
                                            {
                                              action: "Set Depth Chart",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "depth chart": row,
                                              },
                                            },
                                          ]}
                                        >
                                          {row["title"]}
                                        </Span>
                                      </DivBlock>
                                    </LinkBlock>
                                  </ListItem>
                                </Fragment>
                              );
                            })}
                        </List>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGamesData: () => {
      dispatch({ type: "GET_GAMES" });
      return Promise.resolve();
    },
    getPracticesData: () => {
      dispatch({ type: "GET_PRACTICES" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerGradesPage);
