import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock, LinkBlock } from "../modules/Basic";
import { FormBlock, Label, TextInput } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Image } from "../modules/Media";
import { MenuButton, NavBar, NavMenu } from "../modules/Navigation";
import { Heading, Span, TextLink } from "../modules/Typography";

class ResetPasswordPage extends Component {
  render() {
    //let { row, index, errors } = this.props;
    let { FormResetPasswordErrors } = this.props;

    return (
      <Fragment>
        <Body className={"body"}>
          <DivBlock>
            <NavBar
              breakPoint={"sm"}
              style={{ backgroundColor: "#000000", color: "#ffffff" }}
              type={"down"}
            >
              <LinkBlock
                href={"/"}
                style={{ float: "left" }}
                target={"_self"}
                type={"spa"}
              >
                <DivBlock
                  style={{
                    paddingBottom: "7.5px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "7.5px",
                  }}
                >
                  <Image
                    src={process.env.REACT_APP_LOGO}
                    style={{ height: "37.5px", maxWidth: "auto" }}
                  ></Image>
                </DivBlock>
              </LinkBlock>

              <MenuButton>
                <DivBlock
                  style={{
                    backgroundColor: "black",
                    border: "0px",
                    height: "3px",
                    margin: "4px 0",
                    minWidth: "30px",
                    width: "30px",
                  }}
                ></DivBlock>

                <DivBlock
                  style={{
                    backgroundColor: "black",
                    border: "0px",
                    height: "3px",
                    margin: "4px 0",
                    minWidth: "30px",
                    width: "30px",
                  }}
                ></DivBlock>

                <DivBlock
                  style={{
                    backgroundColor: "black",
                    border: "0px",
                    height: "3px",
                    margin: "4px 0",
                    minWidth: "30px",
                    width: "30px",
                  }}
                ></DivBlock>
              </MenuButton>

              <NavMenu>
                <TextLink
                  href={"/pathone"}
                  style={{
                    color: "#ffffff",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                  type={"spa"}
                >
                  Home
                </TextLink>

                <TextLink
                  href={"/pathtwo"}
                  style={{
                    color: "#ffffff",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                  type={"spa"}
                >
                  About Us
                </TextLink>

                <TextLink
                  href={"/paththree"}
                  style={{
                    color: "#ffffff",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                  type={"spa"}
                >
                  Contact Us
                </TextLink>

                <TextLink
                  href={"/paththree"}
                  style={{
                    color: "#ffffff",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                  type={"spa"}
                >
                  Login
                </TextLink>
              </NavMenu>
            </NavBar>

            <DivBlock className={"mt-5"}>
              <Columns style={{ display: "flex", justifyContent: "center" }}>
                <Column
                  lg={6}
                  md={6}
                  sm={10}
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  xs={11}
                >
                  <DivBlock className={"card"} style={{}}>
                    <DivBlock className={"card-body"}>
                      <DivBlock className={"text-center"}>
                        <Heading className={"h2 remove-bg"} type={"h1"}>
                          Reset Password
                        </Heading>
                      </DivBlock>

                      <FormBlock
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        actions={[
                          {
                            action: "Login",
                            trigger: "onsubmit",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: {},
                          },
                        ]}
                      >
                        <DivBlock className={"form-group"}>
                          <Label className={"label"}>New Password</Label>

                          <TextInput
                            className={"form-control"}
                            name={"FormResetPassword.password"}
                            placeholder={""}
                            type={"password"}
                          ></TextInput>

                          <If
                            condition={
                              FormResetPasswordErrors &&
                              FormResetPasswordErrors["password"]
                                ? true
                                : false
                            }
                            show={true}
                          >
                            <Then>
                              <Span className={"invalid-feedback"}>
                                {FormResetPasswordErrors
                                  ? FormResetPasswordErrors["password"]
                                  : ""}
                              </Span>
                            </Then>
                          </If>
                        </DivBlock>

                        <Button
                          className={"btn btn-primary"}
                          actions={[
                            {
                              action: "Reset Password",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          Reset Password
                        </Button>
                      </FormBlock>
                    </DivBlock>
                  </DivBlock>
                </Column>
              </Columns>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(ResetPasswordPage);
