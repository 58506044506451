import React, { PureComponent } from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
// import _ from "lodash";

import Label from "../Label";

const useStyles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 520,
    maxWidth: 600,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
});

class CustomDropDown extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {
      value: [],
      errorMessage: "",
      hasError: true,
      addIntangibleString: "",
    };
  }

  handleChange = (event, name1, position, updatedPosition) => {
    const checked = event.target.checked;
    const {
      CheckIntangibles,
      AllPositions,
      customCreateIntangible,
      setCustomIntangible,
    } = this.props;

    if (checked) {
      this.setState({ addIntangibleString: "" });
      let subArr = AllPositions.filter((item) => item.name === updatedPosition);

      let secondArr = subArr.map((v) => ({ position_id: v.id, name: name1 }));

      customCreateIntangible(
        {
          inputVariables: "test",
        },
        { listPayload: secondArr }
      );
      this.setState({ addIntangibleString: updatedPosition });
    } else {
      const finalArr = CheckIntangibles?.filter(
        (item) => item.position_name + "_" + item.name === position
      );

      if (finalArr.length > 0) {
        this.setState({ addIntangibleString: "" });

        setCustomIntangible(
          { inputVariables: "test" },
          {
            idd: finalArr[0]?.intangible_id,
            "input delete intangible id": finalArr[0]?.intangible_id,
          }
        );
      }
    }
  };

  render() {
    const {
      IntangibleValueNameMap,
      AllPositions,
      CheckIntangibles,
      AddedIntangibleData,
      SeasonYearData,
      selectedSeasonYear
    } = this.props;

    const Checkbox = ({ value, name, position, updatedPosition, id,disabled }) => {
      return (
        <input
          type="checkbox"
          onChange={(e) => {
            this.handleChange(e, name, position, updatedPosition);
          }}
          id={position}
          name={name}
          value={name}
          disabled={disabled}
          checked={
            CheckIntangibles?.some(
              (data) => position === data.position_name + "_" + data.name
            )
              ? true
              : false
          }
        />
      );
    };

    return (
      <FormControl className={"form-control intangible-column"} style={{paddingLeft:30,paddingTop:30}} error={this.state.hasError}>
        {AllPositions &&
          AllPositions.map((res, pIndex) => (
            
            <div style={{marginBottom:pIndex>=0?30:0}}>
              <Label className={"intangible-label"}>{res.name}</Label>
              {IntangibleValueNameMap &&
                IntangibleValueNameMap.map((data, index) => {
                  return (
                    <div key={index}>
                      <Checkbox
                        name={data.name}
                        value={data.value}
                        position={res.name + "_" + data.name}
                        updatedPosition={res.name}
                        id={res.name + "_" + data.name}
                        disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                      />
                      <Label>{data.name}</Label>
                    </div>
                  );
                })}

              {this.state.addIntangibleString.length > 0 &&
                this.state.addIntangibleString === res.name && (
                  <FormHelperText>
                    {AddedIntangibleData !== "Added Successfully"
                      ? AddedIntangibleData
                      : null}
                  </FormHelperText>
                )}
            </div>
          ))}
      </FormControl>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    customCreateIntangible: (payload, input) => {
      dispatch({ type: "CREATE_INTANGIBLE", payload, inputVariables: input });
      return Promise.resolve();
    },
    
    setCustomIntangible: (payload, input1) => {
      dispatch({
        type: "SET_DELETE_INTANGIBLE",
        payload,
        inputVariables: input1,
      });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};
export default withStyles(useStyles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(CustomDropDown)
);
