import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, DivBlock } from "../modules/Basic";
import { DataSeries, MultipleDataGraph } from "../modules/Graphs";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { Image } from "../modules/Media";
import { SelectDropDown } from "../modules/Forms";
import TextField from "@material-ui/core/TextField";
import { Column } from "../modules/Layout";
import { debounced, startPageTimer, trackPageDuration } from "../utils/function";
import { CircularProgress } from "@material-ui/core";
import { IMAGES } from "../assets";

class ComparePlayersPage extends Component {
  constructor(props) {
    super(props)
    this.state={
      value:'',
      startTime: null,
      searchValue:'',
    }
  }

  componentDidMount(){
    startPageTimer('Leaderboards');
    this.setState({ startTime: Date.now() });
    this.props?.getPlayerCompareData();
  }

  
  componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear){
      dispatch({ type: "LOADING_EVENT",inputVariables:{loader:true} });
      this.props.getPlayerCompareData();
      
      // dispatch({ type: "LOADING_EVENT",inputVariables:{loader:false} });
    }
  }
  

  componentWillUnmount() {
    this.props?.clearDropDownValue();
    trackPageDuration('Leaderboards',this.state.startTime)
  }

  handleChange = (e) =>{
    const { dispatch } = this.props;
    const { value } = e.target;
    this.setState({ searchValue: value, loading: true });
    debounced(() => {
      fetch(`${process.env.REACT_APP_BASE_URL}/comparison_reports?text_search=${value}&year=${this.props?.selectedSeasonYear}&position_id=${this.props?.filterPlayerPositions}&intangible_id=${this.props?.filterPlayerIntangible}`,
        {method:'GET',headers:{ 
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }})
        .then((response) => response.json()
        .then((text) => {
          dispatch({ type: "GET_PLAYER_COMPARE_VALUES",
              inputVariables: { rosterValue:text.data } 
          });
          this.setState({ loading: false });
        })).catch((err)=>{
          this.setState({ loading: false });
          console.log('eer',err)
        });
    }, 500);
  }

  render() {
    
    let {
      Exercises,
      NavBarDropDownIndex,
      PlayerComparisonIntangible,
      Positions,
      ShowUserGraph,
      tablecss,
      CompareSortingTable,
      PlayerComparisonIntangibleCollection,
      PlayerCompare,
      filterPlayerPositions,
      filterPlayerIntangible,
      dispatch,
      isDefaultLoaded
    } = this.props;

    const handlePositionsFilters = () =>{  
      if(Positions&&isArray(Positions)){
        let Allposition = [...Positions];
        Allposition.unshift({abbreviation: "All Positions", id: -1})
       return Allposition.map(data => ({...data,abbreviation : `${data["abbreviation"]}`}))
      }
    }

    const handleIntangibleFilters = () =>{
      if(PlayerComparisonIntangibleCollection && isArray(PlayerComparisonIntangibleCollection)){
        let intangibleData = [{name:'All Intangibles',intangible_id:-1}]
        let AllIntangible =[...intangibleData,...PlayerComparisonIntangibleCollection]
        return AllIntangible;
      }
    }

    const handleSelectedValue = () =>{
      if(filterPlayerPositions === -1){
        let currentPosition = handlePositionsFilters() && handlePositionsFilters()?.find(item => item.id === filterPlayerPositions)
        return currentPosition?.abbreviation;
      }else{
        if(filterPlayerPositions && filterPlayerIntangible){
          let currentPosition = handlePositionsFilters() && handlePositionsFilters()?.find(item => item.id === filterPlayerPositions)
          let currentIntangible = handleIntangibleFilters() && handleIntangibleFilters()?.find(item => item.intangible_id === filterPlayerIntangible )
          let currentData = `${currentPosition?.abbreviation + ' / ' + 
          `${currentIntangible === undefined? 'All Intangibles' :currentIntangible?.name === 'All' ? currentIntangible?.name + ' Intangibles' : currentIntangible?.name}`}`
          return  currentData; 
        }
      }
    }

    const ordinal = (n) => {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return `${n}${suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]}`;
    };


    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Positions",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            // {
            //   action: "Get Exercises",
            //   trigger: "onload",
            //   timeout: "",
            //   pollingPeriod: "",
            //   inputVariables: {},
            // },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 1 : 1,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    backgroundColor: "#f8f8fb",
                    bottom: "0px",
                    display: "inline-block",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"} style={{}}>
                      <DivBlock style={{}}>
                        <DivBlock
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Leaderboards
                          </Heading>

                          <Span className={"h2 h2-span"} style={{}}></Span>
                        </DivBlock>
                      </DivBlock>

                      <DivBlock>
                        <Column
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        xl={9} className={"mb-3 dropdown-container"} style={{}}>
                        <DivBlock className="compare-player-container">
                            <DivBlock className="filterbox-dropdown">
                            <SelectDropDown
                                className={"form-control"}
                                dataSet={handlePositionsFilters()}
                                dataSetLabel={"abbreviation"}
                                dataSetValue={"id"}
                                enableReinitialize={true}
                                initialValue={filterPlayerPositions}
                                name={"filterPlayerPositions"}
                                options={[]}
                                type={"number"}
                                style={{
                                  alignItems:'center',
                                  borderRadius:'5px',
                                  fontSize: '14px',
                                  width: '80px',
                                }}
                                actions={[
                                  {
                                    action: "Player Position Collection",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      searchValue: this.state.value
                                    },
                                  },
                                ]}
                                >
                                
                              </SelectDropDown>
                            </DivBlock>
                            <DivBlock className="filterbox-dropdown">
                            <SelectDropDown
                                className={"form-control"}
                                dataSet={handleIntangibleFilters()}
                                disabled={ filterPlayerPositions === -1 || filterPlayerPositions === undefined || filterPlayerPositions === 0? true :false  }
                                dataSetLabel={"name"}
                                dataSetValue={"intangible_id"}
                                enableReinitialize={true}
                                initialValue={filterPlayerIntangible}
                                name={"filterPlayerIntangible"}
                                options={[]}
                                type={"number"}
                                style={{
                                  alignItems:'center',
                                  borderRadius:'5px',
                                  fontSize: '14px',
                                  width: '80px',
                                }}
                                actions={[
                                  {
                                    action: "Player Intangible Collection",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      searchValue:this.state.value
                                    },
                                  },
                                ]}
                                >
                                
                              </SelectDropDown>
                            </DivBlock>
                          </DivBlock>

                          {/* <DivBlock
                            style={{
                              backgroundColor: "#f8f8fb",
                              borderBottomLeftRadius: "0.25em",
                              borderBottomRightRadius: "0.25em",
                              borderRadius: "0.25em",
                              borderTopLeftRadius: "0.25em",
                              borderTopRightRadius: "0.25em",
                              marginLeft: "0.5px",
                            }}
                            className="filterbox-dropdown"
                          >
                            <DivBlock
                              className={"btn btn-reset remove-value"}
                              style={{marginTop:'0px'}}
                              actions={[
                                {
                                  action: "RemoveValue",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    searchValue:this.state.value
                                  },
                                },
                              ]}
                            >
                              <Span>Reset</Span>
                            </DivBlock>
                          </DivBlock> */}
                          <DivBlock className="roster-input">
                          <TextField
                                id="outlined-search"
                                type="search"
                                size="small"
                                variant="outlined"
                                placeholder="Search Player"
                                autoComplete="off"
                                onChange={(e)=>this.handleChange(e)}
                                value={this.state.searchValue}
                                fullWidth
                                sx={{ marginLeft: "0px" }}
                                InputProps={{
                                  style: {
                                    height: "35px",
                                    width: "250px",
                                  },
                                  startAdornment: this.state.searchValue ? (
                                    this.state.loading ? (
                                      <CircularProgress
                                        size={20}
                                        thickness={3}
                                        style={{
                                          display: "flex",
                                          marginRight: "5px",
                                          color: "#48c46e",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={IMAGES.SearchIcon}
                                        alt="icon"
                                        height={"20px"}
                                        width={"20px"}
                                        style={{ marginRight: "5px" }}
                                      />
                                    )
                                  ) : (
                                    <img
                                      src={IMAGES.SearchIcon}
                                      alt="icon"
                                      height={"20px"}
                                      width={"20px"}
                                      style={{ marginRight: "5px" }}
                                    />
                                  ),
                                }}
                              />
                          </DivBlock>
                        </Column>

                        <DivBlock
                          style={{ marginBottom: "10px", paddingBottom: "0px" }}
                        >
                          <Span
                            style={{
                              color: "#404757",
                              fontFamily:"'Poppins', sans-serif",
                              fontSize: "15px",
                              fontWeight: "400",
                              paddingRight: "10px",
                            }}
                          >
                            {handleSelectedValue()}
                          </Span>
                        </DivBlock>
                        {isDefaultLoaded ? null :
                        <DivBlock className="table-responsive-sm">    
                        {PlayerCompare && 
                        isArray(PlayerCompare) && 
                        PlayerCompare?.length > 0 ? 
                        <table className={tablecss} style={{}}>
                          <thead>
                            <tr className={"thead-bg"} style={{}}>
                            <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}/>
                              </th>
                              <th className={"th-border"} style={{}}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  style={{}}
                                  actions={[
                                    {
                                      action: "TOGGLECOMPARESORTING",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        inputCompare: {
                                          ToggleSorting:
                                            CompareSortingTable !== true,
                                          sortingField: "position",
                                        },
                                      },
                                    },
                                  ]}
                                >
                                  Position
                                </Span>
                              </th>

                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action: "TOGGLECOMPARESORTING",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        inputCompare: {
                                          ToggleSorting:
                                            CompareSortingTable !== true,
                                          sortingField: "name",
                                        },
                                      },
                                    },
                                  ]}
                                >
                                  Name
                                </Span>
                              </th>

                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action:
                                        "TOGGLECOMPARESORTINGNUMBER",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        inputCompare: {
                                          ToggleSorting:
                                            CompareSortingTable !== true,
                                          sortingField: "avg_game_grade",
                                        },
                                      },
                                    },
                                  ]}
                                >
                                  Game AVG
                                </Span>
                              </th>

                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action:
                                        "TOGGLECOMPARESORTINGNUMBER",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        inputCompare: {
                                          ToggleSorting:
                                            CompareSortingTable !== true,
                                          sortingField: "avg_practice_grade",
                                        },
                                      },
                                    },
                                  ]}
                                >
                                  Practice AVG
                                </Span>
                              </th>
                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action:
                                        "TOGGLECOMPARESORTINGRANK",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        inputCompare: {
                                          ToggleSorting:
                                            CompareSortingTable !== true,
                                          sortingField: "student_school_rank",
                                        },
                                      },
                                    },
                                  ]}
                                >
                                  School Rank
                                </Span>
                              </th>
                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action:
                                        "TOGGLECOMPARESORTINGRANK",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        inputCompare: {
                                          ToggleSorting:
                                            CompareSortingTable !== true,
                                          sortingField: "student_coachtool_rank",
                                        },
                                      },
                                    },
                                  ]}
                                >
                                  CoachTools Rank
                                </Span>
                              </th>

                              {/* <th className={"th-border"}>
                                <Span
                                >Actions</Span>
                              </th> */}
                            </tr>
                          </thead>

                          <tbody style={{ overflow: "visible" }}>
                          {PlayerCompare && 
                          isArray(PlayerCompare) && 
                          PlayerCompare?.map((row,index,)=>{
                          return (
                              <Fragment key={index} >
                                <tr className={""}>
                                <td className="common-table-data"
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}>
                                  <Image src={process.env.REACT_APP_PROFILE + row?.profile_image?.thumbnail}
                                    style={{
                                      height: '35px',
                                      width: '35px',
                                      borderRadius:'50%'
                                    }}
                                  />
                                  </td>
                                  <td className="common-table-data">
                                    <Span
                                      className={"a a-link"}
                                      style={{cursor:'text'}}
                                      // actions={[
                                      //   {
                                      //     action: "Get Grades Graph",
                                      //     trigger: "onclick",
                                      //     timeout: "",
                                      //     pollingPeriod: "",
                                      //     inputVariables: {
                                      //       "position id input":
                                      //         row["position_id"],
                                      //       "intangible id input":
                                      //         PlayerComparisonIntangible,
                                      //     },
                                      //   },
                                      // ]}
                                    >
                                      {row["position"]}
                                    </Span>
                                  </td>

                                  <td className="common-table-data">
                                    <Span
                                      className={"table-span"}
                                      style={{}}
                                    >
                                      {row["name"]}
                                    </Span>
                                  </td>

                                  <td className="common-table-data">
                                    <Span className={"table-span"}>
                                      {row["avg_game_grade"] ? row["avg_game_grade"] : 'None' }
                                    </Span>
                                  </td>

                                  <td className="common-table-data">
                                    <Span className={"table-span"}>
                                      {row["avg_practice_grade"] ? row["avg_practice_grade"] : 'None'}
                                    </Span>
                                  </td>
                                  
                                  <td className="common-table-data">
                                    <Span className={"table-span"}>
                                      {row?.student_school_rank?.ranking ? 
                                      ordinal(row?.student_school_rank?.ranking?.rank) + '/' + row?.student_school_rank?.ranking?.total_players  
                                      : 'None'}
                                    </Span>
                                  </td>

                                  <td className="common-table-data">
                                    <Span className={"table-span"}>
                                    {row?.student_coachtool_rank?.ranking ? 
                                      ordinal(row?.student_coachtool_rank?.ranking?.rank) + '/' + row?.student_coachtool_rank?.ranking?.total_players  
                                      : 'None'}
                                    </Span>
                                  </td>
                                </tr>
                              </Fragment>
                            );
                          })}
                          </tbody>
                        </table> : 
                        <Span className={"table-span"} style={{fontSize:"20px"}}>
                        No data found</Span>}
                        </DivBlock>}
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>

                  <If condition={ShowUserGraph > 0 ? true : false} show={false}>
                    <Then>
                      <DivBlock
                        className={"modal"}
                        actions={[
                          {
                            action: "Set New Position",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { "input new position": false },
                          },
                          {
                            action: "Edit Position",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { "input position": null },
                          },
                        ]}
                      >
                        <DivBlock className={"modal-dialog large"}>
                          <DivBlock className={"modal-content"}>
                            <DivBlock className={"modal-header"}>
                              <Heading
                                className="modal-heading"
                                type={"h5"}
                              >
                                Graph
                              </Heading>

                              <Span
                                className={"close"}
                                actions={[
                                  {
                                    action: "Hide User Graph",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                x
                              </Span>
                            </DivBlock>

                            <DivBlock className={"modal-body"}>
                              <DivBlock
                                style={{
                                  borderBottomColor: "#dddddd",
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "1px",
                                  borderColor: "#dddddd",
                                  borderLeftColor: "#dddddd",
                                  borderLeftStyle: "solid",
                                  borderLeftWidth: "1px",
                                  borderRightColor: "#dddddd",
                                  borderRightStyle: "solid",
                                  borderRightWidth: "1px",
                                  borderStyle: "solid",
                                  borderTopColor: "#dddddd",
                                  borderTopStyle: "solid",
                                  borderTopWidth: "1px",
                                  borderWidth: "1px",
                                  marginTop: "0px",
                                  paddingTop: "10px",
                                }}
                              >
                                <DivBlock
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                  }}
                                >
                                  {Exercises &&
                                    isArray(Exercises) &&
                                    Exercises.map((row, index) => {
                                      return (
                                        <Fragment>
                                          <DivBlock>
                                            <DivBlock
                                              style={{
                                                alignItems: "center",
                                                borderBottomColor: "#dddddd",
                                                borderBottomStyle: "solid",
                                                borderBottomWidth: "1px",
                                                borderColor: "#dddddd",
                                                borderLeftColor: "#dddddd",
                                                borderLeftStyle: "solid",
                                                borderLeftWidth: "1px",
                                                borderRightColor: "#dddddd",
                                                borderRightStyle: "solid",
                                                borderRightWidth: "1px",
                                                borderStyle: "solid",
                                                borderTopColor: "#dddddd",
                                                borderTopStyle: "solid",
                                                borderTopWidth: "1px",
                                                borderWidth: "1px",
                                                display: "flex",
                                                fontFamily:"'Poppins', sans-serif",
                                                marginLeft: "5px",
                                                paddingBottom: "5px",
                                                paddingLeft: "5px",
                                                paddingRight: "5px",
                                                paddingTop: "5px",
                                              }}
                                            >
                                              <Span>{row["name"]}</Span>

                                              <Span
                                                className={"key " + row.color}
                                                style={{}}
                                              >
                                                s
                                              </Span>
                                            </DivBlock>
                                          </DivBlock>
                                        </Fragment>
                                      );
                                    })}
                                </DivBlock>

                                <MultipleDataGraph
                                  alignChartTitle={"center"}
                                  chartTitle={"Chart title here"}
                                  chartTitleFontFamily={
                                    "Helvetica Neue, Helvetica, Arial, sans-serif"
                                  }
                                  chartTitleFontSize={30}
                                  dataSet={"ExerciseData"}
                                  dataSetX={"date"}
                                  fill={"#35b3ee"}
                                  showChartTitle={false}
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    fontFamily:"'Poppins', sans-serif",
                                    height: "250px",
                                    justifyContent: "center",
                                  }}
                                  xAxisAt={"bottom"}
                                  xAxisLabel={"Date"}
                                  xClassName={"react-stockcharts-x-axis"}
                                  xFill={"none"}
                                  xFontFamily={
                                    "Helvetica Neue, Helvetica, Arial, sans-serif"
                                  }
                                  xFontSize={12}
                                  xFontWeight={400}
                                  xInnerTickSize={5}
                                  xOpacity={1}
                                  xOrient={"bottom"}
                                  xOuterTickSize={0}
                                  xShowDomain={true}
                                  xShowGrid={true}
                                  xShowTickLabel={true}
                                  xShowTicks={true}
                                  xStroke={"#000000"}
                                  xStrokeWidth={1}
                                  xTickLabelColor={"black"}
                                  xTickPadding={6}
                                  xTickStroke={"#000000"}
                                  xTickStrokeOpacity={1}
                                  xTicks={5}
                                  xZoomHeight={25}
                                  yAxisAt={"left"}
                                  yAxisLabel={"Average Input"}
                                  yClassName={"react-stockcharts-y-axis"}
                                  yFill={"none"}
                                  yFontFamily={
                                    "Helvetica Neue, Helvetica, Arial, sans-serif"
                                  }
                                  yFontSize={12}
                                  yFontWeight={400}
                                  yInnerTickSize={2}
                                  yOpacity={1}
                                  yOrient={"left"}
                                  yOuterTickSize={0}
                                  yShowDomain={true}
                                  yShowGrid={true}
                                  yShowTickLabel={true}
                                  yShowTicks={true}
                                  yStroke={"#000000"}
                                  yStrokeWidth={1}
                                  yTickLabelColor={"black"}
                                  yTickPadding={1}
                                  yTickStroke={"#000000"}
                                  yTickStrokeOpacity={1}
                                  yTicks={10}
                                  yZoomWidth={25}
                                >
                                  {Exercises &&
                                    isArray(Exercises) &&
                                    Exercises.map((row, index) => {
                                      return (
                                        <Fragment>
                                          <DataSeries
                                            dataSetY={`${row["Id"]}`}
                                            fill={row.color}
                                            type={"line"}
                                          ></DataSeries>
                                        </Fragment>
                                      );
                                    })}
                                </MultipleDataGraph>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearDropDownValue: () => {
      dispatch({
        type: "CLEAR_COMPARE_VALUE",
      });
      return Promise.resolve();
    },
    getIntagibleData: () => {
      dispatch({ type: "GET_INTANGIBLES" });
      return Promise.resolve();
    },
    getExcercisesData: () => {
      dispatch({ type: "GET_EXERCISES" });
      return Promise.resolve();
    },
    getPlayerCompareData: () => {
      dispatch({ type: "GET_PLAYER_USERS" ,inputVariables: { 
        'intangible id input' :-1,
        'position id input' : -1,
        'rosterValue':'',
        isDefault: true
      }, });
      return Promise.resolve();
    },
    dispatch: dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparePlayersPage);
