import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock } from "../modules/Basic";
import { FormBlock, Label } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Span, Paragraph } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { CustomDropDown } from "../modules/Forms";
import { startPageTimer, trackPageDuration } from "../utils/function";


class SubmitIntangiblesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownValue: [],
      hasError: false,
      errorMessage: "",
      dropDownValueOuter: [],
      startTime: null,
    };
  }
  
  componentDidMount(){
    startPageTimer('Intangibles');
    this.setState({ startTime: Date.now() });
  }
    
  componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear){
      dispatch({ type: "LOADING_EVENT",inputVariables:{loader:true} });
      this.props.getIntagibleData();
      
      // dispatch({ type: "LOADING_EVENT",inputVariables:{loader:false} });
    }
  }
  
  componentWillUnmount(){
    trackPageDuration('Intangibles',this.state.startTime)
  }
  render() {
    let {
      CurrentDepthChart,
      IntangibleToDelete,
      Intangibles,
      NavBarDropDownIndex,
      Positions,
      IntangibleValueName,
    } = this.props;
    let generateArr = IntangibleValueName.map((data, index) => {
      if (data?.positionName?.length) return data.positionName?.split(",");
    });
    let totalLength = 0;
    generateArr?.map((res) => {
      if (res !== undefined) {
        totalLength = res?.length + totalLength;
      }
    });

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Intangibles",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 2 : 2,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    backgroundColor: "#f8f8fb",
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                  actions={[
                    {
                      action: "Get Intangibles",
                      trigger: "onload",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {},
                    },
                    {
                      action: "Get Positions",
                      trigger: "onload",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {},
                    },
                  ]}
                >
                  <DivBlock style={{ height: "100%" }}>
                    <DivBlock className={"body-main"}>
                      <DivBlock style={{}}>
                        <DivBlock
                          className="game_title"
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Intangibles
                          </Heading>

                          <Span className={"h2 h2-span"} style={{}}>
                            Grading /
                          </Span>

                          <Span className={"h2 h2-inner-span"} style={{}}>
                            Intangibles
                          </Span>
                        </DivBlock>
                        <Columns style={{ "backgroundColor": "#ffffff", "fontSize": "0px", "marginBottom": "10px" }} >

                          <Column lg={12} md={12} sm={12} style={{ fontFamily:"'Poppins', sans-serif", "marginBottom": "1rem", "marginLeft": "10px", "marginRight": "0px", "marginTop": "15px", "paddingBottom": "0px", "paddingTop": "0px" }} xs={12} >

                            <DivBlock style={{ "alignItems": "center", "display": "flex", "fontSize": "13px", "justifyContent": "flex-start" }} >

                              <Paragraph style={{ "fontSize": "14px", "marginBottom": 0, "marginLeft": 0, "marginRight": "5px", "marginTop": 0 }} >
                                Click the checkbox to assign an intangible to a position group. These will be used for purposes of grading players on a play-by-play basis. You can assign up to 7 intangibles per position group. Removing a previously assigned intangible will remove any past grades entered for that intangible.
                              </Paragraph>
                            </DivBlock>
                          </Column>
                        </Columns>
                        {IntangibleValueName && (
                          <CustomDropDown
                            IntangibleValueNameMap={IntangibleValueName}
                            AllPositions={Positions}
                            CheckIntangibles={Intangibles}
                          />
                        )}
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
            <If condition={IntangibleToDelete ? true : false} show={true}>
              <Then>
                <DivBlock
                  className={"modal"}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Delete Intangible
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Delete Intangible",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  idd: null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Are you sure you want to delete this intangible?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Set Delete Intangible",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      idd: null,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Delete Intangible",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "input delete intangible id":
                                        IntangibleToDelete,
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getIntagibleData: () => {
      dispatch({ type: "GET_INTANGIBLES" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitIntangiblesPage);
