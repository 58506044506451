import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock } from "../modules/Basic";
import { DatePicker } from "../modules/Components";
import { FormBlock, Label, SelectDropDown, TextInput } from "../modules/Forms";
import { DataSeries, MultipleDataGraph } from "../modules/Graphs";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import PlayerSideNavComponent from "../components/PlayerSideNavComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { handleDate, startPageTimer, trackPageDuration } from "../utils/function";

class PlayerExercisesPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      startTime: null,
    };
  }

  componentDidMount(){
    startPageTimer('Player Training');
    this.setState({ startTime: Date.now() })
  }

  componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear){
      dispatch({ type: "LOADING_EVENT",inputVariables:{loader:true} });
        this.props.getExcercisesData();
        this.props?.getUserExcercisesData();
    }
  }

  componentWillUnmount(){
    trackPageDuration('Player Training',this.state.startTime)
  }

  render() {
    let {
      CurrentDepthChart,
      CurrentUser,
      EditUserExercise,
      Exercises,
      FormCreateUserExercise,
      FormCreateUserExerciseErrors,
      FormEditUserExercise,
      FormEditUserExerciseErrors,
      NewUserExercise,
      PlayerExerciseToDelete,
      UserExercises,
      SeasonYearData,
      selectedSeasonYear
    } = this.props;
    const { FormatDate } = window;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get User Exercises",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Exercises",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Exercises Graph User",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "userid input": CurrentUser ? CurrentUser?.id : 0,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              data-snippet-id={297}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <PlayerSideNavComponent
                data-snippet-id={302}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></PlayerSideNavComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <Heading className={"h2"} style={{}} type={"h2"}>
                        Training
                      </Heading>

                      <Columns>
                        <Column
                          lg={12}
                          md={12}
                          sm={12}
                          style={{ marginBottom: "1rem" }}
                          xs={12}
                        >
                          <DivBlock
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className={"btn btn-primary"}
                              style={
                                SeasonYearData?.[0]?.year === selectedSeasonYear? 
                                {backgroundColor: "#48c46e",
                                borderColor: "#48c46e"}:
                                {backgroundColor: "#a0d2a0",
                                borderColor: "#a0d2a0",}
                              }
                              disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                              actions={[
                                {
                                  action: "New User Exercise",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "input new exercise": true,
                                    "input new injured": true,
                                    "input new intangible": true,
                                    "toggle new user exercise": true,
                                  },
                                },
                              ]}
                            >
                              Record Training
                            </Button>
                          </DivBlock>
                        </Column>
                      </Columns>

                      <DivBlock
                        style={{ maxHeight: "275px", overflow: "auto" }}
                      >
                        <table className={"table redTable"}>
                          <thead>
                            <tr className={"thead-bg"}>
                              <th className={"th-border"}>
                                <Span className={"th-span"}>Date</Span>
                              </th>

                              <th className={"th-border"}>
                                <Span className={"th-span"}>Name</Span>
                              </th>

                              <th className={"th-border"}>
                                <Span className={"th-span"}>Value</Span>
                              </th>
                              {SeasonYearData?.[0]?.year === selectedSeasonYear ?
                              <th className={"th-border"}>
                                <Span className={"th-span"}>Action</Span>
                              </th> : null}
                            </tr>
                          </thead>

                          <tbody style={{ display: "table-row-group" }}>
                            {UserExercises &&
                              isArray(UserExercises) &&
                              UserExercises.map((row, index) => {
                                return (
                                  <Fragment>
                                    <tr style={{ display: "table-row" }}>
                                      <td className={"td-padding"} style={{}}>
                                        <Span className={"table-span"}>
                                          {handleDate(row["date"])}
                                        </Span>
                                      </td>

                                      <td className={"td-padding"}>
                                        <Span className={"table-span"}>
                                          {row["name"]}
                                        </Span>
                                      </td>

                                      <td className={"td-padding"}>
                                        <Span className={"table-span"}>
                                          {row["integer_value"]}
                                        </Span>
                                      </td>
                                      {SeasonYearData?.[0]?.year === selectedSeasonYear ?
                                      <td
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <Span
                                          className={"btn table-span"}
                                          actions={[
                                            {
                                              action: "Edit User Exercise",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "user exercise": row,
                                              },
                                            },
                                          ]}
                                        >
                                          Update
                                        </Span>

                                        <Span
                                          className={"btn table-span"}
                                          actions={[
                                            {
                                              action:
                                                "Set Delete Player Exercise",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "player exercise id": row["id"],
                                                "user exercise id": row["id"],
                                              },
                                            },
                                          ]}
                                        >
                                          Delete
                                        </Span>
                                      </td> : null}
                                    </tr>
                                  </Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                      </DivBlock>

                      <DivBlock
                        style={{
                          borderBottomColor: "#dddddd",
                          borderBottomStyle: "solid",
                          borderBottomWidth: "1px",
                          borderColor: "#dddddd",
                          borderLeftColor: "#dddddd",
                          borderLeftStyle: "solid",
                          borderLeftWidth: "1px",
                          borderRightColor: "#dddddd",
                          borderRightStyle: "solid",
                          borderRightWidth: "1px",
                          borderStyle: "solid",
                          borderTopColor: "#dddddd",
                          borderTopStyle: "solid",
                          borderTopWidth: "1px",
                          borderWidth: "1px",
                          marginTop: "20px",
                          paddingTop: "10px",
                        }}
                      >
                        <DivBlock
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          {Exercises &&
                            isArray(Exercises) &&
                            Exercises.map((row, index) => {
                              return (
                                <Fragment>
                                  <DivBlock>
                                    <DivBlock
                                      style={{
                                        alignItems: "center",
                                        borderBottomColor: "#dddddd",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1px",
                                        borderColor: "#dddddd",
                                        borderLeftColor: "#dddddd",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1px",
                                        borderRightColor: "#dddddd",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1px",
                                        borderStyle: "solid",
                                        borderTopColor: "#dddddd",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1px",
                                        borderWidth: "1px",
                                        display: "flex",
                                        fontFamily:"'Poppins', sans-serif",
                                        marginLeft: "5px",
                                        paddingBottom: "5px",
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      <Span>{row["name"]}</Span>

                                      <Span
                                        className={"key " + row.color}
                                        style={{}}
                                      >
                                        s
                                      </Span>
                                    </DivBlock>
                                  </DivBlock>
                                </Fragment>
                              );
                            })}
                        </DivBlock>

                        <MultipleDataGraph
                          alignChartTitle={"center"}
                          chartTitle={"Chart title here"}
                          chartTitleFontFamily={
                            "Helvetica Neue, Helvetica, Arial, sans-serif"
                          }
                          chartTitleFontSize={30}
                          dataSet={"ExerciseData"}
                          dataSetX={"date"}
                          fill={"#35b3ee"}
                          showChartTitle={false}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            fontFamily:"'Poppins', sans-serif",
                            height: "250px",
                            justifyContent: "center",
                          }}
                          xAxisAt={"bottom"}
                          xAxisLabel={"Date"}
                          xClassName={"react-stockcharts-x-axis"}
                          xFill={"none"}
                          xFontFamily={
                            "Helvetica Neue, Helvetica, Arial, sans-serif"
                          }
                          xFontSize={12}
                          xFontWeight={400}
                          xInnerTickSize={5}
                          xOpacity={1}
                          xOrient={"bottom"}
                          xOuterTickSize={0}
                          xShowDomain={true}
                          xShowGrid={true}
                          xShowTickLabel={true}
                          xShowTicks={true}
                          xStroke={"#000000"}
                          xStrokeWidth={1}
                          xTickLabelColor={"black"}
                          xTickPadding={6}
                          xTickStroke={"#000000"}
                          xTickStrokeOpacity={1}
                          xTicks={5}
                          xZoomHeight={25}
                          yAxisAt={"left"}
                          yAxisLabel={"Average Input"}
                          yClassName={"react-stockcharts-y-axis"}
                          yFill={"none"}
                          yFontFamily={
                            "Helvetica Neue, Helvetica, Arial, sans-serif"
                          }
                          yFontSize={12}
                          yFontWeight={400}
                          yInnerTickSize={2}
                          yOpacity={1}
                          yOrient={"left"}
                          yOuterTickSize={0}
                          yShowDomain={true}
                          yShowGrid={true}
                          yShowTickLabel={true}
                          yShowTicks={true}
                          yStroke={"#000000"}
                          yStrokeWidth={1}
                          yTickLabelColor={"black"}
                          yTickPadding={2}
                          yTickStroke={"#000000"}
                          yTickStrokeOpacity={1}
                          yTicks={10}
                          yZoomWidth={25}
                        >
                          {Exercises &&
                            isArray(Exercises) &&
                            Exercises.map((row, index) => {
                              return (
                                <Fragment>
                                  <DataSeries
                                    dataSetY={`${row["Id"]}`}
                                    fill={row.color}
                                    type={"line"}
                                  ></DataSeries>
                                </Fragment>
                              );
                            })}
                        </MultipleDataGraph>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>

          <If condition={NewUserExercise ? true : false} show={false}>
            <Then>
              <DivBlock
                className={"modal"}
                actions={[
                  {
                    action: "Set New Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input new position": false },
                  },
                  {
                    action: "Edit Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input position": null },
                  },
                ]}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Record Exercise
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "New User Exercise",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "user exercise": null,
                                "toggle new user exercise": false,
                              },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          actions={[
                            {
                              action: "Create User",
                              trigger: "onsubmit",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Exercise</Label>

                            <SelectDropDown
                              className={"form-control"}
                              dataSet={Exercises}
                              dataSetLabel={"name"}
                              dataSetValue={"Id"}
                              initialValue={
                                Exercises && Exercises.length > 0
                                  ? Exercises[0].Id
                                  : 0
                              }
                              name={"FormCreateUserExercise.exercise id"}
                              options={[
                                { label: "Option 1", value: "option_1" },
                              ]}
                              type={"number"}
                            ></SelectDropDown>

                            <If
                              condition={
                                FormCreateUserExerciseErrors &&
                                FormCreateUserExerciseErrors["exercise id"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormCreateUserExerciseErrors
                                    ? FormCreateUserExerciseErrors[
                                        "exercise id"
                                      ]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Date</Label>

                            <DivBlock>
                              <DatePicker
                                className={"form-control"}
                                name={"FormCreateUserExercise.date"}
                              ></DatePicker>
                            </DivBlock>

                            <If
                              condition={
                                FormCreateUserExerciseErrors &&
                                FormCreateUserExerciseErrors["date"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormCreateUserExerciseErrors
                                    ? FormCreateUserExerciseErrors["date"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Stat</Label>

                            <TextInput
                              className={"form-control"}
                              initialValue={0}
                              name={"FormCreateUserExercise.integer value"}
                              placeholder={""}
                              type={"decimal"}
                            ></TextInput>

                            <If
                              condition={
                                FormCreateUserExerciseErrors &&
                                FormCreateUserExerciseErrors["integer_value"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormCreateUserExerciseErrors
                                    ? FormCreateUserExerciseErrors[
                                        "integer value"
                                      ]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <Button
                            className={"btn btn-primary"}
                            actions={[
                              {
                                action: "Create User Exercise",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input date": FormCreateUserExercise
                                    ? FormatDate(FormCreateUserExercise["date"])
                                    : "",
                                },
                              },
                            ]}
                          >
                            Submit
                          </Button>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>

          <If
            condition={
              EditUserExercise && EditUserExercise["id"] > 0 ? true : false
            }
            show={false}
          >
            <Then>
              <DivBlock
                className={"modal"}
                actions={[
                  {
                    action: "Set New Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input new position": false },
                  },
                  {
                    action: "Edit Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input position": null },
                  },
                ]}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Edit Exercise
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Edit User Exercise",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: { "user exercise": null },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          actions={[
                            {
                              action: "Create User",
                              trigger: "onsubmit",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Exercise</Label>

                            <SelectDropDown
                              className={"form-control"}
                              dataSet={Exercises}
                              dataSetLabel={"name"}
                              dataSetValue={"Id"}
                              enableReinitialize={false}
                              initialValue={
                                Exercises && Exercises.length > 0
                                  ? Exercises[0].Id
                                  : 0
                              }
                              name={"FormEditUserExercise.exercise id"}
                              options={[]}
                              type={"number"}
                            ></SelectDropDown>

                            <If
                              condition={
                                FormEditUserExerciseErrors &&
                                FormEditUserExerciseErrors["exercise id"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormEditUserExerciseErrors
                                    ? FormEditUserExerciseErrors["exercise id"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Date</Label>

                            <DivBlock>
                              <DatePicker
                                className={"form-control"}
                                name={"FormEditUserExercise.date"}
                              ></DatePicker>
                            </DivBlock>

                            <If
                              condition={
                                FormEditUserExerciseErrors &&
                                FormEditUserExerciseErrors["date"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormEditUserExerciseErrors
                                    ? FormEditUserExerciseErrors["date"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Stat</Label>

                            <TextInput
                              className={"form-control"}
                              initialValue={
                                EditUserExercise
                                  ? EditUserExercise["integer_value"]
                                  : 0
                              }
                              name={"FormEditUserExercise.integer value"}
                              placeholder={""}
                              type={"decimal"}
                            ></TextInput>

                            <If
                              condition={
                                FormEditUserExerciseErrors &&
                                FormEditUserExerciseErrors["integer value"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormEditUserExerciseErrors
                                    ? FormEditUserExerciseErrors[
                                        "integer value"
                                      ]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <Button
                            className={"btn btn-primary"}
                            actions={[
                              {
                                action: "Update User Exercise",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "date input string": FormEditUserExercise
                                    ? FormatDate(FormEditUserExercise["date"])
                                    : "",
                                },
                              },
                            ]}
                          >
                            Submit
                          </Button>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>

          <If condition={PlayerExerciseToDelete ? true : false} show={true}>
            <Then>
              <DivBlock
                className={"modal"}
                actions={[
                  {
                    action: "Set New Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input new position": false },
                  },
                  {
                    action: "Edit Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input position": null },
                  },
                ]}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Delete Exercise
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Set Delete Player Exercise",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "Depth Chart Id": null,
                                "alias id input": null,
                                "input edit depth chart": null,
                                "input new row": false,
                                "input position": null,
                                "player exercise id": null,
                              },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          actions={[
                            {
                              action: "Create Depth Chart",
                              trigger: "onsubmit",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          <DivBlock>
                            <Label className={"label"}>
                              Are you sure you want to delete this exercise?
                            </Label>
                          </DivBlock>

                          <DivBlock
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className={"btn btn-reset DeleteBtn"}
                              actions={[
                                {
                                  action: "Set Delete Player Exercise",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "Depth Chart Id": null,
                                    "player exercise id": null,
                                  },
                                },
                              ]}
                            >
                              Cancel
                            </Button>

                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Delete User Exercise",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "user exercise id": PlayerExerciseToDelete,
                                    "depth chart id to delete":
                                      CurrentDepthChart
                                        ? CurrentDepthChart["Id"]
                                        : "",
                                  },
                                },
                              ]}
                            >
                              Yes
                            </Button>
                          </DivBlock>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getExcercisesData: () => {
      dispatch({ type: "GET_EXERCISES" });
      return Promise.resolve();
    },
    getUserExcercisesData: () => {
      dispatch({ type: "GET_USER_EXERCISES" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerExercisesPage);
