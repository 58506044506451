import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { checkForOnClick, onClickTrigger, onLoadTrigger } from "../../../helpers";
import { connect } from "react-redux";

class Paragraph extends PureComponent { // eslint-disable-line react/prefer-stateless-function
    componentWillMount() {
        const { actions, dispatch } = this.props;
        onLoadTrigger(actions, dispatch);
    }

    onClick = (e) => {
        const { actions, dispatch } = this.props;
        checkForOnClick(actions, dispatch) && onClickTrigger(actions, dispatch);
    }

    render() {
        const { className, style, id, isEdit, inline, children } = this.props;
        return (
            <p {...inline} {...{ className, style, id }} onClick={!isEdit ? this.onClick : null} ref={inline ? inline?.innerRef : null}>
                {children}
            </p>
        );
    }
}

Paragraph.propTypes = {
    style: PropTypes.object,
};
Paragraph.defaultProps = {
    style: {},
};

export default connect()(Paragraph);
