import React, { PureComponent } from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Label from "../Label";

const useStyles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 520,
    maxWidth: 600,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
});

class CustomProductionPoint extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // hasError: true,
      addProductionString: "",
    };
  }

  handleChange = (
    event,
    name1,
    position,
    updatedPosition,
    productionPointId
  ) => {
    this.setState({ addProductionString: "" });
    const checked = event.target.checked;
    const {
      AllPositions,
      customCreateProductionPoint,
      setCustomProudctionPoint,
    } = this.props;
    let subArr = AllPositions.filter((item) => item.name === updatedPosition);

    let secondArr = subArr.map((v) => ({
      position_id: v.id,
      production_id: productionPointId,
    }));
    if (checked) {
      this.setState({ addProductionString: "" });
      customCreateProductionPoint(
        {
          inputVariables: "test",
        },
        { listPayload: secondArr }
      );
      this.setState({ addProductionString: updatedPosition });
    } else {
      this.setState({ addProductionString: "" });
      setCustomProudctionPoint({ productionData: secondArr });
      this.setState({ addProductionString: updatedPosition });
    }
  };

  render() {
    const {
      ProudctionPointValueMap,
      AllPositions,
      CheckProduction,
      AddedProductionData,
      SeasonYearData,
      selectedSeasonYear,
    } = this.props;
    const Checkbox = ({
      value,
      name,
      position,
      updatedPosition,
      id,
      disabled,
      productionPointId,
    }) => {
      return (
        <input
          type="checkbox"
          onChange={(e) => {
            this.handleChange(
              e,
              name,
              position,
              updatedPosition,
              productionPointId
            );
          }}
          id={position}
          name={name}
          value={name}
          disabled={disabled}
          checked={
            CheckProduction?.some(
              (data) =>
                position ===
                data?.position?.name + "_" + data?.production_point?.name
            )
              ? true
              : false
          }
        />
      );
    };

    return (
      <FormControl
        className={"form-control intangible-column"}
        style={{ paddingLeft: 30, paddingTop: 30 }}
        error={this.state.hasError}
      >
        {AllPositions &&
          AllPositions.map((res, pIndex) => (
            <div style={{ marginBottom: pIndex >= 0 ? 30 : 0 }}>
              <Label className={"intangible-label"}>{res.name}</Label>
              {ProudctionPointValueMap &&
                ProudctionPointValueMap.map((data, index) => {
                  return (
                    <div key={index}>
                      <Checkbox
                        name={data.name}
                        productionPointId={data?.id}
                        value={data.value}
                        position={res.name + "_" + data.name}
                        updatedPosition={res.name}
                        id={res.name + "_" + data.name}
                        disabled={
                          SeasonYearData?.[0]?.year === selectedSeasonYear
                            ? false
                            : true
                        }
                      />
                      <Label>{data.name}</Label>
                    </div>
                  );
                })}
              {this.state.addProductionString.length > 0 &&
                this.state.addProductionString === res.name && (
                  <FormHelperText
                    style={{
                      color:
                        AddedProductionData === "Added Successfully"
                          ? "#48c46e"
                          : "red",
                    }}
                  >
                    {AddedProductionData ? AddedProductionData : ""}
                  </FormHelperText>
                )}
            </div>
          ))}
      </FormControl>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    customCreateProductionPoint: (payload, input) => {
      dispatch({
        type: "ADD_SCHOOL_PRODUCTION_POINT",
        payload,
        inputVariables: input,
      });
      return Promise.resolve();
    },

    setCustomProudctionPoint: (value) => {
      dispatch({
        type: "SET_DELETE_PRODUCTION_POINT",
        inputVariables: value,
      });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};
export default withStyles(useStyles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(CustomProductionPoint)
);
