import { makeStyles } from "@material-ui/core";
import { IMAGES } from "../../../../assets";

const useStyles = makeStyles((theme) => ({
  icon: {
    display: "flex",
    height: "20px",
    width: "20px",
  },
  Down: {
    display: "flex",
    height: "20px",
    width: "20px",
    transform: "rotate(-90deg)",
  },

}));

const RightIcon = ({ expand, ...props }) => {
  const classes = useStyles();
  return (
    <img
      src={IMAGES.RightIcon}
      className={expand ? classes.Down : classes.icon}
      alt="icon"
      height={"20px"}
      width={"20px"}
    />
  );
};

export default RightIcon;
