import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock } from "../modules/Basic";
import { DatePicker } from "../modules/Components";
import {
  CustomAutoComplete,
  CustomTabbing,
  FileUpload,
  FormBlock,
  HudlVideoUpload,
  Label,
  SelectDropDown,
  TextInput,
  VideoBox,
} from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { Else, If, Then } from "../modules/Logic";
import { Heading, Paragraph, Span, TextLink } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { Image } from "../modules/Media";
import Checkbox from "../modules/Forms/components/CheckBox";
import { play_grading_table_columns } from "../utils/constant";
import { GetScaleTypeValue, capitalize, debounced, handleDate, handleFilter, startPageTimer, trackPageDuration } from "../utils/function";
import CustomCheckbox from "../modules/customCheckbox";
import $ from 'jquery';
import CustomSelectDropDown from "../modules/Forms/components/CustomSelectDropDown";
import { withRouter } from 'react-router-dom';
import Loader from "../modules/Loader";
import { CircularProgress, FormHelperText } from "@material-ui/core";
import { deleteCurrentProductionData } from "../redux/actions";

var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

class PlayByPlayGradingPage extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props?.location?.search);
    this.draggableElementRef = React.createRef();
    this.state = {
      tabValue: "",
      toggleClear:false,
      gradingPlayData:[],
      gradeCheckAll:false,
      selectedGradeDataArray:[],
      openCustomize: false,
      defaultCheckbox: {},
      checkboxData:{},
      tableHeaderData:[],
      setDragOver:'',
      clonePackages:[],
      productionPointDataArray:[],
      myParam:Object.fromEntries(params.entries()),
      startTime: null,
      productionPointMessage:'',
      videoValue:'',
    };
  }
  
  async componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: "GET_PLAY_BY_PLAY_GAME_LOADER",inputVariables:{loader:true} }); 
    startPageTimer('Play By Play Grading');
    this.setState({ startTime: Date.now() });
    try {
      await this.fetchData();
    } catch (error) {
      dispatch({ type: "GET_PLAY_BY_PLAY_GAME_LOADER",inputVariables:{loader:false} }); 
    }
  }

  async fetchData() {
    const {
      getGameByIdData,
      getPlayByPlayData,
      getGameData,
      getRosterData,
      getPackageData,
      getPositionsData,
      getIntangiblesData,
      getScaleData,
      getProductionPointData,
      dispatch
    } = this.props;
    try {
      const gameByIdCallback = await new Promise((resolve) => {
        getGameByIdData((callback) => resolve(callback));
      });

      if (gameByIdCallback?.status === 200) {
        const playByPlayCallback = await new Promise((resolve) => {
          getPlayByPlayData(this.state.myParam.playgradeid, (callback) => resolve(callback));
        });

        if (playByPlayCallback?.status === 200) {
          const results = await Promise.allSettled([
            getGameData(),
            getRosterData(),
            getPackageData(),
            getPositionsData(),
            getIntangiblesData(),
            getScaleData(),
            getProductionPointData(),
          ]);
                  
          const allSuccessful = results.every((result) => result.status === 'fulfilled');
          
          if (!allSuccessful) {
            dispatch({ type: "GET_PLAY_BY_PLAY_GAME_LOADER",inputVariables:{loader:false} });
          }
        }else{
          dispatch({ type: "GET_PLAY_BY_PLAY_GAME_LOADER",inputVariables:{loader:false} });
        }
      }else{
        dispatch({ type: "GET_PLAY_BY_PLAY_GAME_LOADER",inputVariables:{loader:false} });
      }
    } catch (error) {
      // dispatch({ type: "GET_PLAY_BY_PLAY_GAME_LOADER",inputVariables:{loader:false} });
    } finally {
      // dispatch({ type: "GET_PLAY_BY_PLAY_GAME_LOADER",inputVariables:{loader:false} });
    }
  }
    

  async componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.UserGrades !== this.props?.UserGrades){
      this.setState({gradingPlayData:this.props?.UserGrades})  
    }
    if(previousProps?.gradingBulkDataClear !== this.props?.gradingBulkDataClear){
      this.setState({gradeCheckAll:false,selectedGradeDataArray:[]})
    }
    if(previousProps?.CustomFieldTitles !== this.props?.CustomFieldTitles){
      let staticData = Object.keys(play_grading_table_columns).map((item,index)=>{
        return {
          name:item,
          id:`static-${index}`
        }
      })
      let customData = this.props?.CustomFieldTitles && isArray(this.props?.CustomFieldTitles) && this.props?.CustomFieldTitles?.map(item => {return {name:item?.title, id:item.id}})
      if(staticData && customData){
        let combineData = [...staticData,...customData]
        this.setState({tableHeaderData:combineData});
      }
    }
    if(previousProps?.gradingIndexData !== this.props?.gradingIndexData){
      if(isArray(this.props?.gradingIndexData) && isArray(this.state.tableHeaderData)){
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
        let currentPossitionArray = this.state.tableHeaderData.map(item=>item.id);
        let allOperationsComplete = false;
        await sleep(300);
        for(let i=0;i<this.props?.gradingIndexData?.length; i++){
          
          
          let srcText = this.props?.gradingIndexData[i].id;
          let destText = currentPossitionArray[i];
          await sleep(300);
          this.changePosition(srcText,destText);
          let srcCurrentIndex =   currentPossitionArray.findIndex(e=>e===srcText);
          currentPossitionArray.slice(srcCurrentIndex,1);
          const insert = (arr, index, newItem) => [
            ...arr.slice(0, index),
            newItem,
              ...arr.slice(index)
            ]
            currentPossitionArray = insert(currentPossitionArray, i+1, srcText);
            if (i === this.props.gradingIndexData.length - 1) {
              allOperationsComplete = true;
            }
        }
        if (allOperationsComplete) {
          dispatch({ type: "GET_PLAY_BY_PLAY_GAME_LOADER",inputVariables:{loader:false} }); 
        }
      }
    }
  }
    
    componentWillUnmount(){
      trackPageDuration('Play By Play Grading',this.state.startTime)
      this.setState({tableHeaderData:[]});
      this.props?.clearGradingIndexData();
    }
    
    listenerDragStart = (e) => {
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text',e.target.id);

      if (!isIE11) {
        //Create column's container
        var dragGhost = document.createElement("table");
        dragGhost.classList.add("tableGhost");
        dragGhost.classList.add("table-bordered");
        
        //in order tor etrieve the column's original width
        var srcStyle = document.defaultView.getComputedStyle(e.target);
      
        dragGhost.style.width = srcStyle.getPropertyValue("width");
        
        //Create head's clone
        var theadGhost = document.createElement("thead");
        var thisGhost = e.target.cloneNode(true);
        theadGhost.appendChild(thisGhost);
        dragGhost.appendChild(theadGhost);
        
        //Hide ghost
        dragGhost.style.position = "absolute";
        dragGhost.style.top = "-1500px";
        
        document.body.appendChild(dragGhost);
        e.dataTransfer.setDragImage(dragGhost, 0, 0);
      }
    }
    listenerDragEnter = (e) => {
      e.currentTarget.classList.add('over');
    }

    listenerDragOver = (e) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
      return false;
    }
    listenerDragLeave = (e) => {
      e.currentTarget.classList.remove('over');
    }

    listenerDrop = (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      var srcTxt = e.dataTransfer.getData('text');
      var destTxt = e.target.id;
    
      if(destTxt === ''){
        return false;
      }
      var dragSrcEl = $(`#${srcTxt}`);
      var dropIndex =  $(`#${destTxt}`).index()
      var srcIndex = dragSrcEl.index() + 1;
      var destIndex = dropIndex +1;

      if(dragSrcEl.index() === dropIndex){
        return false
      }
      if(dropIndex<dragSrcEl.index()){
        dragSrcEl.insertBefore(e.target);
        $.each($('.grading-table tr td:nth-child(' + srcIndex + ')'), function (i, val) {
          var index = i + 1;
          $(this).insertBefore($('.grading-table tr:nth-child(' + index + ') td:nth-child(' + destIndex + ')'));
        });
      } else {
        dragSrcEl.insertAfter(e.target);
        $.each($('.grading-table tr td:nth-child(' + srcIndex + ')'), function (i, val) {
          var index = i + 1;
          $(this).insertAfter($('.grading-table tr:nth-child(' + index + ') td:nth-child(' + destIndex + ')'));
        });
      }
      return false;
    }

    listenerDragEnd = (e) => {
      var cols = document.querySelectorAll('.grading-table th');
      let idArray = [];
      [].forEach.call(cols, function (col) {
        if(col.id !== ''){
          idArray.push({id:col?.id})
        }
        col.classList.remove('over');
        col.style.opacity = 1;
      });
      const elements = document.getElementsByClassName('tableGhost');
      while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
      }
      let game = this.props?.CurrentGame;
      let user = this.props?.CurrentUser;

      let finalData = {idArray,game,user}
      this.props?.updateGradingTableColumns(finalData)
      
    }



  handleCheckbox(e) {
    const { name, checked } = e.target;
    const cloneCheckBox = { ...this.props.initialCheckbox,  [name]: checked}
    this.setState({checkboxData:cloneCheckBox})
    this.props.updateCheckboxConfig(cloneCheckBox)
  }

  changePosition(srcTxt, destTxt){
    var col = document.getElementById(destTxt);
    if (srcTxt !== destTxt && (srcTxt !== '' || destTxt !== '')) {
      var dragSrcEl = $(`#${srcTxt}`);
      var dropIndex =  $(`#${destTxt}`).index()
      var srcIndex = dragSrcEl.index() + 1;
      var destIndex = dropIndex + 1;
        dragSrcEl.insertAfter(col);
        $.each($('.grading-table tr td:nth-child(' + srcIndex + ')'), function (i, val) {
          var index = i + 1;
          $(this).insertAfter($('.grading-table tr:nth-child(' + index + ') td:nth-child(' + destIndex + ')'));
        });
    }
  } 

  handleCheckAll = () =>{
    const { dispatch } = this.props;
    dispatch({ type: "PLAY_BY_PLAY_CHECK_ALL"});       
  }

  handleOnCheckBoxChange = (data) =>{
    const { dispatch } = this.props;
    dispatch({ type: "PLAY_BY_PLAY_ON_CHECK_BOX_CHANGE",inputVariables:{data:data}});    
   }

  handleInputChange = (e,id) =>{
      const { sendNewProductionPoint,dispatch } = this.props;  
      dispatch({ type: "PRODUCTION_POINT_ERROR_MESSAGE",inputVariables:{message:e,id:id}})
      // sendNewProductionPoint(e,this.state.tabValue, (callback) => {
      //   if(callback?.status === 200){
      //     let tempData = this.state.productionPointDataArray;
      //     const availableItems = tempData[id]?.filter(item => this.props?.productionPoints.some(obj => obj.name.toLowerCase().replace(/\s/g, '') === item.toLowerCase().replace(/\s/g, '')));
      //     tempData[id] = availableItems
      //     this.setState({productionPointDataArray:tempData})
      //     // dispatch({ type: "PRODUCTION_POINT_ERROR_MESSAGE",inputVariables:{message:'The production point request is sent to Admin'}})
      //   }
      // });      
   } 

  handleDropDownData = (newVal,id,isSuccess) =>{
    const { dispatch } = this.props;
    let tempData = this.state.productionPointDataArray;
    tempData[id] = newVal;
    this.setState({productionPointDataArray:tempData})
    if(isSuccess?.isSuccess === false){
    }else{
      const availableItems = tempData[id]?.filter(item => this.props?.productionPoints.some(obj => obj.name.toLowerCase().replace(/\s/g, '') === item.toLowerCase().replace(/\s/g, '')));
      if(availableItems?.length > 0){
        dispatch({ type: "SEND_NEW_PRODUCTION_POINT_REQUEST",inputVariables:{name:availableItems[availableItems?.length - 1],position_id:this.state.tabValue}})
      }
    }
    
   }

   handleBlurApiCall = (values,id) =>{
    const availableItems = values?.filter(item => this.props?.productionPoints.some(obj => obj?.name === item));
    const { dispatch } = this.props;
      dispatch({ type: "UPDATE_PRODUCTION_POINT",inputVariables:{play_depth_chart_point:id,data:availableItems?.length > 0 ? availableItems : []} });       
   }

   handleSelectTab = (e) =>{
    const { dispatch } = this.props;
    this.setState({ tabValue: e, toggleClear:false })
    if(e){
      dispatch({ type: "GET_SCHOOL_PRODUCTION_POINT", inputVariables:{position_id: e}});   
    }
    dispatch({ type: "REMOVE_CHECKBOX_GRADING_VALUE"});   
   }
   handleChange = (value) =>{
    const { dispatch } = this.props;
    dispatch({ type:"CHECKBOX_BULK_GRADING_SCALE_DATA",
    inputVariables:{'id':this.state.selectedGradeDataArray, 'position value':this.state.tabValue,"gradingValue":value} })
   }

  handleCancelProductionPoint = () =>{
  const { dispatch } = this.props;
  let tempData = this.state.productionPointDataArray;
  const availableItems = tempData[this.props?.productionPointErrorId]?.filter(item => this.props?.productionPoints.some(obj => obj.name.toLowerCase().replace(/\s/g, '') === item.toLowerCase().replace(/\s/g, '')));
  tempData[this.props?.productionPointErrorId] = availableItems
  this.setState({productionPointDataArray:tempData})
  dispatch({ type: "PRODUCTION_POINT_ERROR_MESSAGE"})
  }

  handleSubmitProductionPoint = () =>{
  const { sendNewProductionPoint,dispatch } = this.props;
    sendNewProductionPoint(this.props.productionPointErrorMessage,this.state.tabValue, (callback) => {
        if(callback?.status === 200){
          let tempData = this.state.productionPointDataArray;
          const availableItems = tempData[this.props?.productionPointErrorId]?.filter(item => this.props?.productionPoints.some(obj => obj.name.toLowerCase().replace(/\s/g, '') === item.toLowerCase().replace(/\s/g, '')));
          tempData[this.props?.productionPointErrorId] = availableItems
          this.setState({productionPointDataArray:tempData})
          dispatch({ type: "PRODUCTION_POINT_ERROR_MESSAGE"})
          dispatch({ type: "PRODUCTION_POINT_SUCCESS_MESSAGE"})
        }
    });  
  }

  handleGradesButton = (row,index) =>{
    const { dispatch } = this.props;

    if(row?.depth_chart_id){
      dispatch({
        type: "SET_PLAY_DEPTH_CHART",
        inputVariables: {
          "toggle depth chart": true,
          "depth chart input ": row,
          "depth chart index": index,
        },
      });
      dispatch({
        type: "SET_HUDL_VIDEO",
        inputVariables: {
          "depth chart row": row,
        },
      });
      dispatch({
        type: "GET_USER_GRADES",
        inputVariables: {
          depthChartId: row?.["depth_chart_id"],
          playRowId: row?.["id"],
        },
      });
      
      dispatch({
        type: "GET_ALL_PLAY_DEPTH_CHART_USERS",
        inputVariables: {
          "input depth chart rowid": row?.["depth_chart_id"],
          "input play rowid": row?.["id"],
        },
      });
  } else {
    dispatch({
      type: "TOGGLE_CHOOSE_PACKAGE",
      // inputVariables: {
      //   depthChartId: row?.["depth_chart_id"],
      //   playRowId: row?.["id"],
      // },
    });
  }
  }
  
  handleSaveAndNextGrades = () =>{
    const { dispatch } = this.props;
    dispatch({type: "SET_NEXT_DEPTH_CHART"});
  }

  handleVideoChange = (e) =>{
    const { value } = e.target;
    const trimmedValue = value.trim();
    const httpsUrl = trimmedValue.replace(/^http:/, "https:");
    this.setState({videoValue:httpsUrl});
  }

  handleHudlVideoUpload = () =>{
    const { dispatch } = this.props;
    dispatch({type: "SET_UPLOAD_VIDEO"});
    this.setState({videoValue:''});
  }

  handlePackage = () => {
    const packageOptions = this.props?.Packages?.map((data) => ({
      name: data.title,
      value: data.Id
    })) || [];
  
    packageOptions.unshift({ name: "None", value: 'NULL' });
  
    return packageOptions;
  }

  render() {
    let {
      CurrentDepthChart,
      DepthChartPackageEnableButton,
      CurrentGame,
      CurrentPlayDepthChartId,
      CurrentPlayId,
      CurrentPlayPackage,
      CurrentTitle,
      CurrentTitleId,
      CurrentUser,
      CustomFieldTitles,
      DropdownRosterFilter,
      DepthChartPlayIdVariableFlag,
      EditField,
      EditGame,
      initialCheckbox,
      FormCreateNewPlayErrors,
      FormNewPlayHeadingErrors,
      GameDateResult,
      Intangibles,
      LastIndex,
      MaximumTitles,
      NavBarDropDownIndex,
      NewData,
      NewIntangible,
      NewPlayRow,
      NewRow,
      Packages,
      PlayByPlayInfo,
      PlayRowToDelete,
      PlayerComparisonIntangible,
      ToggleDeletePlayDepthChartUser,
      ToggleDepthChart,
      ToggleGameDelete,
      ToggleUserDropDown,
      UserGrades,
      Users,
      currentCustomTitleCsvPlayMappingIndex,
      playmapping,
      GetScaleTypeData,
      ProductionPointsData,
      DepthChartIndex,
      CurrentPlay,
      CurrentName,
      BulkDropDownData,
      BulkDeleteModal,
      GradingPositionScaleTypeData,
      SelectedBulkType,
      selectedPackage,
      defaultViewData,
      selectedDefaultView,
      currentSelectedView,
      saveViewModal,
      SeasonYearData,
      playByPlayGradingMessage,
      ToggleUploadVideo,
      FormUploadVideoErrors,
      GetUploadedVideo,
      CheckboxError,
      PlayUploadMappingError,
      currentSelectedPositionData,
      gradingPlayerNameError,
      userGradingError,
      updateBulkEdit,
      checkAll,
      PlayCheckboxError,
      playByPlayLoader,
      productionPointErrorMessage,
      productionPointError,
      productionPointSuccessMessage,
      CsvPlayMappingPackage
    } = this.props;

    var posFilterData =
    this.state.gradingPlayData &&
    isArray( this.state.gradingPlayData) &&
    this.state.gradingPlayData &&
    this.state.gradingPlayData.filter((item) => this.state?.tabValue?.includes(item.position));

    var filterData =
      this.state.gradingPlayData &&
      isArray(this.state.gradingPlayData) &&
      this.state.gradingPlayData &&
      this.state.gradingPlayData.filter((item) => this.state?.tabValue?.includes(item.position)).map(val => val.position_id);

      let removeDuplicatePositionId = [...new Set(filterData?filterData:'')];

      let getScaleTypeId = GetScaleTypeData && isArray(GetScaleTypeData) && GetScaleTypeData.filter((item)=> removeDuplicatePositionId.includes(item.position_id))


    let gradeDropDownData = GradingPositionScaleTypeData[getScaleTypeId?.[0]?.scale_type]  

    const clonePackages = Packages?.map((data) => {
      return { name: data.title, value: data.Id };
    });

    clonePackages?.unshift({ name: "choose package", value: -1 });


    // POP-UP MODAL CHECKBOXES
     const handleGradeCheckAll = () =>{
      let playByPlayDataClone = [...this.state.gradingPlayData];
       let changeData= playByPlayDataClone &&
      isArray(playByPlayDataClone) &&
      playByPlayDataClone &&
      playByPlayDataClone.filter((item) => this.state?.tabValue?.includes(item.position))

      changeData && isArray(changeData) && changeData.map((val) =>
         val['isChecked'] = !this.state.gradeCheckAll
      )
      let selectedData = changeData && isArray(changeData) && changeData.filter(item => item.isChecked).map(val=>val.user_id)
      this.setState({gradingPlayData:changeData,gradeCheckAll:!this.state.gradeCheckAll,selectedGradeDataArray:selectedData})
    }

    const handleGradeOnCheckBoxChange = (data) =>{
      
     let index= this.state.gradingPlayData.findIndex(item => item?.id=== data?.id) 
     this.state.gradingPlayData[index]['isChecked']  = !data?.isChecked 
      let isSelectedData = this.state.gradingPlayData.filter(item=>item.isChecked).map((item)=>{return item.user_id})
      let changeData= this.state.gradingPlayData &&
      isArray(this.state.gradingPlayData) &&
      this.state.gradingPlayData &&
      this.state.gradingPlayData.filter((item) => this.state?.tabValue?.includes(item.position))

      this.setState({gradeCheckAll:isSelectedData.length===changeData?.length ? true : false,selectedGradeDataArray:isSelectedData})

    }
    const removeDuplicates = (arr) => {
      if (arr) {
        return arr?.filter((item, index) => {
          let itemIndex = arr?.findIndex((e) => e?.title_id === item?.title_id);
          return itemIndex === index;
        });
      }
      return [];
    };

    let checkValue = defaultViewData && isArray(defaultViewData) && defaultViewData?.find(val => val.id === selectedDefaultView )

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 5 : 99,
              },
            },
          ]}
        >
          {playByPlayLoader ? 
          <Loader isLoaded={playByPlayLoader}/> : null}
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  paddingLeft: "0px",
                }}>
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <DivBlock style={{}}>
                        <DivBlock
                          className="game_title"
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Play-by-Play Grading
                          </Heading>

                          <Span className={"h2 h2-span"} style={{}}>
                            Grading /
                          </Span>

                          <Span className={"h2 h2-inner-span"} style={{}}>
                            Play-by-Play Grading
                          </Span>
                        </DivBlock>
                      </DivBlock>
                      {/* {isDefaultLoaded ? <div ></div> : */}
                      <div style={{display:playByPlayLoader? "none" : "block"}}> 
                      {playByPlayGradingMessage ? 
                        <Span className={"message-span"}>{playByPlayGradingMessage}</Span> :
                        <DivBlock>
                      <Columns
                        style={{ backgroundColor: "#ffffff", fontSize: "0px" }}
                      >
                        <Column
                          lg={12}
                          md={12}
                          sm={12}
                          style={{
                            fontFamily:"'Poppins', sans-serif",
                            marginBottom: "1rem",
                            marginLeft: "10px",
                            marginRight: "0px",
                            marginTop: "15px",
                            paddingBottom: "0px",
                            paddingTop: "0px",
                          }}
                          xs={12}
                        >
                          <DivBlock
                          className="paly_by_play"
                          >
                            <Paragraph
                              style={{
                                fontSize: "14px",
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: "5px",
                                marginTop: 0,
                                fontWeight: "600",
                              }}
                            >
                            Choose package to load personnel and grades. 
                            </Paragraph>
                            <Paragraph
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px",
                                fontWeight: "500",
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: '10px',
                                marginTop: 0,
                              }}
                            >
                             Don&#39;t have any packages created?&nbsp; 
                              <TextLink
                              href={"/add_depth_chart"}
                              style={{ fontSize: "14px", marginRight: "5px" }}
                              type={"spa"}
                            >
                             Click here to build your packages.
                            </TextLink>
                           
                               Don&#39;t have any Intangibles?&nbsp;  
                              
                            <TextLink
                            href={"/intangibles"}
                            style={{ fontSize: "14px" }}
                            type={"spa"}
                            >
                              Click here to add intangibles for your school. 
                            </TextLink>
                            </Paragraph>
                          </DivBlock>
                        </Column>
                      </Columns>

                      <Columns
                        selectedLayout={"9/3"}
                        style={{
                          alignItems: "starts",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Column xl={5} lg={5} md={12} sm={12} xs={12}>
                          <DivBlock
                            className="grading-name-container"
                          >
                            <DivBlock style={{display: 'flex',alignItems: 'center'}}>
                            <Heading
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "16px",
                                fontWeight: "500",
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                              }}
                              type={"h1"}
                            >
                              {CurrentGame
                                ? CurrentGame["title"] +
                                  " " +
                                  `(` +
                                  handleDate(GameDateResult) +
                                  `)`
                                : ""}
                            </Heading>
                            <DivBlock 
                                actions={[
                                  {
                                    action: "Set Edit Game",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: { "Edit Game": CurrentGame },
                                  },
                                ]}
                              >  
                                <i className={"fa fa-edit"}></i>
                              </DivBlock>
                            </DivBlock>

                            <DivBlock
                            className="play-dropdown-container">
                              <DivBlock className="default-view-container">
                              <SelectDropDown
                                className={"form-control"}
                                dataSet={defaultViewData?.length > 0 ? defaultViewData : 'No data available'}
                                dataSetLabel={"name"}
                                dataSetValue={"id"}
                                enableReinitialize={true}
                                initialValue={ currentSelectedView ? currentSelectedView : (selectedDefaultView === 0 || selectedDefaultView === undefined) ? defaultViewData?.[0]?.id : selectedDefaultView}
                                name={"selectedDefaultView"}
                                type={"number"}
                                style={{
                                  alignItems:'center',
                                  borderRadius:'5px',
                                  fontSize: '14px',
                                  // width: '80px',
                                }}
                                actions={[
                                  {
                                    action: "Set Saved View",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                                >
                                
                              </SelectDropDown>
                              </DivBlock>
                              <DivBlock
                              className="customize-view-container"
                              actions={[
                                {
                                  action: "Set Default Value",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                }
                              ]}>
                                <span style={{paddingRight: "1.5rem"}} >Customize View</span>
                                {this.props.customizeView ? <i style={{fontSize: "1.25rem"}} className="fa fa-solid fa-angle-up"></i>
                                : <i style={{fontSize: "1.25rem"}} className="fa fa-solid fa-angle-down"></i>}
                              </DivBlock>
                              <DivBlock className="btn-delete-set-container">
                              {(defaultViewData && defaultViewData[0]?.id === selectedDefaultView) || selectedDefaultView === '' ? null :
                              <DivBlock
                              className={"btn btn-reset btn-delete-set-view"}
                              actions={[
                                {
                                  action: "Set Delete Saved View",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    value:true
                                  },
                                },
                              ]}
                            >
                              <Span>Delete</Span>
                            </DivBlock>}

                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                          <DivBlock className="bulk-container">
                        <DivBlock className="bulk-sub-container">
                        <SelectDropDown
                          className={"form-control bulk-dropdown"}
                          style={{
                            alignItems:'center',
                          }}
                          name={"SelectedBulkType"}
                          enableReinitialize={true}
                          options={BulkDropDownData}
                          initialValue={SelectedBulkType}
                        ></SelectDropDown>

                        {SelectedBulkType === 'setpackage' ?
                        <SelectDropDown
                          className={"form-control bulk-dropdown"}
                          style={{
                            alignItems:'center',
                          }}
                          placeholder='Set Package'
                          name={"selectedPackage"}
                          dataSet={Packages}
                          dataSetLabel={"title"}
                          dataSetValue={"Id"}
                          enableReinitialize={true}
                          // options={Packages}
                          initialValue={selectedPackage}
                        ></SelectDropDown>:null}

                        <Button
                          className={SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? "btn btn-primary grading-bulk-button" : "btn btn-primary grading-bulk-button-disabled"}
                          disabled={SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? false : true}
                          actions={[
                            {
                              action: "Checkbox Bulk Grading",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                // 'id':this.state.selectedPlayDataArray,
                              },
                            },
                          ]}
                        >
                          Apply
                        </Button>
                        </DivBlock>
                        <DivBlock>
                          
                        </DivBlock>
                      </DivBlock>
                        <If
                            condition={
                              PlayCheckboxError &&
                              PlayCheckboxError?.[0]
                                ? true
                                : false
                            }
                            show={true}
                          >
                            <Then>
                              <Span className={"invalid-feedback"}>
                                {PlayCheckboxError
                                  ? PlayCheckboxError[0]
                                  : ""}
                              </Span>
                            </Then>
                        </If>
                        </Column>
                        {SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ?
                        <Column xl={7} lg={7} md={12} sm={12} xs={12}>
                          <DivBlock className="upload-button"s  >
                            <Label>Import Plays</Label>
                            <FileUpload
                              FileName={"Filename"}
                              name={"PlayUpload"}
                              style={{ fontFamily:"'Poppins', sans-serif", }}
                            ></FileUpload>
                            <If
                              condition={
                                this.props?.uploadPlayError !== '' &&
                                this.props?.uploadPlayError
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {this.props?.uploadPlayError
                                    ? this.props?.uploadPlayError
                                    : ""}
                                </Span>
                              </Then>
                          </If>

                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Upload Play",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "input open roster filter":
                                      DropdownRosterFilter !== true,
                                  },
                                },
                              ]}
                            >
                              Import
                            </Button>
                          </DivBlock>

                          <DivBlock className="upload-button">
                          <Label>Import Grades</Label>
                          <FileUpload
                            FileName={"Filename"}
                            name={"GradeUpload"}
                            id="fileInput"
                            accept={".xlsx"}
                            style={{ fontFamily:"'Poppins', sans-serif", }}
                          ></FileUpload>
                          <If
                              condition={
                                this.props?.uploadGradeError !== '' &&
                                this.props?.uploadGradeError
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {this.props?.uploadGradeError
                                    ? this.props?.uploadGradeError
                                    : ""}
                                </Span>
                              </Then>
                          </If>

                          <Button
                            className={"btn btn-primary"}
                            actions={[
                              {
                                action: "Upload Grade",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            Import
                          </Button>
                          </DivBlock>

                        <DivBlock className="upload-button">
                          <Label>Import Hudl Video</Label>
                          <HudlVideoUpload/>
                          </DivBlock>
                        </Column> : null}
                      </Columns>

                    {/* {this.props?.uploadGradeMessage !== '' ? 
                      <DivBlock
                        className={"gradeUploadMessage"}
                        style={{
                          background:
                            this.props?.uploadGradeMessage ===
                            "Grades imported successfully"
                              ? "#48c46ec2"
                              : "#f443369e",
                        }}
                      >
                        <Span className={"success-span"}>
                          {this.props?.uploadGradeMessage !== ""
                            ? this.props?.uploadGradeMessage
                            : ""}
                        </Span>
                        <Span
                            className={"close"}
                            actions={[
                              {
                                action: "REMOVE_VALUE",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  value:false
                                },
                              },
                            ]}
                          >
                            x
                      </Span>
                    </DivBlock> : null} */}


                      {this.props?.customizeView ? 
                        <Columns
                        style={{ backgroundColor: "#ffffff", fontSize: "0px", marginBottom:"10px",paddingBottom:'10px' }}
                      >
                        <Column
                          lg={12}
                          md={12}
                          sm={12}
                          style={{
                            fontFamily:"'Poppins', sans-serif",
                            marginBottom: "1rem",
                            marginLeft: "10px",
                            marginRight: "0px",
                            marginTop: "15px",
                            paddingBottom: "0px",
                            paddingTop: "0px",
                          }}
                          xs={12}
                        >
                          <DivBlock
                            style={{
                              alignItems: "start",
                              display: "flex",
                              fontSize: "14px",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Paragraph
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px",
                                fontWeight: "700",
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                              }}
                            >
                              Columns
                            </Paragraph>
                            <Paragraph
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px",
                                fontWeight: "400",
                                fontStyle: "italic",
                                color:"gray",
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                              }}
                            >
                              Hide/Show columns 
                            </Paragraph>
                            <DivBlock style={{marginTop:"1rem", display: "flex", flexWrap:"wrap"}} >
                              {
                                Object.keys(play_grading_table_columns)?.map((data, index) => {
                                 return <>
                                 {data === '#' || data === 'play' || data === 'package'?null : 
                                  <div style={{marginRight: "1rem"}} key={index} >
                                  <CustomCheckbox handleChange={(e) => this.handleCheckbox(e)} name={data} value={initialCheckbox[data]} label={capitalize(data)} disabled={checkValue?.name === "Default View" ? false :true} />
                                 </div>}
                                 </>
                                })
                              }
                              {
                                CustomFieldTitles &&
                                isArray(CustomFieldTitles) &&
                                CustomFieldTitles?.map((data, index) => {
                                  return <div style={{marginRight: "1rem"}} key={index} >
                                  <CustomCheckbox handleChange={(e) => this.handleCheckbox(e)} name={data?.title}  value={initialCheckbox[data?.title]} disabled={checkValue?.name === "Default View" ? false :true} label={capitalize(data["title"])} />
                                 </div>
                                })
                              }
                            </DivBlock>
                          </DivBlock>
                          {(defaultViewData && defaultViewData[0]?.id === selectedDefaultView) || selectedDefaultView === '' ?
                          <DivBlock className="save-view-container">
                              <Paragraph
                                  style={{
                                    fontSize: "14px",
                                    color:"gray",
                                  }}
                                >
                                  Save column set view as
                              </Paragraph>
                                
                              <DivBlock className="save-view-input">
                                <TextInput
                                  className={"form-control"}
                                  initialValue={""}
                                  name={"FormNewDefaultViewTitle"}
                                  placeholder={""}
                                ></TextInput>

                                <If
                                  condition={
                                    FormNewPlayHeadingErrors &&
                                    FormNewPlayHeadingErrors.title
                                      ? true
                                      : false
                                  }
                                >
                                  <Then>
                                    <Span className={"invalid-feedback save-view-inputerror"}>
                                      {FormNewPlayHeadingErrors
                                        ? FormNewPlayHeadingErrors.title
                                        : ""}
                                    </Span>
                                  </Then>
                                </If>
                              </DivBlock>
                              <DivBlock>
                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Set Default View",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      value:this.state.checkboxData
                                      },
                                  },
                                ]}
                                >
                                Save
                              </Button>
                              </DivBlock>
                              <Paragraph
                                style={{
                                  fontSize: "14px",
                                  color:"gray",
                                }}
                              >
                                {'(optional)'}
                              </Paragraph>
                              <DivBlock
                              className="save-view-close"
                              style={{cursor:'pointer'}}
                                actions={[
                                {
                                  action: "Toggle Customize View",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                                // onClick={()=>this.setState({openCustomize:false})}
                              >
                                Close
                            </DivBlock>
                            </DivBlock>:null}
                        </Column>
                        </Columns> : null}
                      
                      <DivBlock className={"invalid-school-feedback"}>
                        <If condition={MaximumTitles ? true : false}>
                          <Then>
                            <DivBlock className={"invalid-school-feedback"}>
                              <Span
                                style={{
                                  color: "#dc3545",
                                  fontFamily:"'Poppins', sans-serif",
                                  marginBottom: "0.25em",
                                  marginTop: "0.25em",
                                }}
                              >
                                {MaximumTitles}
                              </Span>
                            </DivBlock>
                          </Then>
                        </If>
                      </DivBlock>                    

                      <DivBlock
                        className={"table-scroll"}
                        style={{ overflowX: "scroll" }}
                      >
                        <table className={"table table-bordered grading-table"}>
                          <thead>
                            <tr className={"thead-bg"}>
                              <th
                                draggable={false}
                                className={"th-border"}
                                style={{ minWidth: "80px" }}
                              >
                                <Checkbox 
                                handleClick={this.handleCheckAll}
                                isChecked={checkAll}
                                type="checkbox" 
                                name="selectAll" 
                                id="selectAll"/>
                              </th>
                              <th
                                draggable={false}
                                className={"th-border"}
                                style={{ minWidth: "80px" }}
                              >
                                <Span className={"th-span"}></Span>
                              </th>
                              {this.state?.tableHeaderData && this.state?.tableHeaderData &&
                                (handleFilter(this.state?.tableHeaderData,initialCheckbox))
                                ?.map((item,index)=>{
                                  return(
                                    <th
                                    id={item?.id}
                                    className={"th-border th-span draggble"}
                                    draggable="true"
                                    title={item?.name}
                                    onDragStart={(e)=>this.listenerDragStart(e)}
                                    onDragEnter={(e)=>this.listenerDragEnter(e)}
                                    onDragOver={(e) => this.listenerDragOver(e)}
                                    onDragLeave={(e)=>this.listenerDragLeave(e)}
                                    onDrop={(e)=>this.listenerDrop(e)}
                                    onDragEnd={(e)=>this.listenerDragEnd(e)}
                                    style={{ minWidth: "80px",cursor:'grab' }}>
                                      {item?.name}
                                    </th>
                                  )
                                })
                              }
                              {SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ?
                              <th className={"th-border th-border-new"} draggable={false}>
                                <Span className={"th-span"}></Span>
                              </th>:null}
                            </tr>
                          </thead>
                          <tbody>
                            {PlayByPlayInfo &&
                              isArray(PlayByPlayInfo) &&
                              PlayByPlayInfo?.map((row, index) => {
                                return (
                                  <Fragment>
                                    <tr>
                                      <td
                                        draggable={false}
                                       className="play-by-play"
                                      >
                                        <Checkbox 
                                        type="checkbox"
                                        isChecked={row.isChecked}  
                                        name={row.name} 
                                        handleClick={()=>this.handleOnCheckBoxChange(row)}
                                        />
                                      </td>
                                      <td
                                        draggable={false}
                                        className="play-by-play"
                                      >
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <button
                                            className={"btn btn-primary"}
                                            // disabled={
                                            //   !(
                                            //     row["title"] ||
                                            //     DepthChartPackageEnableButton[
                                            //       row["id"]
                                            //     ] === row["id"]
                                            //   )
                                            // }
                                            // style={
                                            //   row["title"] ||
                                            //   DepthChartPackageEnableButton[
                                            //     row["id"]
                                            //   ] === row["id"]
                                            //     ? {
                                            //         // backgroundColor: "#90EE90",
                                            //         backgroundColor: "#48c46e",
                                            //         borderColor: "#48c46e",
                                            //         // borderColor: "#90EE90",
                                            //       }
                                            //     : {
                                            //         backgroundColor: "#a0d2a0",
                                            //         borderColor: "#a0d2a0",
                                            //       }
                                            // }
                                            onClick={()=>this.handleGradesButton(row,index)}
                                            // actions={[
                                            //   {
                                            //     action: "Set Play Depth Chart",
                                            //     trigger: "onclick",
                                            //     timeout: "",
                                            //     pollingPeriod: "",
                                            //     inputVariables: {
                                            //       "toggle depth chart": true,
                                            //       "depth chart input ": row,
                                            //       "depth chart index":index,
                                                  
                                            //     },
                                            //   },
                                            // ]}
                                          >
                                            Grades
                                          </button>
                                        </DivBlock>
                                      </td>
                                      <td
                                       className="play-by-play"
                                      >
                                        <TextInput
                                          className={"text-height"}
                                          initialValue={row["index"]}
                                          name={"FormPlayIndex"}
                                          enableReinitialize={true}
                                          placeholder={""}
                                          type={"number_positive"}
                                          actions={[
                                            {
                                              action: "Update Play Index",
                                              trigger: "onchange",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                play_id_index: row["id"],
                                              },
                                            },
                                            // {
                                            //   action:
                                            //     "Get Play Info After Refresh",
                                            //   trigger: "onblur",
                                            //   timeout: "",
                                            //   pollingPeriod: "",
                                            //   inputVariables: {
                                            //     gameid: CurrentGame["Id"],
                                            //   },
                                            // },
                                          ]}
                                        ></TextInput>
                                      </td>

                                      <td
                                       className="play-by-play"
                                      >
                                        <TextInput
                                          className={"text-height"}
                                          enableReinitialize={true}
                                          initialValue={row["name"]}
                                          name={"FormPlayName"}
                                          placeholder={""}
                                          actions={[
                                            {
                                              action: "Update Play Name",
                                              trigger: "onchange",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                play_id: row["id"],
                                                play_name: row["name"],
                                              },
                                            },
                                            // {
                                            //   action: "Get Play By Play Info",
                                            //   trigger: "onblur",
                                            //   timeout: "",
                                            //   pollingPeriod: "",
                                            //   inputVariables: {
                                            //     gameid: CurrentGame["Id"],
                                            //   },
                                            // },
                                          ]}
                                        ></TextInput>
                                      </td>

                                      {initialCheckbox['qtr'] ? <td
                                       
                                      //  onDragStart={this.handleDragStart}
                                      //  onDragOver={this.handleDragOver}
                                      //  onDrop={this.handleOnDrop}
                                      //  onDragEnter={this.handleDragEnter}
                                      //  dragOver={row === this.state.setDragOver}
                                      >
                                        <SelectDropDown
                                          className={
                                            "text-height dropdown-padding"
                                          }
                                          
                                          initialValue={row["qtr"]}
                                          enableReinitialize={true}
                                          name={"FormPlayQtr"}
                                          options={[
                                            { label: "1", value: "1" },
                                            { label: "2", value: "2" },
                                            { label: "3", value: "3" },
                                            { label: "4", value: "4" },
                                          ]}
                                          style={{}}
                                          type={"number"}
                                          actions={[
                                            {
                                              action: "Update Play Qtr",
                                              trigger: "onchange",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                play_id_qtr: row["id"],
                                              },
                                            },
                                          ]}
                                        ></SelectDropDown>
                                      </td> : null}

                                      { initialCheckbox['series'] ? <td
                                        className="play-by-play"
                                      >
                                        <TextInput
                                          initialValue={row["series"]}
                                          name={"FormPlaySeries"}
                                          enableReinitialize={true}
                                          placeholder={""}
                                          style={{
                                            background: "#fff",
                                            border: "1px solid #ced4da",
                                            borderRadius: "0.25rem",
                                            color: "#495057",
                                            display: "block",
                                            fontSize: "1rem",
                                            fontWeight: 400,
                                            height: "33px",
                                            lineHeight: 1.5,
                                            paddingBottom: "0.375rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "0.375rem",
                                            width: "100%",
                                          }}
                                          type={"number_positive"}
                                          actions={[
                                            {
                                              action: "Update Play Series",
                                              trigger: "onchange",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                play_id_series: row["id"],
                                              },
                                            },
                                            // {
                                            //   action: "Get Play By Play Info",
                                            //   trigger: "onblur",
                                            //   timeout: "",
                                            //   pollingPeriod: "",
                                            //   inputVariables: {
                                            //     gameid: CurrentGame["Id"],
                                            //   },
                                            // },
                                          ]}
                                        ></TextInput>
                                      </td> : null}

                                      {initialCheckbox['down'] ? <td
                                        className="play-by-play"
                                      >
                                        <TextInput
                                          initialValue={row["down"]}
                                          name={"FormPlayDown"}
                                          enableReinitialize={true}
                                          placeholder={""}
                                          style={{
                                            background: "#fff",
                                            border: "1px solid #ced4da",
                                            borderRadius: "0.25rem",
                                            color: "#495057",
                                            display: "block",
                                            fontSize: "1rem",
                                            fontWeight: 400,
                                            height: "33px",
                                            lineHeight: 1.5,
                                            paddingBottom: "0.375rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "0.375rem",
                                            width: "100%",
                                          }}
                                          type={"number_positive"}
                                          actions={[
                                            {
                                              action: "Update Play Down",
                                              trigger: "onchange",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                play_id_down: row["id"],
                                              },
                                            },
                                            // {
                                            //   action: "Get Play By Play Info",
                                            //   trigger: "onblur",
                                            //   timeout: "",
                                            //   pollingPeriod: "",
                                            //   inputVariables: {
                                            //     gameid: CurrentGame["Id"],
                                            //   },
                                            // },
                                          ]}
                                        ></TextInput>
                                      </td> : null}

                                      { initialCheckbox['distance'] ? <td className="play-by-play" >
                                      <TextInput
                                          initialValue={row["distance"] || 0}
                                          name={"FormPlayDistance"}
                                          enableReinitialize={true}
                                          placeholder={""}
                                          style={{
                                            background: "#fff",
                                            border: "1px solid #ced4da",
                                            borderRadius: "0.25rem",
                                            color: "#495057",
                                            display: "block",
                                            fontSize: "1rem",
                                            fontWeight: 400,
                                            height: "33px",
                                            lineHeight: 1.5,
                                            paddingBottom: "0.375rem",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "0.375rem",
                                            width: "100%",
                                          }}
                                          type={"number_positive"}
                                          actions={[
                                            {
                                              action: "Update Play Distance",
                                              trigger: "onchange",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                play_id_distance: row["id"],
                                              },
                                            },
                                            // {
                                            //   action: "Get Play By Play Info",
                                            //   trigger: "onblur",
                                            //   timeout: "",
                                            //   pollingPeriod: "",
                                            //   inputVariables: {
                                            //     gameid: CurrentGame["Id"],
                                            //   },
                                            // },
                                          ]}
                                        ></TextInput>
                                      </td> : null}

                                      { initialCheckbox['type'] ? <td
                                        className="play-by-play"
                                      >
                                        <SelectDropDown
                                          initialValue={row["type"]}
                                          enableReinitialize={true}
                                          name={"FormPlayType"}
                                          options={[
                                            { label: "Run", value: "Run" },
                                            { label: "Pass", value: "Pass" },
                                            { label: "RPO", value: "RPO" },
                                            { label: "Screen", value: "Screen" },
                                            { label: "Punt", value: "Punt" },
                                            { label: "Kickoff", value: "Kickoff" },
                                            { label: "FG", value: "FG" },
                                            { label: "PAT", value: "PAT" },
                                            { label: "2 PT", value: "2 PT" },
                                          ]}
                                          style={{
                                            background: "#fff",
                                            border: "1px solid #ced4da",
                                            borderRadius: "0.25rem",
                                            color: "#495057",
                                            display: "block",
                                            fontSize: "1rem",
                                            fontWeight: 400,
                                            height: "33px",
                                            lineHeight: 1.5,
                                            minWidth: "80px",
                                            paddingBottom: "0.375rem",
                                            paddingLeft: "1px",
                                            paddingRight: "4px",
                                            paddingTop: "0.375rem",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action: "Update Play Type",
                                              trigger: "onchange",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                play_id_type: row["id"],
                                              },
                                            },
                                          ]}
                                        ></SelectDropDown>
                                      </td> : null}

                                      <td
                                       className="play-by-play"
                                      >
                                        {clonePackages &&
                                          isArray(clonePackages) && (
                                            <SelectDropDown
                                              initialValue={
                                                row["depth_chart_id"]
                                                  ? row["depth_chart_id"]
                                                  : -1
                                              }
                                              isHide={
                                                row["title"] ||
                                                DepthChartPackageEnableButton[
                                                  row["id"]
                                                ] === row["id"] ? true :false
                                              }
                                              disabled={SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? false : true}
                                              name={"DepthChartPackageId"}
                                              dataSetLabel={"name"}
                                              dataSetValue={"value"}
                                              options={[]}
                                              enableReinitialize={true}
                                              dataSet={clonePackages}
                                              style={{
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                color: "#495057",
                                                display: "block",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                height: "33px",
                                                lineHeight: 1.5,
                                                maxWidth: "100%",
                                                minWidth: "235px",
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "1px",
                                                paddingRight: "4px",
                                                paddingTop: "0.375rem",
                                                width: "100%",
                                                textAlign: "center",
                                              }}
                                              actions={[
                                                {
                                                  action:
                                                    "Set Play Grades Dropdown",
                                                  trigger: "onchange",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    depth_chart_id: row["Id"],
                                                    "intangible id input":
                                                      PlayerComparisonIntangible,
                                                    play_id_package: row["id"],
                                                    check_title: row["title"],
                                                  },
                                                },
                                              ]}
                                            ></SelectDropDown>
                                          )}
                                      </td>
                                      {/* <If
                                        condition={
                                          CustomFieldTitles ? true : false
                                        }
                                        style={{ marginTop: "10px" }}
                                      >
                                        <Then> */}
                                        
                                      {removeDuplicates(row["custom_field"]) &&
                                        isArray(
                                          removeDuplicates(row["custom_field"])
                                        ) &&
                                        removeDuplicates(
                                          row["custom_field"]
                                        ).map((modData, i) => {
                                          return (
                                            <>
                                              { initialCheckbox[modData['title_name']] ? <td
                                              className="play-by-play"
                                              key={i}
                                            >
                                              <TextInput
                                                initialValue={modData["data"]}
                                                name={"FormNewData"}
                                                enableReinitialize={true}
                                                placeholder={""}
                                                style={{
                                                  background: "#fff",
                                                  border: "1px solid #ced4da",
                                                  borderRadius: "0.25rem",
                                                  color: "#495057",
                                                  display: "block",
                                                  fontSize: "1rem",
                                                  fontWeight: 400,
                                                  height: "33px",
                                                  lineHeight: 1.5,
                                                  paddingBottom: "0.375rem",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "0.375rem",
                                                  width: "auto",
                                                }}
                                                actions={[
                                                  {
                                                    action:
                                                      "Create Custom Field data",
                                                    trigger: "onblur",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      "play id": row["id"],
                                                      play_id: row["id"],
                                                      "title id":
                                                        modData["title_id"],
                                                    },
                                                  },
                                                  {
                                                    action: "Update Custom Field data",
                                                    trigger: "onfocus",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      name:modData["data"]
                                                    },
                                                  },
                                                ]}
                                              ></TextInput>
                                            </td> : null }
                                            </>
                                            // </Fragment>
                                          );
                                        })}
                                      {/* </Then>
                                      </If> */}
                                      {SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year)?
                                      <td
                                        className="play-by-play"
                                      >
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <DivBlock
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              marginRight: "10px",
                                            }}
                                            actions={[
                                              {
                                                action: "Set Delete Play Row",
                                                trigger: "onclick",
                                                timeout: "",
                                                pollingPeriod: "",
                                                inputVariables: {
                                                  "input delete row id":
                                                    row["id"],
                                                  "input play row id":
                                                    row["id"],
                                                  "toggle delete": true,
                                                },
                                              },
                                            ]}
                                          >
                                            <i className={"fa fa-trash"}></i>
                                          </DivBlock>
                                        </DivBlock>
                                      </td>:null}
                                    </tr>
                                  </Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                      </DivBlock>

                      <DivBlock>
                        <Button
                          className={"btn btn-primary"}
                          style={
                            SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? 
                            {backgroundColor: "#48c46e",
                            borderColor: "#48c46e"}:
                            {backgroundColor: "#a0d2a0",
                            borderColor: "#a0d2a0",}
                          }
                          disabled={SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? false : true}
                          actions={[
                            {
                              action: "Toggle New Play Row",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "game id row": CurrentGame
                                  ? CurrentGame["Id"]
                                  : 0,
                                "input new play row": true,
                              },
                            },
                          ]}
                        >
                          Add Play
                        </Button>
                      </DivBlock>

                      <DivBlock>
                        <Button
                          className={"btn btn-primary"}
                          style={
                            SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year)? 
                            {backgroundColor: "#48c46e",
                            borderColor: "#48c46e"}:
                            {backgroundColor: "#a0d2a0",
                            borderColor: "#a0d2a0",}
                          }
                          disabled={SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? false : true}
                          actions={[
                            {
                              action: "Toggle New Row",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "input new row": true,
                              },
                            },
                          ]}
                        >
                          Add New Field for Game / Practice
                        </Button>
                      </DivBlock>
                      </DivBlock>}
                      </div>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>


            <If condition={this.props?.togglePackageSelect ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Select Package
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "TOGGLE_CHOOSE_PACKAGE",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                 value:false
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}>
                            <DivBlock>
                              <Label className={"label"}>
                                You must first assign a package to the play before you can grade players.
                              </Label>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

              {/* Saved Views Delete */}
            <If condition={saveViewModal ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "Set New Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input new position": false },
                    },
                    {
                      action: "Edit Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input position": null },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Delete View
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Delete Saved View",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                 value:false
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Are you sure you want to delete this view?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Set Delete Saved View",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      value:false
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Delete Saved View",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: { },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            {/* Bulk Dropdwon Delete Modal */}
            <If condition={BulkDeleteModal ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "Set New Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input new position": false },
                    },
                    {
                      action: "Edit Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input position": null },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Delete Play
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Bulk Play Row",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  'bulkmodal':false
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Are you sure you want to delete this play?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Set Bulk Play Row",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      'bulkmodal':false
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Delete Checkbox Bulk Grading",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      'bulkmodal':false
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={playmapping && playmapping.id ? true : false}
              show={false}
            >
              <Then>
                <DivBlock
                  className={"modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Play Mapping
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Hide Play Mapping",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input position": null,
                                  null: null,
                                  nullval: null,
                                  "Edit User": null,
                                  "input new user": false,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}>
                            <DivBlock className={"form-group"}>
                              <table
                                style={{
                                  borderCollapse: "collapse",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th className={"th-border"}>
                                      <Span className={"th-span"}>
                                        Coachtools Files
                                      </Span>
                                    </th>

                                    <th className={"th-border"}>
                                      <Span className={"th-span"}>
                                        Fields in file
                                      </Span>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>
                                        Play No.
                                      </Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          playmapping
                                            ? playmapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.index"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>Play</Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          playmapping
                                            ? playmapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.name"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>Qtr</Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          playmapping
                                            ? playmapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.qtr"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>
                                        Series
                                      </Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          playmapping
                                            ? playmapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.series"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown" 
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>Down</Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          playmapping
                                            ? playmapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.down"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>Distance</Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          playmapping
                                            ? playmapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.distance"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>


                                  <tr>
                                    <td 
                                    className="play-mapping-table-row">
                                      <Span className={"table-span"}>
                                        Play Type
                                      </Span>
                                    </td>

                                    <td 
                                    className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          playmapping
                                            ? playmapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.type"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                        actions={[
                                          {
                                            action: "SetCsvPlayMappingTitle",
                                            trigger: "onchange",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              index:
                                                currentCustomTitleCsvPlayMappingIndex,
                                            },
                                          },
                                        ]}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td 
                                    className="play-mapping-table-row">
                                      <Span className={"table-span"}>
                                        Package
                                      </Span>
                                    </td>
                                    <td 
                                    className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          this.handlePackage() && isArray(this.handlePackage())
                                            ? this.handlePackage()
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"value"}
                                        initialValue={(CsvPlayMappingPackage === undefined || CsvPlayMappingPackage === 0) ? null : CsvPlayMappingPackage}
                                        name={"CsvPlayMappingPackage"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                        // actions={[
                                        //   {
                                        //     action: "SetCsvPlayMappingTitle",
                                        //     trigger: "onchange",
                                        //     timeout: "",
                                        //     pollingPeriod: "",
                                        //     inputVariables: {
                                        //       index:
                                        //         currentCustomTitleCsvPlayMappingIndex,
                                        //     },
                                        //   },
                                        // ]}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  {CustomFieldTitles &&
                                    isArray(CustomFieldTitles) &&
                                    CustomFieldTitles.map((row, ind) => {
                                      return (
                                        <tr key={ind}>
                                          <td className="play-mapping-table-row">
                                            <Span className={"table-span"}>
                                              {row["title"]}
                                            </Span>
                                          </td>

                                          <td className="play-mapping-table-row">
                                            <SelectDropDown
                                              dataSet={
                                                playmapping
                                                  ? playmapping["mapping"]
                                                  : []
                                              }
                                              dataSetLabel={"name"}
                                              dataSetValue={"index"}
                                              initialValue={"99"}
                                              name={
                                                "currentCustomTitleCsvPlayMappingIndex"
                                              }
                                              options={[]}
                                              className="play-mapping-table-row-dropdown"
                                              type={"number"}
                                              actions={[
                                                {
                                                  action:
                                                    "Get_Csv_Play_Mapping_Titles_Cache",
                                                  trigger: "onchange",
                                                  timeout: "10",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    custom_title: row["title"],
                                                    title_id: row["id"],
                                                    index:
                                                      currentCustomTitleCsvPlayMappingIndex,
                                                  },
                                                },
                                              ]}
                                            ></SelectDropDown>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </DivBlock>
                            <If
                              condition={PlayUploadMappingError ? true : false}
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {PlayUploadMappingError ? PlayUploadMappingError : ""}
                                </Span>
                              </Then>
                            </If>

                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Map Play Csv",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              Submit
                            </Button>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={EditGame ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "New Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input new injured": false,
                        "input new position": false,
                      },
                    },
                    {
                      action: "Edit Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input injured player": null,
                        "input position": null,
                      },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Edit Game
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Edit Game",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "Edit Game": null,
                                  "input new exercise": false,
                                  "input new intangible":
                                    NewIntangible !== true,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create User",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Title</Label>

                              <TextInput
                                className={"form-control"}
                                initialValue={EditGame ? EditGame["title"] : ""}
                                name={"FormEditGame.title"}
                                placeholder={""}
                              ></TextInput>
                            </DivBlock>

                            <DivBlock className={"form-group"} style={{}}>
                              <Label className={"label"} style={{}}>
                                Date
                              </Label>

                              <DatePicker
                                className={"form-control"}
                                name={"FormEditGame.date"}
                                style={{}}
                              ></DatePicker>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Update Game",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                Submit
                              </Button>

                              <Button
                                className={"btn btn-reset"}
                                actions={[
                                  {
                                    action: "Set Delete Game",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "toggle delete game": true,
                                      "game practice id": EditGame
                                        ? EditGame["Id"]
                                        : 0,
                                    },
                                  },
                                ]}
                              >
                                Delete
                              </Button>
                            </DivBlock>

                            <If
                              condition={ToggleGameDelete ? true : false}
                              show={false}
                              style={{ zIndex: 500 }}
                            >
                              <Then>
                                <DivBlock
                                  className={"modal"}
                                >
                                  <DivBlock className={"modal-dialog"}>
                                    <DivBlock className={"modal-dialog"}>
                                      <DivBlock className={"modal-content"}>
                                        <DivBlock className={"modal-header"}>
                                          <Heading
                                            className="modal-heading"
                                            type={"h5"}
                                          >
                                            Delete Game / Practice
                                          </Heading>

                                          <Span
                                            className={"close"}
                                            actions={[
                                              {
                                                action: "Set Delete Game",
                                                trigger: "onclick",
                                                timeout: "",
                                                pollingPeriod: "",
                                                inputVariables: {
                                                  "game practice id": null,
                                                },
                                              },
                                            ]}
                                          >
                                            x
                                          </Span>
                                        </DivBlock>

                                        <DivBlock className={"modal-body"}>
                                          <FormBlock
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "100%",
                                            }}
                                            actions={[
                                              {
                                                action: "Create Depth Chart",
                                                trigger: "onsubmit",
                                                timeout: "",
                                                pollingPeriod: "",
                                                inputVariables: {},
                                              },
                                            ]}
                                          >
                                            <DivBlock>
                                              <Label className={"label"}>
                                                Are you sure you want to delete
                                                this? It cannot be undone.
                                              </Label>
                                            </DivBlock>

                                            <DivBlock
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <Button
                                                className={
                                                  "btn btn-reset DeleteBtn"
                                                }
                                                actions={[
                                                  {
                                                    action: "Set Delete Game",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      "game practice id": null,
                                                    },
                                                  },
                                                ]}
                                              >
                                                Cancel
                                              </Button>

                                              <Button
                                                className={"btn btn-primary"}
                                                actions={[
                                                  {
                                                    action: "Delete Game",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      "depth chart id to delete":
                                                        CurrentDepthChart
                                                          ? CurrentDepthChart[
                                                              "Id"
                                                            ]
                                                          : "",
                                                    },
                                                  },
                                                ]}
                                              >
                                                Yes
                                              </Button>
                                            </DivBlock>
                                          </FormBlock>
                                        </DivBlock>
                                      </DivBlock>
                                    </DivBlock>
                                  </DivBlock>
                                </DivBlock>
                              </Then>
                            </If>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={NewRow ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "New Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input new injured": false,
                        "input new position": false,
                      },
                    },
                    {
                      action: "Edit Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input position": null,
                        "input injured player": null,
                      },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Add New Field for Game / Practice
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Toggle New Row",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input new exercise": false,
                                  "input new intangible":
                                    NewIntangible !== true,
                                  "input new row": false,
                                  "Edit Game": false,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Title</Label>

                              <TextInput
                                className={"form-control"}
                                initialValue={""}
                                name={"FormNewPlayHeading.title"}
                                placeholder={""}
                              ></TextInput>

                              <If
                                condition={
                                  FormNewPlayHeadingErrors &&
                                  FormNewPlayHeadingErrors.title
                                    ? true
                                    : false
                                }
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormNewPlayHeadingErrors
                                      ? FormNewPlayHeadingErrors.title
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Create New Column",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                Submit
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={NewData ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "New Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input new injured": false,
                        "input new position": false,
                      },
                    },
                    {
                      action: "Edit Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input injured player": null,
                        "input position": null,
                      },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          ></Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Toggle New Data",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input data toggle": false,
                                  "input new exercise": false,
                                  "input new intangible":
                                    NewIntangible !== true,
                                  "input new row": false,
                                  "Edit Game": false,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create User",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>
                                {CurrentTitle ? CurrentTitle : 0}
                              </Label>

                              <TextInput
                                className={"form-control"}
                                initialValue={EditField}
                                name={"FormNewData"}
                                placeholder={""}
                              ></TextInput>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Create Custom Field data",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "play id": CurrentPlayId,
                                      "title id": CurrentTitleId,
                                    },
                                  },
                                ]}
                              >
                                Submit
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={PlayRowToDelete ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "Set New Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input new position": false },
                    },
                    {
                      action: "Edit Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input position": null },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Delete Play
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Delete Play Row",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "alias id input": null,
                                  "input delete row id": null,
                                  "input edit depth chart": null,
                                  "input new row": false,
                                  "input position": null,
                                  "toggle delete": false,
                                  "Depth Chart Id": null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Are you sure you want to delete this play?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Set Delete Play Row",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "input delete row id": null,
                                      "toggle delete": false,
                                      "Depth Chart Id": null,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Delete Play Row",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "depth chart id to delete":
                                        CurrentDepthChart
                                          ? CurrentDepthChart["Id"]
                                          : "",
                                      "input play row id": CurrentPlayId,
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>


            <If condition={ToggleDepthChart ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal play-modal usergrade"}
                >
                  <DivBlock className={"modal-dialog larger"} style={{}}>
                    <DivBlock className={"modal-dialog larger"} style={{}}>
                      <DivBlock className={"modal-content large"} style={{}}>
                        <DivBlock className={"modal-header"}>
                        <DivBlock className="header-wrapper">
                          <DivBlock>
                          <Heading
                            className="modal-heading"
                            type={"h4"}
                          >
                            {CurrentPlay || CurrentName ? CurrentPlay ? "Play #" + CurrentPlay  +  `${CurrentName ? `(${CurrentName})` : ''}` : '' : ''}
                          </Heading>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            {CurrentPlayPackage ? CurrentPlayPackage : ""}
                          </Heading>
                          </DivBlock>
                          {UserGrades && UserGrades.length > 0 ?
                          <DivBlock className="uploadVideo">
                          <button
                            onClick={this.handleHudlVideoUpload}
                            className={"btn btn-primary"}
                            disabled={SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? false : true}
                            style={SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? 
                              {backgroundColor: "#48c46e",
                              borderColor: "#48c46e",
                              fontWeight:500,
                              marginLeft:'15px',}:
                              {backgroundColor: "#a0d2a0",
                              borderColor: "#a0d2a0",
                              fontWeight:500,
                              marginLeft:'15px'}
                            }
                            // actions={[
                            //   {
                            //     action: "Set Upload Video",
                            //     trigger: "onclick",
                            //     timeout: "",
                            //     pollingPeriod: "",
                            //     inputVariables: {},
                            //   },
                            // ]}
                          >
                            Add Hudl Video
                          </button>
                          </DivBlock> : null}
                          </DivBlock>
                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Remove ToggleDepthChart",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>
                        
                        {UserGrades && UserGrades.length > 0 ?
                        <DivBlock>
                          {GetUploadedVideo && isArray(GetUploadedVideo) ?
                          <DivBlock className="videoPlayer">
                            <VideoBox
                            videoUrls={GetUploadedVideo}
                            loop autoPlay/> 
                          </DivBlock> : null}
                        <DivBlock className="custom-tab-wrapper" onClick={()=>this.setState({gradeCheckAll:false,selectedGradeDataArray:[]})}>
                          <CustomTabbing
                            getTabId={(e) => this.handleSelectTab(e)}
                            toggleValue = {this.state.toggleClear}
                          />
                            <div className="grade-tab">
                            {gradeDropDownData?.map((item)=>
                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "CHECKBOX_BULK_GRADING_SCALE_DATA",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    'id':this.state.selectedGradeDataArray,
                                    'position value':this.state.tabValue,
                                    "gradingValue":item?.value

                                  }
                                },
                              ]}
                            >
                              {item?.label}
                            </Button>
                           
                            )}        
                          </div>
                        </DivBlock> 
                        <DivBlock className="mobile-checkbox">
                          <Checkbox 
                            handleClick={handleGradeCheckAll}
                            isChecked={this.state.gradeCheckAll}
                            type="checkbox" 
                            name="selectAll" 
                            id="selectAll"/>
                            <span className="checkbox-span" onClick={handleGradeCheckAll}>Select All Player</span>
                        </DivBlock>
                        <If
                          condition={
                            CheckboxError &&
                            CheckboxError?.[0]
                              ? true
                              : false
                          }
                          show={true}
                        >
                          <Then>
                            <Span style={{paddingLeft:'1rem'}} className={"invalid-feedback"}>
                              {CheckboxError
                                ? CheckboxError[0]
                                : ""}
                            </Span>
                          </Then>
                        </If>

                          <If
                          condition={
                            userGradingError && userGradingError !== ''
                              ? true
                              : false
                          }
                          show={true}
                        >
                          <Then>
                            <Span className={"invalid-feedback"} style={{
                              display: 'flex',justifyContent: 'center'
                              }}>
                              {userGradingError ? userGradingError
                                : ""}
                            </Span>
                          </Then>
                        </If>

                        <If
                          condition={
                            productionPointSuccessMessage && productionPointSuccessMessage !== ''
                              ? true
                              : false
                          }
                          show={true}
                        >
                          <Then>
                            <Span className={"invalid-feedback"} style={{
                              display: 'flex',justifyContent: 'center',color:'#48c46e'
                              }}>
                              {productionPointSuccessMessage ? productionPointSuccessMessage
                                : ""}
                            </Span>
                          </Then>
                        </If>

                        {this.props?.toggleUserGradeLoader ?
                        <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <CircularProgress
                          size={40}
                          thickness={4}
                          style={{
                            display: ["flex", "-webkit-inline-box"],
                            color: "#48c46e",
                            margin:'20px 0',
                          }}
                        /></div>:
                        <DivBlock className={"modal-body"}>
                          <DivBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                              <DivBlock
                                className="mobile_overflow"
                                style={{ maxWidth: "100%", overflow: "auto",maxHeight:'600px' }}
                              >
                                <table
                                  className={"table grading desktop_tabbing"}
                                  style={{}}
                                >
                                  <thead>
                                    <tr className={"thead-bg"}>
                                      <th className={"th-border"}>
                                      <Checkbox 
                                        handleClick={handleGradeCheckAll}
                                        isChecked={this.state.gradeCheckAll}
                                        type="checkbox" 
                                        name="selectAll" 
                                        id="selectAll"/>
                                      </th>

                                      <th className={"th-border"}>
                                        <Span className={"th-span"}></Span>
                                      </th>

                                      <th className={"th-border"}>
                                        <Span className={"th-span"}></Span>
                                      </th>

                                      <th className={"th-border"}>
                                        <Span className={"th-span"}>
                                          Position
                                        </Span>
                                      </th>

                                      <th className={"th-border player-name"}>
                                        <Span className={"th-span"}>
                                          Personnel
                                        </Span>
                                      </th>

                                      <th className={"th-border"}>
                                        <Span className={"th-span"}>
                                          Production Points
                                        </Span>
                                      </th>

                                      {Intangibles &&
                                        isArray(Intangibles) &&
                                        Intangibles.length > 0 &&
                                        Intangibles.map((row, arrayInd) => {
                                          if (
                                            this.state.tabValue ===
                                            row.position_name
                                          ) {
                                            return (
                                              <th
                                                key={arrayInd}
                                                className={"th-border"}
                                                style={{ minWidth: "100px" }}
                                              >
                                                <Span className={"th-span"}>
                                                  {row["name"]}
                                                </Span>
                                              </th>
                                            );
                                          }
                                        })}

                                      <th className={"th-border"}>
                                        <Span className={"th-span"}>
                                          Notes
                                        </Span>
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {posFilterData &&
                                      isArray(posFilterData) &&
                                      posFilterData.map((val, index) => {
                                        let disableVar = false;
                                        if (
                                          CurrentUser?.user_type ===
                                          "Position Coach"
                                        ) {
                                          disableVar = !CurrentUser?.secondary
                                            ?.split(",")
                                            ?.includes(val["position"]);
                                        }

                                        return (
                                          <tr key={index}>
                                            <td className="grading-scale">
                                            <Checkbox 
                                              type="checkbox"
                                              isChecked={val.isChecked}  
                                              name={val.name} 
                                              disabled={val?.["name"] !== '' ? false : true }
                                              handleClick={()=>handleGradeOnCheckBoxChange(val)}
                                              />
                                            </td>

                                            <td
                                              className="grading-scale"
                                            >
                                              <DivBlock
                                                style={{
                                                  cursor: "pointer",
                                                  pointerEvents:
                                                    disableVar === true
                                                      ? "none"
                                                      : null,
                                                }}
                                                actions={[
                                                  {
                                                    action:
                                                      "Set Delete Play Depth Chart User",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      "input play depth chart user id":
                                                        val,
                                                      "toggle delete row": true,
                                                    },
                                                  },
                                                ]}
                                              >
                                                <i
                                                  className={"fa fa-trash"}
                                                ></i>
                                              </DivBlock>
                                            </td>


                                            <td className="grading-scale" >
                                              <Image src={process.env.REACT_APP_PROFILE + val?.profile_image?.thumbnail}
                                                style={{
                                                height: '35px',
                                                width: '35px',
                                                marginLeft:'20px',
                                                borderRadius:'50%'
                                                }}/>
                                            </td>

                                            <td
                                              className="grading-scale"
                                            >
                                              <Span className={"table-span"}>
                                                {val["position"]}
                                              </Span>
                                            </td>

                                            <td
                                              className="grading-scale"
                                            >
                                              <DivBlock
                                                style={{
                                                  cursor: "pointer",
                                                  pointerEvents:
                                                    disableVar === true
                                                      ? "none"
                                                      : null,
                                                }}
                                                actions={[
                                                  {
                                                    action:
                                                      "Set User DropDown",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      "user id": val,
                                                      "user name":
                                                        val["name"],
                                                      "toggle dropdown": true,
                                                    },
                                                  },
                                                ]}
                                              >
                                                <Span
                                                  className={"table-span"}
                                                >
                                                  {val["name"] !== '' ? val["player_number"] ? val['name'] + " ("+ val["player_number"] +")" :val['name'] : 'None'}
                                                </Span>
                                              </DivBlock>
                                            </td>
                                              
                                            <td
                                              className="grading-scale">
                                              <CustomSelectDropDown
                                              options={this.props?.productionPointsData?.map((option) => option?.production_point?.name)}  
                                              onInputChange={(e)=>this.handleInputChange(e,val?.id)}
                                              disabled={(SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) && val["name"] !== '') ? false : true}
                                              handleChange={(e,newVal,id,isSuccess)=>this.handleDropDownData(newVal,val?.id,isSuccess)}
                                              value={this.state?.productionPointDataArray?.[val?.id] ? 
                                                this.state.productionPointDataArray?.[val.id] 
                                                : (val?.production_point === "None" || val?.production_point === "") ? [] : val?.production_point?.split(' | ')}
                                              onBlur={()=>this.handleBlurApiCall(this.state.productionPointDataArray[val?.id],val?.id)}
                                              id={val?.id}
                                              productionPoint={this.props?.productionPoints}
                                              tabValue={this.state.tabValue}
                                              />
                                            </td>

                                            {Intangibles &&
                                              isArray(Intangibles) &&
                                              Intangibles.length > 0 &&
                                              Intangibles.map(
                                                (inRow, inIndex) => {
                                                  let updatedItem =
                                                    val["user_grades"] &&
                                                    isArray(
                                                      val["user_grades"]
                                                    ) &&
                                                    val["user_grades"].filter(
                                                      (gradeItem) =>
                                                        gradeItem?.intangible_name ===
                                                        inRow?.["name"]
                                                    );
                                                  if (
                                                    updatedItem &&
                                                    isArray(updatedItem)
                                                  ) {
                                                      GetScaleTypeData?.filter(
                                                        (val) =>
                                                          val?.position_id ===
                                                          updatedItem[0]
                                                            ?.position_id
                                                      )    
                                                  }
                                                  if (
                                                    this.state.tabValue ===
                                                    inRow.position_name
                                                  ) {
                                                    return (
                                                      <td
                                                        key={inIndex}
                                                        className="grading-scale-intangible"
                                                      >
                                                        {updatedItem?.length >
                                                          0 &&
                                                        val["scale_type"] ? (
                                                          <DivBlock>
                                                            <SelectDropDown
                                                              disabled={
                                                                disableVar ? disableVar :
                                                                (SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year)&&val["name"] !== '') ? false :true
                                                                // SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? false 
                                                                //  val["name"] === '' ? true : false
                                                              }
                                                              dataSet={""}
                                                              dataSetLabel={
                                                                ""
                                                              }
                                                              dataSetValue={
                                                                ""
                                                              }
                                                              enableReinitialize={
                                                                false
                                                              }
                                                              initialValue={
                                                                isArray(
                                                                  updatedItem
                                                                ) &&
                                                                updatedItem[0]?.[
                                                                  "integer_value"
                                                                ]
                                                                  ? isArray(
                                                                      updatedItem
                                                                    ) &&
                                                                    updatedItem[0]?.[
                                                                      "integer_value"
                                                                    ]
                                                                  : "None"
                                                              }
                                                              name={
                                                                "currentuserintegervalue"
                                                              }
                                                              options={GetScaleTypeValue(val[ "scale_type" ],updatedItem,GradingPositionScaleTypeData)}
                                                              className="scale-type"
                                                              type={
                                                                "plain"
                                                              }
                                                              actions={[
                                                                {
                                                                  action:
                                                                    "Create User Grade",
                                                                  trigger:
                                                                    "onchange",
                                                                  timeout:
                                                                    "",
                                                                  pollingPeriod:
                                                                    "",
                                                                  inputVariables:
                                                                    {
                                                                      "input depth chartid":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "depthchart_id"
                                                                        ],
                                                                      "input intangible id":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "intangible_id"
                                                                        ],
                                                                      "input intangibleid":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "intangible_id"
                                                                        ],
                                                                      "input row id":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "id"
                                                                        ],
                                                                      "input user id":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "user_id"
                                                                        ],
                                                                      "input userid":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "user_id"
                                                                        ],
                                                                        "row data":val
                                                                    },
                                                                },
                                                              ]}
                                                            ></SelectDropDown>
                                                          </DivBlock>
                                                        ) : (
                                                        <DivBlock>
                                                          <SelectDropDown
                                                            disabled={
                                                              disableVar ? disableVar :
                                                              (SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year)&&val["name"] !== '') ? false : true
                                                            }
                                                            dataSet={""}
                                                            dataSetLabel={
                                                              ""
                                                            }
                                                            dataSetValue={
                                                              ""
                                                            }
                                                            enableReinitialize={
                                                              false
                                                            }
                                                            initialValue={
                                                              isArray(
                                                                updatedItem
                                                              ) &&
                                                              updatedItem[0]?.[
                                                                "integer_value"
                                                              ]
                                                                ? isArray(
                                                                    updatedItem
                                                                  ) &&
                                                                  updatedItem[0]?.[
                                                                    "integer_value"
                                                                  ]
                                                                : "NULL"
                                                            }
                                                            name={
                                                              "currentuserintegervalue"
                                                            }
                                                            options={GradingPositionScaleTypeData?.[val?.["scale_type"]]}
                                                            className="scale-type"
                                                            type={
                                                              "plain"
                                                            }
                                                            actions={[
                                                              {
                                                                action:
                                                                  "Create User Grade",
                                                                trigger:
                                                                  "onchange",
                                                                timeout:
                                                                  "",
                                                                pollingPeriod:
                                                                  "",
                                                                inputVariables:
                                                                  {
                                                                    "input depth chartid":
                                                                      val?.depthchart_id,
                                                                    "input intangibleid":
                                                                      inRow?.intangible_id,
                                                                    "input row id":
                                                                      isArray(
                                                                        updatedItem
                                                                      ) &&
                                                                      updatedItem[0]?.[
                                                                        "id"
                                                                      ],
                                                                    "input userid":
                                                                      val?.user_id,
                                                                  },
                                                              },
                                                            ]}
                                                          ></SelectDropDown>
                                                        </DivBlock>
                                                        )}
                                                      </td>
                                                    );
                                                  }
                                                }
                                              )}

                                            <td
                                            className="grading-scale"
                                            >
                                              <TextInput
                                                initialValue={val["notes"]}
                                                name={"FormPlayUserNotes"}
                                                enableReinitialize={true}
                                                placeholder={""}
                                                disabled={disableVar ? disableVar : 
                                                  (SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) && val["name"] !== '') ? false : true}
                                                style={{
                                                  background: "#fff",
                                                  border: "1px solid #ced4da",
                                                  borderRadius: "0.25rem",
                                                  color: "#495057",
                                                  // display: "block",
                                                  fontFamily:"'Poppins', sans-serif",
                                                  fontSize: "13px",
                                                  fontWeight: 400,
                                                  lineHeight: 1.5,
                                                  paddingBottom: "0.375rem",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "0.375rem",
                                                  width: "auto",
                                                }}
                                                actions={[
                                                  {
                                                    action:
                                                      "Update Play User Notes",
                                                    trigger: "onblur",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      depth_chart_id_notes:
                                                        val["id"],
                                                    },
                                                  },
                                                  {
                                                    action: "Edit Play User Notes",
                                                    trigger: "onfocus",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      value: val["notes"],
                                                    },
                                                  },
                                                ]}
                                              ></TextInput>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>

                                {/* {"MOBILE CSS FOR GRADING SECTION"} */}

                                <DivBlock className="mobile_tabbing">
                                  <DivBlock className="mobile_tab_desc">
                                    {posFilterData &&
                                      isArray(posFilterData) &&
                                      posFilterData.map((val, index) => {
                                        let disableVar = false;
                                        if (
                                          CurrentUser?.user_type ===
                                          "Position Coach"
                                        ) {
                                          disableVar = !CurrentUser?.secondary
                                            ?.split(",")
                                            ?.includes(val["position"]);
                                        }

                                        return (
                                          <DivBlock className="mobile_tab-top">
                                            <ul>
                                              <li>
                                                <Checkbox 
                                                type="checkbox"
                                                isChecked={val.isChecked}  
                                                name={val.name} 
                                                handleClick={()=>handleGradeOnCheckBoxChange(val)}
                                                />
                                              </li>
                                              <li style={{display: 'flex'}}>
                                                <Image 
                                                src={process.env.REACT_APP_PROFILE + val?.profile_image?.thumbnail}
                                                style={{
                                                  width:'35px',
                                                  height:'35px',
                                                  borderRadius:'50%'
                                                }}/>
                                              </li>
                                              <li>
                                              <DivBlock
                                                  className="table-span"
                                                  style={
                                                    disableVar === true
                                                      ? {
                                                          pointerEvents:
                                                            "none",
                                                        }
                                                      : {}
                                                  }
                                                  actions={[
                                                    {
                                                      action:
                                                        "Set User DropDown",
                                                      trigger: "onclick",
                                                      timeout: "",
                                                      pollingPeriod: "",
                                                      inputVariables: {
                                                        "user id": val,
                                                        "user name":
                                                          val["name"],
                                                        "toggle dropdown": true,
                                                      },
                                                    },
                                                  ]}
                                                >
                                                  <Span
                                                    className={"table-span"}
                                                  >
                                                    {val["name"] !== '' ? val["player_number"] ? val['name'] + " ("+ val["player_number"] +")" :val['name'] : 'None'}
                                                  </Span>
                                                </DivBlock>
                                              </li>
                                              <li>
                                                <Span className="table-span">
                                                  {val["position"]}
                                                </Span>
                                              </li>
                                              <li>
                                                <DivBlock
                                                  style={{
                                                    cursor: "pointer",
                                                    pointerEvents:
                                                      disableVar === true
                                                        ? "none"
                                                        : null,
                                                  }}
                                                  actions={[
                                                    {
                                                      action:
                                                        "Set Delete Play Depth Chart User",
                                                      trigger: "onclick",
                                                      timeout: "",
                                                      pollingPeriod: "",
                                                      inputVariables: {
                                                        "input play depth chart user id":
                                                          val,
                                                        "toggle delete row": true,
                                                      },
                                                    },
                                                  ]}
                                                >
                                                  <i
                                                    className={"fa fa-trash"}
                                                  ></i>
                                                </DivBlock>
                                              </li>
                                            </ul>
                                            <DivBlock className="production-main">
                                              <DivBlock className="production">
                                                <Span className="th-span">
                                                  {" "}
                                                  Production Points
                                                </Span>
                                               <CustomSelectDropDown
                                                options={this.props?.productionPointsData?.map((option) => option.production_point?.name)}
                                                suggestionText={<FormHelperText style={{fontStyle:'italic'}}>*Type and enter to create new production point.</FormHelperText>}
                                                onInputChange={(e)=>this.handleInputChange(e,val?.id)}
                                                disabled={(SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) && val["name"] !== '') ? false : true}
                                                handleChange={(e,newVal,id,isSuccess)=>this.handleDropDownData(newVal,val?.id,isSuccess)}
                                                value={this.state.productionPointDataArray[val?.id] ? this.state.productionPointDataArray[val.id] : (val?.production_point === "None" || val?.production_point === "")   ? "" : val?.production_point?.split(' | ')}
                                                onBlur={()=>this.handleBlurApiCall(this.state.productionPointDataArray[val?.id],val?.id)}
                                                id={val?.id}
                                                productionPoint={this.props?.productionPoints}
                                                tabValue={this.state.tabValue}
                                              />
                                              </DivBlock>
                                              {Intangibles &&
                                                isArray(Intangibles) &&
                                                Intangibles.length > 0 &&
                                                Intangibles.map(
                                                  (inRow, arrayInd) => {
                                                    let updatedItem =
                                                      val["user_grades"] &&
                                                      isArray(
                                                        val["user_grades"]
                                                      ) &&
                                                      val[
                                                        "user_grades"
                                                      ].filter(
                                                        (gradeItem) =>
                                                          gradeItem?.intangible_name ===
                                                          inRow?.["name"]
                                                      );
                                                    if (
                                                      updatedItem &&
                                                      isArray(updatedItem)
                                                    ) {
                                                        GetScaleTypeData?.filter(
                                                              (val) =>
                                                                val?.position_id ===
                                                                updatedItem[0]
                                                                  ?.position_id
                                                            )
                                                    }
                                                    if (
                                                      this.state.tabValue ===
                                                      inRow.position_name
                                                    ) {
                                                      return (
                                                        <DivBlock
                                                          key={arrayInd}
                                                          style={{
                                                            borderColor:
                                                              "#000000",
                                                            paddingBottom:
                                                              "5px",
                                                            paddingTop: "5px",
                                                          }}
                                                        >
                                                          <Span className="th-span">
                                                            {inRow["name"]}
                                                          </Span>
                                                          {updatedItem?.length >
                                                            0 &&
                                                          val[
                                                            "scale_type"
                                                          ] ? (
                                                            <DivBlock>
                                                            <SelectDropDown
                                                              disabled={
                                                                disableVar ? disableVar :
                                                                (SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year)&&val["name"] !== '') ? false : true
                                                              }
                                                              enableReinitialize={
                                                                false
                                                              }
                                                              initialValue={
                                                                isArray(
                                                                  updatedItem
                                                                ) &&
                                                                updatedItem[0]?.[
                                                                  "integer_value"
                                                                ]
                                                                  ? isArray(
                                                                      updatedItem
                                                                    ) &&
                                                                    updatedItem[0]?.[
                                                                      "integer_value"
                                                                    ]
                                                                  : "None"
                                                              }
                                                              name={
                                                                "currentuserintegervalue"
                                                              }
                                                              options={GetScaleTypeValue(val[ "scale_type" ],updatedItem,GradingPositionScaleTypeData)}
                                                              className="scale-type"
                                                              type={
                                                                "plain"
                                                              }
                                                              actions={[
                                                                {
                                                                  action:
                                                                    "Create User Grade",
                                                                  trigger:
                                                                    "onchange",
                                                                  timeout:
                                                                    "",
                                                                  pollingPeriod:
                                                                    "",
                                                                  inputVariables:
                                                                    {
                                                                      "input depth chartid":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "depthchart_id"
                                                                        ],
                                                                      "input intangible id":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "intangible_id"
                                                                        ],
                                                                      "input intangibleid":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "intangible_id"
                                                                        ],
                                                                      "input row id":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "id"
                                                                        ],
                                                                      "input user id":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "user_id"
                                                                        ],
                                                                      "input userid":
                                                                        isArray(
                                                                          updatedItem
                                                                        ) &&
                                                                        updatedItem[0]?.[
                                                                          "user_id"
                                                                        ],
                                                                    },
                                                                },
                                                              ]}
                                                            ></SelectDropDown>
                                                          </DivBlock>
                                                        ) : (
                                                        <DivBlock>
                                                          <SelectDropDown
                                                            disabled={
                                                              disableVar ? disableVar :
                                                              (SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year)&&val["name"] !== '') ? false :true
                                                            }
                                                            enableReinitialize={
                                                              false
                                                            }
                                                            initialValue={
                                                              isArray(
                                                                updatedItem
                                                              ) &&
                                                              updatedItem[0]?.[
                                                                "integer_value"
                                                              ]
                                                                ? isArray(
                                                                    updatedItem
                                                                  ) &&
                                                                  updatedItem[0]?.[
                                                                    "integer_value"
                                                                  ]
                                                                : "NULL"
                                                            }
                                                            name={
                                                              "currentuserintegervalue"
                                                            }
                                                            options={GradingPositionScaleTypeData?.[val?.["scale_type"]]}
                                                            className="scale-type"
                                                            type={
                                                              "plain"
                                                            }
                                                            actions={[
                                                              {
                                                                action:
                                                                  "Create User Grade",
                                                                trigger:
                                                                  "onchange",
                                                                timeout:
                                                                  "",
                                                                pollingPeriod:
                                                                  "",
                                                                inputVariables:
                                                                  {
                                                                    "input depth chartid":
                                                                      val?.depthchart_id,
                                                                    "input intangibleid":
                                                                      inRow?.intangible_id,
                                                                    "input row id":
                                                                      isArray(
                                                                        updatedItem
                                                                      ) &&
                                                                      updatedItem[0]?.[
                                                                        "id"
                                                                      ],
                                                                    "input userid":
                                                                      val?.user_id,
                                                                  },
                                                              },
                                                            ]}
                                                          ></SelectDropDown>
                                                        </DivBlock>
                                                          )}
                                                        </DivBlock>
                                                      );
                                                    }
                                                  }
                                                )}
                                              <DivBlock className="production">
                                                <Span className="th-span">
                                                  Notes
                                                </Span>
                                                <TextInput
                                                  initialValue={val["notes"]}
                                                  name={"FormPlayUserNotes"}
                                                  enableReinitialize={true}
                                                  placeholder={""}
                                                  disabled={disableVar ? disableVar : 
                                                    (SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) && val["name"] !== '') ? false : true}
                                                  style={{
                                                    background: "#fff",
                                                    border:
                                                      "1px solid #ced4da",
                                                    borderRadius: "0.25rem",
                                                    color: "#495057",
                                                    // display: "block",
                                                    fontFamily:"'Poppins', sans-serif",
                                                    fontSize: "13px",
                                                    fontWeight: 400,
                                                    lineHeight: 1.5,
                                                    paddingBottom: "0.375rem",
                                                    paddingLeft: "0.75rem",
                                                    paddingRight: "0.75rem",
                                                    paddingTop: "0.375rem",
                                                    width: "auto",
                                                  }}
                                                  actions={[
                                                    {
                                                      action:
                                                        "Update Play User Notes",
                                                      trigger: "onblur",
                                                      timeout: "",
                                                      pollingPeriod: "",
                                                      inputVariables: {
                                                        depth_chart_id_notes:
                                                          val["id"],
                                                      },
                                                    },
                                                    {
                                                      action: "Edit Position",
                                                      trigger: "onfocus",
                                                      timeout: "",
                                                      pollingPeriod: "",
                                                      inputVariables: {
                                                        "input position":
                                                          null,
                                                        value: val["notes"],
                                                      },
                                                    },
                                                  ]}
                                                ></TextInput>
                                              </DivBlock>
                                            </DivBlock>
                                          </DivBlock>
                                        );
                                      })}
                                  </DivBlock>
                                </DivBlock>
                              </DivBlock>

                                <DivBlock
                                  className="modal_desktop_btn"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Button
                                    className={"btn btn-primary"}
                                    actions={[
                                      {
                                        action: "add grading play",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          position:this.state.tabValue
                                        },
                                      },
                                    ]}
                                  >
                                    Add Player
                                  </Button>
                                  <DivBlock style={{marginLeft:"10px"}}
                                  onClick={()=> this.setState({toggleClear:true})}>
                                    <Button
                                    className={"btn btn-primary"}
                                    style={{marginRight:"20px"}}
                                    actions={[
                                      {
                                        action: "Remove ToggleDepthChart",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {},
                                      },
                                    ]}
                                  >
                                    Save & Close
                                  </Button>
                                  <button
                                    className={"btn btn-primary"}
                                    disabled={DepthChartIndex>=PlayByPlayInfo?.length}
                                    style={
                                       DepthChartIndex >= PlayByPlayInfo?.length?{
                                        backgroundColor: "#a0d2a0",
                                        borderColor: "#a0d2a0",
                                    }
                                  : {
                                      backgroundColor: "#48c46e",
                                      borderColor: "#48c46e",
                                    }}
                                    onClick={this.handleSaveAndNextGrades}
                                    // actions={[
                                    //   {
                                    //     action: "Set Next Depth Chart",
                                    //     trigger: "onclick",
                                    //     timeout: "",
                                    //     pollingPeriod: "",
                                    //     inputVariables: {},
                                    //   },
                                    // ]}
                                  >
                                    Save & Next
                                  </button>
                                  </DivBlock>
                                </DivBlock>
                          </DivBlock>
                        </DivBlock>}
                        </DivBlock>:
                         <DivBlock style={{ textAlign: "center",padding:'2rem' }}>
                         <Span
                           style={{
                             fontFamily:"'Poppins', sans-serif",
                             fontSize: "14px",
                             fontWeight: "400",
                             textAlign: "center",
                           }}
                         >
                           Please first add data on the depth chart
                           page and then select here.
                         </Span>
                       </DivBlock>}
                                             
                        {/* Mobile Responsive pop-up modal buttons */}
                        { UserGrades && UserGrades.length > 0 ?
                        <DivBlock
                          className="modal_mobile_btn"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                           <Button
                            className={"btn btn-primary"}
                            actions={[
                              {
                                action: "add grading play",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  position:this.state.tabValue
                                },
                              },
                            ]}
                          >
                            Add Player
                          </Button>
                          <Button
                            className={"btn btn-primary"}
                            style={{marginLeft:"10px"}}
                            actions={[
                              {
                                action: "Remove ToggleDepthChart",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            Save & Close
                          </Button>
                          <DivBlock style={{marginLeft:"10px"}}
                          onClick={()=> this.setState({toggleClear:true})}>
                          <button
                            className={"btn btn-primary"}
                            disabled={DepthChartIndex>=PlayByPlayInfo?.length}
                            style={
                                DepthChartIndex >= PlayByPlayInfo?.length ?{
                                backgroundColor: "#a0d2a0",
                                borderColor: "#a0d2a0",
                            }
                          : {
                              backgroundColor: "#48c46e",
                              borderColor: "#48c46e",
                            }}
                            onClick={this.handleSaveAndNextGrades}
                            // actions={[
                            //   {
                            //     action: "Set Next Depth Chart",
                            //     trigger: "onclick",
                            //     timeout: "",
                            //     pollingPeriod: "",
                            //     inputVariables: {},
                            //   },
                            // ]}
                          >
                            Save & Next
                          </button>
                          </DivBlock>
                        </DivBlock> : null}
                         
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={productionPointError ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal play-modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Production Point
                          </Heading>

                          <span
                            className={"close"}
                            onClick={this.handleCancelProductionPoint}
                            // actions={[
                            //   {
                            //     action: "PRODUCTION_POINT_ERROR_MESSAGE",
                            //     trigger: "onclick",
                            //     timeout: "",
                            //     pollingPeriod: "",
                            //     inputVariables: {},
                            //   },
                            // ]}
                          >
                            x
                          </span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}>
                            <DivBlock>
                              <Label className={"label"}>
                                The following production point is not in our system. Would you like to request that it be added? Please note - this may take a day or two for approval. Contact support for faster approval.
                              </Label>
                              <Label className={"label"}>
                                Production Point Name : {this.props?.productionPointErrorMessage}
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                className={"btn btn-reset DeleteBtn"}
                                onClick={this.handleCancelProductionPoint}
                                // actions={[
                                //   {
                                //     action: "PRODUCTION_POINT_ERROR_MESSAGE",
                                //     trigger: "onclick",
                                //     timeout: "",
                                //     pollingPeriod: "",
                                //     inputVariables: {},
                                //   },
                                // ]}
                              >
                                Cancel
                              </button>

                              <button
                                className={"btn btn-primary"}
                                onClick={this.handleSubmitProductionPoint}
                                // actions={[
                                //   {
                                //     action: "PRODUCTION_POINT_ERROR_MESSAGE",
                                //     trigger: "onclick",
                                //     timeout: "",
                                //     pollingPeriod: "",
                                //     inputVariables: {},
                                //   },
                                // ]}
                              >
                                Yes
                              </button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={updateBulkEdit ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal play-modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            User Grades
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Bulk Edit Row",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}>
                            <DivBlock>
                              <Label className={"label"}>
                              This will overwrite all grades for this position group. Are you sure?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Set Bulk Edit Row",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Update Bulk Grading Scale Data",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            {/* Upload Video */}
            <If condition={ToggleUploadVideo ? true : false} show={true}>
              <Then>
                <DivBlock className={"modal play-modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}>
                              Upload link
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Upload Video",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              }
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Link</Label>

                            {/* <TextInput
                              className={"form-control"}
                              initialValue={""}
                              name={"FormUploadVideo.uploadVideo"}
                              placeholder={""}
                            ></TextInput> */}
                             <input
                              className="form-control"
                              onChange={this.handleVideoChange}
                              value={this.state.videoValue}
                            />

                            <If
                              condition={
                                FormUploadVideoErrors &&
                                FormUploadVideoErrors.name
                                  ? true
                                  : false
                              }
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormUploadVideoErrors
                                    ? FormUploadVideoErrors.name
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}>
                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Update Video Link",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    play_id:CurrentPlayId,
                                    currentVideoValue:this.state.videoValue
                                  },
                                },
                              ]}
                            >
                              Submit
                            </Button>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            {/* Change player in pop-up modal */}
            <If
              condition={ToggleUserDropDown ? true : false}
              show={true}
            >
              <Then>
                <DivBlock
                  className={"modal play-modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          ></Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set User DropDown",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "package id input": null,
                                  "toggle notes": false,
                                  "alias id input": null,
                                  "input new row": false,
                                  "input position": null,
                                  "notes id input": null,
                                  "toggle dropdown": false,
                                  "toggle package": false,
                                  "input edit depth chart":
                                    null,
                                },
                              },
                              {
                                action:
                                  "Toggle DepthChartUser DropDown",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input depth chart user": false,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}>
                          
                            <DivBlock className={"form-group"}>
                              <Label style={{marginBottom: "1.5px"}} className={"label"}>Name</Label>
                              {Users && isArray(Users) && Users ?
                              <CustomAutoComplete name="position_autocomplete" size="small" id="auto_complete"
                                data={(Users && isArray(Users))&& Users?.map(val => { return { ...val, title: `${val?.name}` } })}
                                selectedValue={{ currentSelectedPositionData }}
                              /> 
                              : null}
                            </DivBlock>
                            <If
                              condition={
                                gradingPlayerNameError &&
                                gradingPlayerNameError !== ''
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span
                                  className={
                                    "invalid-feedback"
                                  }
                                >
                                  {gradingPlayerNameError
                                    ? gradingPlayerNameError
                                    : ""}
                                </Span>
                              </Then>
                            </If>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-primary"}
                                style={
                                  SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year)? 
                                  {backgroundColor: "#48c46e",
                                  borderColor: "#48c46e"}:
                                  {backgroundColor: "#a0d2a0",
                                  borderColor: "#a0d2a0",}
                                }
                                disabled={SeasonYearData?.[0]?.year === Number(this.state?.myParam?.year) ? false : true}
                                actions={[
                                  {
                                    action: "Update Play User",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {currentSelectedPositionData},
                                  },
                                ]}
                              >
                                Save
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            {/*  Delete Player in pop-up modal */}
            <If
              condition={
                ToggleDeletePlayDepthChartUser ? true : false
              }
              show={false}
            >
              <Then>
                <DivBlock
                  className={"modal play-modal"}
                  actions={[
                    {
                      action: "Set New Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input new position": false,
                      },
                    },
                    {
                      action: "Edit Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input position": null,
                      },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Delete User
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action:
                                  "Set Delete Play Depth Chart User",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "toggle delete row": false,
                                  "Depth Chart Id": null,
                                  "alias id input": null,
                                  "input edit depth chart":
                                    null,
                                  "input new row": false,
                                  "input play depth chart user id":
                                    null,
                                  "input position": null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Are you sure you want to delete
                                this user?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={
                                  "btn btn-reset DeleteBtn"
                                }
                                actions={[
                                  {
                                    action:
                                      "Set Delete Play Depth Chart User",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "Depth Chart Id": null,
                                      "input play depth chart user id":
                                        null,
                                      "toggle delete row": false,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action:
                                      "Delete User Depth Chart",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "input depth chart user id":
                                        CurrentPlayDepthChartId,
                                      "user depth chart id":
                                        CurrentPlayDepthChartId,
                                      "depth chart id to delete":
                                        CurrentDepthChart
                                          ? CurrentDepthChart[
                                              "Id"
                                            ]
                                          : "",
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={NewPlayRow ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "Set New Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input new position": false },
                    },
                    {
                      action: "Edit Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input position": null },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Add Play
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Toggle New Play Row",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "alias id input": null,
                                  "input edit depth chart": null,
                                  "input new play row": false,
                                  "input new row": false,
                                  "input position": null,
                                  "package id input": null,
                                  "toggle package": false,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Play No.</Label>

                              <TextInput
                                initialValue={LastIndex ? LastIndex + 1 : 1}
                                name={"FormCreateNewPlay.index"}
                                placeholder={""}
                                style={{
                                  background: "#fff",
                                  border: "1px solid #ced4da",
                                  borderRadius: "0.25rem",
                                  color: "#495057",
                                  display: "block",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  lineHeight: 1.5,
                                  paddingBottom: "0.375rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  paddingTop: "0.375rem",
                                  width: "100%",
                                }}
                                type={"number"}
                              ></TextInput>

                              <If
                                condition={
                                  FormCreateNewPlayErrors &&
                                  FormCreateNewPlayErrors.index
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormCreateNewPlayErrors
                                      ? FormCreateNewPlayErrors["index"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Name</Label>

                              <TextInput
                                name={"FormCreateNewPlay.name"}
                                placeholder={""}
                                style={{
                                  background: "#fff",
                                  border: "1px solid #ced4da",
                                  borderRadius: "0.25rem",
                                  color: "#495057",
                                  display: "block",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  lineHeight: 1.5,
                                  paddingBottom: "0.375rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  paddingTop: "0.375rem",
                                  width: "100%",
                                }}
                              ></TextInput>

                              <If
                                condition={
                                  FormCreateNewPlayErrors &&
                                  FormCreateNewPlayErrors.name
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormCreateNewPlayErrors
                                      ? FormCreateNewPlayErrors["name"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Qtr</Label>

                              <SelectDropDown
                                dataSet={""}
                                dataSetLabel={""}
                                dataSetValue={""}
                                initialValue={"1"}
                                name={"FormCreateNewPlay.qtr"}
                                options={[
                                  { label: "1", value: "1" },
                                  { label: "2", value: "2" },
                                  { label: "3", value: "3" },
                                  { label: "4", value: "4" },
                                ]}
                                style={{
                                  background: "#fff",
                                  border: "1px solid #ced4da",
                                  borderRadius: "0.25rem",
                                  color: "#495057",
                                  display: "block",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  lineHeight: 1.5,
                                  paddingBottom: "0.375rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  paddingTop: "0.375rem",
                                  width: "100%",
                                }}
                                type={"number"}
                              ></SelectDropDown>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Series</Label>

                              <TextInput
                                initialValue={0}
                                name={"FormCreateNewPlay.series"}
                                placeholder={""}
                                style={{
                                  background: "#fff",
                                  border: "1px solid #ced4da",
                                  borderRadius: "0.25rem",
                                  color: "#495057",
                                  display: "block",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  lineHeight: 1.5,
                                  paddingBottom: "0.375rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  paddingTop: "0.375rem",
                                  width: "100%",
                                }}
                                type={"number"}
                              ></TextInput>

                              <If
                                condition={
                                  FormCreateNewPlayErrors &&
                                  FormCreateNewPlayErrors.series
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormCreateNewPlayErrors
                                      ? FormCreateNewPlayErrors["series"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Down</Label>

                              <TextInput
                                initialValue={0}
                                name={"FormCreateNewPlay.down"}
                                placeholder={""}
                                style={{
                                  background: "#fff",
                                  border: "1px solid #ced4da",
                                  borderRadius: "0.25rem",
                                  color: "#495057",
                                  display: "block",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  lineHeight: 1.5,
                                  paddingBottom: "0.375rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  paddingTop: "0.375rem",
                                  width: "100%",
                                }}
                                type={"number"}
                              ></TextInput>

                              <If
                                condition={
                                  FormCreateNewPlayErrors &&
                                  FormCreateNewPlayErrors.down
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormCreateNewPlayErrors
                                      ? FormCreateNewPlayErrors["down"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Type</Label>

                              <SelectDropDown
                                dataSet={""}
                                dataSetLabel={""}
                                dataSetValue={""}
                                initialValue={"Run"}
                                name={"FormCreateNewPlay.type"}
                                options={[
                                  { label: "Run", value: "Run" },
                                  { label: "Pass", value: "Pass" },
                                ]}
                                style={{
                                  background: "#fff",
                                  border: "1px solid #ced4da",
                                  borderRadius: "0.25rem",
                                  color: "#495057",
                                  display: "block",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  lineHeight: 1.5,
                                  paddingBottom: "0.375rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  paddingTop: "0.375rem",
                                  width: "100%",
                                }}
                                type={"plain"}
                              ></SelectDropDown>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Package</Label>

                              <SelectDropDown
                                dataSet={Packages}
                                dataSetLabel={"title"}
                                dataSetValue={"Id"}
                                initialValue={Packages ? Packages?.[0]?.Id : 0}
                                name={"FormCreateNewPlay.depth chart id"}
                                options={[]}
                                style={{
                                  background: "#fff",
                                  border: "1px solid #ced4da",
                                  borderRadius: "0.25rem",
                                  color: "#495057",
                                  display: "block",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  lineHeight: 1.5,
                                  paddingBottom: "0.375rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  paddingTop: "0.375rem",
                                  width: "100%",
                                }}
                                type={"number"}
                              ></SelectDropDown>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Create New Play Row",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                Save
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
            <If
              condition={DepthChartPlayIdVariableFlag ? true : false}
              show={true}
            >
              <Then>
                <DivBlock
                  className={"modal"}
                  // actions={[
                  //   {
                  //     action: "Set New Position",
                  //     trigger: "onclick",
                  //     timeout: "",
                  //     pollingPeriod: "",
                  //     inputVariables: { "input new position": false },
                  //   },
                  //   {
                  //     action: "Edit Position",
                  //     trigger: "onclick",
                  //     timeout: "",
                  //     pollingPeriod: "",
                  //     inputVariables: { "input position": null },
                  //   },
                  // ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Change Package
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Play Grades Dropdown",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  depth_chart_id: null,
                                  "intangible id input": null,
                                  play_id_package: null,
                                  "position id input": null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            // actions={[
                            //   {
                            //     action: "Create Depth Chart",
                            //     trigger: "onsubmit",
                            //     timeout: "",
                            //     pollingPeriod: "",
                            //     inputVariables: {},
                            //   },
                            // ]}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Alert! If you change this package, all grading
                                data for this play will be deleted. Are you sure
                                you want to change this?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Set Play Grades Dropdown",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      depth_chart_id: null,
                                      "intangible id input": null,
                                      play_id_package: null,
                                      "position id input": null,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Update Play Package",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      // "depth chart id to delete":
                                      //   CurrentDepthChart
                                      //     ? CurrentDepthChart["Id"]
                                      //     : "",
                                      // "input delete intangible id":
                                      //   "IntangibleToDelete",
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

 {/* {this.props?.uploadGradeMessage !== '' ? 
                      <DivBlock
                        className={"gradeUploadMessage"}
                        style={{
                          background:
                            this.props?.uploadGradeMessage ===
                            "Grades imported successfully"
                              ? "#48c46ec2"
                              : "#f443369e",
                        }}
                      >
                        <Span className={"success-span"}>
                          {this.props?.uploadGradeMessage !== ""
                            ? this.props?.uploadGradeMessage
                            : ""}
                        </Span>
                        <Span
                            className={"close"}
                            actions={[
                              {
                                action: "REMOVE_VALUE",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  value:false
                                },
                              },
                            ]}
                          >
                            x
                      </Span>
                    </DivBlock> : null} */}

            <If
              condition={this.props?.uploadGradeMessage !== '' ? true : false}
              show={true}
            >

              <Then>
                <DivBlock
                  className={"modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                           Grade Upload Message
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "REMOVE_VALUE",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  value:false
                                },
                              },
                            ]}
                          >
                            x
                      </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <DivBlock style={{
                              color:
                              this.props?.uploadGradeMessage ===
                              "Grades successfully imported."
                              ? "#48c46e"
                              : "red",
                            }}>
                              <Label className={"label"}>
                                {this.props?.uploadGradeMessage !== ""
                            ? this.props?.uploadGradeMessage
                            : ""}
                              </Label>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGameData: (callback) => {
      dispatch({ type: "GET_GAME", inputVariables: {
        isDefault: true,callback
      }});
      return Promise.resolve();
    },
    getRosterData: () => {
      dispatch({ type: "GET_USERS"});
      return Promise.resolve();
    },
    getScaleData: () => {
      dispatch({ type: "GET_SCALE_TYPE" });
      return Promise.resolve();
    },
    getProductionPointData: () => {
      dispatch({ type: "GET_PRODUCTION_POINT" });
      return Promise.resolve();
    },
    getSchoolProductionPointData: (callback) => {
      dispatch({ type: "GET_SCHOOL_PRODUCTION_POINT",inputVariables:{callback} });
      return Promise.resolve();
    },
    getGameByIdData: (callback) => {
      dispatch({ type: "GET_GAME_BY_ID",inputVariables:{callback} });
      return Promise.resolve();
    },
    getPackageData: () => {
      dispatch({ type: "GET_PACKAGES" });
      return Promise.resolve();
    },
    getPositionsData: () => {
      dispatch({ type: "GET_POSITIONS" });
      return Promise.resolve();
    },
    getIntangiblesData: () => {
      dispatch({ type: "GET_INTANGIBLES" });
      return Promise.resolve();
    },
    getPlayByPlayData: (value,callback) => {
      dispatch({ type: "GET_PLAY_BY_PLAY_INFO", inputVariables:{
        gameid:value,callback
      } });
    },
    updateCheckboxConfig: (inputVariables) => {
      dispatch({ type: "UPDATE_CONFIGURABLE_COLUMNS", inputVariables });
      return Promise.resolve();
    },
    getUserData: () => {
      dispatch({ type: "GET_USER_GRADES" });
      return Promise.resolve();
    },
    changeInput: (payload) => {
      dispatch({ type: "change_input", payload });
      return Promise.resolve();
    },
    updatePlayUserNotesMethod: (inputVariables) => {
      dispatch({ type: "UPDATE_PLAY_USER_NOTES", inputVariables });
      return Promise.resolve();
    },
    updateGradingTableColumns:(inputVariables) =>{
      dispatch({type:"UPDATE_GRADING_TABLE_COLUMNS_INDEX", inputVariables})
      return Promise.resolve();
    },
    clearGradingIndexData: () =>{
      dispatch({type:"CLEAR_GRADING_INDEX"})
      return Promise.resolve();
    },
    sendNewProductionPoint: (name,position_id,callback) =>{
      dispatch({ type: "SEND_NEW_PRODUCTION_POINT_REQUEST",inputVariables:{name:name,position_id:position_id,callback}})
      return Promise.resolve();
    },
    // GetGradingTableColumns:(inputVariables) =>{
    //   dispatch({type:"GET_GRADING_TABLE_COLUMNS_INDEX", inputVariables})
    //   return Promise.resolve();
    // },
    dispatch: dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlayByPlayGradingPage));
