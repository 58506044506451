import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { IMAGES } from "../../../../assets";

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    flexDirection: "row-reverse", // Reverse the direction to move the icon to the left
  },
  icon: {
    display: "flex",
    height: "20px",
    width: "20px",
  },
  iconDown: {
    display: "flex",
    height: "20px",
    width: "20px",
    transform: "rotate(90deg)",
    // alignItems:'center',
    // justifyContent:'center'
  },
  greenRadio: {
    "&$checked": {
      color: "#48c46e",
    },
  },
  checked: {
    color: "#48c46e",
  },
}));

const InnerAccordion = ({ value, parentId, latestUpdatedValue, ...props }) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("");

  //   const handleGameAndPracitce = (name) => {
  //     // console.log("name :>> ", name);
  //   };

  const handleRadioChange = (event,item) => {
    setSelectedValue(event.target.value);
    latestUpdatedValue({gameId:event.target.value,teamId:item?.team_id});
  };

  const CardComponent = ({ gameData, parentId, index, ...props }) => {
    return (
      <div style={{ width: "100%" }}>
        {gameData?.map((item) => {
          if (item?.folder_id) {
            return (
              <Accordion
                key={item?.folder_id}
                expanded={props.innerExpand[item?.folder_id]}
                onChange={() => props.handleInnerDataChange(item?.folder_id)}
              >
                <AccordionSummary
                  expandIcon={""}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    className={
                      props.innerExpand[item?.folder_id]
                        ? classes.iconDown
                        : classes.icon
                    }
                  >
                    <img src={IMAGES.RightIcon} alt="icon" />
                  </div>
                  <span style={{ paddingLeft: "10px" }}>{item?.name}</span>
                </AccordionSummary>
                <AccordionDetails>
                  {item?.children?.length > 0 ? (
                    <CardComponent
                      gameData={item?.children}
                      parentId={parentId}
                      handleInnerDataChange={props.handleInnerDataChange}
                      innerExpand={props.innerExpand}
                      index={item?.folder_id}
                    />
                  ) : (
                    "No data available"
                  )}
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return (
              <AccordionSummary
                key={index}
                expandIcon={""}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                // onClick={() => handleGameAndPracitce(item?.name)}
              >
                <RadioGroup value={selectedValue} onChange={(event)=>handleRadioChange(event,item)}>
                  {item?.game ? (
                    <FormControlLabel
                      value={item?.game?.game_id}
                      control={
                        <Radio
                          classes={{
                            root: classes.greenRadio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={
                        <>
                          {item?.game ? (
                            <div style={{display:'flex',flexDirection:'column'}}>
                              <span>{item?.name}</span>
                              <span>{item?.game?.angle_str}</span>
                            </div>
                          ) : null}
                        </>
                      }
                    />
                  ) : null}
                </RadioGroup>

                {/* {item?.game ? (
                  <>
                    <span>{item?.name}</span>
                    <span>{item?.game?.angle_str}</span>
                  </>
                ) : null} */}
              </AccordionSummary>
            );
          }
        })}
      </div>
    );
  };
  return (
    <div style={{ width: "100%" }}>
      {value?.map((val, index) => (
        <Accordion
          key={val?.folder_id}
          expanded={props.innerExpand[val?.folder_id]}
          onChange={() => props.handleInnerDataChange(val?.folder_id)}
        >
          <AccordionSummary
            expandIcon={""}
            aria-controls={`panel-${parentId}-${index}-content`}
            id={`panel-${parentId}-${index}-header`}
          >
            <div
              className={
                props.innerExpand[val?.folder_id]
                  ? classes.iconDown
                  : classes.icon
              }
            >
              <img src={IMAGES.RightIcon} alt="icon" />
            </div>
            <span style={{ paddingLeft: "10px" }}>{val?.name}</span>
          </AccordionSummary>
          <AccordionDetails>
            {val?.children?.length > 0 ? (
              <CardComponent
                gameData={val?.children}
                parentId={parentId}
                handleInnerDataChange={props.handleInnerDataChange}
                innerExpand={props.innerExpand}
                index={val?.folder_id}
              />
            ) : (
              "No data available"
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default InnerAccordion;
