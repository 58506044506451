import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {onLoadTrigger} from "../../../helpers";
import BubbleGraphCanvas from './BubbleGraphCanvas.js';
import '../style.css';
import _ from 'lodash'

class BubbleGraph extends Component { // eslint-disable-line react/prefer-stateless-function

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            redraw: false,
            graph: null
        };
    }

    componentWillMount() {
        const { actions, dispatch } = this.props;
        onLoadTrigger(actions, dispatch)
        this.setState({graph: this.returnGraph()})
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {redraw: nextRedraw} = nextState
        const {redraw } = this.state
        if (!redraw){
            this.setState({redraw: true, graph: null})
        }

        return (
          nextProps !== this.props ||
          nextState !== this.state
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {redraw: prevRedraw} = prevState
        const {redraw} = this.state
        if (!prevRedraw && redraw){
            this.setState({redraw: false, graph:this.returnGraph()})
        }
    }


    handleResize = ()=>{
        this.setState({redraw: true, graph: null})
        this.debounceRedraw()
    }

    handleRedraw = () =>{
        
        setTimeout(()=>{
            
            this.setState({redraw: false, graph: this.returnGraph()})
        })
    }
    debounceRedraw = _.debounce(this.handleRedraw, 100)


    returnGraph = () =>{
        const { style, store, dataSet, defaultData,defaultData3} = this.props;
        let data = defaultData
        if (dataSet) {
            if (typeof dataSet === "string") {
                data = store[dataSet] ? store[dataSet] : defaultData
            } else {
                data = dataSet
            }
        }

        return <BubbleGraphCanvas
          data={data}
          {...this.props}
        />
    }

    render() {
        const { style } = this.props;
        return (
            <div className="dittofi-graph-container dittofi-graph-container-canvas" {...this.props.inline} style={style}>
                {this.state.graph}
            </div>
        );
    }
}


BubbleGraph.propTypes = {
    data: PropTypes.array,
    type: PropTypes.oneOf(["svg", "hybrid"]),
    fill: PropTypes.string,
    xTicks: PropTypes.number,
};

BubbleGraph.defaultProps = {
    type: "svg",
    style: {},
    defaultData3:

        [
            {
                "Id":1,
                "adj low":17.4,
                "adj close":17.56,
                "adj open":17.66,
                "adj volume":3830546,
                "symbol":"VOD",
                "close":17.56,
                "volume":3818019,
                "low":17.4,
                "adj high":17.895,
                "exchange":"XNAS",
                "date":"2021-03-04T00:00:00+0000",
                "open":17.66,
                "high":17.895
            },
            {
                "Id":2,
                "adj low":17.47,
                "adj close":17.63,
                "adj open":17.53,
                "adj volume":2705820,
                "symbol":"VOD",
                "close":17.63,
                "volume":2701648,
                "low":17.47,
                "adj high":17.72,
                "exchange":"XNAS",
                "date":"2021-03-03T00:00:00+0000",
                "open":17.53,
                "high":17.7199
            },
            {
                "Id":3,
                "adj low":17.49,
                "adj close":17.52,
                "adj open":17.58,
                "adj volume":1880643,
                "symbol":"VOD",
                "close":17.52,
                "volume":1874164,
                "low":17.49,
                "adj high":17.64,
                "exchange":"XNAS",
                "date":"2021-03-02T00:00:00+0000",
                "open":17.58,
                "high":17.64
            },
            {
                "Id":4,
                "adj low":17.29,
                "adj close":17.44,
                "adj open":17.32,
                "adj volume":2978652,
                "symbol":"VOD",
                "close":17.44,
                "volume":2978652,
                "low":17.29,
                "adj high":17.525,
                "exchange":"XNAS",
                "date":"2021-03-01T00:00:00+0000",
                "open":17.32,
                "high":17.525
            },
            {
                "Id":5,
                "adj low":17.08,
                "adj close":17.18,
                "adj open":17.42,
                "adj volume":3285087,
                "symbol":"VOD",
                "close":17.18,
                "volume":3328100,
                "low":17.08,
                "adj high":17.43,
                "exchange":"XNAS",
                "date":"2021-02-26T00:00:00+0000",
                "open":17.42,
                "high":17.43
            },
            {
                "Id":6,
                "adj low":17.57,
                "adj close":17.58,
                "adj open":17.93,
                "adj volume":4046441,
                "symbol":"VOD",
                "close":17.58,
                "volume":4044585,
                "low":17.57,
                "adj high":17.975,
                "exchange":"XNAS",
                "date":"2021-02-25T00:00:00+0000",
                "open":17.93,
                "high":17.975
            },
            {
                "Id":7,
                "adj low":17.845,
                "adj close":17.94,
                "adj open":18.05,
                "adj volume":4925182,
                "symbol":"VOD",
                "close":17.94,
                "volume":4905528,
                "low":17.845,
                "adj high":18.07,
                "exchange":"XNAS",
                "date":"2021-02-24T00:00:00+0000",
                "open":18.05,
                "high":18.07
            },
            {
                "Id":8,
                "adj low":18.06,
                "adj close":18.52,
                "adj open":18.23,
                "adj volume":3505419,
                "symbol":"VOD",
                "close":18.52,
                "volume":3505419,
                "low":18.06,
                "adj high":18.555,
                "exchange":"XNAS",
                "date":"2021-02-23T00:00:00+0000",
                "open":18.23,
                "high":18.555
            },
            {
                "Id":9,
                "adj low":18.39,
                "adj close":18.4,
                "adj open":18.4,
                "adj volume":3726441,
                "symbol":"VOD",
                "close":18.4,
                "volume":3725274,
                "low":18.39,
                "adj high":18.63,
                "exchange":"XNAS",
                "date":"2021-02-22T00:00:00+0000",
                "open":18.4,
                "high":18.63
            },
            {
                "Id":10,
                "adj low":18.48,
                "adj close":18.49,
                "adj open":18.65,
                "adj volume":2280264,
                "symbol":"VOD",
                "close":18.49,
                "volume":2280264,
                "low":18.48,
                "adj high":18.66,
                "exchange":"XNAS",
                "date":"2021-02-19T00:00:00+0000",
                "open":18.65,
                "high":18.66
            },
            {
                "Id":11,
                "adj low":18.285,
                "adj close":18.44,
                "adj open":18.43,
                "adj volume":2039832,
                "symbol":"VOD",
                "close":18.44,
                "volume":2039832,
                "low":18.285,
                "adj high":18.49,
                "exchange":"XNAS",
                "date":"2021-02-18T00:00:00+0000",
                "open":18.43,
                "high":18.49
            },
            {
                "Id":12,
                "adj low":18.47,
                "adj close":18.6,
                "adj open":18.56,
                "adj volume":2359235,
                "symbol":"VOD",
                "close":18.6,
                "volume":2359235,
                "low":18.47,
                "adj high":18.67,
                "exchange":"XNAS",
                "date":"2021-02-17T00:00:00+0000",
                "open":18.56,
                "high":18.67
            },
            {
                "Id":13,
                "adj low":18.66,
                "adj close":18.73,
                "adj open":18.68,
                "adj volume":2786884,
                "symbol":"VOD",
                "close":18.73,
                "volume":2786884,
                "low":18.66,
                "adj high":18.87,
                "exchange":"XNAS",
                "date":"2021-02-16T00:00:00+0000",
                "open":18.68,
                "high":18.87
            },
            {
                "Id":14,
                "adj low":18.49,
                "adj close":18.7,
                "adj open":18.5,
                "adj volume":1871490,
                "symbol":"VOD",
                "close":18.7,
                "volume":1871490,
                "low":18.49,
                "adj high":18.755,
                "exchange":"XNAS",
                "date":"2021-02-12T00:00:00+0000",
                "open":18.5,
                "high":18.755
            },
            {
                "Id":15,
                "adj low":18.51,
                "adj close":18.69,
                "adj open":18.65,
                "adj volume":2504714,
                "symbol":"VOD",
                "close":18.69,
                "volume":2504714,
                "low":18.51,
                "adj high":18.7,
                "exchange":"XNAS",
                "date":"2021-02-11T00:00:00+0000",
                "open":18.65,
                "high":18.7
            },
            {
                "Id":16,
                "adj low":18.44,
                "adj close":18.53,
                "adj open":18.56,
                "adj volume":2031352,
                "symbol":"VOD",
                "close":18.53,
                "volume":2031352,
                "low":18.44,
                "adj high":18.66,
                "exchange":"XNAS",
                "date":"2021-02-10T00:00:00+0000",
                "open":18.56,
                "high":18.66
            },
            {
                "Id":17,
                "adj low":18.4,
                "adj close":18.47,
                "adj open":18.5,
                "adj volume":2154627,
                "symbol":"VOD",
                "close":18.47,
                "volume":2154627,
                "low":18.4,
                "adj high":18.51,
                "exchange":"XNAS",
                "date":"2021-02-09T00:00:00+0000",
                "open":18.5,
                "high":18.51
            },
            {
                "Id":18,
                "adj low":18.31,
                "adj close":18.45,
                "adj open":18.5476,
                "adj volume":5376353,
                "symbol":"VOD",
                "close":18.45,
                "volume":5376353,
                "low":18.31,
                "adj high":18.585,
                "exchange":"XNAS",
                "date":"2021-02-08T00:00:00+0000",
                "open":18.5476,
                "high":18.585
            },
            {
                "Id":19,
                "adj low":18.45,
                "adj close":18.59,
                "adj open":18.45,
                "adj volume":6253471,
                "symbol":"VOD",
                "close":18.59,
                "volume":6253471,
                "low":18.45,
                "adj high":18.67,
                "exchange":"XNAS",
                "date":"2021-02-05T00:00:00+0000",
                "open":18.45,
                "high":18.67
            },
            {
                "Id":20,
                "adj low":18.59,
                "adj close":18.8,
                "adj open":18.7,
                "adj volume":4121014,
                "symbol":"VOD",
                "close":18.8,
                "volume":4121014,
                "low":18.59,
                "adj high":18.83,
                "exchange":"XNAS",
                "date":"2021-02-04T00:00:00+0000",
                "open":18.7,
                "high":18.83
            },
            {
                "Id":21,
                "adj low":18.35,
                "adj close":18.79,
                "adj open":18.41,
                "adj volume":9626573,
                "symbol":"VOD",
                "close":18.79,
                "volume":9626573,
                "low":18.35,
                "adj high":18.82,
                "exchange":"XNAS",
                "date":"2021-02-03T00:00:00+0000",
                "open":18.41,
                "high":18.82
            },
            {
                "Id":22,
                "adj low":17.405,
                "adj close":17.54,
                "adj open":17.44,
                "adj volume":2577917,
                "symbol":"VOD",
                "close":17.54,
                "volume":2577917,
                "low":17.405,
                "adj high":17.57,
                "exchange":"XNAS",
                "date":"2021-02-02T00:00:00+0000",
                "open":17.44,
                "high":17.57
            },
            {
                "Id":23,
                "adj low":17.26,
                "adj close":17.45,
                "adj open":17.4,
                "adj volume":3786464,
                "symbol":"VOD",
                "close":17.45,
                "volume":3786464,
                "low":17.26,
                "adj high":17.51,
                "exchange":"XNAS",
                "date":"2021-02-01T00:00:00+0000",
                "open":17.4,
                "high":17.51
            },
            {
                "Id":24,
                "adj low":17.015,
                "adj close":17.15,
                "adj open":17.36,
                "adj volume":5599479,
                "symbol":"VOD",
                "close":17.15,
                "volume":5599479,
                "low":17.015,
                "adj high":17.44,
                "exchange":"XNAS",
                "date":"2021-01-29T00:00:00+0000",
                "open":17.36,
                "high":17.44
            },
            {
                "Id":25,
                "adj low":17.13,
                "adj close":17.51,
                "adj open":17.17,
                "adj volume":3596591,
                "symbol":"VOD",
                "close":17.51,
                "volume":3596591,
                "low":17.13,
                "adj high":17.65,
                "exchange":"XNAS",
                "date":"2021-01-28T00:00:00+0000",
                "open":17.17,
                "high":17.65
            },
            {
                "Id":26,
                "adj low":17.455,
                "adj close":17.52,
                "adj open":17.79,
                "adj volume":4080992,
                "symbol":"VOD",
                "close":17.52,
                "volume":4080992,
                "low":17.455,
                "adj high":17.8,
                "exchange":"XNAS",
                "date":"2021-01-27T00:00:00+0000",
                "open":17.79,
                "high":17.8
            },
            {
                "Id":27,
                "adj low":17.755,
                "adj close":17.99,
                "adj open":17.8,
                "adj volume":4041312,
                "symbol":"VOD",
                "close":17.99,
                "volume":4041312,
                "low":17.755,
                "adj high":18.045,
                "exchange":"XNAS",
                "date":"2021-01-26T00:00:00+0000",
                "open":17.8,
                "high":18.045
            },
            {
                "Id":28,
                "adj low":17.3882,
                "adj close":17.78,
                "adj open":17.43,
                "adj volume":3161257,
                "symbol":"VOD",
                "close":17.78,
                "volume":3161257,
                "low":17.3882,
                "adj high":17.79,
                "exchange":"XNAS",
                "date":"2021-01-25T00:00:00+0000",
                "open":17.43,
                "high":17.79
            },
            {
                "Id":29,
                "adj low":17.441,
                "adj close":17.58,
                "adj open":17.55,
                "adj volume":1817079,
                "symbol":"VOD",
                "close":17.58,
                "volume":1817079,
                "low":17.441,
                "adj high":17.635,
                "exchange":"XNAS",
                "date":"2021-01-22T00:00:00+0000",
                "open":17.55,
                "high":17.635
            },
            {
                "Id":30,
                "adj low":17.515,
                "adj close":17.63,
                "adj open":17.67,
                "adj volume":2610202,
                "symbol":"VOD",
                "close":17.63,
                "volume":2610202,
                "low":17.515,
                "adj high":17.7,
                "exchange":"XNAS",
                "date":"2021-01-21T00:00:00+0000",
                "open":17.67,
                "high":17.7
            },
            {
                "Id":31,
                "adj low":17.32,
                "adj close":17.51,
                "adj open":17.52,
                "adj volume":2251549,
                "symbol":"VOD",
                "close":17.51,
                "volume":2251549,
                "low":17.32,
                "adj high":17.52,
                "exchange":"XNAS",
                "date":"2021-01-20T00:00:00+0000",
                "open":17.52,
                "high":17.52
            },
            {
                "Id":32,
                "adj low":17.225,
                "adj close":17.46,
                "adj open":17.3,
                "adj volume":3897559,
                "symbol":"VOD",
                "close":17.46,
                "volume":3897559,
                "low":17.225,
                "adj high":17.525,
                "exchange":"XNAS",
                "date":"2021-01-19T00:00:00+0000",
                "open":17.3,
                "high":17.525
            },
            {
                "Id":33,
                "adj low":17.195,
                "adj close":17.36,
                "adj open":17.39,
                "adj volume":2713526,
                "symbol":"VOD",
                "close":17.36,
                "volume":2713526,
                "low":17.195,
                "adj high":17.4,
                "exchange":"XNAS",
                "date":"2021-01-15T00:00:00+0000",
                "open":17.39,
                "high":17.4
            },
            {
                "Id":34,
                "adj low":17.55,
                "adj close":17.65,
                "adj open":17.58,
                "adj volume":3250922,
                "symbol":"VOD",
                "close":17.65,
                "volume":3250922,
                "low":17.55,
                "adj high":17.7466,
                "exchange":"XNAS",
                "date":"2021-01-14T00:00:00+0000",
                "open":17.58,
                "high":17.7466
            },
            {
                "Id":35,
                "adj low":17.39,
                "adj close":17.45,
                "adj open":17.53,
                "adj volume":3072986,
                "symbol":"VOD",
                "close":17.45,
                "volume":3072986,
                "low":17.39,
                "adj high":17.61,
                "exchange":"XNAS",
                "date":"2021-01-13T00:00:00+0000",
                "open":17.53,
                "high":17.61
            },
            {
                "Id":36,
                "adj low":17.045,
                "adj close":17.16,
                "adj open":17.19,
                "adj volume":2882035,
                "symbol":"VOD",
                "close":17.16,
                "volume":2882035,
                "low":17.045,
                "adj high":17.22,
                "exchange":"XNAS",
                "date":"2021-01-12T00:00:00+0000",
                "open":17.19,
                "high":17.22
            },
            {
                "Id":37,
                "adj low":17.3,
                "adj close":17.43,
                "adj open":17.33,
                "adj volume":2629890,
                "symbol":"VOD",
                "close":17.43,
                "volume":2629890,
                "low":17.3,
                "adj high":17.475,
                "exchange":"XNAS",
                "date":"2021-01-11T00:00:00+0000",
                "open":17.33,
                "high":17.475
            },
            {
                "Id":38,
                "adj low":17.345,
                "adj close":17.54,
                "adj open":17.6,
                "adj volume":3650973,
                "symbol":"VOD",
                "close":17.54,
                "volume":3650973,
                "low":17.345,
                "adj high":17.62,
                "exchange":"XNAS",
                "date":"2021-01-08T00:00:00+0000",
                "open":17.6,
                "high":17.62
            },
            {
                "Id":39,
                "adj low":17.675,
                "adj close":17.73,
                "adj open":17.92,
                "adj volume":4455264,
                "symbol":"VOD",
                "close":17.73,
                "volume":4455264,
                "low":17.675,
                "adj high":17.935,
                "exchange":"XNAS",
                "date":"2021-01-07T00:00:00+0000",
                "open":17.92,
                "high":17.935
            },
            {
                "Id":40,
                "adj low":17.53,
                "adj close":17.62,
                "adj open":17.64,
                "adj volume":3868371,
                "symbol":"VOD",
                "close":17.62,
                "volume":3868371,
                "low":17.53,
                "adj high":17.81,
                "exchange":"XNAS",
                "date":"2021-01-06T00:00:00+0000",
                "open":17.64,
                "high":17.81
            },
            {
                "Id":41,
                "adj low":16.845,
                "adj close":17.12,
                "adj open":16.85,
                "adj volume":3058079,
                "symbol":"VOD",
                "close":17.12,
                "volume":3058079,
                "low":16.845,
                "adj high":17.155,
                "exchange":"XNAS",
                "date":"2021-01-05T00:00:00+0000",
                "open":16.85,
                "high":17.155
            },
            {
                "Id":42,
                "adj low":16.785,
                "adj close":16.93,
                "adj open":17.04,
                "adj volume":3698815,
                "symbol":"VOD",
                "close":16.93,
                "volume":3698815,
                "low":16.785,
                "adj high":17.13,
                "exchange":"XNAS",
                "date":"2021-01-04T00:00:00+0000",
                "open":17.04,
                "high":17.13
            },
            {
                "Id":43,
                "adj low":16.46,
                "adj close":16.48,
                "adj open":16.59,
                "adj volume":4218343,
                "symbol":"VOD",
                "close":16.48,
                "volume":4218343,
                "low":16.46,
                "adj high":16.6263,
                "exchange":"XNAS",
                "date":"2020-12-31T00:00:00+0000",
                "open":16.59,
                "high":16.6263
            },
            {
                "Id":44,
                "adj low":16.62,
                "adj close":16.65,
                "adj open":16.79,
                "adj volume":2376731,
                "symbol":"VOD",
                "close":16.65,
                "volume":2376731,
                "low":16.62,
                "adj high":16.8,
                "exchange":"XNAS",
                "date":"2020-12-30T00:00:00+0000",
                "open":16.79,
                "high":16.8
            },
            {
                "Id":45,
                "adj low":16.61,
                "adj close":16.64,
                "adj open":16.85,
                "adj volume":2686521,
                "symbol":"VOD",
                "close":16.64,
                "volume":2686521,
                "low":16.61,
                "adj high":16.8789,
                "exchange":"XNAS",
                "date":"2020-12-29T00:00:00+0000",
                "open":16.85,
                "high":16.8789
            },
            {
                "Id":46,
                "adj low":16.72,
                "adj close":16.75,
                "adj open":16.83,
                "adj volume":2215263,
                "symbol":"VOD",
                "close":16.75,
                "volume":2215263,
                "low":16.72,
                "adj high":16.85,
                "exchange":"XNAS",
                "date":"2020-12-28T00:00:00+0000",
                "open":16.83,
                "high":16.85
            },
            {
                "Id":47,
                "adj low":16.585,
                "adj close":16.71,
                "adj open":16.71,
                "adj volume":1064183,
                "symbol":"VOD",
                "close":16.71,
                "volume":1064183,
                "low":16.585,
                "adj high":16.775,
                "exchange":"XNAS",
                "date":"2020-12-24T00:00:00+0000",
                "open":16.71,
                "high":16.775
            },
            {
                "Id":48,
                "adj low":16.61,
                "adj close":16.64,
                "adj open":16.62,
                "adj volume":2639666,
                "symbol":"VOD",
                "close":16.64,
                "volume":2639666,
                "low":16.61,
                "adj high":16.745,
                "exchange":"XNAS",
                "date":"2020-12-23T00:00:00+0000",
                "open":16.62,
                "high":16.745
            },
            {
                "Id":49,
                "adj low":16.2,
                "adj close":16.26,
                "adj open":16.28,
                "adj volume":3124758,
                "symbol":"VOD",
                "close":16.26,
                "volume":3124758,
                "low":16.2,
                "adj high":16.32,
                "exchange":"XNAS",
                "date":"2020-12-22T00:00:00+0000",
                "open":16.28,
                "high":16.32
            },
            {
                "Id":50,
                "adj low":16.1,
                "adj close":16.4,
                "adj open":16.22,
                "adj volume":6534110,
                "symbol":"VOD",
                "close":16.4,
                "volume":6534110,
                "low":16.1,
                "adj high":16.45,
                "exchange":"XNAS",
                "date":"2020-12-21T00:00:00+0000",
                "open":16.22,
                "high":16.45
            },
            {
                "Id":51,
                "adj low":17.03,
                "adj close":17.15,
                "adj open":17.07,
                "adj volume":6170405,
                "symbol":"VOD",
                "close":17.15,
                "volume":6170405,
                "low":17.03,
                "adj high":17.19,
                "exchange":"XNAS",
                "date":"2020-12-18T00:00:00+0000",
                "open":17.07,
                "high":17.19
            },
            {
                "Id":52,
                "adj low":17.03,
                "adj close":17.12,
                "adj open":17.16,
                "adj volume":5309064,
                "symbol":"VOD",
                "close":17.12,
                "volume":5309064,
                "low":17.03,
                "adj high":17.19,
                "exchange":"XNAS",
                "date":"2020-12-17T00:00:00+0000",
                "open":17.16,
                "high":17.19
            },
            {
                "Id":53,
                "adj low":17.565,
                "adj close":17.0788139697,
                "adj open":17.1757975812,
                "adj volume":4250995,
                "symbol":"VOD",
                "close":17.61,
                "volume":4250995,
                "low":17.565,
                "adj high":17.2048926646,
                "exchange":"XNAS",
                "date":"2020-12-16T00:00:00+0000",
                "open":17.71,
                "high":17.74
            },
            {
                "Id":54,
                "adj low":17.3501,
                "adj close":17.030322164,
                "adj open":17.0691156086,
                "adj volume":4947968,
                "symbol":"VOD",
                "close":17.56,
                "volume":4947968,
                "low":17.3501,
                "adj high":17.0691156086,
                "exchange":"XNAS",
                "date":"2020-12-15T00:00:00+0000",
                "open":17.6,
                "high":17.6
            },
            {
                "Id":55,
                "adj low":17.33,
                "adj close":17.34,
                "adj open":17.81,
                "adj volume":5138898,
                "symbol":"VOD",
                "close":17.34,
                "volume":5138898,
                "low":17.33,
                "adj high":17.82,
                "exchange":"XNAS",
                "date":"2020-12-14T00:00:00+0000",
                "open":17.81,
                "high":17.82
            },
            {
                "Id":56,
                "adj low":17.2,
                "adj close":17.21,
                "adj open":17.55,
                "adj volume":5172565,
                "symbol":"VOD",
                "close":17.21,
                "volume":5172565,
                "low":17.2,
                "adj high":17.615,
                "exchange":"XNAS",
                "date":"2020-12-11T00:00:00+0000",
                "open":17.55,
                "high":17.615
            },
            {
                "Id":57,
                "adj low":17.7,
                "adj close":17.8,
                "adj open":17.76,
                "adj volume":2891085,
                "symbol":"VOD",
                "close":17.8,
                "volume":2891085,
                "low":17.7,
                "adj high":17.95,
                "exchange":"XNAS",
                "date":"2020-12-10T00:00:00+0000",
                "open":17.76,
                "high":17.95
            },
            {
                "Id":58,
                "adj low":17.76,
                "adj close":17.87,
                "adj open":17.9318,
                "adj volume":4958381,
                "symbol":"VOD",
                "close":17.87,
                "volume":4958381,
                "low":17.76,
                "adj high":18.05,
                "exchange":"XNAS",
                "date":"2020-12-09T00:00:00+0000",
                "open":17.9318,
                "high":18.05
            },
            {
                "Id":59,
                "adj low":17.42,
                "adj close":17.52,
                "adj open":17.5,
                "adj volume":3441448,
                "symbol":"VOD",
                "close":17.52,
                "volume":3441448,
                "low":17.42,
                "adj high":17.585,
                "exchange":"XNAS",
                "date":"2020-12-08T00:00:00+0000",
                "open":17.5,
                "high":17.585
            },
            {
                "Id":60,
                "adj low":17.61,
                "adj close":17.81,
                "adj open":17.77,
                "adj volume":3441167,
                "symbol":"VOD",
                "close":17.81,
                "volume":3441167,
                "low":17.61,
                "adj high":17.81,
                "exchange":"XNAS",
                "date":"2020-12-07T00:00:00+0000",
                "open":17.77,
                "high":17.81
            },
            {
                "Id":61,
                "adj low":17.475,
                "adj close":17.55,
                "adj open":17.48,
                "adj volume":4573346,
                "symbol":"VOD",
                "close":17.55,
                "volume":4573346,
                "low":17.475,
                "adj high":17.67,
                "exchange":"XNAS",
                "date":"2020-12-04T00:00:00+0000",
                "open":17.48,
                "high":17.67
            },
            {
                "Id":62,
                "adj low":17.08,
                "adj close":17.12,
                "adj open":17.19,
                "adj volume":3966746,
                "symbol":"VOD",
                "close":17.12,
                "volume":3966746,
                "low":17.08,
                "adj high":17.235,
                "exchange":"XNAS",
                "date":"2020-12-03T00:00:00+0000",
                "open":17.19,
                "high":17.235
            },
            {
                "Id":63,
                "adj low":16.76,
                "adj close":17.03,
                "adj open":16.78,
                "adj volume":5048557,
                "symbol":"VOD",
                "close":17.03,
                "volume":5048557,
                "low":16.76,
                "adj high":17.06,
                "exchange":"XNAS",
                "date":"2020-12-02T00:00:00+0000",
                "open":16.78,
                "high":17.06
            },
            {
                "Id":64,
                "adj low":16.76,
                "adj close":16.84,
                "adj open":16.77,
                "adj volume":3520999,
                "symbol":"VOD",
                "close":16.84,
                "volume":3520999,
                "low":16.76,
                "adj high":16.96,
                "exchange":"XNAS",
                "date":"2020-12-01T00:00:00+0000",
                "open":16.77,
                "high":16.96
            },
            {
                "Id":65,
                "adj low":16.56,
                "adj close":16.58,
                "adj open":16.78,
                "adj volume":2770878,
                "symbol":"VOD",
                "close":16.58,
                "volume":2770878,
                "low":16.56,
                "adj high":16.81,
                "exchange":"XNAS",
                "date":"2020-11-30T00:00:00+0000",
                "open":16.78,
                "high":16.81
            },
            {
                "Id":66,
                "adj low":16.74,
                "adj close":16.85,
                "adj open":16.77,
                "adj volume":2006095,
                "symbol":"VOD",
                "close":16.85,
                "volume":2006095,
                "low":16.74,
                "adj high":16.8641,
                "exchange":"XNAS",
                "date":"2020-11-27T00:00:00+0000",
                "open":16.77,
                "high":16.8641
            },
            {
                "Id":67,
                "adj low":16.7,
                "adj close":16.76,
                "adj open":16.81,
                "adj volume":2493448,
                "symbol":"VOD",
                "close":16.76,
                "volume":2493448,
                "low":16.7,
                "adj high":16.829,
                "exchange":"XNAS",
                "date":"2020-11-25T00:00:00+0000",
                "open":16.81,
                "high":16.829
            },
            {
                "Id":68,
                "adj low":16.65,
                "adj close":16.86,
                "adj open":16.66,
                "adj volume":2351754,
                "symbol":"VOD",
                "close":16.86,
                "volume":2351754,
                "low":16.65,
                "adj high":16.9342,
                "exchange":"XNAS",
                "date":"2020-11-24T00:00:00+0000",
                "open":16.66,
                "high":16.9342
            },
            {
                "Id":69,
                "adj low":16.28,
                "adj close":16.39,
                "adj open":16.48,
                "adj volume":2553046,
                "symbol":"VOD",
                "close":16.39,
                "volume":2553046,
                "low":16.28,
                "adj high":16.5,
                "exchange":"XNAS",
                "date":"2020-11-23T00:00:00+0000",
                "open":16.48,
                "high":16.5
            },
            {
                "Id":70,
                "adj low":16.34,
                "adj close":16.47,
                "adj open":16.4,
                "adj volume":2923156,
                "symbol":"VOD",
                "close":16.47,
                "volume":2923156,
                "low":16.34,
                "adj high":16.5583,
                "exchange":"XNAS",
                "date":"2020-11-20T00:00:00+0000",
                "open":16.4,
                "high":16.5583
            },
            {
                "Id":71,
                "adj low":16.17,
                "adj close":16.34,
                "adj open":16.22,
                "adj volume":2742410,
                "symbol":"VOD",
                "close":16.34,
                "volume":2742410,
                "low":16.17,
                "adj high":16.35,
                "exchange":"XNAS",
                "date":"2020-11-19T00:00:00+0000",
                "open":16.22,
                "high":16.35
            },
            {
                "Id":72,
                "adj low":16.31,
                "adj close":16.32,
                "adj open":16.35,
                "adj volume":3092003,
                "symbol":"VOD",
                "close":16.32,
                "volume":3092003,
                "low":16.31,
                "adj high":16.539,
                "exchange":"XNAS",
                "date":"2020-11-18T00:00:00+0000",
                "open":16.35,
                "high":16.539
            },
            {
                "Id":73,
                "adj low":16.29,
                "adj close":16.35,
                "adj open":16.64,
                "adj volume":4081591,
                "symbol":"VOD",
                "close":16.35,
                "volume":4081591,
                "low":16.29,
                "adj high":16.67,
                "exchange":"XNAS",
                "date":"2020-11-17T00:00:00+0000",
                "open":16.64,
                "high":16.67
            },
            {
                "Id":74,
                "adj low":16.67,
                "adj close":16.74,
                "adj open":16.71,
                "adj volume":7999596,
                "symbol":"VOD",
                "close":16.74,
                "volume":7999596,
                "low":16.67,
                "adj high":16.995,
                "exchange":"XNAS",
                "date":"2020-11-16T00:00:00+0000",
                "open":16.71,
                "high":16.995
            },
            {
                "Id":75,
                "adj low":15.691,
                "adj close":16.12,
                "adj open":15.71,
                "adj volume":4099240,
                "symbol":"VOD",
                "close":16.12,
                "volume":4099240,
                "low":15.691,
                "adj high":16.12,
                "exchange":"XNAS",
                "date":"2020-11-13T00:00:00+0000",
                "open":15.71,
                "high":16.12
            },
            {
                "Id":76,
                "adj low":15.415,
                "adj close":15.49,
                "adj open":15.65,
                "adj volume":3666792,
                "symbol":"VOD",
                "close":15.49,
                "volume":3666792,
                "low":15.415,
                "adj high":15.7659,
                "exchange":"XNAS",
                "date":"2020-11-12T00:00:00+0000",
                "open":15.65,
                "high":15.7659
            },
            {
                "Id":77,
                "adj low":15.48,
                "adj close":15.69,
                "adj open":15.6,
                "adj volume":4993602,
                "symbol":"VOD",
                "close":15.69,
                "volume":4993602,
                "low":15.48,
                "adj high":15.7,
                "exchange":"XNAS",
                "date":"2020-11-11T00:00:00+0000",
                "open":15.6,
                "high":15.7
            },
            {
                "Id":78,
                "adj low":15.42,
                "adj close":15.59,
                "adj open":15.44,
                "adj volume":7813806,
                "symbol":"VOD",
                "close":15.59,
                "volume":7813806,
                "low":15.42,
                "adj high":15.67,
                "exchange":"XNAS",
                "date":"2020-11-10T00:00:00+0000",
                "open":15.44,
                "high":15.67
            },
            {
                "Id":79,
                "adj low":14.85,
                "adj close":14.89,
                "adj open":15.09,
                "adj volume":5591177,
                "symbol":"VOD",
                "close":14.89,
                "volume":5591177,
                "low":14.85,
                "adj high":15.14,
                "exchange":"XNAS",
                "date":"2020-11-09T00:00:00+0000",
                "open":15.09,
                "high":15.14
            },
            {
                "Id":80,
                "adj low":13.94,
                "adj close":13.97,
                "adj open":14.12,
                "adj volume":2573773,
                "symbol":"VOD",
                "close":13.97,
                "volume":2573773,
                "low":13.94,
                "adj high":14.1681,
                "exchange":"XNAS",
                "date":"2020-11-06T00:00:00+0000",
                "open":14.12,
                "high":14.1681
            },
            {
                "Id":81,
                "adj low":14,
                "adj close":14.11,
                "adj open":14,
                "adj volume":3304915,
                "symbol":"VOD",
                "close":14.11,
                "volume":3304915,
                "low":14,
                "adj high":14.21,
                "exchange":"XNAS",
                "date":"2020-11-05T00:00:00+0000",
                "open":14,
                "high":14.21
            },
            {
                "Id":82,
                "adj low":13.64,
                "adj close":13.9,
                "adj open":13.79,
                "adj volume":3659896,
                "symbol":"VOD",
                "close":13.9,
                "volume":3659896,
                "low":13.64,
                "adj high":14,
                "exchange":"XNAS",
                "date":"2020-11-04T00:00:00+0000",
                "open":13.79,
                "high":14
            },
            {
                "Id":83,
                "adj low":13.865,
                "adj close":13.92,
                "adj open":13.94,
                "adj volume":3446938,
                "symbol":"VOD",
                "close":13.92,
                "volume":3446938,
                "low":13.865,
                "adj high":14.06,
                "exchange":"XNAS",
                "date":"2020-11-03T00:00:00+0000",
                "open":13.94,
                "high":14.06
            },
            {
                "Id":84,
                "adj low":13.645,
                "adj close":13.81,
                "adj open":13.73,
                "adj volume":4290985,
                "symbol":"VOD",
                "close":13.81,
                "volume":4290985,
                "low":13.645,
                "adj high":13.88,
                "exchange":"XNAS",
                "date":"2020-11-02T00:00:00+0000",
                "open":13.73,
                "high":13.88
            },
            {
                "Id":85,
                "adj low":13.33,
                "adj close":13.51,
                "adj open":13.49,
                "adj volume":3372568,
                "symbol":"VOD",
                "close":13.51,
                "volume":3372568,
                "low":13.33,
                "adj high":13.525,
                "exchange":"XNAS",
                "date":"2020-10-30T00:00:00+0000",
                "open":13.49,
                "high":13.525
            },
            {
                "Id":86,
                "adj low":13.2,
                "adj close":13.55,
                "adj open":13.2936,
                "adj volume":5912464,
                "symbol":"VOD",
                "close":13.55,
                "volume":5912464,
                "low":13.2,
                "adj high":13.64,
                "exchange":"XNAS",
                "date":"2020-10-29T00:00:00+0000",
                "open":13.2936,
                "high":13.64
            },
            {
                "Id":87,
                "adj low":13.28,
                "adj close":13.43,
                "adj open":13.45,
                "adj volume":6759000,
                "symbol":"VOD",
                "close":13.43,
                "volume":6759000,
                "low":13.28,
                "adj high":13.56,
                "exchange":"XNAS",
                "date":"2020-10-28T00:00:00+0000",
                "open":13.45,
                "high":13.56
            },
            {
                "Id":88,
                "adj low":13.91,
                "adj close":13.98,
                "adj open":14.2,
                "adj volume":4558518,
                "symbol":"VOD",
                "close":13.98,
                "volume":4558518,
                "low":13.91,
                "adj high":14.245,
                "exchange":"XNAS",
                "date":"2020-10-27T00:00:00+0000",
                "open":14.2,
                "high":14.245
            },
            {
                "Id":89,
                "adj low":14.41,
                "adj close":14.52,
                "adj open":14.62,
                "adj volume":4442371,
                "symbol":"VOD",
                "close":14.52,
                "volume":4442371,
                "low":14.41,
                "adj high":14.62,
                "exchange":"XNAS",
                "date":"2020-10-26T00:00:00+0000",
                "open":14.62,
                "high":14.62
            },
            {
                "Id":90,
                "adj low":14.72,
                "adj close":14.84,
                "adj open":14.8,
                "adj volume":6919188,
                "symbol":"VOD",
                "close":14.84,
                "volume":6919188,
                "low":14.72,
                "adj high":14.93,
                "exchange":"XNAS",
                "date":"2020-10-23T00:00:00+0000",
                "open":14.8,
                "high":14.93
            },
            {
                "Id":91,
                "adj low":14.25,
                "adj close":14.55,
                "adj open":14.25,
                "adj volume":6935782,
                "symbol":"VOD",
                "close":14.55,
                "volume":6935782,
                "low":14.25,
                "adj high":14.58,
                "exchange":"XNAS",
                "date":"2020-10-22T00:00:00+0000",
                "open":14.25,
                "high":14.58
            },
            {
                "Id":92,
                "adj low":14.04,
                "adj close":14.33,
                "adj open":14.13,
                "adj volume":8730294,
                "symbol":"VOD",
                "close":14.33,
                "volume":8730294,
                "low":14.04,
                "adj high":14.42,
                "exchange":"XNAS",
                "date":"2020-10-21T00:00:00+0000",
                "open":14.13,
                "high":14.42
            },
            {
                "Id":93,
                "adj low":14.39,
                "adj close":14.46,
                "adj open":14.41,
                "adj volume":3056450,
                "symbol":"VOD",
                "close":14.46,
                "volume":3056450,
                "low":14.39,
                "adj high":14.59,
                "exchange":"XNAS",
                "date":"2020-10-20T00:00:00+0000",
                "open":14.41,
                "high":14.59
            },
            {
                "Id":94,
                "adj low":14.19,
                "adj close":14.21,
                "adj open":14.35,
                "adj volume":4436631,
                "symbol":"VOD",
                "close":14.21,
                "volume":4436631,
                "low":14.19,
                "adj high":14.42,
                "exchange":"XNAS",
                "date":"2020-10-19T00:00:00+0000",
                "open":14.35,
                "high":14.42
            },
            {
                "Id":95,
                "adj low":14.0975,
                "adj close":14.28,
                "adj open":14.11,
                "adj volume":4334682,
                "symbol":"VOD",
                "close":14.28,
                "volume":4334682,
                "low":14.0975,
                "adj high":14.335,
                "exchange":"XNAS",
                "date":"2020-10-16T00:00:00+0000",
                "open":14.11,
                "high":14.335
            },
            {
                "Id":96,
                "adj low":14.115,
                "adj close":14.32,
                "adj open":14.15,
                "adj volume":3918735,
                "symbol":"VOD",
                "close":14.32,
                "volume":3918735,
                "low":14.115,
                "adj high":14.33,
                "exchange":"XNAS",
                "date":"2020-10-15T00:00:00+0000",
                "open":14.15,
                "high":14.33
            },
            {
                "Id":97,
                "adj low":14.33,
                "adj close":14.37,
                "adj open":14.43,
                "adj volume":1908486,
                "symbol":"VOD",
                "close":14.37,
                "volume":1908486,
                "low":14.33,
                "adj high":14.5,
                "exchange":"XNAS",
                "date":"2020-10-14T00:00:00+0000",
                "open":14.43,
                "high":14.5
            },
            {
                "Id":98,
                "adj low":14.5518,
                "adj close":14.6,
                "adj open":14.67,
                "adj volume":2635563,
                "symbol":"VOD",
                "close":14.6,
                "volume":2635563,
                "low":14.5518,
                "adj high":14.69,
                "exchange":"XNAS",
                "date":"2020-10-13T00:00:00+0000",
                "open":14.67,
                "high":14.69
            },
            {
                "Id":99,
                "adj low":14.655,
                "adj close":14.77,
                "adj open":14.73,
                "adj volume":2212874,
                "symbol":"VOD",
                "close":14.77,
                "volume":2212874,
                "low":14.655,
                "adj high":14.83,
                "exchange":"XNAS",
                "date":"2020-10-12T00:00:00+0000",
                "open":14.73,
                "high":14.83
            },
            {
                "Id":100,
                "adj low":14.44,
                "adj close":14.63,
                "adj open":14.6,
                "adj volume":2258206,
                "symbol":"VOD",
                "close":14.63,
                "volume":2258206,
                "low":14.44,
                "adj high":14.68,
                "exchange":"XNAS",
                "date":"2020-10-09T00:00:00+0000",
                "open":14.6,
                "high":14.68
            },
            {
                "Id":101,
                "adj low":118.62,
                "adj close":120.13,
                "adj open":121.75,
                "adj volume":178154975,
                "symbol":"AAPL",
                "close":120.13,
                "volume":164527934,
                "low":118.62,
                "adj high":123.6,
                "exchange":"XNAS",
                "date":"2021-03-04T00:00:00+0000",
                "open":121.75,
                "high":123.6
            },
            {
                "Id":102,
                "adj low":121.84,
                "adj close":122.06,
                "adj open":124.81,
                "adj volume":112966340,
                "symbol":"AAPL",
                "close":122.06,
                "volume":112965897,
                "low":121.84,
                "adj high":125.71,
                "exchange":"XNAS",
                "date":"2021-03-03T00:00:00+0000",
                "open":124.81,
                "high":125.71
            },
            {
                "Id":103,
                "adj low":125.015,
                "adj close":125.12,
                "adj open":128.41,
                "adj volume":102260945,
                "symbol":"AAPL",
                "close":125.12,
                "volume":102260945,
                "low":125.015,
                "adj high":128.72,
                "exchange":"XNAS",
                "date":"2021-03-02T00:00:00+0000",
                "open":128.41,
                "high":128.72
            },
            {
                "Id":104,
                "adj low":122.79,
                "adj close":127.79,
                "adj open":123.75,
                "adj volume":116307892,
                "symbol":"AAPL",
                "close":127.79,
                "volume":116307892,
                "low":122.79,
                "adj high":127.93,
                "exchange":"XNAS",
                "date":"2021-03-01T00:00:00+0000",
                "open":123.75,
                "high":127.93
            },
            {
                "Id":105,
                "adj low":121.2,
                "adj close":121.26,
                "adj open":122.59,
                "adj volume":163424672,
                "symbol":"AAPL",
                "close":121.26,
                "volume":164320000,
                "low":121.2,
                "adj high":124.85,
                "exchange":"XNAS",
                "date":"2021-02-26T00:00:00+0000",
                "open":122.59,
                "high":124.85
            },
            {
                "Id":106,
                "adj low":120.54,
                "adj close":120.99,
                "adj open":124.68,
                "adj volume":144766924,
                "symbol":"AAPL",
                "close":120.99,
                "volume":148199540,
                "low":120.54,
                "adj high":126.4585,
                "exchange":"XNAS",
                "date":"2021-02-25T00:00:00+0000",
                "open":124.68,
                "high":126.4585
            },
            {
                "Id":107,
                "adj low":122.23,
                "adj close":125.35,
                "adj open":124.94,
                "adj volume":111039904,
                "symbol":"AAPL",
                "close":125.35,
                "volume":111039904,
                "low":122.23,
                "adj high":125.56,
                "exchange":"XNAS",
                "date":"2021-02-24T00:00:00+0000",
                "open":124.94,
                "high":125.56
            },
            {
                "Id":108,
                "adj low":118.39,
                "adj close":125.86,
                "adj open":123.76,
                "adj volume":158273022,
                "symbol":"AAPL",
                "close":125.86,
                "volume":158273022,
                "low":118.39,
                "adj high":126.71,
                "exchange":"XNAS",
                "date":"2021-02-23T00:00:00+0000",
                "open":123.76,
                "high":126.705
            },
            {
                "Id":109,
                "adj low":125.6,
                "adj close":126,
                "adj open":128.01,
                "adj volume":102886922,
                "symbol":"AAPL",
                "close":126,
                "volume":100925374,
                "low":125.6,
                "adj high":129.72,
                "exchange":"XNAS",
                "date":"2021-02-22T00:00:00+0000",
                "open":128.01,
                "high":129.72
            },
            {
                "Id":110,
                "adj low":128.8,
                "adj close":129.87,
                "adj open":130.24,
                "adj volume":87668834,
                "symbol":"AAPL",
                "close":129.87,
                "volume":87668834,
                "low":128.8,
                "adj high":130.71,
                "exchange":"XNAS",
                "date":"2021-02-19T00:00:00+0000",
                "open":130.24,
                "high":130.71
            },
            {
                "Id":111,
                "adj low":127.41,
                "adj close":129.71,
                "adj open":129.2,
                "adj volume":96856748,
                "symbol":"AAPL",
                "close":129.71,
                "volume":96856748,
                "low":127.41,
                "adj high":129.995,
                "exchange":"XNAS",
                "date":"2021-02-18T00:00:00+0000",
                "open":129.2,
                "high":129.995
            },
            {
                "Id":112,
                "adj low":129.47,
                "adj close":130.84,
                "adj open":131.25,
                "adj volume":97372199,
                "symbol":"AAPL",
                "close":130.84,
                "volume":97372199,
                "low":129.47,
                "adj high":132.22,
                "exchange":"XNAS",
                "date":"2021-02-17T00:00:00+0000",
                "open":131.25,
                "high":132.22
            },
            {
                "Id":113,
                "adj low":132.79,
                "adj close":133.19,
                "adj open":135.49,
                "adj volume":80576316,
                "symbol":"AAPL",
                "close":133.19,
                "volume":80576316,
                "low":132.79,
                "adj high":136.01,
                "exchange":"XNAS",
                "date":"2021-02-16T00:00:00+0000",
                "open":135.49,
                "high":136.01
            },
            {
                "Id":114,
                "adj low":133.6921,
                "adj close":135.37,
                "adj open":134.35,
                "adj volume":60145130,
                "symbol":"AAPL",
                "close":135.37,
                "volume":60145130,
                "low":133.6921,
                "adj high":135.53,
                "exchange":"XNAS",
                "date":"2021-02-12T00:00:00+0000",
                "open":134.35,
                "high":135.53
            },
            {
                "Id":115,
                "adj low":133.77,
                "adj close":135.13,
                "adj open":135.9,
                "adj volume":64280029,
                "symbol":"AAPL",
                "close":135.13,
                "volume":64280029,
                "low":133.77,
                "adj high":136.39,
                "exchange":"XNAS",
                "date":"2021-02-11T00:00:00+0000",
                "open":135.9,
                "high":136.39
            },
            {
                "Id":116,
                "adj low":134.4,
                "adj close":135.39,
                "adj open":136.48,
                "adj volume":72647988,
                "symbol":"AAPL",
                "close":135.39,
                "volume":72647988,
                "low":134.4,
                "adj high":136.99,
                "exchange":"XNAS",
                "date":"2021-02-10T00:00:00+0000",
                "open":136.48,
                "high":136.99
            },
            {
                "Id":117,
                "adj low":135.85,
                "adj close":136.01,
                "adj open":136.62,
                "adj volume":75986989,
                "symbol":"AAPL",
                "close":136.01,
                "volume":75986989,
                "low":135.85,
                "adj high":137.877,
                "exchange":"XNAS",
                "date":"2021-02-09T00:00:00+0000",
                "open":136.62,
                "high":137.877
            },
            {
                "Id":118,
                "adj low":134.92,
                "adj close":136.91,
                "adj open":136.03,
                "adj volume":71297214,
                "symbol":"AAPL",
                "close":136.91,
                "volume":71297214,
                "low":134.92,
                "adj high":136.96,
                "exchange":"XNAS",
                "date":"2021-02-08T00:00:00+0000",
                "open":136.03,
                "high":136.96
            },
            {
                "Id":119,
                "adj low":135.86,
                "adj close":136.76,
                "adj open":137.35,
                "adj volume":75693830,
                "symbol":"AAPL",
                "close":136.76,
                "volume":75693830,
                "low":135.86,
                "adj high":137.42,
                "exchange":"XNAS",
                "date":"2021-02-05T00:00:00+0000",
                "open":137.35,
                "high":137.42
            },
            {
                "Id":120,
                "adj low":134.59,
                "adj close":137.1843638886,
                "adj open":136.0959953273,
                "adj volume":84183061,
                "symbol":"AAPL",
                "close":137.39,
                "volume":84183061,
                "low":134.59,
                "adj high":137.1943489213,
                "exchange":"XNAS",
                "date":"2021-02-04T00:00:00+0000",
                "open":136.3,
                "high":137.4
            },
            {
                "Id":121,
                "adj low":133.61,
                "adj close":133.7395276165,
                "adj open":135.556803563,
                "adj volume":89880937,
                "symbol":"AAPL",
                "close":133.94,
                "volume":89880937,
                "low":133.61,
                "adj high":135.5667885956,
                "exchange":"XNAS",
                "date":"2021-02-03T00:00:00+0000",
                "open":135.76,
                "high":135.77
            },
            {
                "Id":122,
                "adj low":134.61,
                "adj close":134.99,
                "adj open":135.73,
                "adj volume":82266419,
                "symbol":"AAPL",
                "close":134.99,
                "volume":82266419,
                "low":134.61,
                "adj high":136.31,
                "exchange":"XNAS",
                "date":"2021-02-02T00:00:00+0000",
                "open":135.73,
                "high":136.31
            },
            {
                "Id":123,
                "adj low":130.93,
                "adj close":134.14,
                "adj open":133.75,
                "adj volume":106239823,
                "symbol":"AAPL",
                "close":134.14,
                "volume":106239823,
                "low":130.93,
                "adj high":135.38,
                "exchange":"XNAS",
                "date":"2021-02-01T00:00:00+0000",
                "open":133.75,
                "high":135.38
            },
            {
                "Id":124,
                "adj low":130.21,
                "adj close":131.96,
                "adj open":135.83,
                "adj volume":177523812,
                "symbol":"AAPL",
                "close":131.96,
                "volume":177523812,
                "low":130.21,
                "adj high":136.74,
                "exchange":"XNAS",
                "date":"2021-01-29T00:00:00+0000",
                "open":135.83,
                "high":136.74
            },
            {
                "Id":125,
                "adj low":136.7,
                "adj close":137.09,
                "adj open":139.52,
                "adj volume":142621128,
                "symbol":"AAPL",
                "close":137.09,
                "volume":142621128,
                "low":136.7,
                "adj high":141.99,
                "exchange":"XNAS",
                "date":"2021-01-28T00:00:00+0000",
                "open":139.52,
                "high":141.99
            },
            {
                "Id":126,
                "adj low":140.41,
                "adj close":142.06,
                "adj open":143.43,
                "adj volume":140843759,
                "symbol":"AAPL",
                "close":142.06,
                "volume":140843759,
                "low":140.41,
                "adj high":144.3,
                "exchange":"XNAS",
                "date":"2021-01-27T00:00:00+0000",
                "open":143.43,
                "high":144.3
            },
            {
                "Id":127,
                "adj low":141.37,
                "adj close":143.16,
                "adj open":143.6,
                "adj volume":98390555,
                "symbol":"AAPL",
                "close":143.16,
                "volume":98390555,
                "low":141.37,
                "adj high":144.3,
                "exchange":"XNAS",
                "date":"2021-01-26T00:00:00+0000",
                "open":143.6,
                "high":144.3
            },
            {
                "Id":128,
                "adj low":136.54,
                "adj close":142.92,
                "adj open":143.07,
                "adj volume":157611713,
                "symbol":"AAPL",
                "close":142.92,
                "volume":157611713,
                "low":136.54,
                "adj high":145.09,
                "exchange":"XNAS",
                "date":"2021-01-25T00:00:00+0000",
                "open":143.07,
                "high":145.09
            },
            {
                "Id":129,
                "adj low":135.02,
                "adj close":139.07,
                "adj open":136.28,
                "adj volume":114459360,
                "symbol":"AAPL",
                "close":139.07,
                "volume":114459360,
                "low":135.02,
                "adj high":139.85,
                "exchange":"XNAS",
                "date":"2021-01-22T00:00:00+0000",
                "open":136.28,
                "high":139.85
            },
            {
                "Id":130,
                "adj low":133.59,
                "adj close":136.87,
                "adj open":133.8,
                "adj volume":120529544,
                "symbol":"AAPL",
                "close":136.87,
                "volume":120529544,
                "low":133.59,
                "adj high":139.67,
                "exchange":"XNAS",
                "date":"2021-01-21T00:00:00+0000",
                "open":133.8,
                "high":139.67
            },
            {
                "Id":131,
                "adj low":128.55,
                "adj close":132.03,
                "adj open":128.66,
                "adj volume":104319489,
                "symbol":"AAPL",
                "close":132.03,
                "volume":104319489,
                "low":128.55,
                "adj high":132.49,
                "exchange":"XNAS",
                "date":"2021-01-20T00:00:00+0000",
                "open":128.66,
                "high":132.49
            },
            {
                "Id":132,
                "adj low":126.938,
                "adj close":127.83,
                "adj open":127.78,
                "adj volume":90757329,
                "symbol":"AAPL",
                "close":127.83,
                "volume":90757329,
                "low":126.938,
                "adj high":128.71,
                "exchange":"XNAS",
                "date":"2021-01-19T00:00:00+0000",
                "open":127.78,
                "high":128.71
            },
            {
                "Id":133,
                "adj low":127,
                "adj close":127.14,
                "adj open":128.78,
                "adj volume":111598531,
                "symbol":"AAPL",
                "close":127.14,
                "volume":111598531,
                "low":127,
                "adj high":130.2242,
                "exchange":"XNAS",
                "date":"2021-01-15T00:00:00+0000",
                "open":128.78,
                "high":130.2242
            },
            {
                "Id":134,
                "adj low":128.76,
                "adj close":128.91,
                "adj open":130.8,
                "adj volume":90221755,
                "symbol":"AAPL",
                "close":128.91,
                "volume":90221755,
                "low":128.76,
                "adj high":131,
                "exchange":"XNAS",
                "date":"2021-01-14T00:00:00+0000",
                "open":130.8,
                "high":131
            },
            {
                "Id":135,
                "adj low":128.49,
                "adj close":130.89,
                "adj open":128.76,
                "adj volume":88636831,
                "symbol":"AAPL",
                "close":130.89,
                "volume":88636831,
                "low":128.49,
                "adj high":131.45,
                "exchange":"XNAS",
                "date":"2021-01-13T00:00:00+0000",
                "open":128.76,
                "high":131.45
            },
            {
                "Id":136,
                "adj low":126.86,
                "adj close":128.8,
                "adj open":128.5,
                "adj volume":90440255,
                "symbol":"AAPL",
                "close":128.8,
                "volume":90440255,
                "low":126.86,
                "adj high":129.69,
                "exchange":"XNAS",
                "date":"2021-01-12T00:00:00+0000",
                "open":128.5,
                "high":129.69
            },
            {
                "Id":137,
                "adj low":128.5,
                "adj close":128.98,
                "adj open":129.19,
                "adj volume":100620880,
                "symbol":"AAPL",
                "close":128.98,
                "volume":100620880,
                "low":128.5,
                "adj high":130.17,
                "exchange":"XNAS",
                "date":"2021-01-11T00:00:00+0000",
                "open":129.19,
                "high":130.17
            },
            {
                "Id":138,
                "adj low":130.23,
                "adj close":132.05,
                "adj open":132.43,
                "adj volume":105158245,
                "symbol":"AAPL",
                "close":132.05,
                "volume":105158245,
                "low":130.23,
                "adj high":132.63,
                "exchange":"XNAS",
                "date":"2021-01-08T00:00:00+0000",
                "open":132.43,
                "high":132.63
            },
            {
                "Id":139,
                "adj low":127.86,
                "adj close":130.92,
                "adj open":128.36,
                "adj volume":109578157,
                "symbol":"AAPL",
                "close":130.92,
                "volume":109578157,
                "low":127.86,
                "adj high":131.63,
                "exchange":"XNAS",
                "date":"2021-01-07T00:00:00+0000",
                "open":128.36,
                "high":131.63
            },
            {
                "Id":140,
                "adj low":126.382,
                "adj close":126.6,
                "adj open":127.72,
                "adj volume":155087970,
                "symbol":"AAPL",
                "close":126.6,
                "volume":155087970,
                "low":126.382,
                "adj high":131.0499,
                "exchange":"XNAS",
                "date":"2021-01-06T00:00:00+0000",
                "open":127.72,
                "high":131.0499
            },
            {
                "Id":141,
                "adj low":128.43,
                "adj close":131.01,
                "adj open":128.89,
                "adj volume":97664898,
                "symbol":"AAPL",
                "close":131.01,
                "volume":97664898,
                "low":128.43,
                "adj high":131.74,
                "exchange":"XNAS",
                "date":"2021-01-05T00:00:00+0000",
                "open":128.89,
                "high":131.74
            },
            {
                "Id":142,
                "adj low":126.76,
                "adj close":129.41,
                "adj open":133.52,
                "adj volume":143301887,
                "symbol":"AAPL",
                "close":129.41,
                "volume":143301887,
                "low":126.76,
                "adj high":133.6116,
                "exchange":"XNAS",
                "date":"2021-01-04T00:00:00+0000",
                "open":133.52,
                "high":133.6116
            },
            {
                "Id":143,
                "adj low":131.72,
                "adj close":132.69,
                "adj open":134.08,
                "adj volume":99116586,
                "symbol":"AAPL",
                "close":132.69,
                "volume":99116586,
                "low":131.72,
                "adj high":134.74,
                "exchange":"XNAS",
                "date":"2020-12-31T00:00:00+0000",
                "open":134.08,
                "high":134.74
            },
            {
                "Id":144,
                "adj low":133.4,
                "adj close":133.72,
                "adj open":135.58,
                "adj volume":96452124,
                "symbol":"AAPL",
                "close":133.72,
                "volume":96452124,
                "low":133.4,
                "adj high":135.99,
                "exchange":"XNAS",
                "date":"2020-12-30T00:00:00+0000",
                "open":135.58,
                "high":135.99
            },
            {
                "Id":145,
                "adj low":134.3409,
                "adj close":134.87,
                "adj open":138.05,
                "adj volume":121047324,
                "symbol":"AAPL",
                "close":134.87,
                "volume":121047324,
                "low":134.3409,
                "adj high":138.789,
                "exchange":"XNAS",
                "date":"2020-12-29T00:00:00+0000",
                "open":138.05,
                "high":138.789
            },
            {
                "Id":146,
                "adj low":133.51,
                "adj close":136.69,
                "adj open":133.99,
                "adj volume":123124632,
                "symbol":"AAPL",
                "close":136.69,
                "volume":123124632,
                "low":133.51,
                "adj high":137.34,
                "exchange":"XNAS",
                "date":"2020-12-28T00:00:00+0000",
                "open":133.99,
                "high":137.34
            },
            {
                "Id":147,
                "adj low":131.1,
                "adj close":131.97,
                "adj open":131.32,
                "adj volume":54930064,
                "symbol":"AAPL",
                "close":131.97,
                "volume":54930064,
                "low":131.1,
                "adj high":133.46,
                "exchange":"XNAS",
                "date":"2020-12-24T00:00:00+0000",
                "open":131.32,
                "high":133.46
            },
            {
                "Id":148,
                "adj low":130.78,
                "adj close":130.96,
                "adj open":132.16,
                "adj volume":88223692,
                "symbol":"AAPL",
                "close":130.96,
                "volume":88223692,
                "low":130.78,
                "adj high":132.43,
                "exchange":"XNAS",
                "date":"2020-12-23T00:00:00+0000",
                "open":132.16,
                "high":132.43
            },
            {
                "Id":149,
                "adj low":129.65,
                "adj close":131.88,
                "adj open":131.61,
                "adj volume":169351825,
                "symbol":"AAPL",
                "close":131.88,
                "volume":169351825,
                "low":129.65,
                "adj high":134.405,
                "exchange":"XNAS",
                "date":"2020-12-22T00:00:00+0000",
                "open":131.61,
                "high":134.405
            },
            {
                "Id":150,
                "adj low":123.449,
                "adj close":128.23,
                "adj open":125.02,
                "adj volume":121251553,
                "symbol":"AAPL",
                "close":128.23,
                "volume":121251553,
                "low":123.449,
                "adj high":128.31,
                "exchange":"XNAS",
                "date":"2020-12-21T00:00:00+0000",
                "open":125.02,
                "high":128.31
            },
            {
                "Id":151,
                "adj low":126.12,
                "adj close":126.655,
                "adj open":128.96,
                "adj volume":192541496,
                "symbol":"AAPL",
                "close":126.655,
                "volume":192541496,
                "low":126.12,
                "adj high":129.1,
                "exchange":"XNAS",
                "date":"2020-12-18T00:00:00+0000",
                "open":128.96,
                "high":129.1
            },
            {
                "Id":152,
                "adj low":128.045,
                "adj close":128.7,
                "adj open":128.9,
                "adj volume":94359811,
                "symbol":"AAPL",
                "close":128.7,
                "volume":94359811,
                "low":128.045,
                "adj high":129.58,
                "exchange":"XNAS",
                "date":"2020-12-17T00:00:00+0000",
                "open":128.9,
                "high":129.58
            },
            {
                "Id":153,
                "adj low":126.56,
                "adj close":127.81,
                "adj open":127.41,
                "adj volume":98208591,
                "symbol":"AAPL",
                "close":127.81,
                "volume":98208591,
                "low":126.56,
                "adj high":128.37,
                "exchange":"XNAS",
                "date":"2020-12-16T00:00:00+0000",
                "open":127.41,
                "high":128.37
            },
            {
                "Id":154,
                "adj low":124.13,
                "adj close":127.88,
                "adj open":124.34,
                "adj volume":157572262,
                "symbol":"AAPL",
                "close":127.88,
                "volume":157572262,
                "low":124.13,
                "adj high":127.9,
                "exchange":"XNAS",
                "date":"2020-12-15T00:00:00+0000",
                "open":124.34,
                "high":127.9
            },
            {
                "Id":155,
                "adj low":121.54,
                "adj close":121.78,
                "adj open":122.6,
                "adj volume":79075988,
                "symbol":"AAPL",
                "close":121.78,
                "volume":79075988,
                "low":121.54,
                "adj high":123.35,
                "exchange":"XNAS",
                "date":"2020-12-14T00:00:00+0000",
                "open":122.6,
                "high":123.35
            },
            {
                "Id":156,
                "adj low":120.55,
                "adj close":122.41,
                "adj open":122.43,
                "adj volume":86939786,
                "symbol":"AAPL",
                "close":122.41,
                "volume":86939786,
                "low":120.55,
                "adj high":122.76,
                "exchange":"XNAS",
                "date":"2020-12-11T00:00:00+0000",
                "open":122.43,
                "high":122.76
            },
            {
                "Id":157,
                "adj low":120.15,
                "adj close":123.24,
                "adj open":120.5,
                "adj volume":81312170,
                "symbol":"AAPL",
                "close":123.24,
                "volume":81312170,
                "low":120.15,
                "adj high":123.87,
                "exchange":"XNAS",
                "date":"2020-12-10T00:00:00+0000",
                "open":120.5,
                "high":123.87
            },
            {
                "Id":158,
                "adj low":121,
                "adj close":121.78,
                "adj open":124.53,
                "adj volume":115089193,
                "symbol":"AAPL",
                "close":121.78,
                "volume":115089193,
                "low":121,
                "adj high":125.95,
                "exchange":"XNAS",
                "date":"2020-12-09T00:00:00+0000",
                "open":124.53,
                "high":125.95
            },
            {
                "Id":159,
                "adj low":123.09,
                "adj close":124.38,
                "adj open":124.37,
                "adj volume":82225512,
                "symbol":"AAPL",
                "close":124.38,
                "volume":82225512,
                "low":123.09,
                "adj high":124.98,
                "exchange":"XNAS",
                "date":"2020-12-08T00:00:00+0000",
                "open":124.37,
                "high":124.98
            },
            {
                "Id":160,
                "adj low":122.25,
                "adj close":123.75,
                "adj open":122.31,
                "adj volume":86711990,
                "symbol":"AAPL",
                "close":123.75,
                "volume":86711990,
                "low":122.25,
                "adj high":124.57,
                "exchange":"XNAS",
                "date":"2020-12-07T00:00:00+0000",
                "open":122.31,
                "high":124.57
            },
            {
                "Id":161,
                "adj low":121.52,
                "adj close":122.25,
                "adj open":122.6,
                "adj volume":78260421,
                "symbol":"AAPL",
                "close":122.25,
                "volume":78260421,
                "low":121.52,
                "adj high":122.8608,
                "exchange":"XNAS",
                "date":"2020-12-04T00:00:00+0000",
                "open":122.6,
                "high":122.8608
            },
            {
                "Id":162,
                "adj low":122.21,
                "adj close":122.94,
                "adj open":123.52,
                "adj volume":78967630,
                "symbol":"AAPL",
                "close":122.94,
                "volume":78967630,
                "low":122.21,
                "adj high":123.78,
                "exchange":"XNAS",
                "date":"2020-12-03T00:00:00+0000",
                "open":123.52,
                "high":123.78
            },
            {
                "Id":163,
                "adj low":120.89,
                "adj close":123.08,
                "adj open":122.02,
                "adj volume":89004195,
                "symbol":"AAPL",
                "close":123.08,
                "volume":89004195,
                "low":120.89,
                "adj high":123.37,
                "exchange":"XNAS",
                "date":"2020-12-02T00:00:00+0000",
                "open":122.02,
                "high":123.37
            },
            {
                "Id":164,
                "adj low":120.01,
                "adj close":122.72,
                "adj open":121.01,
                "adj volume":125920963,
                "symbol":"AAPL",
                "close":122.72,
                "volume":125920963,
                "low":120.01,
                "adj high":123.4693,
                "exchange":"XNAS",
                "date":"2020-12-01T00:00:00+0000",
                "open":121.01,
                "high":123.4693
            },
            {
                "Id":165,
                "adj low":116.81,
                "adj close":119.05,
                "adj open":116.97,
                "adj volume":169410176,
                "symbol":"AAPL",
                "close":119.05,
                "volume":169410176,
                "low":116.81,
                "adj high":120.97,
                "exchange":"XNAS",
                "date":"2020-11-30T00:00:00+0000",
                "open":116.97,
                "high":120.97
            },
            {
                "Id":166,
                "adj low":116.22,
                "adj close":116.59,
                "adj open":116.57,
                "adj volume":46691331,
                "symbol":"AAPL",
                "close":116.59,
                "volume":46691331,
                "low":116.22,
                "adj high":117.49,
                "exchange":"XNAS",
                "date":"2020-11-27T00:00:00+0000",
                "open":116.57,
                "high":117.49
            },
            {
                "Id":167,
                "adj low":115.17,
                "adj close":116.03,
                "adj open":115.55,
                "adj volume":76499234,
                "symbol":"AAPL",
                "close":116.03,
                "volume":76499234,
                "low":115.17,
                "adj high":116.75,
                "exchange":"XNAS",
                "date":"2020-11-25T00:00:00+0000",
                "open":115.55,
                "high":116.75
            },
            {
                "Id":168,
                "adj low":112.59,
                "adj close":115.17,
                "adj open":113.91,
                "adj volume":113226248,
                "symbol":"AAPL",
                "close":115.17,
                "volume":113226248,
                "low":112.59,
                "adj high":115.85,
                "exchange":"XNAS",
                "date":"2020-11-24T00:00:00+0000",
                "open":113.91,
                "high":115.85
            },
            {
                "Id":169,
                "adj low":113.75,
                "adj close":113.85,
                "adj open":117.18,
                "adj volume":127959318,
                "symbol":"AAPL",
                "close":113.85,
                "volume":127959318,
                "low":113.75,
                "adj high":117.6202,
                "exchange":"XNAS",
                "date":"2020-11-23T00:00:00+0000",
                "open":117.18,
                "high":117.6202
            },
            {
                "Id":170,
                "adj low":117.29,
                "adj close":117.34,
                "adj open":118.64,
                "adj volume":73604287,
                "symbol":"AAPL",
                "close":117.34,
                "volume":73604287,
                "low":117.29,
                "adj high":118.77,
                "exchange":"XNAS",
                "date":"2020-11-20T00:00:00+0000",
                "open":118.64,
                "high":118.77
            },
            {
                "Id":171,
                "adj low":116.81,
                "adj close":118.64,
                "adj open":117.59,
                "adj volume":74112972,
                "symbol":"AAPL",
                "close":118.64,
                "volume":74112972,
                "low":116.81,
                "adj high":119.06,
                "exchange":"XNAS",
                "date":"2020-11-19T00:00:00+0000",
                "open":117.59,
                "high":119.06
            },
            {
                "Id":172,
                "adj low":118,
                "adj close":118.03,
                "adj open":118.61,
                "adj volume":76322111,
                "symbol":"AAPL",
                "close":118.03,
                "volume":76322111,
                "low":118,
                "adj high":119.82,
                "exchange":"XNAS",
                "date":"2020-11-18T00:00:00+0000",
                "open":118.61,
                "high":119.82
            },
            {
                "Id":173,
                "adj low":118.96,
                "adj close":119.39,
                "adj open":119.55,
                "adj volume":74270973,
                "symbol":"AAPL",
                "close":119.39,
                "volume":74270973,
                "low":118.96,
                "adj high":120.6741,
                "exchange":"XNAS",
                "date":"2020-11-17T00:00:00+0000",
                "open":119.55,
                "high":120.6741
            },
            {
                "Id":174,
                "adj low":118.146,
                "adj close":120.3,
                "adj open":118.92,
                "adj volume":91183018,
                "symbol":"AAPL",
                "close":120.3,
                "volume":91183018,
                "low":118.146,
                "adj high":120.99,
                "exchange":"XNAS",
                "date":"2020-11-16T00:00:00+0000",
                "open":118.92,
                "high":120.99
            },
            {
                "Id":175,
                "adj low":117.87,
                "adj close":119.26,
                "adj open":119.44,
                "adj volume":81688586,
                "symbol":"AAPL",
                "close":119.26,
                "volume":81688586,
                "low":117.87,
                "adj high":119.6717,
                "exchange":"XNAS",
                "date":"2020-11-13T00:00:00+0000",
                "open":119.44,
                "high":119.6717
            },
            {
                "Id":176,
                "adj low":118.57,
                "adj close":119.21,
                "adj open":119.62,
                "adj volume":103350674,
                "symbol":"AAPL",
                "close":119.21,
                "volume":103350674,
                "low":118.57,
                "adj high":120.53,
                "exchange":"XNAS",
                "date":"2020-11-12T00:00:00+0000",
                "open":119.62,
                "high":120.53
            },
            {
                "Id":177,
                "adj low":116.44,
                "adj close":119.49,
                "adj open":117.19,
                "adj volume":112294954,
                "symbol":"AAPL",
                "close":119.49,
                "volume":112294954,
                "low":116.44,
                "adj high":119.63,
                "exchange":"XNAS",
                "date":"2020-11-11T00:00:00+0000",
                "open":117.19,
                "high":119.63
            },
            {
                "Id":178,
                "adj low":114.13,
                "adj close":115.97,
                "adj open":115.55,
                "adj volume":138023390,
                "symbol":"AAPL",
                "close":115.97,
                "volume":138023390,
                "low":114.13,
                "adj high":117.59,
                "exchange":"XNAS",
                "date":"2020-11-10T00:00:00+0000",
                "open":115.55,
                "high":117.59
            },
            {
                "Id":179,
                "adj low":116.05,
                "adj close":116.32,
                "adj open":120.5,
                "adj volume":154515315,
                "symbol":"AAPL",
                "close":116.32,
                "volume":154515315,
                "low":116.05,
                "adj high":121.99,
                "exchange":"XNAS",
                "date":"2020-11-09T00:00:00+0000",
                "open":120.5,
                "high":121.99
            },
            {
                "Id":180,
                "adj low":116.13,
                "adj close":118.69,
                "adj open":118.32,
                "adj volume":114457922,
                "symbol":"AAPL",
                "close":118.69,
                "volume":114457922,
                "low":116.13,
                "adj high":119.2,
                "exchange":"XNAS",
                "date":"2020-11-06T00:00:00+0000",
                "open":118.32,
                "high":119.2
            },
            {
                "Id":181,
                "adj low":116.8686,
                "adj close":118.8247672316,
                "adj open":117.7466293789,
                "adj volume":126387074,
                "symbol":"AAPL",
                "close":119.03,
                "volume":126387074,
                "low":116.8686,
                "adj high":119.4137499474,
                "exchange":"XNAS",
                "date":"2020-11-05T00:00:00+0000",
                "open":117.95,
                "high":119.62
            },
            {
                "Id":182,
                "adj low":112.35,
                "adj close":114.7518020102,
                "adj open":113.9431986206,
                "adj volume":138235482,
                "symbol":"AAPL",
                "close":114.95,
                "volume":138235482,
                "low":112.35,
                "adj high":115.3906985155,
                "exchange":"XNAS",
                "date":"2020-11-04T00:00:00+0000",
                "open":114.14,
                "high":115.59
            },
            {
                "Id":183,
                "adj low":108.73,
                "adj close":110.2495781993,
                "adj open":109.4709230834,
                "adj volume":107624448,
                "symbol":"AAPL",
                "close":110.44,
                "volume":107624448,
                "low":108.73,
                "adj high":111.2977677783,
                "exchange":"XNAS",
                "date":"2020-11-03T00:00:00+0000",
                "open":109.66,
                "high":111.49
            },
            {
                "Id":184,
                "adj low":107.32,
                "adj close":108.5824576307,
                "adj open":108.9218713991,
                "adj volume":122866899,
                "symbol":"AAPL",
                "close":108.77,
                "volume":122866899,
                "low":107.32,
                "adj high":110.4891643887,
                "exchange":"XNAS",
                "date":"2020-11-02T00:00:00+0000",
                "open":109.11,
                "high":110.68
            },
            {
                "Id":185,
                "adj low":107.72,
                "adj close":108.6723024517,
                "adj open":110.8685091888,
                "adj volume":190573476,
                "symbol":"AAPL",
                "close":108.86,
                "volume":190573476,
                "low":107.72,
                "adj high":111.7969056731,
                "exchange":"XNAS",
                "date":"2020-10-30T00:00:00+0000",
                "open":111.06,
                "high":111.99
            },
            {
                "Id":186,
                "adj low":112.2,
                "adj close":115.1211640523,
                "adj open":112.1762504731,
                "adj volume":146129173,
                "symbol":"AAPL",
                "close":115.32,
                "volume":146129173,
                "low":112.2,
                "adj high":116.7283880735,
                "exchange":"XNAS",
                "date":"2020-10-29T00:00:00+0000",
                "open":112.37,
                "high":116.93
            },
            {
                "Id":187,
                "adj low":111.1,
                "adj close":111.0082677993,
                "adj open":114.8516295891,
                "adj volume":143937823,
                "symbol":"AAPL",
                "close":111.2,
                "volume":143937823,
                "low":111.1,
                "adj high":115.2309743892,
                "exchange":"XNAS",
                "date":"2020-10-28T00:00:00+0000",
                "open":115.05,
                "high":115.43
            },
            {
                "Id":188,
                "adj low":114.5399,
                "adj close":116.398957063,
                "adj open":115.2908709365,
                "adj volume":92276772,
                "symbol":"AAPL",
                "close":116.6,
                "volume":92276772,
                "low":114.5399,
                "adj high":117.0777845999,
                "exchange":"XNAS",
                "date":"2020-10-27T00:00:00+0000",
                "open":115.49,
                "high":117.28
            },
            {
                "Id":189,
                "adj low":112.88,
                "adj close":114.8516295891,
                "adj open":113.813422768,
                "adj volume":111850657,
                "symbol":"AAPL",
                "close":115.05,
                "volume":111850657,
                "low":112.88,
                "adj high":116.3490432735,
                "exchange":"XNAS",
                "date":"2020-10-26T00:00:00+0000",
                "open":114.01,
                "high":116.55
            },
            {
                "Id":190,
                "adj low":114.28,
                "adj close":114.8416468312,
                "adj open":116.1893191471,
                "adj volume":82572645,
                "symbol":"AAPL",
                "close":115.04,
                "volume":82572645,
                "low":114.28,
                "adj high":116.3490432735,
                "exchange":"XNAS",
                "date":"2020-10-23T00:00:00+0000",
                "open":116.39,
                "high":116.55
            },
            {
                "Id":191,
                "adj low":114.59,
                "adj close":115.5504226418,
                "adj open":117.2474914841,
                "adj volume":101987954,
                "symbol":"AAPL",
                "close":115.75,
                "volume":101987954,
                "low":114.59,
                "adj high":117.8364741999,
                "exchange":"XNAS",
                "date":"2020-10-22T00:00:00+0000",
                "open":117.45,
                "high":118.04
            },
            {
                "Id":192,
                "adj low":116.45,
                "adj close":116.6684915261,
                "adj open":116.4688363682,
                "adj volume":89945980,
                "symbol":"AAPL",
                "close":116.87,
                "volume":89945980,
                "low":116.45,
                "adj high":118.5003276,
                "exchange":"XNAS",
                "date":"2020-10-21T00:00:00+0000",
                "open":116.67,
                "high":118.705
            },
            {
                "Id":193,
                "adj low":115.63,
                "adj close":117.3073880315,
                "adj open":115.9996467471,
                "adj volume":124423728,
                "symbol":"AAPL",
                "close":117.51,
                "volume":124423728,
                "low":115.63,
                "adj high":118.7748534421,
                "exchange":"XNAS",
                "date":"2020-10-20T00:00:00+0000",
                "open":116.2,
                "high":118.98
            },
            {
                "Id":194,
                "adj low":115.66,
                "adj close":115.7800260734,
                "adj open":119.7531637159,
                "adj volume":120639337,
                "symbol":"AAPL",
                "close":115.98,
                "volume":120639337,
                "low":115.66,
                "adj high":120.2113723033,
                "exchange":"XNAS",
                "date":"2020-10-19T00:00:00+0000",
                "open":119.96,
                "high":120.419
            },
            {
                "Id":195,
                "adj low":118.81,
                "adj close":118.8147844737,
                "adj open":121.0708877581,
                "adj volume":115393808,
                "symbol":"AAPL",
                "close":119.02,
                "volume":115393808,
                "low":118.81,
                "adj high":121.3384256697,
                "exchange":"XNAS",
                "date":"2020-10-16T00:00:00+0000",
                "open":121.28,
                "high":121.548
            },
            {
                "Id":196,
                "adj low":118.15,
                "adj close":120.5018705581,
                "adj open":118.5153017368,
                "adj volume":112559219,
                "symbol":"AAPL",
                "close":120.71,
                "volume":112559219,
                "low":118.15,
                "adj high":120.9910256949,
                "exchange":"XNAS",
                "date":"2020-10-15T00:00:00+0000",
                "open":118.72,
                "high":121.2
            },
            {
                "Id":197,
                "adj low":119.62,
                "adj close":120.981042937,
                "adj open":120.791370537,
                "adj volume":151062308,
                "symbol":"AAPL",
                "close":121.19,
                "volume":151062308,
                "low":119.62,
                "adj high":122.8178703898,
                "exchange":"XNAS",
                "date":"2020-10-14T00:00:00+0000",
                "open":121,
                "high":123.03
            },
            {
                "Id":198,
                "adj low":119.65,
                "adj close":120.891198116,
                "adj open":125.0540081585,
                "adj volume":262330451,
                "symbol":"AAPL",
                "close":121.1,
                "volume":262330451,
                "low":119.65,
                "adj high":125.1738012532,
                "exchange":"XNAS",
                "date":"2020-10-13T00:00:00+0000",
                "open":125.27,
                "high":125.39
            },
            {
                "Id":199,
                "adj low":119.2845,
                "adj close":124.1855082215,
                "adj open":119.8529912948,
                "adj volume":240226769,
                "symbol":"AAPL",
                "close":124.4,
                "volume":240226769,
                "low":119.2845,
                "adj high":124.9641633374,
                "exchange":"XNAS",
                "date":"2020-10-12T00:00:00+0000",
                "open":120.06,
                "high":125.18
            },
            {
                "Id":200,
                "adj low":114.92,
                "adj close":116.7683191051,
                "adj open":115.0812330207,
                "adj volume":100506865,
                "symbol":"AAPL",
                "close":116.97,
                "volume":100506865,
                "low":114.92,
                "adj high":116.7982673788,
                "exchange":"XNAS",
                "date":"2020-10-09T00:00:00+0000",
                "open":115.28,
                "high":117
            }
        ],
    defaultData: [
        {
            "name": "China",
            "region": "East Asia & Pacific",
            "income": 648.86068677913,
            "population": 731149966.56273,
            "lifeExpectancy": 56.558222188827
        },
        {
            "name": "India",
            "region": "South Asia",
            "income": 699.8921741744,
            "population": 492897584.25067,
            "lifeExpectancy": 46.163342976293
        },
        {
            "name": "France",
            "region": "Europe & Central Asia",
            "income": 13074.263804711,
            "population": 49103385.881204,
            "lifeExpectancy": 71.473800954277
        },
        {
            "name": "Japan",
            "region": "East Asia & Pacific",
            "income": 8791.7631153023,
            "population": 99599456.164557,
            "lifeExpectancy": 70.969704324304
        },
        {
            "name": "Germany",
            "region": "Europe & Central Asia",
            "income": 14664.769673893,
            "population": 76086898.329226,
            "lifeExpectancy": 70.683180674005
        },
        {
            "name": "Italy",
            "region": "Europe & Central Asia",
            "income": 9400.1564282226,
            "population": 52259171.777436,
            "lifeExpectancy": 70.8623188188
        },
        {
            "name": "Bangladesh",
            "region": "South Asia",
            "income": 754.05120787467,
            "population": 61292463.244881,
            "lifeExpectancy": 42.661698538528
        },
        {
            "name": "Indonesia",
            "region": "East Asia & Pacific",
            "income": 795.86387271293,
            "population": 106754651.72634,
            "lifeExpectancy": 44.744933313296
        },
        {
            "name": "United Kingdom",
            "region": "Europe & Central Asia",
            "income": 13873.045716272,
            "population": 54581399.218545,
            "lifeExpectancy": 71.546819325995
        },
        {
            "name": "Pakistan",
            "region": "South Asia",
            "income": 923.11864597814,
            "population": 58720063.023744,
            "lifeExpectancy": 52.328571965285
        },
        {
            "name": "Spain",
            "region": "Europe & Central Asia",
            "income": 7488.633793816,
            "population": 32374718.789909,
            "lifeExpectancy": 71.195337190517
        },
        {
            "name": "Nigeria",
            "region": "Sub-Saharan Africa",
            "income": 1246.4298996882,
            "population": 45900097.977496,
            "lifeExpectancy": 39.281644437765
        },
        {
            "name": "Ukraine",
            "region": "Europe & Central Asia",
            "income": 5685.2656100302,
            "population": 45581392.093925,
            "lifeExpectancy": 71.843234774768
        },
        {
            "name": "Poland",
            "region": "Europe & Central Asia",
            "income": 6310.2099267386,
            "population": 31475322.677387,
            "lifeExpectancy": 69.823800954277
        },
        {
            "name": "Turkey",
            "region": "Europe & Central Asia",
            "income": 3121.7943847917,
            "population": 32524903.965361,
            "lifeExpectancy": 53.67539129128
        },
        {
            "name": "United States",
            "region": "America",
            "income": 22041.774651539,
            "population": 196085484.76538,
            "lifeExpectancy": 70.425795168501
        },
        {
            "name": "Korea, Rep.",
            "region": "East Asia & Pacific",
            "income": 1483.1969042342,
            "population": 29282313.408724,
            "lifeExpectancy": 56.696981628283
        },
        {
            "name": "Mexico",
            "region": "America",
            "income": 5542.8427091709,
            "population": 46244453.968366,
            "lifeExpectancy": 59.714313033024
        },
        {
            "name": "Vietnam",
            "region": "East Asia & Pacific",
            "income": 752.57669609648,
            "population": 38142976.622159,
            "lifeExpectancy": 46.963288875531
        },
        {
            "name": "Romania",
            "region": "Europe & Central Asia",
            "income": 6107.089858737,
            "population": 19116938.241537,
            "lifeExpectancy": 66.885283089755
        },
        {
            "name": "Congo, Dem. Rep.",
            "region": "Sub-Saharan Africa",
            "income": 889.67949656235,
            "population": 19240811.601871,
            "lifeExpectancy": 42.637439606267
        },
        {
            "name": "Sudan",
            "region": "Sub-Saharan Africa",
            "income": 1804.7371518203,
            "population": 12315544.680054,
            "lifeExpectancy": 44.862210617275
        },
        {
            "name": "Ireland",
            "region": "Europe & Central Asia",
            "income": 7258.5733843033,
            "population": 2882318.0674005,
            "lifeExpectancy": 70.303018371717
        },
        {
            "name": "Thailand",
            "region": "East Asia & Pacific",
            "income": 1212.2101190968,
            "population": 32831057.390465,
            "lifeExpectancy": 57.206981628283
        },
        {
            "name": "Brazil",
            "region": "America",
            "income": 3359.2564601571,
            "population": 85039293.700605,
            "lifeExpectancy": 56.998005785776
        },
        {
            "name": "Korea, Dem. Rep.",
            "region": "East Asia & Pacific",
            "income": 1566.5149716347,
            "population": 12155519.391968,
            "lifeExpectancy": 58.734933313296
        },
        {
            "name": "Hungary",
            "region": "Europe & Central Asia",
            "income": 8759.6136641996,
            "population": 10177911.68971,
            "lifeExpectancy": 69.770216403051
        },
        {
            "name": "Nepal",
            "region": "South Asia",
            "income": 692.70502122704,
            "population": 11016461.653079,
            "lifeExpectancy": 40.659596122779
        },
        {
            "name": "Myanmar",
            "region": "East Asia & Pacific",
            "income": 663.8098504715,
            "population": 25296947.760905,
            "lifeExpectancy": 47.991294661307
        },
        {
            "name": "Belgium",
            "region": "Europe & Central Asia",
            "income": 12654.187272044,
            "population": 9495248.5779765,
            "lifeExpectancy": 70.635283089755
        },
        {
            "x": "2020-01-31T00:00:00+0000",
            "name": "Austria",
            "region": "Europe & Central Asia",
            "income": 12426.328166585,
            "population": 7311306.4669196,
            "lifeExpectancy": 70.194825111771
        },
        {
            "name": "Portugal",
            "region": "Europe & Central Asia",
            "income": 5860.0530499305,
            "population": 9113015.3435774,
            "lifeExpectancy": 65.803018371717
        },
        {
            "name": "Afghanistan",
            "region": "South Asia",
            "income": 836.05090468497,
            "population": 11206727.928166,
            "lifeExpectancy": 33.295903370027
        },
        {
            "name": "Tanzania",
            "region": "Sub-Saharan Africa",
            "income": 825.02390126612,
            "population": 12155496.627005,
            "lifeExpectancy": 45.234825111771
        },
        {
            "name": "Ethiopia",
            "region": "Sub-Saharan Africa",
            "income": 503.63618138784,
            "population": 27188480.019461,
            "lifeExpectancy": 41.565903370027
        },
        {
            "name": "Morocco",
            "region": "Middle East & North Africa",
            "income": 1650.5338497952,
            "population": 14341370.595484,
            "lifeExpectancy": 49.509084044032
        },
        {
            "name": "Algeria",
            "region": "Middle East & North Africa",
            "income": 3124.8325427358,
            "population": 12260078.865988,
            "lifeExpectancy": 50.309650223541
        },
        {
            "name": "Yemen, Rep.",
            "region": "Middle East & North Africa",
            "income": 850.73213885863,
            "population": 6600645.7576361,
            "lifeExpectancy": 36.242210617275
        },
        {
            "name": "Sweden",
            "region": "Europe & Central Asia",
            "income": 15677.930420408,
            "population": 7792250.8969831,
            "lifeExpectancy": 74.077439606267
        },
        {
            "name": "Kenya",
            "region": "Sub-Saharan Africa",
            "income": 1019.6508438216,
            "population": 9797526.4343465,
            "lifeExpectancy": 49.802264718037
        },
        {
            "name": "Belarus",
            "region": "Europe & Central Asia",
            "income": 4406.6722772141,
            "population": 8672307.2339107,
            "lifeExpectancy": 73.304313033024
        },
        {
            "name": "Netherlands",
            "region": "Europe & Central Asia",
            "income": 12992.517647368,
            "population": 12420572.671601,
            "lifeExpectancy": 73.560512078747
        },
        {
            "name": "Greece",
            "region": "Europe & Central Asia",
            "income": 7632.864664688,
            "population": 8600338.9239584,
            "lifeExpectancy": 70.509029943269
        },
        {
            "name": "Bulgaria",
            "region": "Europe & Central Asia",
            "income": 5251.2175297742,
            "population": 8246145.343089,
            "lifeExpectancy": 71.244716910245
        },
        {
            "name": "Philippines",
            "region": "East Asia & Pacific",
            "income": 1770.5979201262,
            "population": 34086315.508059,
            "lifeExpectancy": 55.992722696021
        },
        {
            "name": "Serbia",
            "region": "Europe & Central Asia",
            "income": 7855.3304715032,
            "population": 7890235.714919,
            "lifeExpectancy": 66.173288875531
        },
        {
            "name": "Slovak Republic",
            "region": "Europe & Central Asia",
            "income": 8035.7924533193,
            "population": 4400778.2767404,
            "lifeExpectancy": 70.410566179509
        },
        {
            "name": "Uganda",
            "region": "Sub-Saharan Africa",
            "income": 883.0260919713,
            "population": 8587412.0087538,
            "lifeExpectancy": 47.185957470789
        },
        {
            "name": "Mozambique",
            "region": "Sub-Saharan Africa",
            "income": 541.3804365631,
            "population": 8447499.9743397,
            "lifeExpectancy": 37.475903370027
        },
        {
            "name": "Saudi Arabia",
            "region": "Middle East & North Africa",
            "income": 15611.134802194,
            "population": 5439524.782545,
            "lifeExpectancy": 48.461806740053
        },
        {
            "name": "Cambodia",
            "region": "East Asia & Pacific",
            "income": 509.15151857835,
            "population": 6742609.1907803,
            "lifeExpectancy": 45.201132359019
        },
        {
            "name": "Kazakhstan",
            "region": "Europe & Central Asia",
            "income": 5791.1789829318,
            "population": 12121467.857647,
            "lifeExpectancy": 60.809596122779
        },
        {
            "name": "Ghana",
            "region": "Sub-Saharan Africa",
            "income": 1145.2965980388,
            "population": 8195889.4275463,
            "lifeExpectancy": 47.369029943269
        },
        {
            "name": "Taiwan",
            "region": "East Asia & Pacific",
            "income": 2787.9920727355,
            "population": 13248811.98114,
            "lifeExpectancy": 67.4
        },
        {
            "name": "Switzerland",
            "region": "Europe & Central Asia",
            "income": 22461.080616148,
            "population": 5984857.1965285,
            "lifeExpectancy": 72.440566179509
        },
        {
            "name": "Uzbekistan",
            "region": "Europe & Central Asia",
            "income": 2129.0101874578,
            "population": 10461524.039486,
            "lifeExpectancy": 61.383800954277
        },
        {
            "name": "Cameroon",
            "region": "Sub-Saharan Africa",
            "income": 1487.4372645302,
            "population": 6193159.4326558,
            "lifeExpectancy": 44.079596122779
        },
        {
            "name": "Madagascar",
            "region": "Sub-Saharan Africa",
            "income": 1582.1164947214,
            "population": 6172669.436225,
            "lifeExpectancy": 42.165903370027
        },
        {
            "name": "Burkina Faso",
            "region": "Sub-Saharan Africa",
            "income": 738.49226096104,
            "population": 5067811.5586655,
            "lifeExpectancy": 39.953800954277
        },
        {
            "name": "Angola",
            "region": "Sub-Saharan Africa",
            "income": 5264.844952474,
            "population": 5187283.231694,
            "lifeExpectancy": 35.315903370027
        },
        {
            "name": "South Africa",
            "region": "Sub-Saharan Africa",
            "income": 6742.4521985949,
            "population": 20326484.5572,
            "lifeExpectancy": 51.268005785776
        },
        {
            "name": "Chad",
            "region": "Sub-Saharan Africa",
            "income": 1225.5580903182,
            "population": 3403841.6608183,
            "lifeExpectancy": 42.950108201525
        },
        {
            "name": "Mali",
            "region": "Sub-Saharan Africa",
            "income": 536.93461997971,
            "population": 5078398.1576436,
            "lifeExpectancy": 36.834771011008
        },
        {
            "name": "Peru",
            "region": "America",
            "income": 5662.5836397791,
            "population": 11727209.497689,
            "lifeExpectancy": 50.640674381035
        },
        {
            "name": "Niger",
            "region": "Sub-Saharan Africa",
            "income": 1080.214733441,
            "population": 4417866.5425856,
            "lifeExpectancy": 37.837385505504
        },
        {
            "name": "Croatia",
            "region": "Europe & Central Asia",
            "income": 6841.5240398993,
            "population": 4151095.9904572,
            "lifeExpectancy": 68.125337190517
        },
        {
            "name": "Sri Lanka",
            "region": "South Asia",
            "income": 1072.6382097907,
            "population": 11413457.249239,
            "lifeExpectancy": 60.022264718037
        },
        {
            "name": "Colombia",
            "region": "America",
            "income": 2643.9001217267,
            "population": 19084930.821355,
            "lifeExpectancy": 59.375903370027
        },
        {
            "name": "Finland",
            "region": "Europe & Central Asia",
            "income": 10709.063773528,
            "population": 4577266.0901304,
            "lifeExpectancy": 69.540674381035
        },
        {
            "name": "Denmark",
            "region": "Europe & Central Asia",
            "income": 14707.567465905,
            "population": 4789216.4819476,
            "lifeExpectancy": 72.463180674005
        },
        {
            "name": "Bolivia",
            "region": "America",
            "income": 2469.5920276515,
            "population": 3926098.3466957,
            "lifeExpectancy": 44.484825111771
        },
        {
            "name": "Iraq",
            "region": "Middle East & North Africa",
            "income": 8925.2144189804,
            "population": 8183747.7960326,
            "lifeExpectancy": 55.156011571552
        },
        {
            "name": "Cote d'Ivoire",
            "region": "Sub-Saharan Africa",
            "income": 2106.8384483601,
            "population": 4484529.0647331,
            "lifeExpectancy": 43.598059886539
        },
        {
            "name": "Zimbabwe",
            "region": "Sub-Saharan Africa",
            "income": 545.22268625315,
            "population": 4804066.6725401,
            "lifeExpectancy": 53.510620280272
        },
        {
            "name": "Georgia",
            "region": "Europe & Central Asia",
            "income": 2845.9727332156,
            "population": 4506929.9217418,
            "lifeExpectancy": 65.978005785776
        },
        {
            "name": "Senegal",
            "region": "Sub-Saharan Africa",
            "income": 1678.9386764098,
            "population": 3832862.9980839,
            "lifeExpectancy": 41.471644437765
        },
        {
            "name": "Somalia",
            "region": "Sub-Saharan Africa",
            "income": 1214.527167224,
            "population": 3339342.7036105,
            "lifeExpectancy": 38.303800954277
        },
        {
            "name": "Guinea",
            "region": "Sub-Saharan Africa",
            "income": 700.22514971635,
            "population": 3371966.94094,
            "lifeExpectancy": 37.941132359019
        },
        {
            "name": "Norway",
            "region": "Europe & Central Asia",
            "income": 14328.703948604,
            "population": 3747220.8880039,
            "lifeExpectancy": 73.951132359019
        },
        {
            "name": "Rwanda",
            "region": "Sub-Saharan Africa",
            "income": 486.60171732352,
            "population": 3338531.3777661,
            "lifeExpectancy": 43.787951685013
        },
        {
            "name": "Burundi",
            "region": "Sub-Saharan Africa",
            "income": 385.91631476124,
            "population": 3236168.9896683,
            "lifeExpectancy": 43.075337190517
        },
        {
            "name": "Azerbaijan",
            "region": "Europe & Central Asia",
            "income": 3932.8857002635,
            "population": 4673396.1634294,
            "lifeExpectancy": 63.411698538528
        },
        {
            "name": "Tunisia",
            "region": "Middle East & North Africa",
            "income": 1991.0204328061,
            "population": 4652833.6615321,
            "lifeExpectancy": 51.132776796784
        },
        {
            "name": "Bosnia and Herzegovina",
            "region": "Europe & Central Asia",
            "income": 2135.2862640418,
            "population": 3530908.4044032,
            "lifeExpectancy": 63.872264718037
        },
        {
            "name": "Canada",
            "region": "America",
            "income": 15740.460803246,
            "population": 20369152.511553,
            "lifeExpectancy": 71.892668595259
        },
        {
            "name": "Sierra Leone",
            "region": "Sub-Saharan Africa",
            "income": 1220.8154476462,
            "population": 2613314.2450314,
            "lifeExpectancy": 34.593234774768
        },
        {
            "name": "Lithuania",
            "region": "Europe & Central Asia",
            "income": 9293.4514066035,
            "population": 2988796.8385243,
            "lifeExpectancy": 71.816361348011
        },
        {
            "name": "Chile",
            "region": "America",
            "income": 4957.894217981,
            "population": 8648950.6418455,
            "lifeExpectancy": 59.670674381035
        },
        {
            "name": "Papua New Guinea",
            "region": "East Asia & Pacific",
            "income": 1473.4610767555,
            "population": 2033402.0859225,
            "lifeExpectancy": 41.311186459781
        },
        {
            "name": "Zambia",
            "region": "Sub-Saharan Africa",
            "income": 1726.1467520757,
            "population": 3772975.8425067,
            "lifeExpectancy": 47.192210617275
        },
        {
            "name": "Malawi",
            "region": "Sub-Saharan Africa",
            "income": 456.81949280535,
            "population": 4000103.6412819,
            "lifeExpectancy": 39.059542022016
        },
        {
            "name": "Haiti",
            "region": "America",
            "income": 1523.0748630574,
            "population": 4207934.3761506,
            "lifeExpectancy": 45.402776796784
        },
        {
            "name": "Moldova",
            "region": "Europe & Central Asia",
            "income": 3792.5498803805,
            "population": 3381417.0479017,
            "lifeExpectancy": 63.626415448773
        },
        {
            "name": "Benin",
            "region": "Sub-Saharan Africa",
            "income": 1049.0777213811,
            "population": 2355917.4029755,
            "lifeExpectancy": 43.373288875531
        },
        {
            "name": "Mongolia",
            "region": "East Asia & Pacific",
            "income": 1181.9706540933,
            "population": 1113260.946012,
            "lifeExpectancy": 50.26385505504
        },
        {
            "name": "Guatemala",
            "region": "America",
            "income": 3145.1929725364,
            "population": 4594005.058008,
            "lifeExpectancy": 49.023342976293
        },
        {
            "name": "Latvia",
            "region": "Europe & Central Asia",
            "income": 4104.2286309501,
            "population": 2273440.3623624,
            "lifeExpectancy": 71.168409662997
        },
        {
            "name": "Cuba",
            "region": "America",
            "income": 5159.7132340234,
            "population": 7948439.6277567,
            "lifeExpectancy": 67.519650223541
        },
        {
            "name": "Libya",
            "region": "Middle East & North Africa",
            "income": 16879.89310854,
            "population": 1679175.5023106,
            "lifeExpectancy": 49.373288875531
        },
        {
            "name": "Argentina",
            "region": "America",
            "income": 7969.9447574858,
            "population": 22542538.801668,
            "lifeExpectancy": 65.774771011008
        },
        {
            "name": "Ecuador",
            "region": "America",
            "income": 4484.4796855393,
            "population": 5240080.9897058,
            "lifeExpectancy": 56.145903370027
        },
        {
            "name": "Slovenia",
            "region": "Europe & Central Asia",
            "income": 9244.9840620656,
            "population": 1629510.936657,
            "lifeExpectancy": 69.22
        },
        {
            "name": "Tajikistan",
            "region": "Europe & Central Asia",
            "income": 3786.9886589316,
            "population": 2575485.8082429,
            "lifeExpectancy": 58.633800954277
        },
        {
            "name": "Togo",
            "region": "Sub-Saharan Africa",
            "income": 1418.8599530375,
            "population": 1681783.7363339,
            "lifeExpectancy": 47.19539129128
        },
        {
            "name": "Albania",
            "region": "Europe & Central Asia",
            "income": 2639.8615798174,
            "population": 1922595.7776985,
            "lifeExpectancy": 66.043234774768
        },
        {
            "name": "Armenia",
            "region": "Europe & Central Asia",
            "income": 2658.6863293319,
            "population": 2259427.5862419,
            "lifeExpectancy": 68.519596122779
        },
        {
            "name": "Jamaica",
            "region": "America",
            "income": 6005.7551831536,
            "population": 1811107.838374,
            "lifeExpectancy": 66.972210617275
        },
        {
            "name": "Bhutan",
            "region": "South Asia",
            "income": 803.82585407822,
            "population": 964161.9480783,
            "lifeExpectancy": 38.804313033024
        },
        {
            "name": "Macedonia, FYR",
            "region": "Europe & Central Asia",
            "income": 4180.3477533156,
            "population": 1485608.6726528,
            "lifeExpectancy": 64.162264718037
        },
        {
            "name": "Mauritania",
            "region": "Sub-Saharan Africa",
            "income": 1396.6432486757,
            "population": 1208764.8532141,
            "lifeExpectancy": 46.314825111771
        },
        {
            "name": "Turkmenistan",
            "region": "Europe & Central Asia",
            "income": 5406.1374388386,
            "population": 1924617.259308,
            "lifeExpectancy": 56.903800954277
        },
        {
            "name": "Estonia",
            "region": "Europe & Central Asia",
            "income": 6974.3060745911,
            "population": 1297576.5219597,
            "lifeExpectancy": 70.686307247248
        },
        {
            "name": "Australia",
            "region": "East Asia & Pacific",
            "income": 13835.514462186,
            "population": 11609734.102528,
            "lifeExpectancy": 70.853638651989
        },
        {
            "name": "Lebanon",
            "region": "Middle East & North Africa",
            "income": 6425.7148604276,
            "population": 2108200.4876207,
            "lifeExpectancy": 63.346415448773
        },
        {
            "name": "Oman",
            "region": "Middle East & North Africa",
            "income": 2898.9824443025,
            "population": 692923.98053876,
            "lifeExpectancy": 45.551806740053
        },
        {
            "name": "Congo, Rep.",
            "region": "Sub-Saharan Africa",
            "income": 2591.8674820603,
            "population": 1145443.8579479,
            "lifeExpectancy": 51.891752639291
        },
        {
            "name": "Liberia",
            "region": "Sub-Saharan Africa",
            "income": 717.91635458543,
            "population": 1236092.0161927,
            "lifeExpectancy": 42.703800954277
        },
        {
            "name": "Malaysia",
            "region": "East Asia & Pacific",
            "income": 2286.5397028215,
            "population": 9846790.7971222,
            "lifeExpectancy": 58.144421234549
        },
        {
            "name": "Lesotho",
            "region": "Sub-Saharan Africa",
            "income": 462.37268249615,
            "population": 969457.76034114,
            "lifeExpectancy": 48.350566179509
        },
        {
            "name": "El Salvador",
            "region": "America",
            "income": 4242.4913543976,
            "population": 3105005.0012398,
            "lifeExpectancy": 54.914879212533
        },
        {
            "name": "Namibia",
            "region": "Sub-Saharan Africa",
            "income": 4047.5729590112,
            "population": 684847.57842732,
            "lifeExpectancy": 50.272264718037
        },
        {
            "name": "Guinea-Bissau",
            "region": "Sub-Saharan Africa",
            "income": 716.91205319909,
            "population": 599302.8594883,
            "lifeExpectancy": 36.073234774768
        },
        {
            "name": "Jordan",
            "region": "Middle East & North Africa",
            "income": 2820.6945673817,
            "population": 1097729.4966375,
            "lifeExpectancy": 50.274421234549
        },
        {
            "name": "Israel",
            "region": "Middle East & North Africa",
            "income": 8374.6484051546,
            "population": 2627890.6055153,
            "lifeExpectancy": 70.317439606267
        },
        {
            "name": "Eritrea",
            "region": "Sub-Saharan Africa",
            "income": 457.45647706353,
            "population": 1767726.1485893,
            "lifeExpectancy": 41.521698538528
        },
        {
            "name": "Nicaragua",
            "region": "America",
            "income": 4480.9912694894,
            "population": 1795195.5749709,
            "lifeExpectancy": 50.799138144795
        },
        {
            "name": "Cyprus",
            "region": "Europe & Central Asia",
            "income": 5607.5526601796,
            "population": 594116.98538528,
            "lifeExpectancy": 69.907951685013
        },
        {
            "name": "Mauritius",
            "region": "Sub-Saharan Africa",
            "income": 2450.7664778149,
            "population": 770242.77897584,
            "lifeExpectancy": 61.161644437765
        },
        {
            "name": "Guyana",
            "region": "America",
            "income": 2264.1540271255,
            "population": 653119.56358718,
            "lifeExpectancy": 58.658517864523
        },
        {
            "name": "West Bank and Gaza",
            "region": "Middle East & North Africa",
            "income": 2532.1706349326,
            "population": 1230470.9818913,
            "lifeExpectancy": 50.2823188188
        },
        {
            "name": "Gabon",
            "region": "Sub-Saharan Africa",
            "income": 8032.5147683811,
            "population": 480019.346846,
            "lifeExpectancy": 43.023397077056
        },
        {
            "name": "Puerto Rico",
            "region": "America",
            "income": 6496.9722793704,
            "population": 2620935.8692565,
            "lifeExpectancy": 70.591132359019
        },
        {
            "name": "Paraguay",
            "region": "America",
            "income": 2226.8039005147,
            "population": 2216086.1289402,
            "lifeExpectancy": 64.804771011008
        },
        {
            "name": "Timor-Leste",
            "region": "East Asia & Pacific",
            "income": 1075.217099843,
            "population": 560222.94698877,
            "lifeExpectancy": 36.769650223541
        },
        {
            "name": "Malta",
            "region": "Europe & Central Asia",
            "income": 2651.0786110381,
            "population": 317835.62632904,
            "lifeExpectancy": 69.236873426757
        },
        {
            "name": "Fiji",
            "region": "East Asia & Pacific",
            "income": 1971.737224706,
            "population": 471780.68993501,
            "lifeExpectancy": 57.879596122779
        },
        {
            "name": "Honduras",
            "region": "America",
            "income": 2478.8551733854,
            "population": 2395881.7405418,
            "lifeExpectancy": 49.985957470789
        },
        {
            "name": "Luxembourg",
            "region": "Europe & Central Asia",
            "income": 19341.957403163,
            "population": 333391.47142803,
            "lifeExpectancy": 69.328921741744
        },
        {
            "name": "Montenegro",
            "region": "Europe & Central Asia",
            "income": 5807.0384160499,
            "population": 495385.19423677,
            "lifeExpectancy": 65.940728481797
        },
        {
            "name": "Botswana",
            "region": "Sub-Saharan Africa",
            "income": 1101.2151530977,
            "population": 544311.45001315,
            "lifeExpectancy": 52.662210617275
        },
        {
            "name": "New Zealand",
            "region": "East Asia & Pacific",
            "income": 15247.146889206,
            "population": 2677637.1097419,
            "lifeExpectancy": 71.169433820491
        },
        {
            "name": "Reunion",
            "region": "Sub-Saharan Africa",
            "income": 3818.8940658226,
            "population": 401113.48762069,
            "lifeExpectancy": 59.481752639291
        },
        {
            "name": "Swaziland",
            "region": "Sub-Saharan Africa",
            "income": 2440.8062114438,
            "population": 407741.8731262,
            "lifeExpectancy": 46.014313033024
        },
        {
            "name": "Martinique",
            "region": "America",
            "income": 8171.2325037611,
            "population": 314852.01784574,
            "lifeExpectancy": 65.916981628283
        },
        {
            "name": "Barbados",
            "region": "America",
            "income": 7004.6879366571,
            "population": 235964.82875606,
            "lifeExpectancy": 67.130620280272
        },
        {
            "name": "Kuwait",
            "region": "Middle East & North Africa",
            "income": 86425.483317805,
            "population": 513349.05312395,
            "lifeExpectancy": 63.175499492805
        },
        {
            "name": "Guadeloupe",
            "region": "America",
            "income": 3435.8658048224,
            "population": 305843.91114701,
            "lifeExpectancy": 65.499542022016
        },
        {
            "name": "Suriname",
            "region": "America",
            "income": 4588.8702830522,
            "population": 343897.01190968,
            "lifeExpectancy": 61.820108201525
        },
        {
            "name": "Equatorial Guinea",
            "region": "Sub-Saharan Africa",
            "income": 864.06902731337,
            "population": 255609.79062253,
            "lifeExpectancy": 38.47692752752
        },
        {
            "name": "Panama",
            "region": "America",
            "income": 4163.9998268024,
            "population": 1356928.7917121,
            "lifeExpectancy": 63.517493707029
        },
        {
            "name": "Bahrain",
            "region": "Middle East & North Africa",
            "income": 14186.392395837,
            "population": 195585.83288876,
            "lifeExpectancy": 58.889650223541
        },
        {
            "name": "French Polynesia",
            "region": "East Asia & Pacific",
            "income": 15322.906035241,
            "population": 97753.621144382,
            "lifeExpectancy": 58.471698538528
        },
        {
            "name": "Iceland",
            "region": "Europe & Central Asia",
            "income": 13510.709138896,
            "population": 194911.57748807,
            "lifeExpectancy": 73.358247360709
        },
        {
            "name": "Trinidad and Tobago",
            "region": "America",
            "income": 5517.3904282977,
            "population": 949742.99188489,
            "lifeExpectancy": 65.413180674005
        },
        {
            "name": "Solomon Islands",
            "region": "East Asia & Pacific",
            "income": 1287.2525317362,
            "population": 146159.03370027,
            "lifeExpectancy": 52.094879212533
        },
        {
            "name": "Comoros",
            "region": "Sub-Saharan Africa",
            "income": 1819.2889487921,
            "population": 210458.40158545,
            "lifeExpectancy": 45.771698538528
        },
        {
            "name": "Cape Verde",
            "region": "Sub-Saharan Africa",
            "income": 751.45367885186,
            "population": 237650.87553068,
            "lifeExpectancy": 54.609084044032
        },
        {
            "name": "Uruguay",
            "region": "America",
            "income": 5709.3976000301,
            "population": 2715304.6780253,
            "lifeExpectancy": 68.623692752752
        },
        {
            "name": "Costa Rica",
            "region": "America",
            "income": 4015.231972048,
            "population": 1527753.9490551,
            "lifeExpectancy": 64.762776796784
        },
        {
            "name": "Samoa",
            "region": "East Asia & Pacific",
            "income": 3439.5198882919,
            "population": 129590.56377503,
            "lifeExpectancy": 52.734879212533
        },
        {
            "name": "Netherlands Antilles",
            "region": "America",
            "income": 9465.5155117657,
            "population": 148259.40613142,
            "lifeExpectancy": 67.736415448773
        },
        {
            "name": "Maldives",
            "region": "South Asia",
            "income": 648.64082360897,
            "population": 100270.95469061,
            "lifeExpectancy": 47.227547807792
        },
        {
            "name": "United Arab Emirates",
            "region": "Middle East & North Africa",
            "income": 10667.755800178,
            "population": 153887.95363865,
            "lifeExpectancy": 57.5723188188
        },
        {
            "name": "Macao, China",
            "region": "East Asia & Pacific",
            "income": 7130.7499839201,
            "population": 230205.19686667,
            "lifeExpectancy": 63.778626066048
        },
        {
            "name": "Singapore",
            "region": "East Asia & Pacific",
            "income": 4454.4353368149,
            "population": 1924413.5251907,
            "lifeExpectancy": 67.281698538528
        },
        {
            "name": "New Caledonia",
            "region": "East Asia & Pacific",
            "income": 20001.567379118,
            "population": 92809.830634557,
            "lifeExpectancy": 59.758517864523
        },
        {
            "name": "Grenada",
            "region": "America",
            "income": 1919.8865990908,
            "population": 93518.240184844,
            "lifeExpectancy": 63.928975842507
        },
        {
            "name": "Vanuatu",
            "region": "East Asia & Pacific",
            "income": 2142.6410955091,
            "population": 75724.762069354,
            "lifeExpectancy": 49.97385505504
        },
        {
            "name": "Belize",
            "region": "America",
            "income": 2040.968908968,
            "population": 109452.73903896,
            "lifeExpectancy": 64.334879212533
        },
        {
            "name": "Djibouti",
            "region": "Middle East & North Africa",
            "income": 3023.373408348,
            "population": 117194.39628809,
            "lifeExpectancy": 41.269084044032
        },
        {
            "name": "Sao Tome and Principe",
            "region": "Sub-Saharan Africa",
            "income": 1312.4871901416,
            "population": 69539.990306947,
            "lifeExpectancy": 53.616981628283
        },
        {
            "name": "Hong Kong, China",
            "region": "East Asia & Pacific",
            "income": 6239.6398361949,
            "population": 3623172.2696021,
            "lifeExpectancy": 69.263288875531
        },
        {
            "name": "Aruba",
            "region": "America",
            "income": 3990.32966131,
            "population": 58713.573731074,
            "lifeExpectancy": 67.720620280272
        },
        {
            "name": "Tonga",
            "region": "East Asia & Pacific",
            "income": 1757.75211209,
            "population": 75974.967276553,
            "lifeExpectancy": 63.506415448773
        },
        {
            "name": "Micronesia, Fed. Sts.",
            "region": "East Asia & Pacific",
            "income": 3230.1971115083,
            "population": 49924.012322952,
            "lifeExpectancy": 59.895903370027
        },
        {
            "name": "Qatar",
            "region": "Middle East & North Africa",
            "income": 69506.352202825,
            "population": 75839.950520345,
            "lifeExpectancy": 57.438113987301
        },
        {
            "name": "French Guiana",
            "region": "America",
            "income": 5770.4922428785,
            "population": 41722.713754368,
            "lifeExpectancy": 62.622884998309
        },
        {
            "name": "Mayotte",
            "region": "Sub-Saharan Africa",
            "income": 9617.82,
            "population": 32198.828380359,
            "lifeExpectancy": 59.11385505504
        },
        {
            "name": "Brunei",
            "region": "East Asia & Pacific",
            "income": 45820.663555998,
            "population": 105742.83176166,
            "lifeExpectancy": 64.173288875531
        },
        {
            "name": "Tokelau",
            "region": "East Asia & Pacific",
            "income": 889.43,
            "population": 1810.0371416764,
            "lifeExpectancy": 69
        }
    ],
    dataSet: "",
    dataSetX: "income",
    dataSetY: "lifeExpectancy",
    dataSetRadius: "population",
    fill: "#000",
    seriesName: "",
};

const mapStateToProps = function(state){
    return {
        store: state.reducer,
    }
}
export default connect (mapStateToProps, null) (BubbleGraph);

