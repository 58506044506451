import { useState } from "react";
import { connect } from "react-redux";
import { DivBlock } from "../../../Basic";
import Label from "../Label";
import { If, Then } from "../../../Logic";
import { Heading, Span } from "../../../Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import { qwikcutApi } from "../../../../utils/function";
import { apiUrl, qwikCutApiEndpoints } from "../../../../utils/constant";
import QwikcutPlayGames from "./QwikcutPlayGames";
import GetQwikcutGameStats from "./GetQwikcutGameStats";
import { GetPackagesEndpoint } from "../../../../api/api";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      width: "100%",
    },
  },
}));

const QwikcutVideo = ({ dispatch, teamData, ...props }) => {
  const classes = useStyles();
  const [qwikcutPlay, setQwikCutPlay] = useState(false);
  const [qwikcutPlayGameData, setQwikcutPlayGameData] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [qwikcutGameData, setQwikcutGameData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [toggleQwikcutGameStats, setToggleQwikcutGameStats] = useState(false);
  const [qwikcutGameStatsColums, setQwikcutGameStatsColums] = useState([]);
  const [qwikcutGameStatsRows, setQwikcutGameStatsRows] = useState([]);
  const [gameId, setGameId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");

  const { state } = props;

  const handleQwikcutVideo = async () => {
    const { getValidateQwikcutGame } = props;
    const valueCallback = await new Promise((resolve) => {
      getValidateQwikcutGame((callback) => resolve(callback));
    });
    if (valueCallback) {
      const apiUrl = `/quickcut`;
      const qwikcutToken = localStorage.getItem("qwikcut_token");
      const qwikcutUserId = localStorage.getItem("qwikcut_userid");
      const payload = {
        query_variables: {
          schoolid: state?.CurrentUser?.school_id,
          year: state?.selectedSeasonYear,
        },
      };

      if (qwikcutToken && qwikcutUserId) {
        setIsLoading(true);
        setQwikcutPlayGameData(true);
        GetPackagesEndpoint(payload).then((res) => {
          setPackageData(res?.data);
        });
        const userTeamOption = {
          api_url: qwikCutApiEndpoints.getUserTeams.replace(
            "{id}",
            qwikcutUserId
          ),
          method_type: "GET",
          authorization: qwikcutToken,
        };
        qwikcutApi(apiUrl, userTeamOption).then((userTeamData) => {
          const userTeamScheduleOption = {
            api_url: qwikCutApiEndpoints.getUserTeamsSchedule
              .replace("{user_id}", qwikcutUserId)
              .replace("{team_id}", userTeamData?.value?.[0]?.team_id),
            method_type: "GET",
            authorization: qwikcutToken,
          };
          setTeamId(userTeamData?.value?.[0]?.team_id);
          qwikcutApi(apiUrl, userTeamScheduleOption).then(
            (userTeamSchedule) => {
              setQwikcutGameData(userTeamSchedule?.value);
              setIsLoading(false);
            }
          );
        });
      } else {
        setQwikCutPlay(!qwikcutPlay);
      }
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });

    setErrors({
      ...errors,
      [id]: "",
    });
  };

  const validateEmail = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: isValid ? "" : "Invalid email address",
    }));

    return isValid;
  };

  const validatePassword = () => {
    const isValid = formData.password.trim() !== "";
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: isValid ? "" : "Password cannot be empty",
    }));

    return isValid;
  };

  const handleButtonClick = async () => {
    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (isEmailValid && isPasswordValid) {
      setIsLoading(true);
      const raw = {
        api_url: qwikCutApiEndpoints.authenticateUrl,
        method_type: "POST",
        body: formData,
      };
      const payload = {
        query_variables: {
          schoolid: state?.CurrentUser?.school_id,
          year: state?.selectedSeasonYear,
        },
      };
      qwikcutApi(apiUrl, raw).then((res) => {
        if (res.success) {
          setErrors({ email: "", password: "" });
          localStorage.setItem("qwikcut_token", res?.token);
          localStorage.setItem("qwikcut_userid", res?.userid);
          const userTeamOption = {
            api_url: qwikCutApiEndpoints.getUserTeams.replace(
              "{id}",
              res?.userid
            ),
            method_type: "GET",
            authorization: res?.token,
          };
          qwikcutApi(apiUrl, userTeamOption).then((userTeamData) => {
            const userTeamScheduleOption = {
              api_url: qwikCutApiEndpoints.getUserTeamsSchedule
                .replace("{user_id}", res?.userid)
                .replace("{team_id}", userTeamData?.value?.[0]?.team_id),
              method_type: "GET",
              authorization: res?.token,
            };
            setTeamId(userTeamData?.value?.[0]?.team_id);
            qwikcutApi(apiUrl, userTeamScheduleOption).then(
              (userTeamSchedule) => {
                setQwikcutGameData(userTeamSchedule?.value);
                setQwikCutPlay(false);
                setQwikcutPlayGameData(true);
                setIsLoading(false);
              }
            );
          });
          GetPackagesEndpoint(payload).then((res) => {
            setPackageData(res?.data);
          });
        } else {
          setErrors({ ...errors, error: res?.message });
          setIsLoading(false);
        }
      });
    }
  };

  const handleQwikcutPlayGamesVideo = () => {
    setQwikcutPlayGameData(!qwikcutPlayGameData);
    setQwikcutGameData([]);
    setIsLoading(false);
    setSelectedValue("");
    setSelectedPackage("");
    setTeamId("");
  };

  const handleSelectedGameAndPractice = (value) => {
    setSelectedValue(value);
  };

  const handleClick = async () => {
    const qwikcutToken = localStorage.getItem("qwikcut_token");
    if (selectedValue !== "") {
      setIsLoading(true);
      setQwikcutPlayGameData(false);
      setToggleQwikcutGameStats(true);
      const statsColumnsOption = {
        api_url: qwikCutApiEndpoints.getStatsColumns.replace(
          "{team_id}",
          selectedValue?.teamId
        ),
        method_type: "GET",
        authorization: qwikcutToken,
      };
      const gameStatsOption = {
        api_url: qwikCutApiEndpoints.getGameStats
          .replace("{game_id}", selectedValue?.gameId)
          .replace("{team_id}", selectedValue?.teamId),
        method_type: "GET",
        authorization: qwikcutToken,
      };
      const colsData = await qwikcutApi(apiUrl, statsColumnsOption);
      setQwikcutGameStatsColums(colsData.value?.columns);

      const rowsData = await qwikcutApi(apiUrl, gameStatsOption);
      setQwikcutGameStatsRows(rowsData.value?.stats);
      setGameId(rowsData.value?.gameid);
      if (
        colsData.value?.columns?.length > 0 &&
        rowsData.value?.stats?.length > 0
      ) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleQwicutStatsCancel = () => {
    setToggleQwikcutGameStats(!toggleQwikcutGameStats);
    setQwikcutGameStatsRows([]);
    setQwikcutGameStatsColums([]);
    setGameId("");
    setTeamId("");
    setSelectedPackage("");
  };

  const handlePackageChange = (e) => {
    setSelectedPackage(e.target.value);
  };

  const handleLogoutAccount = () => {
    localStorage.removeItem("qwikcut_token");
    localStorage.removeItem("qwikcut_userid");
    setQwikCutPlay(true);
    setQwikcutPlayGameData(false);
  };

  return (
    <div>
      <button className={"btn btn-primary"} onClick={handleQwikcutVideo}>
        Import From Qwikcut
      </button>
      <If condition={qwikcutPlay ? true : false} show={true}>
        <Then>
          <DivBlock className={"modal play-modal"}>
            <DivBlock className={"modal-dialog"}>
              <DivBlock className={"modal-dialog"}>
                <DivBlock className={"modal-content"}>
                  <DivBlock className={"modal-header"}>
                    <Heading className="modal-heading" type={"h5"}>
                      Login From Qwikcut Account
                    </Heading>

                    <span onClick={handleQwikcutVideo} className={"close"}>
                      x
                    </span>
                  </DivBlock>

                  <DivBlock className={"modal-body"}>
                    <DivBlock className={"form-group"}>
                      <form className={classes.root}>
                        <Label>Email</Label>

                        <TextField
                          id="email"
                          variant="outlined"
                          value={formData.email}
                          onChange={handleInputChange}
                          error={Boolean(errors.email)}
                          helperText={errors.email}
                          inputProps={{ style: { height: "6px" } }}
                        />
                        <Label>Password</Label>
                        <TextField
                          id="password"
                          type="password"
                          variant="outlined"
                          value={formData.password}
                          onChange={handleInputChange}
                          error={Boolean(errors.password)}
                          helperText={errors.password}
                          inputProps={{ style: { height: "6px" } }}
                        />
                      </form>
                    </DivBlock>
                    {errors.error ? (
                      <Span className={"invalid-feedback"}>
                        {errors?.error}
                      </Span>
                    ) : null}

                    <DivBlock
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className={"btn btn-primary"}
                        onClick={handleButtonClick}
                        disabled={isLoading ? true : false}
                      >
                        {isLoading ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress
                              size={25}
                              thickness={3}
                              style={{
                                display: ["flex", "-webkit-inline-box"],
                                color: "white",
                                margin: "0 5px",
                              }}
                            />
                            Loading ...
                          </div>
                        ) : (
                          <div>Submit</div>
                        )}
                      </button>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Then>
      </If>
      <If condition={qwikcutPlayGameData ? true : false} show={true}>
        <Then>
          <DivBlock className={"modal play-modal"}>
            <DivBlock className={"modal-dialog"}>
              <DivBlock className={"modal-dialog"}>
                <DivBlock className={"modal-content"}>
                  <DivBlock className={"modal-header"}>
                    <Heading className="modal-heading" type={"h5"}>
                      Qwikcut Games & practices
                    </Heading>

                    <button
                      onClick={handleLogoutAccount}
                      className={"btn btn-primary"}
                    >
                      Logout from qwikcut account
                    </button>
                    <span
                      onClick={handleQwikcutPlayGamesVideo}
                      className={"close"}
                    >
                      x
                    </span>
                  </DivBlock>
                  {isLoading ? (
                    <CircularProgress
                      size={35}
                      thickness={4}
                      style={{
                        display: ["flex", "-webkit-inline-box"],
                        color: "#48c46e",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "10px auto",
                      }}
                    />
                  ) : (
                    <DivBlock className={"modal-body"}>
                      <DivBlock className={"form-group"}>
                        {qwikcutGameData?.length > 0
                          ? qwikcutGameData?.map((item, index) => (
                              <QwikcutPlayGames
                                data={item}
                                latestUpdatedValue={(value) => {
                                  handleSelectedGameAndPractice(value);
                                }}
                              />
                            ))
                          : null}
                        <Label className={"label"}>Package</Label>
                        <select
                          onChange={handlePackageChange}
                          className={"form-control"}
                          name={"Package"}
                          value={selectedPackage || ""}
                        >
                          <option value="" disabled>
                            Select an option
                          </option>
                          {packageData?.map((result, index) => (
                            <option key={index} value={result?.Id}>
                              {result?.title}
                            </option>
                          ))}
                        </select>
                        <button
                          className={"btn btn-primary"}
                          onClick={handleClick}
                          disabled={selectedValue === "" ? true : false}
                          style={
                            selectedValue === ""
                              ? {
                                  backgroundColor: "#a0d2a0",
                                  borderColor: "#a0d2a0",
                                }
                              : {
                                  backgroundColor: "#48c46e",
                                  borderColor: "#48c46e",
                                }
                          }
                        >
                          Apply
                        </button>
                      </DivBlock>
                    </DivBlock>
                  )}
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Then>
      </If>
      {toggleQwikcutGameStats ? (
        <GetQwikcutGameStats
          toggleGameStats={toggleQwikcutGameStats}
          loading={isLoading}
          columns={qwikcutGameStatsColums}
          rows={qwikcutGameStatsRows}
          handleCancel={handleQwicutStatsCancel}
          gameId={gameId}
          teamData={teamData}
          selectedPackageId={selectedPackage}
          teamId={teamId}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    state: state.reducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getValidateQwikcutGame: (callback) => {
      dispatch({
        type: "VALIDATE_QWIKCUT_GAMES",
        inputVariables: { callback },
      });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QwikcutVideo);
