import { useRef, useState } from "react";
import { IMAGES } from "../../../../assets";

const VideoBox = ({ videoUrls, stopPropagation, ...props }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const playRef = useRef(null);

  const handlePlay = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    playRef.current.play();
    setIsPlaying(true);
  };

  const playVideo = (index) => {
    if (index >= 0 && index < videoUrls.length) {
      setCurrentVideoIndex(index);
    }
  };

  const playNextVideo = () => {
    const nextIndex = (currentVideoIndex + 1) % videoUrls.length;
    playVideo(nextIndex);
  };

  const playPreviousVideo = () => {
    const previousIndex =
      (currentVideoIndex - 1 + videoUrls.length) % videoUrls.length;
    playVideo(previousIndex);
  };

  const onVideoEnded = () => {
    playNextVideo();
    setIsPlaying(false);
  };

  const handleLoadStart = () => {
    console.log('Video loading started');
    setIsLoading(true);
  };

  const PlayVideo = () =>{
    setIsPlaying(true);
  }

  const PauseVideo = () =>{
    setIsPlaying(false);
  }

  const handleLoadedData = () => {
    setIsLoading(false);
  };

  return (
    <div className="mainVideo">
      <video
        className="videoBox"
        preload="auto|metadata|none"
        ref={playRef}
        controls={true}
        src={videoUrls[currentVideoIndex]}
        onEnded={onVideoEnded}
        controlsList={"noplaybackrate nodownload"}
        disablePictureInPicture={true}
        onPlay={PlayVideo}
        onPause={PauseVideo}
        onLoadedData={handleLoadedData}
        onLoadStart={handleLoadStart}
        {...props}
      ></video>
      {!isPlaying && (
        <div className="playButtonView" onClick={handlePlay}>
          <img src={IMAGES.PlayIcon} alt="play" className="playIcon" />
        </div>
      )}
      {isLoading === false && (
      <div className="controls">
        {currentVideoIndex === 0 ? null : (
          <button onClick={playPreviousVideo}>
            <img
              src={IMAGES.PreviousIcon}
              alt="Previous Video"
              width="30"
              height="30"
            />
          </button>
        )}
        {currentVideoIndex === videoUrls?.length - 1 ? null : (
          <button
            onClick={playNextVideo}
            style={{ transform: "rotate(180deg)" }}
          >
            <img
              src={IMAGES.PreviousIcon}
              alt="Next Video"
              width="30"
              height="30"
            />
          </button>
        )}
      </div>)}
    </div>
  );
};

export default VideoBox;
