import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";

class CustomTabbing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:'',
    };
  }

  componentDidUpdate(){
    if(!this.state.value&& (this.props.UserGrades&&this.props.UserGrades[0]?.position)){
      this.setState({ value:this.props.UserGrades[0].position});
      this.props.getTabId(this.props.UserGrades[0].position);
    }
    if(this.props.toggleValue){
      this.setState({value:this.props.UserGrades[0].position})
      this.props.getTabId(this.props.UserGrades[0].position);
    }
  }

  render() {
    let { UserGrades } = this.props;
    let positionValue=[];
    UserGrades &&isArray(UserGrades)&&UserGrades.map((item)=>
      positionValue.push(item.position)
    )
    
   const number=[ ...new Set(positionValue&&positionValue)]
  
    return (
      <Fragment>
        {number && number?.length > 0 ? 
        <div className="tabbing-container">
          {number &&
            isArray(number) &&
            number.map((item, index) => (
              <span
                key={index}
                onClick={(e) => {
                    this.setState({ value: item });
                    this.props.getTabId(item);
                    // this.props.getUserGradesApi();
                    
            }}
                className="tabbing-label"
                style={
                  this.state.value === item
                    ? { backgroundColor: "#48c46e", color: "white" }
                    :{ backgroundColor: "white", color: "black" }
                }
              >
                {item}
              </span>
            ))}
        </div> : null}
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserGradesApi: () => {
      dispatch({ type: "GET_USER_GRADES" });
      return Promise.resolve();
    },
    
    dispatch: dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomTabbing);
