import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock } from "../modules/Basic";
import { TextInput } from "../modules/Forms";
import { If, Then } from "../modules/Logic";
import { Image } from "../modules/Media";
import { Span, TextLink } from "../modules/Typography";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
  }

  render() {
    let { FormLoginErrors, Unauthorized } = this.props;

    const handleCheckbox = () => {
      this.setState({ isChecked:!this.state.isChecked});
      if (!this.state.isChecked) {
        if (
          this.props?.FormLogin?.email !== "" ||
          this.props?.FormLogin?.password !== ""
        ) {
          localStorage.setItem("username", this.props?.FormLogin?.email);
        }
      } else {
        localStorage.clear();
      }
    };

    return (
      <Fragment>
        <Body className={"body"} style={{}}>
          <DivBlock>
            <DivBlock className="coachtools-login">
              <DivBlock className="left">
                <DivBlock className="logo-blk">
                  <TextLink
                    href={"http://coachtoolsllc.com/"}
                    style={{
                      color: "#ffffff",
                      padding: "20px",
                      textDecoration: "none",
                    }}
                    type={"external"}
                  >
                    <Image
                      alt={"CoachTools Logo"}
                      src={process.env.REACT_APP_LOGO}
                    ></Image>
                  </TextLink>
                </DivBlock>
                <DivBlock className="login-frm-blk">
                  <form className="login-form">
                    <p className="login-txt">Login to your account</p>
                    <DivBlock className="validation-text">
                      <TextInput
                        className={"form-control"}
                        name={"FormLogin.email"}
                        placeholder={"Enter Email"}
                        style={{}}
                      ></TextInput>

                      <If
                        condition={
                          FormLoginErrors && FormLoginErrors["email"]
                            ? true
                            : false
                        }
                        show={true}
                      >
                        <Then>
                          <Span className={"invalid-feedback"} style={{}}>
                            {FormLoginErrors ? FormLoginErrors["email"] : ""}
                          </Span>
                        </Then>
                      </If>
                    </DivBlock>
                    <DivBlock className="validation-text">
                      <TextInput
                        className={"form-control"}
                        name={"FormLogin.password"}
                        placeholder={"Enter Password"}
                        type={"password"}
                        actions={[
                          {
                            action: "Set Login Message",
                            trigger: "onfocus",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: {},
                          },
                        ]}
                      ></TextInput>

                      <If
                        condition={
                          FormLoginErrors && FormLoginErrors["password"]
                            ? true
                            : false
                        }
                        show={true}
                      >
                        <Then>
                          <Span className={"invalid-feedback"}>
                            {FormLoginErrors ? FormLoginErrors["password"] : ""}
                          </Span>
                        </Then>
                      </If>
                    </DivBlock>
                    <DivBlock className="remember-me">
                      <input
                        type="checkbox"
                        name="remember-me"
                        className="remember-me-checkbox"
                        onChange={(e) => handleCheckbox(e)}
                        checked={this.state.isChecked}
                      />
                      <label
                        className="text-checkbox"
                        onClick={() => {
                          handleCheckbox()
                        }}
                      >
                        Remember me
                      </label>
                    </DivBlock>
                    <DivBlock className={"text-center login-component"}>
                      {Unauthorized ? (
                        <DivBlock
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <DivBlock>
                            <Span
                              className="invalid-feedback invalid-login-message"
                              style={{}}
                            >
                              {Unauthorized}
                            </Span>
                          </DivBlock>
                        </DivBlock>
                      ) : null}
                    </DivBlock>

                    <Button
                      className={"login-btn"}
                      actions={[
                        {
                          action: "Login",
                          trigger: "onclick",
                          timeout: "",
                          pollingPeriod: "",
                          inputVariables: {},
                        },
                      ]}
                    >
                      LOGIN
                    </Button>
                    <DivBlock className="forgot-password">
                      <i className="fa fa-lock fa-sm icon-lock"></i>
                      <TextLink
                        // className={"a"}
                        href={"/request_password_reset"}
                        type={"spa"}
                        actions={[
                          {
                            action: "Set Login Message",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: {},
                          },
                        ]}
                      >
                        Forgot password?
                      </TextLink>
                    </DivBlock>
                    {/* <p className="message">
                      Don't have an account?
                      <TextLink
                        href={"/signup"}
                        type={"spa"}
                        actions={[
                          {
                            action: "Set Login Message",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: {},
                          },
                        ]}
                      >
                        Register
                      </TextLink>
                    </p> */}
                  </form>
                </DivBlock>
              </DivBlock>

              <DivBlock className="right">
                <DivBlock className="right-img"></DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(LoginPage);
