import React from "react";
import { DivBlock } from "../../../Basic";
import { Span } from "../../../Typography";
import { If, Then } from "../../../Logic";
import { CircularProgress } from "@material-ui/core";
import { isArray } from "lodash";
import { connect } from "react-redux";

const GetQwikcutGameStats = ({
  toggleGameStats,
  loading,
  handleCancel,
  columns,
  rows,
  gameId,
  dispatch,
  teamData,
  teamId,
  selectedPackageId,
  ...props
}) => {
  const handleUploadQwikcutVideo = () => {
    const qwikcutToken = localStorage.getItem("qwikcut_token"); 
    console.log('gameId :>> ', gameId);
    dispatch({
        type: "CREATE_GAMES",
        inputVariables: {
          teamData:teamData,
          teamId:teamId,
          gameId:gameId,
          depthChartId:selectedPackageId,
          qwikcutToken:qwikcutToken,
          type:'qwikcut'
        },
      });
    handleCancel();
  };
  return (
    <If condition={toggleGameStats ? true : false} show={true}>
      <Then>
        <DivBlock className={"modal play-modal larger"}>
          <DivBlock className={"modal-dialog larger"}>
            <DivBlock className={"modal-dialog larger"}>
              <DivBlock className={"modal-content"}>
                <DivBlock className={"modal-body"}>
                  {loading ? (
                    <CircularProgress
                      size={35}
                      thickness={4}
                      style={{
                        color: "#48c46e",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "10px auto",
                        display: "flex",
                      }}
                    />
                  ) : (
                    <>
                      <DivBlock
                        className={"form-group"}
                        style={{
                          maxHeight: "750px",
                          overflow: "auto",
                          position: "relative",
                          marginBottom: "45px",
                        }}
                      >
                        {rows && isArray(rows) && rows ? (
                          <table className={"table"}>
                            <thead>
                              <tr className={"thead-bg"}>
                                {columns?.map((item) => (
                                  <th className={"th-border"}>
                                    <Span className={"th-span"}>
                                      {item?.display}
                                    </Span>
                                  </th>
                                ))}
                              </tr>
                            </thead>

                            <tbody>
                              {rows &&
                                isArray(rows) &&
                                rows.map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {columns?.map((column, columnIndex) => (
                                      <td
                                        key={columnIndex}
                                        className="common-table-data"
                                      >
                                        <Span className={"td-span"}>
                                          {row[column.name] || ""}
                                        </Span>
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <Span
                            className={"table-span"}
                            style={{ fontSize: "20px", margin: "20px 0" }}
                          >
                            No data found
                          </Span>
                        )}
                      </DivBlock>
                      <div className="qwikcut-game-sticky-bottom">
                        <button
                          className={"btn btn-primary"}
                          style={{ margin: "0px 10px" }}
                          onClick={handleUploadQwikcutVideo}
                        >
                          Upload
                        </button>
                        <button
                          className={"btn btn-reset DeleteBtn"}
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </DivBlock>
      </Then>
    </If>
  );
};

const mapStateToProps = function (state) {
    return {
      state: state.reducer,
    };
  };

export default connect(mapStateToProps, null)(GetQwikcutGameStats);
