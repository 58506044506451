import { Avatar, ButtonBase, makeStyles } from "@material-ui/core";
import { IMAGES } from "../../../../assets";

const useStyles = makeStyles((theme) => ({
  green: {
    backgroundColor: "#48c46e5c",
    borderRadius: "10px",
  },
}));

const IconFilter = ({onClick,...props}) => {
  const classes = useStyles();
  return (
    <ButtonBase
      sx={{ overflow: "hidden" }}
      onClick={onClick}
    >
      <Avatar className={classes.green}>
        <img
          src={IMAGES.FilterIcon}
          alt="icon"
          height={"20px"}
          width={"20px"}
        />
      </Avatar>
    </ButtonBase>
  );
};

export default IconFilter;
