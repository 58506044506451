import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock } from "../modules/Basic";
import {  TextInput } from "../modules/Forms";
import { If, Then } from "../modules/Logic";
import { Image } from "../modules/Media";
import {  Span, TextLink } from "../modules/Typography";

class RequestPasswordResetPage extends Component {
  render() {
    let { FormRequestPasswordResetErrors, ResetPasswordRequestResponseText } =
      this.props;

    return (
      <Fragment>
        <Body className={"body"}>
          <DivBlock>

            <DivBlock className="coachtools-login">
              <DivBlock className="left">
                <DivBlock className="logo-blk">
                  {/* <img src="logo.png" alt="logo" /> */}
                  <TextLink
                  href={"http://coachtoolsllc.com/"}
                  style={{
                    color: "#ffffff",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                  type={"external"}
                >
                  <Image
                    alt={"CoachTools Logo"}
                    src={process.env.REACT_APP_LOGO}
                    // style={{ height: "37.5px", maxWidth: "auto" }}
                  ></Image>
                  </TextLink>
                </DivBlock>
                <DivBlock className="login-frm-blk">
                  <form className="login-form">
                    <p className="login-txt">Request Password Reset</p>

                    <DivBlock className={"invalid-feedback"} style={{}}>
                      <Span>{ResetPasswordRequestResponseText}</Span>
                    </DivBlock>

                    <DivBlock className="validation-text">
                    <TextInput
                      className={"form-control"}
                      name={"FormRequestPasswordReset.email"}
                      placeholder={"Enter Email"}
                    ></TextInput>

                    <If
                      condition={
                        FormRequestPasswordResetErrors &&
                        FormRequestPasswordResetErrors["email"]
                          ? true
                          : false
                      }
                      show={true}
                    >
                      <Then>
                        <Span className={"invalid-feedback"} style={{}}>
                          {FormRequestPasswordResetErrors
                            ? FormRequestPasswordResetErrors["email"]
                            : ""}
                        </Span>
                      </Then>
                    </If>
                    </DivBlock>
                    {/* <button type="submit" className="login-btn">LOGIN</button> */}
                    <Button
                      className={"login-btn"}
                      actions={[
                        {
                          action: "Request Password Reset",
                          trigger: "onclick",
                          timeout: "",
                          pollingPeriod: "",
                          inputVariables: {},
                        },
                      ]}
                    >
                      Request Password Reset
                    </Button>
                    <p className="message">
                      Remembered password?
                      <TextLink
                        className={"a"}
                        href={"/login"}
                        style={{}}
                        type={"spa"}
                      >
                        Login
                      </TextLink>
                    </p>
                  </form>
                </DivBlock>
              </DivBlock>

              <DivBlock className="right">
                <DivBlock className="right-img"></DivBlock>
              </DivBlock>
            </DivBlock>

          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(RequestPasswordResetPage);
