import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock, LinkBlock } from "../modules/Basic";
import { FormBlock, Label, SelectDropDown, TextInput } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { Else, If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { startPageTimer, trackPageDuration } from "../utils/function";

class InjuredPlayersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
    };
  }
  
  componentDidMount(){
  startPageTimer('Injured Players');
  this.setState({ startTime: Date.now() });
  }

  componentWillUnmount(){
  trackPageDuration('Injured Players',this.state.startTime)
  }
  render() {
    let {
      CurrentDepthChart,
      DepthChartListsCategoryFilter,
      EditUser,
      FormInjuredPlayerErrors,
      InjuredPlayerToDelete,
      InjuredPlayers,
      NavBarDropDownIndex,
      NewInjuredPlayer,
      NullPositionError,
      Users,
    } = this.props;
    const { FilterList } = window;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Injured Players",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Users",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: { "intangible id": 0 },
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 0 : 0,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock>
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <DivBlock className={"Link_div"} style={{}}>
                        <LinkBlock
                          className={"Custom_link"}
                          duration={1000}
                          href={"/depth_chart_lists"}
                          style={{}}
                          target={"_self"}
                          type={"spa"}
                        ></LinkBlock>
                      </DivBlock>

                      <Heading className={"h2"} style={{}} type={"h2"}>
                        Injured Players
                      </Heading>

                      <DivBlock
                        style={{
                          alignItems: "center",
                          display: "inline-block",
                          justifyContent: "flex-start",
                        }}
                      >
                        <LinkBlock
                          duration={1000}
                          href={"/depth_chart_lists"}
                          target={"_self"}
                          type={"external"}
                        >
                          <DivBlock>
                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Reset Depth Chart",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "input tagfilter": null,
                                    "input categoryfilter":
                                      DepthChartListsCategoryFilter,
                                    "input depthchart list week filter": null,
                                  },
                                },
                              ]}
                            >
                              Depth Charts
                            </Button>
                          </DivBlock>
                        </LinkBlock>
                      </DivBlock>

                      <Button
                        className={"btn injury-button"}
                        actions={[
                          {
                            action: "New Injured Player",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { "input new injured": true },
                          },
                        ]}
                      >
                        Add Injured Player
                      </Button>

                      <DivBlock style={{ textAlign: "center" }}>
                        <Span
                          className={"invalid-feedback invalid-login-message"}
                          style={{}}
                        >
                          {NullPositionError}
                        </Span>
                      </DivBlock>

                      <If condition={EditUser ? true : false} show={false}>
                        <Then>
                          <Columns
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Column
                              lg={6}
                              md={6}
                              sm={6}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              xs={6}
                            >
                              <DivBlock className={"card"}>
                                <DivBlock className={"card-body"}>
                                  <DivBlock className={"text-center"}>
                                    <Heading
                                      className={"h1"}
                                      style={{}}
                                      type={"h1"}
                                    >
                                      Update Player
                                    </Heading>
                                  </DivBlock>

                                  <FormBlock
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <DivBlock className={"form-group"}>
                                      <Label>Email</Label>

                                      <TextInput
                                        className={"form-control"}
                                        name={"LoginForm.email"}
                                        placeholder={""}
                                      ></TextInput>
                                    </DivBlock>

                                    <DivBlock className={"form-group"}>
                                      <Label>Email</Label>

                                      <TextInput
                                        className={"form-control"}
                                        name={"LoginForm.email"}
                                        placeholder={""}
                                      ></TextInput>
                                    </DivBlock>

                                    <DivBlock className={"form-group"}>
                                      <Label>Email</Label>

                                      <TextInput
                                        className={"form-control"}
                                        name={"LoginForm.email"}
                                        placeholder={""}
                                      ></TextInput>
                                    </DivBlock>

                                    <DivBlock className={"form-group"}>
                                      <Label>Email</Label>

                                      <TextInput
                                        className={"form-control"}
                                        name={"LoginForm.email"}
                                        placeholder={""}
                                      ></TextInput>
                                    </DivBlock>

                                    <DivBlock className={"form-group"}>
                                      <Label>Password</Label>

                                      <TextInput
                                        className={"form-control"}
                                        name={"LoginForm.password"}
                                        placeholder={""}
                                      ></TextInput>
                                    </DivBlock>

                                    <DivBlock
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        className={"btn btn-primary"}
                                        actions={[
                                          {
                                            action: "Update User",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {},
                                          },
                                        ]}
                                      >
                                        Update
                                      </Button>

                                      <DivBlock
                                        style={{ marginLeft: "0.25em" }}
                                      >
                                        <Button
                                          className={"btn btn-primary"}
                                          actions={[
                                            {
                                              action: "Set Edit User",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "Edit User": null,
                                              },
                                            },
                                          ]}
                                        >
                                          Cancel
                                        </Button>
                                      </DivBlock>
                                    </DivBlock>
                                  </FormBlock>

                                  <DivBlock className={"mt-4"}></DivBlock>
                                </DivBlock>
                              </DivBlock>
                            </Column>
                          </Columns>
                        </Then>

                        <Else>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            <thead>
                              <tr className={"thead-bg"}>
                                <th className={"th-border"}>
                                  <Span className={"th-span"}>Name</Span>
                                </th>

                                <th className={"th-border"}>
                                  <Span className={"th-span"} style={{}}>
                                    Position
                                  </Span>
                                </th>

                                <th className={"th-border"}>
                                  <Span className={"th-span"}>Action</Span>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {InjuredPlayers &&
                                isArray(InjuredPlayers) &&
                                InjuredPlayers.map((row, index) => {
                                  return (
                                    <Fragment>
                                      <tr>
                                        <td
                                          style={{
                                            borderBottomColor: "#000000",
                                            borderBottomStyle: "none",
                                            borderBottomWidth: "",
                                            borderLeftColor: "#000000",
                                            borderLeftStyle: "none",
                                            borderLeftWidth: "",
                                            borderRightColor: "#000000",
                                            borderRightStyle: "none",
                                            borderRightWidth: "",
                                            borderStyle: "none",
                                            borderTopColor: "#000000",
                                            borderTopStyle: "none",
                                            borderTopWidth: "",
                                            paddingBottom: "10px",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          <Span className={"table-span"}>
                                            {row["name"]}
                                          </Span>
                                        </td>

                                        <td
                                          style={{
                                            borderBottomColor: "#000000",
                                            borderBottomStyle: "none",
                                            borderBottomWidth: "",
                                            borderLeftColor: "#000000",
                                            borderLeftStyle: "none",
                                            borderLeftWidth: "",
                                            borderRightColor: "#000000",
                                            borderRightStyle: "none",
                                            borderRightWidth: "",
                                            borderStyle: "none",
                                            borderTopColor: "#000000",
                                            borderTopStyle: "none",
                                            borderTopWidth: "",
                                            paddingBottom: "10px",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          <Span className={"table-span"}>
                                            {row["abbreviation"]}
                                          </Span>
                                        </td>

                                        <td
                                          style={{
                                            borderBottomColor: "#000000",
                                            borderBottomStyle: "none",
                                            borderBottomWidth: "",
                                            borderLeftColor: "#000000",
                                            borderLeftStyle: "none",
                                            borderLeftWidth: "",
                                            borderRightColor: "#000000",
                                            borderRightStyle: "none",
                                            borderRightWidth: "",
                                            borderStyle: "none",
                                            borderTopColor: "#000000",
                                            borderTopStyle: "none",
                                            borderTopWidth: "",
                                            paddingBottom: "10px",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          <Span
                                            className={"btn table-span"}
                                            actions={[
                                              {
                                                action:
                                                  "Set Delete Injured Player",
                                                trigger: "onclick",
                                                timeout: "",
                                                pollingPeriod: "",
                                                inputVariables: {
                                                  "injured player id":
                                                    row["id"],
                                                },
                                              },
                                            ]}
                                          >
                                            Remove
                                          </Span>
                                        </td>
                                      </tr>
                                    </Fragment>
                                  );
                                })}
                            </tbody>
                          </table>
                        </Else>
                      </If>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>

            <If condition={NewInjuredPlayer ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "New Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input new position": false,
                        "input new injured": false,
                      },
                    },
                    {
                      action: "Edit Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input injured player": null,
                        "input position": null,
                      },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Add Injured Player
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "New Injured Player",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input injured player": false,
                                  "input new injured": false,
                                  "input new position": false,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create User",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Player</Label>

                              <SelectDropDown
                                className={"form-control"}
                                dataSet={FilterList({
                                  searchData: Users,
                                  property: "injured",
                                  query: false,
                                })}
                                dataSetLabel={"name"}
                                dataSetValue={"Id"}
                                enableReintialize={true}
                                initialValue={
                                  FilterList({
                                    searchData: Users,
                                    property: "injured",
                                    query: false,
                                  }).length > 0
                                    ? FilterList({
                                        searchData: Users,
                                        property: "injured",
                                        query: false,
                                      })[0].Id
                                    : 0
                                }
                                name={"FormInjuredPlayer.user id"}
                                options={[]}
                                type={"number"}
                              ></SelectDropDown>

                              <If
                                condition={
                                  FormInjuredPlayerErrors &&
                                  FormInjuredPlayerErrors["user id"]
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormInjuredPlayerErrors
                                      ? FormInjuredPlayerErrors["user id"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Create Injured Player",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              Submit
                            </Button>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={InjuredPlayerToDelete ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "Set New Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input new position": false },
                    },
                    {
                      action: "Edit Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input position": null },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Delete Injured Player
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Delete Injured Player",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input new row": false,
                                  "input position": null,
                                  "Depth Chart Id": null,
                                  "alias id input": null,
                                  "injured player id": null,
                                  "input edit depth chart": null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Are you sure you want to delete this injured
                                player?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Set Delete Injured Player",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "Depth Chart Id": null,
                                      "injured player id": null,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Delete Injured Player",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "depth chart id to delete":
                                        CurrentDepthChart
                                          ? CurrentDepthChart["Id"]
                                          : "",
                                      "input delete injured player id":
                                        InjuredPlayerToDelete,
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
export default connect(mapStateToProps, null)(InjuredPlayersPage);
