import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { Heading } from "../../../Typography";
import RightIcon from "../IconFilter/RightIcon";
import InnerAccordion from "./InnerAccordion";
import { apiUrl, qwikCutApiEndpoints } from "../../../../utils/constant";
import { qwikcutApi } from "../../../../utils/function";

const useStyles = makeStyles(() => ({
  accordionSummary: {
    flexDirection: "row-reverse",
  },
}));

const QwikcutPlayGames = ({ data, latestUpdatedValue, ...props }) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [innerExpand, setInnerExpand] = useState({});
  const [childrenData, setChildrenData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = () => {
    setExpand(!expand);
  };

  const handleInnerDataChange = (index) => {
    setInnerExpand((prevExpand) => ({
      ...prevExpand,
      [index]: !prevExpand[index],
    }));
  };

  const handleSeasonYear = (data) => {
    const qwikcutToken = localStorage.getItem("qwikcut_token");
    const qwikcutUserId = localStorage.getItem("qwikcut_userid");
    if (data?.children?.length > 0) {
      setChildrenData(data?.children);
    } else {
      setIsLoading(true);
      const userTeamSeasonScheduleOption = {
        api_url: qwikCutApiEndpoints.getUserTeamsSeasonSchedule
          .replace("{user_id}", qwikcutUserId)
          .replace("{team_id}", data?.team_id)
          .replace("{season_id}", data?.season_id),
        method_type: "GET",
        authorization: qwikcutToken,
      };
      qwikcutApi(apiUrl, userTeamSeasonScheduleOption).then(
        (userTeamSeasonSchedule) => {
          setChildrenData(userTeamSeasonSchedule?.value);
          setIsLoading(false);
        }
      );
    }
  };

  return (
    <div style={{ margin: "10px 0" }}>
      <Accordion key={data?.team_id} expanded={expand} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<RightIcon expand={expand} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.accordionSummary}
          onClick={() => handleSeasonYear(data)}
        >
          <Heading
            className="modal-heading"
            type={"h5"}
            style={{ paddingLeft: "10px" }}
          >
            {data?.name}
          </Heading>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <CircularProgress
              size={35}
              thickness={4}
              style={{
                display: ["flex", "-webkit-inline-box"],
                color: "#48c46e",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px auto",
              }}
            />
          ) : (
            <InnerAccordion
              value={childrenData}
              parentId={data?.team_id}
              handleInnerDataChange={handleInnerDataChange}
              innerExpand={innerExpand}
              latestUpdatedValue={latestUpdatedValue}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default QwikcutPlayGames;
