import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock } from "../modules/Basic";
import {
  FileUpload,
  FormBlock,
  Label,
  SelectDropDown,
  TextInput,
} from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { startPageTimer, trackPageDuration } from "../utils/function";

class FilesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
      // message:'',
    };
  }

  componentDidMount(){
    startPageTimer('Files');
    this.setState({ startTime: Date.now() });
  }

  componentWillUnmount(){
    trackPageDuration('Files',this.state.startTime)
  }

  render() {
    let {
      EditGameFile,
      FileToDelete,
      FormEditGameFileErrors,
      GameFiles,
      NavBarDropDownIndex,
    } = this.props;


const downloadTxtFile = (urlFile,filename) => {
  var FileSaver = require('file-saver');
  FileSaver.saveAs(urlFile, filename);
}
  

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Game Files",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 5 : 99,
              },
            },
            {
              action: "Get Support Page",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    backgroundColor: "#f8f8fb",
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                  actions={[
                    {
                      action: "Get Users",
                      trigger: "onload",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {},
                    },
                  ]}
                >
                  <DivBlock
                    actions={[
                      {
                        action: "Get Positions",
                        trigger: "onload",
                        timeout: "",
                        pollingPeriod: "",
                        inputVariables: {},
                      },
                    ]}
                  >
                    <DivBlock className={"body-main"}>
                      <DivBlock style={{}}>
                        <DivBlock
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Files
                          </Heading>

                          <Span className={"h2 h2-span"} style={{}}></Span>
                        </DivBlock>
                      </DivBlock>

                      <Columns>
                        <Column
                          lg={12}
                          md={12}
                          sm={12}
                          style={{ marginBottom: "1rem" }}
                          xs={12}
                        >
                          <DivBlock
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <FileUpload
                              FileName={"Filename"}
                              name={"gamefileupload"}
                              actions={[
                                {
                                  action: "Upload Game File",
                                  trigger: "onchange",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              <Button className={"btn btn-primary"}>
                                Upload Game File
                              </Button>
                            </FileUpload>
                          </DivBlock>
                        </Column>
                      </Columns>
                      <DivBlock className="table-responsive-sm"> 
                      <table
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr className={"thead-bg"}>
                            <th className={"th-border"}>
                              <Span className={"th-span"}>File</Span>
                            </th>

                            <th className={"th-border"}>
                              <Span className={"th-span"}>Action</Span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {GameFiles &&
                            isArray(GameFiles) &&
                            GameFiles.map((row, index) => {
                              return (
                                <Fragment key={index}>
                                  <tr>
                                    <td
                                      style={{
                                        paddingBottom: "10px",
                                        paddingLeft: "0.75em",
                                        paddingRight: "0.75em",
                                        paddingTop: "10px",
                                      }}
                                    >
                                        <DivBlock className="file-name" onClick={()=>downloadTxtFile(row.url,row["name"])}>
                                          <Span className={"table-span"}>
                                            {row["name"]}
                                          </Span>
                                        </DivBlock>
                                    </td>

                                    <td
                                      style={{
                                        paddingBottom: "10px",
                                        paddingLeft: "0.75em",
                                        paddingRight: "0.75em",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <DivBlock
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Button
                                          className={"btn table-span"}
                                          actions={[
                                            {
                                              action: "Edit Game File",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "input edit game file": row,
                                              },
                                            },
                                          ]}
                                        >
                                          Edit
                                        </Button>

                                        <DivBlock
                                          actions={[
                                            {
                                              action: "",
                                              trigger: "",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {},
                                            },
                                          ]}
                                        >
                                          <Button
                                            className={"btn table-span"}
                                            actions={[
                                              {
                                                action: "Set Delete Game File",
                                                trigger: "onclick",
                                                timeout: "",
                                                pollingPeriod: "",
                                                inputVariables: {
                                                  gamefileid: row["Id"],
                                                },
                                              },
                                            ]}
                                          >
                                            Delete
                                          </Button>
                                        </DivBlock>
                                      </DivBlock>
                                    </td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>

            <If condition={EditGameFile ? true : false} show={false}>
              <Then>
                <DivBlock className={"modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Update Game File
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Edit Game File",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input edit game file": null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Position",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Name</Label>

                              <TextInput
                                className={"form-control"}
                                initialValue={
                                  EditGameFile ? EditGameFile["name"] : ""
                                }
                                name={"FormEditGameFile.name"}
                                placeholder={""}
                              ></TextInput>

                              <If
                                condition={
                                  FormEditGameFileErrors &&
                                  FormEditGameFileErrors["name"]
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormEditGameFileErrors &&
                                    FormEditGameFileErrors["name"]
                                      ? FormEditGameFileErrors["name"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Shared</Label>

                              <SelectDropDown
                                className={"form-control"}
                                initialValue={
                                  EditGameFile && EditGameFile["shared"]
                                    ? "true"
                                    : "false"
                                }
                                name={"FormEditGameFile.shared"}
                                options={[
                                  { label: "Shared", value: "true" },
                                  { label: "Unshared", value: "false" },
                                ]}
                                type={"boolean"}
                              ></SelectDropDown>

                              <If
                                condition={
                                  FormEditGameFileErrors &&
                                  FormEditGameFileErrors["shared"]
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormEditGameFileErrors &&
                                    FormEditGameFileErrors["shared"]
                                      ? FormEditGameFileErrors["shared"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Update Game File",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              Submit
                            </Button>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={FileToDelete ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Delete Game File
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Delete Game File",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  gamefileid: null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Are you sure you want to delete this game file?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Set Delete Game File",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      gamefileid: null,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Delete Game File",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "input game file id": FileToDelete,
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(FilesPage);
