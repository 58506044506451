import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock, LinkBlock } from "../modules/Basic";
import { Column, Columns } from "../modules/Layout";
import { Heading, Paragraph, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { startPageTimer, superAdmin, trackPageDuration } from "../utils/function";
import CustomProductionPoint from "../modules/Forms/components/CustomDropDown/CustomProductionPoint";
import { If, Then } from "../modules/Logic";
import { FormBlock, Label } from "../modules/Forms";

class ProductionPointPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
    };
  }

  componentDidMount() {
    startPageTimer("Intangibles");
    this.setState({ startTime: Date.now() });
    this.props?.getProductionPointData();
    this.props?.getSchoolProductionPointData();
    if (!this.props?.Positions) {
      this.props?.getPositionsData();
    }
  }

  componentDidUpdate(previousProps, previousState) {
    const { dispatch } = this.props;
    if (previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear) {
      dispatch({ type: "LOADING_EVENT", inputVariables: { loader: true } });
      this.props.getProductionPointData();
      dispatch({ type: "LOADING_EVENT", inputVariables: { loader: false } });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    trackPageDuration("Production Point", this.state.startTime);
    dispatch({ type: "REMOVE_PRODUCTION_POINT_VALUES" });
  }
  render() {
    let {
      NavBarDropDownIndex,
      productionPoints,
      Positions,
      productionPointsData,
      productionPointToDelete,
      productionPointToDeleteData,
      CurrentUser
    } = this.props;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 2 : 2,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent />
            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    backgroundColor: "#f8f8fb",
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock style={{ height: "100%" }}>
                    <DivBlock className={"body-main"}>
                      <DivBlock style={{}}>
                        <DivBlock
                          className="game_title"
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Manage Production Points
                          </Heading>

                          {/* <Span className={"h2 h2-span"} style={{}}>
                            Grading /
                          </Span>

                          <Span className={"h2 h2-inner-span"} style={{}}>
                            Intangibles
                          </Span> */}
                        </DivBlock>
                        <Columns
                          style={{
                            backgroundColor: "#ffffff",
                            fontSize: "0px",
                            marginBottom: "10px",
                          }}
                        >
                          <Column
                            lg={12}
                            md={12}
                            sm={12}
                            style={{
                              fontFamily: "'Poppins', sans-serif",
                              marginBottom: "1rem",
                              marginLeft: "10px",
                              marginRight: "0px",
                              marginTop: "15px",
                              paddingBottom: "0px",
                              paddingTop: "0px",
                            }}
                            xs={12}
                          >
                            <DivBlock
                              style={{
                                alignItems: "center",
                                display: "flex",
                                fontSize: "13px",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Paragraph
                                style={{
                                  fontSize: "14px",
                                  marginBottom: 0,
                                  marginLeft: 0,
                                  marginRight: "5px",
                                  marginTop: 0,
                                }}
                              >
                                Click the checkbox to assign an production point
                                to a position group. These will be used for
                                purposes of grading players on a play-by-play
                                basis.
                              </Paragraph>
                            </DivBlock>
                          </Column>                              
                          {superAdmin?.includes(CurrentUser["email"]) ? 
                          <DivBlock
                            className={"addbtn-div"}
                            df-snippet-instance-id={301}
                          >
                            <LinkBlock
                              df-snippet-instance-id={301}
                              duration={1000}
                              href={"/add_production_point"}
                              target={"_self"}
                              type={"spa"}
                            >
                              <Button
                                className={"btn btn-primary Depthchart-div"}
                                df-snippet-instance-id={301}
                                // disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                                // style={
                                //   SeasonYearData?.[0]?.year === selectedSeasonYear?
                                //   {backgroundColor: "#48c46e",
                                //   borderColor: "#48c46e",
                                //   fontWeight:500,
                                //   marginLeft:'15px',}:
                                //   {backgroundColor: "#a0d2a0",
                                //   borderColor: "#a0d2a0",
                                //   fontWeight:500,
                                //   marginLeft:'15px'}
                                // }
                              >
                                Add New Production Point
                              </Button>
                            </LinkBlock>
                          </DivBlock> : null}
                        </Columns>
                        {productionPoints?.length > 0 && (
                          <CustomProductionPoint
                            ProudctionPointValueMap={productionPoints}
                            AllPositions={Positions}
                            CheckProduction={productionPointsData}
                          />
                        )}
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
            <If condition={productionPointToDelete ? true : false} show={true}>
              <Then>
                <DivBlock className={"modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading className="modal-heading" type={"h5"}>
                            Delete Production Point
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "SET_DELETE_PRODUCTION_POINT",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  idd: null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Are you sure you want to delete this production
                                point?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "SET_DELETE_PRODUCTION_POINT",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      idd: null,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "ADD_SCHOOL_PRODUCTION_POINT",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      listPayload: productionPointToDeleteData,
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProductionPointData: () => {
      dispatch({ type: "GET_PRODUCTION_POINT" });
      return Promise.resolve();
    },
    getSchoolProductionPointData: () => {
      dispatch({ type: "GET_SCHOOL_PRODUCTION_POINT" });
      return Promise.resolve();
    },
    getPositionsData: () => {
      dispatch({ type: "GET_POSITIONS" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionPointPage);
