import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { connect } from "react-redux";
import { getColorByValue } from "../../utils/function";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

class BarChartLine extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  defaultDatalabelsOptions = {
    align: "end",
    anchor: "start",
    offset: 2,
    display: (context) => context.dataset.label === "Value",
    color: 'black',
    font: {
      size: 14,
      weight: 600,
      family: "Poppins",
    },
  };

  render() {
    const {
      chartLabels,
      chartData,
      chartTitle,
      isSkipYaxis,
      maintainAspectRatio,
      lengthData,
      barBorderRadius,
      datalabelsOptions,
    } = this.props;

    const options = {
      borderRadius: 10,
      indexAxis: "y",
      maintainAspectRatio: maintainAspectRatio,
      barPercentage: 1.1,
      scales: {
        x: {
          beginAtZero: true,
          max: 100,
          position: "bottom",
          ticks: {
            color: "black",
            font: {
              size: 12,
              // weight: "bold",
              family: "Arial",
            },
            stepSize: 5,
          },
          grid: {
            lineWidth: 1,
            borderDash: [2, 2],
            drawBorder: false,
            drawOnChartArea: false,
            drawTicks: true,
            tickColor: "black",
          },
        },
        x2: {
          beginAtZero: true,
          max: 100,
          position: "top",
          ticks: {
            color: "black",
            font: {
              size: 12,

              family: "Arial",
            },
            stepSize: 5,
            // callback: function(value) {
            //   return value + '%';
            // }
          },
          grid: {
            lineWidth: 1,
            borderDash: [2, 2],

            drawBorder: false,
            drawOnChartArea: false,

            tickColor: "black",
          },
        },
        y: {
          barPercentage: 0.5,
          ticks: {
            color: "#333",
            font: {
              size: 12,
              weight: "bold",
              family: "Arial",
            },
          },
          grid: {
            display: false,
          },
        },
      },
      elements: {
        bar: {
          borderWidth: 2,
          borderRadius: barBorderRadius || 0,
        },
      },
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: chartTitle || null,
        },
        legend: {
          display: false,
        },
        tooltip: {
          filter: (tooltipItem) => {
            return tooltipItem.dataset.label !== "Remaining";
          },
          callbacks: {
            label: (context) => {
              if (context.dataset.label === "Remaining") {
                return "";
              }

              const value = context.raw;
              return ` ${value}%`;
            },
          },
        },
        datalabels: {
          ...this.defaultDatalabelsOptions,
        },
      },
    };

    const data = {
      labels: chartLabels,
      datasets: [
        {
          label: "Value",
          data: chartData,
          backgroundColor: (context) => getColorByValue(context.raw),
          hoverBackgroundColor: (context) => getColorByValue(context.raw),
          stack: "stack1",
          borderWidth: 0,
        },
        {
          label: "Remaining",
          data: chartData.map((item) => 100 - item),
          backgroundColor: "#e0e0e0",
          hoverBackgroundColor: "#e0e0e0",
          stack: "stack1",
          borderWidth: 0,
        },
      ],
    };

    const isCheck = (value) => {
      if (value !== 1 && value !== 2 && value !== 3) {
        return value * 47;
      } else if (value === 1) {
        return "100px";
      } else if (value === 2) {
        return "160px";
      } else if (value === 3) {
        return "220px";
      }
    };

    return (
      <div
        style={{
          height: options.maintainAspectRatio
            ? null
            : lengthData
            ? isCheck(lengthData)
            : "700px",
        }}
      >
        <Bar options={options} data={data} />
      </div>
    );
  }
}

BarChartLine.propTypes = {
  chartLabels: PropTypes.array,
  chartData: PropTypes.array,
  chartTitle: PropTypes.string,
  isSkipYaxis: PropTypes.bool,
  maintainAspectRatio: PropTypes.bool,
  lengthData: PropTypes.number,
  barBorderRadius: PropTypes.number,
  datalabelsOptions: PropTypes.object.isRequired,
};

BarChartLine.defaultProps = {
  chartLabels: [],
  chartData: [],
  chartTitle: "",
  isSkipYaxis: false,
  maintainAspectRatio: false,
  lengthData: 0,
  barBorderRadius: 0,
  datalabelsOptions: {},
};

export default connect()(BarChartLine);
