export function makeRequest(url, method, payload){
    let fetchOptions = {
        method: method,
        // credentials: 'include',
        headers: {}
    };

    const baseUrl = process.env.REACT_APP_BASE_URL;

    let finalUrl = `${baseUrl}${url}`;


    fetchOptions.headers["Authorization"]= `Bearer ${localStorage.getItem("token")}`
    if(payload) {

        // Add headers.
        if(payload.header_variables) {
            fetchOptions.headers = payload.header_variables;
        }

         // Add the body variables.
        if(payload.body_variables) {
            if(payload.body_variables instanceof FormData) {
                fetchOptions.body = payload.body_variables;
            } else if(Object.keys(payload.body_variables).length > 0) {
                fetchOptions.headers['Content-Type'] = 'application/json';
                fetchOptions.body = JSON.stringify(payload.body_variables);
            }
        }

        // Add the path variables.
        if(payload.path_variables && Object.keys(payload.path_variables).length > 0) {
            for (const [field, value] of Object.entries(payload.path_variables)) {
                finalUrl = finalUrl.replaceAll(`{${field}}`, value);
            }
        }

        // Add the query variables.
        if(payload.query_variables && Object.keys(payload.query_variables).length > 0) {
            finalUrl += "?" + new URLSearchParams(payload.query_variables).toString();
        }
    }

    return fetch(finalUrl, fetchOptions)
        .then(response => {
            return response.json()
                .then(responseText => {
                    // console.log('window.location :>> ', window.location);
                    if (response?.status === 401) {
                        localStorage.clear();
                        localStorage.setItem("previousRoute",window.location.hash)
                        window.location.href = "/";
                    }
                    return {
                        isSuccess:response?.ok,
                        data: responseText.data ? responseText.data : responseText,
                        status: response.status,
                        message:response?.message
                    };
                }).catch(err => {
                    console.log('err', err)
                    return err
                });
        }).catch(error => {
            console.log('JSON', error)
            return JSON.stringify(error)
        });
}