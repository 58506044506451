import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock } from "../modules/Basic";
import { FormBlock, Label, TextInput } from "../modules/Forms";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";

class DepthChartPositionUsersPage extends Component {
  render() {
    let { DepthChartPositionUsers, NewExercise, NewIntangible } = this.props;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Depth Chart Position Users",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                className={"sidenav"}
                context={{
                  elements: {
                    1849: { type: "Body" },
                    1850: { type: "DivBlock" },
                    1851: { type: "DivBlock" },
                    1862: { type: "DivBlock" },
                    1863: { type: "DivBlock" },
                    1925: { type: "DivBlock" },
                    1926: { type: "DivBlock" },
                    1927: { type: "DivBlock" },
                    1928: { type: "DivBlock" },
                    1929: { type: "Heading" },
                    1930: { type: "DivBlock" },
                    1931: { type: "DivBlock" },
                    1932: { type: "DivBlock" },
                    1933: { type: "DivBlock" },
                    1934: { type: "Span" },
                    1935: { type: "Span" },
                    1936: { type: "If" },
                    1937: { type: "Then" },
                    1938: { type: "DivBlock" },
                    1939: { type: "DivBlock" },
                    1940: { type: "Span" },
                    1941: { type: "DivBlock" },
                    1942: { type: "DivBlock" },
                    1943: { type: "Span" },
                    1944: { type: "Span" },
                    1945: { type: "If" },
                    1946: { type: "Then" },
                    1947: { type: "DivBlock" },
                    1948: { type: "DivBlock" },
                    1949: { type: "DivBlock" },
                    1950: { type: "Span" },
                    1951: { type: "table" },
                    1952: { type: "thead" },
                    1953: { type: "tr" },
                    1954: { type: "th" },
                    1955: { type: "Span" },
                    1956: { type: "Span" },
                    1957: { type: "th" },
                    1958: { type: "Span" },
                    1959: { type: "Span" },
                    1960: { type: "th" },
                    1961: { type: "Span" },
                    1962: { type: "th" },
                    1963: { type: "Span" },
                    1964: { type: "tbody" },
                    1965: { type: "tr" },
                    1966: { type: "td" },
                    1967: { type: "Span" },
                    1968: { type: "td" },
                    1969: { type: "Span" },
                    1970: { type: "td" },
                    1971: { type: "Span" },
                    1972: { type: "td" },
                    1973: { type: "Span" },
                    1974: { type: "td" },
                    1975: { type: "Button" },
                    1976: { type: "DivBlock" },
                    1977: { type: "DivBlock" },
                    1978: { type: "DivBlock" },
                    1979: { type: "DivBlock" },
                    1980: { type: "Span" },
                    1981: { type: "Span" },
                    1982: { type: "MultipleDataGraph" },
                    1984: { type: "If" },
                    2208: { type: "List" },
                    2209: { type: "ListItem" },
                    2210: { type: "LinkBlock" },
                    2211: { type: "DivBlock" },
                    2212: { type: "Span" },
                    2213: { type: "ListItem" },
                    2214: { type: "DivBlock" },
                    2215: { type: "Span" },
                    2216: { type: "Span" },
                    2217: { type: "DivBlock" },
                    2218: { type: "LinkBlock" },
                    2219: { type: "DivBlock" },
                    2220: { type: "Span" },
                    2221: { type: "LinkBlock" },
                    2222: { type: "DivBlock" },
                    2223: { type: "Span" },
                    2224: { type: "ListItem" },
                    2225: { type: "LinkBlock" },
                    2226: { type: "DivBlock" },
                    2227: { type: "Span" },
                    2228: { type: "ListItem" },
                    2229: { type: "DivBlock" },
                    2230: { type: "Span" },
                    2231: { type: "Span" },
                    2232: { type: "DivBlock" },
                    2233: { type: "LinkBlock" },
                    2235: { type: "DivBlock" },
                    2236: { type: "LinkBlock" },
                    2237: { type: "DivBlock" },
                    2238: { type: "Span" },
                    2239: { type: "LinkBlock" },
                    2240: { type: "DivBlock" },
                    2241: { type: "Span" },
                    2242: { type: "LinkBlock" },
                    2243: { type: "DivBlock" },
                    2244: { type: "Span" },
                    2245: { type: "ListItem" },
                    2246: { type: "DivBlock" },
                    2247: { type: "Span" },
                    2248: { type: "Span" },
                    2249: { type: "DivBlock" },
                    2250: { type: "LinkBlock" },
                    2251: { type: "DivBlock" },
                    2252: { type: "Span" },
                    2253: { type: "LinkBlock" },
                    2254: { type: "DivBlock" },
                    2255: { type: "Span" },
                    2256: { type: "ListItem" },
                    2257: { type: "LinkBlock" },
                    2258: { type: "DivBlock" },
                    2259: { type: "Span" },
                    2280: { type: "Span" },
                  },
                  getters: {},
                  listeners: [
                    { name: "mouseOver.boundaries" },
                    { name: "mouseOut.boundaries" },
                    { name: "dragEnter" },
                    { name: "dragLeave" },
                    { name: "dragStart" },
                    { name: "dragEnd" },
                    { name: "mouseDown.mousemenu" },
                    { name: "showSettingsBox.desk" },
                    { name: "textChanged.desk" },
                    { name: "editTextMode.desk" },
                    { name: "componentClick.desk" },
                    { name: "pathnameChanged.desk" },
                    { name: "cut.desk" },
                    { name: "copy.desk" },
                    { name: "editSnippet.desk" },
                    { name: "clone.desk" },
                    { name: "delete.desk" },
                    { name: "before.desk" },
                    { name: "after.desk" },
                    { name: "first.desk" },
                    { name: "last.desk" },
                    { name: "replace.desk" },
                    { name: "select.desk" },
                    { name: "highlight.desk" },
                    { name: "dropZoneHover" },
                    { name: "onDragEnd" },
                    { name: "copyStyleProps" },
                    { name: "pasteStyleProps" },
                    { name: "updateSelectedProps" },
                  ],
                  triggerCache: {
                    after: [{ name: "after.desk" }],
                    before: [{ name: "before.desk" }],
                    componentClick: [{ name: "componentClick.desk" }],
                    cut: [{ name: "cut.desk" }],
                    dragEnd: [{ name: "dragEnd" }],
                    dragEnter: [{ name: "dragEnter" }],
                    dragLeave: [{ name: "dragLeave" }],
                    dragStart: [{ name: "dragStart" }],
                    dropZoneHover: [{ name: "dropZoneHover" }],
                    editSnippet: [{ name: "editSnippet.desk" }],
                    editTextMode: [{ name: "editTextMode.desk" }],
                    first: [{ name: "first.desk" }],
                    mouseDown: [{ name: "mouseDown.mousemenu" }],
                    mouseOut: [{ name: "mouseOut.boundaries" }],
                    mouseOver: [{ name: "mouseOver.boundaries" }],
                    onDragEnd: [{ name: "onDragEnd" }],
                    pathnameChanged: [{ name: "pathnameChanged.desk" }],
                    textChanged: [{ name: "textChanged.desk" }],
                  },
                }}
                data-snippet-id={298}
                df-snippet-instance-id={298}
                draggable={true}
                eleKey={"1863"}
                inline={{ "df-snippet-instance-id": 298, draggable: true }}
                isEdit={true}
                root-snippet={true}
                style={{}}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <Heading className={"h2"} type={"h1"}>
                        {DepthChartPositionUsers
                          ? DepthChartPositionUsers["name"]
                          : ""}
                      </Heading>

                      <table
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr className={"thead-bg"}>
                            <th className={"th-border"}>
                              <Span className={"th-span"}>Rank</Span>
                            </th>

                            <th className={"th-border"}>
                              <Span className={"th-span"}>Name</Span>
                            </th>

                            <th className={"th-border"}>
                              <Span className={"th-span"}>Action</Span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {(DepthChartPositionUsers
                            ? DepthChartPositionUsers["positions"]
                            : []) &&
                            isArray(
                              DepthChartPositionUsers
                                ? DepthChartPositionUsers["positions"]
                                : []
                            ) &&
                            (DepthChartPositionUsers
                              ? DepthChartPositionUsers["positions"]
                              : []
                            ).map((row, index) => {
                              return (
                                <Fragment>
                                  <tr>
                                    <td
                                      style={{
                                        borderBottomColor: "#000000",
                                        borderBottomStyle: "none",
                                        borderBottomWidth: "",
                                        borderLeftColor: "#000000",
                                        borderLeftStyle: "none",
                                        borderLeftWidth: "",
                                        borderRightColor: "#000000",
                                        borderRightStyle: "none",
                                        borderRightWidth: "",
                                        borderStyle: "none",
                                        borderTopColor: "#000000",
                                        borderTopStyle: "none",
                                        borderTopWidth: "",
                                        paddingBottom: "0.375rem",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "0.375rem",
                                      }}
                                    >
                                      <Span className={"table-span"}>
                                        {row["rank"]}
                                      </Span>
                                    </td>

                                    <td
                                      style={{
                                        borderBottomColor: "#000000",
                                        borderBottomStyle: "none",
                                        borderBottomWidth: "",
                                        borderLeftColor: "#000000",
                                        borderLeftStyle: "none",
                                        borderLeftWidth: "",
                                        borderRightColor: "#000000",
                                        borderRightStyle: "none",
                                        borderRightWidth: "",
                                        borderStyle: "none",
                                        borderTopColor: "#000000",
                                        borderTopStyle: "none",
                                        borderTopWidth: "",
                                        paddingBottom: "0.375rem",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "0.375rem",
                                      }}
                                    >
                                      <Span className={"table-span"}>
                                        {row["name"]}
                                      </Span>
                                    </td>

                                    <td
                                      style={{
                                        borderBottomColor: "#000000",
                                        borderBottomStyle: "none",
                                        borderBottomWidth: "",
                                        borderLeftColor: "#000000",
                                        borderLeftStyle: "none",
                                        borderLeftWidth: "",
                                        borderRightColor: "#000000",
                                        borderRightStyle: "none",
                                        borderRightWidth: "",
                                        borderStyle: "none",
                                        borderTopColor: "#000000",
                                        borderTopStyle: "none",
                                        borderTopWidth: "",
                                        paddingBottom: "0.375rem",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "0.375rem",
                                      }}
                                    >
                                      <Span
                                        className={"btn table-span"}
                                        actions={[
                                          {
                                            action: "Delete Depth Chart User",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              "input depth chart user id":
                                                row["id"],
                                            },
                                          },
                                        ]}
                                      >
                                        Delete
                                      </Span>
                                    </td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>

                      <If
                        condition={
                          DepthChartPositionUsers &&
                          DepthChartPositionUsers.row_id > 0
                            ? true
                            : false
                        }
                        show={true}
                        style={{ marginTop: "10px" }}
                      >
                        <Then>
                          <Button
                            className={"btn btn-primary"}
                            actions={[
                              {
                                action: "Delete Depth Chart Row User",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            Delete Row
                          </Button>
                        </Then>
                      </If>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>

            <If condition={NewExercise ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "New Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input new injured": false,
                        "input new position": false,
                      },
                    },
                    {
                      action: "Edit Injured Player",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input injured player": null,
                        "input position": null,
                      },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            New Exercise
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "New Exercise",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input new exercise": false,
                                  "input new intangible":
                                    NewIntangible !== true,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create User",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock className={"form-group"}>
                              <Label>Name</Label>

                              <TextInput
                                className={"form-control"}
                                name={"FormNewExercise.name"}
                                placeholder={""}
                              ></TextInput>
                            </DivBlock>

                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Create Exercise",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              Submit
                            </Button>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(DepthChartPositionUsersPage);
