import React from "react";
import { connect } from "react-redux";
import { DivBlock } from "../Basic";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loader = (props) => {
  const classes = useStyles();
    const {isLoaded} = props;
    return (
      <DivBlock>
        <Backdrop style={{zIndex: 11, visibility: "inherit", opacity: 1}} className={classes.backdrop} open={isLoaded}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </DivBlock>
    );
}

const mapStateToProps = function (state) {
  return state.reducer;
};
export default connect(mapStateToProps, null)(Loader);
