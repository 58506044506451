import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock } from "../modules/Basic";
import { FormBlock, Label } from "../modules/Forms";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { startPageTimer, trackPageDuration } from "../utils/function";

class ManageTagsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
      // message:'',
    };
  }

  componentDidMount() {
    startPageTimer("Manage Tags");
    this.setState({ startTime: Date.now() });
  }

  componentDidUpdate(previousProps, previousState) {
    const { dispatch } = this.props;
    if (previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear) {
      dispatch({ type: "LOADING_EVENT", inputVariables: { loader: true } });
      this.props.getTagsData();
      // dispatch({ type: "LOADING_EVENT",inputVariables:{loader:false} });
    }
  }

  componentWillUnmount() {
    trackPageDuration("Manage Tags", this.state.startTime);
  }
  render() {
    let {
      AllTags,
      CurrentDepthChart,
      NavBarDropDownIndex,
      TagToDelete,
      SeasonYearData,
      selectedSeasonYear,
    } = this.props;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Tags",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 0 : 0,
              },
            },
          ]}
        >
          <DivBlock
            context={{
              elements: {
                7303: { type: "Body" },
                7304: { type: "DivBlock" },
                7738: { type: "DivBlock" },
                7749: { type: "DivBlock" },
                7750: { type: "DivBlock" },
                7812: { type: "DivBlock" },
                7813: { type: "DivBlock" },
                7814: { type: "DivBlock" },
                7815: { type: "DivBlock" },
                7816: { type: "DivBlock" },
                7817: { type: "DivBlock" },
                7818: { type: "Heading" },
                7819: { type: "Span" },
                7820: { type: "Span" },
                7821: { type: "Columns" },
                7822: { type: "Column" },
                7823: { type: "DivBlock" },
                7824: { type: "Button" },
                7825: { type: "table" },
                7826: { type: "thead" },
                7827: { type: "tr" },
                7828: { type: "th" },
                7829: { type: "Span" },
                7830: { type: "th" },
                7831: { type: "Span" },
                7832: { type: "tbody" },
                7833: { type: "tr" },
                7834: { type: "td" },
                7835: { type: "Span" },
                7836: { type: "td" },
                7837: { type: "Span" },
                7838: { type: "If" },
              },
              getters: {},
              listeners: [
                { name: "mouseOver.boundaries" },
                { name: "mouseOut.boundaries" },
                { name: "dragEnter" },
                { name: "dragLeave" },
                { name: "dragEnd" },
                { name: "mouseDown.mousemenu" },
                { name: "showSettingsBox.desk" },
                { name: "textChanged.desk" },
                { name: "editTextMode.desk" },
                { name: "componentClick.desk" },
                { name: "pathnameChanged.desk" },
                { name: "cut.desk" },
                { name: "copy.desk" },
                { name: "create_prefab.desk" },
                { name: "create_partial.desk" },
                { name: "editSnippet.desk" },
                { name: "clone.desk" },
                { name: "delete.desk" },
                { name: "before.desk" },
                { name: "after.desk" },
                { name: "first.desk" },
                { name: "last.desk" },
                { name: "replace.desk" },
                { name: "select.desk" },
                { name: "highlight.desk" },
                { name: "dropZoneHover" },
                { name: "dragEnd" },
                { name: "dragStart" },
                { name: "copyStyleProps" },
                { name: "pasteStyleProps" },
                { name: "updateSelectedProps" },
                { name: "dragStart" },
              ],
              triggerCache: {
                after: [{ name: "after.desk" }],
                componentClick: [{ name: "componentClick.desk" }],
                create_partial: [{ name: "create_partial.desk" }],
                editSnippet: [{ name: "editSnippet.desk" }],
                editTextMode: [{ name: "editTextMode.desk" }],
                last: [{ name: "last.desk" }],
                mouseDown: [{ name: "mouseDown.mousemenu" }],
                mouseOut: [{ name: "mouseOut.boundaries" }],
                mouseOver: [{ name: "mouseOver.boundaries" }],
                pathnameChanged: [{ name: "pathnameChanged.desk" }],
                replace: [{ name: "replace.desk" }],
                "showSettingsBox.desk": [{ name: "showSettingsBox.desk" }],
                textChanged: [{ name: "textChanged.desk" }],
              },
            }}
            df-snippet-instance-id={457}
            draggable={true}
            eleKey={"7304"}
            inline={{ "df-snippet-instance-id": 457, draggable: true }}
            isEdit={true}
            root-snippet={true}
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock
              className={"body-container"}
              df-snippet-instance-id={457}
              style={{}}
            >
              <SideNavBarComponent
                data-snippet-id={298}
                df-snippet-instance-id={457}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                df-snippet-instance-id={457}
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  df-snippet-instance-id={457}
                  style={{
                    backgroundColor: "#f8f8fb",
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock
                    df-snippet-instance-id={457}
                    style={{ backgroundColor: "#f8f8fb" }}
                  >
                    <DivBlock
                      className={"body-main"}
                      df-snippet-instance-id={457}
                    >
                      <DivBlock df-snippet-instance-id={457} style={{}}>
                        <DivBlock
                          df-snippet-instance-id={457}
                          className="game_title"
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading
                            className={"h2"}
                            df-snippet-instance-id={457}
                            style={{}}
                            type={"h2"}
                          >
                            Manage Tags
                          </Heading>

                          <Span
                            className={"h2 h2-span"}
                            df-snippet-instance-id={457}
                            style={{}}
                          >
                            Depth Charts /
                          </Span>

                          <Span
                            className={"h2 h2-inner-span"}
                            df-snippet-instance-id={457}
                            style={{}}
                          >
                            Manage Tags
                          </Span>
                        </DivBlock>
                      </DivBlock>

                      <table
                        df-snippet-instance-id={457}
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <thead df-snippet-instance-id={457}>
                          <tr
                            className={"thead-bg"}
                            df-snippet-instance-id={457}
                          >
                            <th
                              className={"th-border"}
                              df-snippet-instance-id={457}
                            >
                              <Span
                                className={"th-span"}
                                df-snippet-instance-id={457}
                              >
                                Name
                              </Span>
                            </th>
                            {SeasonYearData?.[0]?.year ===
                            selectedSeasonYear ? (
                              <th
                                className={"th-border"}
                                df-snippet-instance-id={457}
                              >
                                <Span
                                  className={"th-span"}
                                  df-snippet-instance-id={457}
                                >
                                  Action
                                </Span>
                              </th>
                            ) : null}
                          </tr>
                        </thead>

                        <tbody df-snippet-instance-id={457}>
                          {AllTags &&
                            isArray(AllTags) &&
                            AllTags.map((row, index) => {
                              return (
                                <Fragment>
                                  <tr df-snippet-instance-id={457}>
                                    <td
                                      df-snippet-instance-id={457}
                                      style={{
                                        paddingBottom: "10px",
                                        paddingLeft: "0.75em",
                                        paddingRight: "0.75em",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <Span
                                        className={"table-span"}
                                        df-snippet-instance-id={457}
                                      >
                                        {row["tag"]}
                                      </Span>
                                    </td>

                                    {SeasonYearData?.[0]?.year ===
                                    selectedSeasonYear ? (
                                      <td
                                        df-snippet-instance-id={457}
                                        style={{
                                          paddingBottom: "10px",
                                          paddingLeft: "0.75em",
                                          paddingRight: "0.75em",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <Span
                                          className={"btn table-span"}
                                          df-snippet-instance-id={457}
                                          actions={[
                                            {
                                              action: "Set Delete Tag",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "input delete injured player id":
                                                  row["id"],
                                                "input delete intangible id":
                                                  row["Id"],
                                                "input delete tag id":
                                                  row["Id"],
                                                "tag id": row["Id"],
                                                "input delete exercise id":
                                                  row["Id"],
                                              },
                                            },
                                          ]}
                                        >
                                          Delete
                                        </Span>
                                      </td>
                                    ) : null}
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>

            <If
              condition={TagToDelete ? true : false}
              df-snippet-instance-id={457}
              show={false}
            >
              <Then df-snippet-instance-id={457}>
                <DivBlock
                  className={"modal"}
                  df-snippet-instance-id={457}
                  actions={[
                    {
                      action: "Set New Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input new position": false },
                    },
                    {
                      action: "Edit Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input position": null },
                    },
                  ]}
                >
                  <DivBlock
                    className={"modal-dialog"}
                    df-snippet-instance-id={457}
                  >
                    <DivBlock
                      className={"modal-dialog"}
                      df-snippet-instance-id={457}
                    >
                      <DivBlock
                        className={"modal-content"}
                        df-snippet-instance-id={457}
                      >
                        <DivBlock
                          className={"modal-header"}
                          df-snippet-instance-id={457}
                        >
                          <Heading
                            df-snippet-instance-id={457}
                            className="modal-heading"
                            type={"h5"}
                          >
                            Delete Tag
                          </Heading>

                          <Span
                            className={"close"}
                            df-snippet-instance-id={457}
                            actions={[
                              {
                                action: "Set Delete Tag",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "alias id input": null,
                                  "input edit depth chart": null,
                                  "input new row": false,
                                  "input position": null,
                                  "tag id": null,
                                  "Depth Chart Id": null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock
                          className={"modal-body"}
                          df-snippet-instance-id={457}
                        >
                          <FormBlock
                            df-snippet-instance-id={457}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock df-snippet-instance-id={457}>
                              <Label
                                className={"label"}
                                df-snippet-instance-id={457}
                              >
                                Are you sure you want to delete this tag?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              df-snippet-instance-id={457}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                df-snippet-instance-id={457}
                                actions={[
                                  {
                                    action: "Set Delete Tag",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "Depth Chart Id": null,
                                      "tag id": null,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                df-snippet-instance-id={457}
                                actions={[
                                  {
                                    action: "Delete Tag",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "depth chart id to delete":
                                        CurrentDepthChart
                                          ? CurrentDepthChart["Id"]
                                          : "",
                                      "input delete tag id": TagToDelete,
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTagsData: () => {
      dispatch({ type: "GET_TAGS", inputVariables: { isDefault: true } });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageTagsPage);
