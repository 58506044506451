import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { checkForOnClick, onClickTrigger, onLoadTrigger } from "../../../helpers";
import { connect } from "react-redux";

class Heading extends PureComponent { // eslint-disable-line react/prefer-stateless-function
    componentWillMount() {
        const { actions, dispatch } = this.props;
        onLoadTrigger(actions, dispatch);
    }

    onClick = (e) => {
        const { actions, dispatch } = this.props;
        checkForOnClick(actions, dispatch) && onClickTrigger(actions, dispatch);
    }

    render() {
        const { children, style, type, className, isEdit, id, inline } = this.props;
        const Tag = `${type}`;

        switch (type) {
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
            case 'p':
                return (
                    <Tag {...{ className, id, style }} {...inline} onClick={!isEdit ? this.onClick : null} ref={inline ? inline?.innerRef : null}>
                        {children}
                    </Tag>
                );
            default:
                return (
                    <h1 {...{ className, id, style }} {...inline} onClick={this.onClick} {...inline} ref={inline ? inline?.innerRef : null}>
                        {children}
                    </h1>
                );
        } // eslint-disable-line
    }
}

Heading.propTypes = {
    style: PropTypes.object,
    type: PropTypes.string,
};
Heading.defaultProps = {
    style: {},
    type: 'h1',
};

export default connect()(Heading);
